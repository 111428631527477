import * as Types from "../constants";

// maintenance actions
export function createMaintenanceNote(data) {
    return {
        type: Types.CREATE_MAINTENANCE_NOTE,
        data
    };
};
export function createMaintenanceNoteSuccess(response) {
    return {
        type: Types.CREATE_MAINTENANCE_NOTE_SUCCESS,
        response
    };
};
export function createMaintenanceNoteError(error) {
    return {
        type: Types.CREATE_MAINTENANCE_NOTE_ERROR,
        error
    };
};
export function resetCreateMaintenanceNote() {
    return {
        type: Types.RESET_CREATE_MAINTENANCE_NOTE
        
    };
};


// maintenance actions
export function updateMaintenanceNote(data) {
    return {
        type: Types.UPDATE_MAINTENANCE_NOTE,
        data
    };
};
export function updateMaintenanceNoteSuccess(response) {
    return {
        type: Types.UPDATE_MAINTENANCE_NOTE_SUCCESS,
        response
    };
};
export function updateMaintenanceNoteError(error) {
    return {
        type: Types.UPDATE_MAINTENANCE_NOTE_ERROR,
        error
    };
};
export function resetUpdateMaintenanceNote() {
    return {
        type: Types.RESET_UPDATE_MAINTENANCE_NOTE
        
    };
};


// maintenance actions
export function fetchMaintenanceNote(data) {
    return {
        type: Types.GET_MAINTENANCE_NOTE,
        data
    };
};
export function fetchMaintenanceNoteSuccess(response) {
    return {
        type: Types.MAINTENANCE_NOTE_SUCCESS,
        response
    };
};
export function fetchMaintenanceNoteError(error) {
    return {
        type: Types.MAINTENANCE_NOTE_ERROR,
        error
    };
};


// maintenance actions
export function fetchMaintenanceNoteById(data) {
    return {
        type: Types.GET_MAINTENANCE_NOTE_BY_ID,
        data
    };
};
export function fetchMaintenanceNoteByIdSuccess(response) {
    return {
        type: Types.MAINTENANCE_NOTE_BY_ID_SUCCESS,
        response
    };
};
export function fetchMaintenanceNoteByIdError(error) {
    return {
        type: Types.MAINTENANCE_NOTE_BY_ID_ERROR,
        error
    };
};
