import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class ColumnText extends Component {
  render() {
    let module = this.props.Module;
    let entity = this.props.Entity;
    let transition = this.props.Transition;
    let name = this.props.Label;

    let attribute_data =
      typeof this.props.localeData.rows !== "undefined" &&
      typeof this.props.localeData.rows.model !== "undefined" &&
      typeof this.props.localeData.rows.model[module] !== "undefined" &&
      typeof this.props.localeData.rows.model[module]["entities"][entity] !==
        "undefined" &&
      typeof this.props.localeData.rows.model[module]["entities"][entity][
        "attributes"
      ] !== "undefined"
        ? this.props.localeData.rows.model[module]["entities"][entity][
            "attributes"
          ]
        : null;

    if (
      attribute_data !== null &&
      typeof attribute_data[name] !== "undefined"
    ) {
      return attribute_data[name]["label"];
    } else if (
      typeof transition !== "undefined" &&
      typeof this.props.localeData.rows !== "undefined" &&
      typeof this.props.localeData.rows.model !== "undefined" &&
      typeof this.props.localeData.rows.model[module] !== "undefined" &&
      typeof this.props.localeData.rows.model[module]["entities"][entity] !==
        "undefined" &&
      typeof this.props.localeData.rows.model[module]["entities"][entity][
        "transitions"
      ] !== "undefined" &&
      typeof this.props.localeData.rows.model[module]["entities"][entity][
        "transitions"
      ][transition] !== "undefined"
    ) {
      return this.props.localeData.rows.model[module]["entities"][entity][
        "transitions"
      ][transition]["label"];
    } else if (
      typeof name === "undefined" &&
      typeof transition === "undefined" &&
      typeof this.props.localeData.rows !== "undefined" &&
      typeof this.props.localeData.rows.model !== "undefined" &&
      typeof this.props.localeData.rows.model[module] !== "undefined" &&
      typeof this.props.localeData.rows.model[module]["entities"][entity] !==
        "undefined" &&
      typeof this.props.localeData.rows.model[module]["entities"][entity][
        "label"
      ] !== "undefined"
    ) {
      return this.props.localeData.rows.model[module]["entities"][entity][
        "label"
      ];
    }
    return "";
  }
}

ColumnText.propTypes = {
  Module: PropTypes.string.isRequired,
  Entity: PropTypes.string.isRequired,
  Label: PropTypes.string,
};

const mapStateToProps = (state) => ({
  localeData: state.Locale.localeData,
});

export default connect(mapStateToProps)(ColumnText);
