export const GET_COMPONENT = 'constants/vehicle/GET_COMPONENT';
export const COMPONENT_SUCCESS = 'constants/vehicle/COMPONENT_SUCCESS';
export const COMPONENT_ERROR = 'constants/vehicle/COMPONENT_ERROR';

export const GET_COMPONENT_BY_ID = 'constants/vehicle/GET_COMPONENT_BY_ID';
export const COMPONENT_BY_ID_SUCCESS = 'constants/vehicle/COMPONENT_BY_ID_SUCCESS';
export const COMPONENT_BY_ID_ERROR = 'constants/vehicle/COMPONENT_BY_ID_ERROR';

export const CREATE_COMPONENT = 'constants/vehicle/CREATE_COMPONENT';
export const CREATE_COMPONENT_SUCCESS = 'constants/vehicle/CREATE_COMPONENT_SUCCESS';
export const CREATE_COMPONENT_ERROR = 'constants/vehicle/CREATE_COMPONENT_ERROR';
export const RESET_CREATE_COMPONENT = 'constants/vehicle/RESET_CREATE_COMPONENT';

export const UPDATE_COMPONENT = 'constants/vehicle/UPDATE_COMPONENT';
export const UPDATE_COMPONENT_SUCCESS = 'constants/vehicle/UPDATE_COMPONENT_SUCCESS';
export const UPDATE_COMPONENT_ERROR = 'constants/vehicle/UPDATE_COMPONENT_ERROR';
export const RESET_UPDATE_COMPONENT = 'constants/vehicle/RESET_UPDATE_COMPONENT';