import * as Types from "../constants";

// company user actions
export function fetchCompanyUser(data) {
    return {
        type: Types.GET_COMPANY_USER,
        data
    };
};

export function fetchCompanyUserSuccess(response) {
    return {
        type: Types.COMPANY_USER_SUCCESS,
        response
    };
};

export function fetchCompanyUserError(error) {
    return {
        type: Types.COMPANY_USER_ERROR,
        error
    };
};


// company user actions
export function fetchCompanyUserById(data) {
    return {
        type: Types.GET_COMPANY_USER_BY_ID,
        data
    };
};

export function fetchCompanyUserByIdSuccess(response) {
    return {
        type: Types.COMPANY_USER_BY_ID_SUCCESS,
        response
    };
};

export function fetchCompanyUserByIdError(error) {
    return {
        type: Types.COMPANY_USER_BY_ID_ERROR,
        error
    };
};





// maintenance actions
export function createCompanyUser(data) {
    return {
        type: Types.CREATE_COMPANY_USER,
        data
    };
};
export function createCompanyUserSuccess(response) {
    return {
        type: Types.CREATE_COMPANY_USER_SUCCESS,
        response
    };
};
export function createCompanyUserError(error) {
    return {
        type: Types.CREATE_COMPANY_USER_ERROR,
        error
    };
};
export function resetCreateCompanyUser() {
    return {
        type: Types.RESET_CREATE_COMPANY_USER
        
    };
};


export function updateCompanyUser(data) {
    return {
        type: Types.UPDATE_COMPANY_USER,
        data
    };
};
export function updateCompanyUserSuccess(response) {
    return {
        type: Types.UPDATE_COMPANY_USER_SUCCESS,
        response
    };
};
export function updateCompanyUserError(error) {
    return {
        type: Types.UPDATE_COMPANY_USER_ERROR,
        error
    };
};
export function resetUpdateCompanyUser() {
    return {
        type: Types.RESET_UPDATE_COMPANY_USER
        
    };
};



