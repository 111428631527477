import { call, all, put, takeLatest } from "redux-saga/effects";
import { authHeader } from "../../../utils";
import request from "../../../services/request";
import * as ActionTypes from "../../actions/constants/types";
import { deleteError, deleteSuccess } from "../../actions/delete";

function* deleteSaga({ data }) {
  try {
    const requestURL = typeof data.url !== "undefined" ? data.url : null;
    let postData = typeof data.post !== "undefined" ? data.post : null;
    if (requestURL !== null) {
      const response = yield call(request, {
        url: requestURL,
        method: "POST",
        headers: {
          Authorization: authHeader(),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: postData,
      });
      yield put(deleteSuccess(response));
    }
  } catch (error) {
    console.log(error);

    if (
      (typeof error !== "undefined" &&
        typeof error.data !== "undefined" &&
        typeof error.data.error !== "undefined" &&
        typeof error.data.error.message !== "undefined") ||
      error.status === 500
    ) {
      yield put(deleteError(error));
    }
  }
}

// function* setDeleteDataSaga({ data }) {
//   try {
//     yield put(setDeleteData(data));
//   } catch (error) {
//     console.log(error);
//   }
// }

export default function* pageSaga() {
  yield all([
    yield takeLatest(ActionTypes.DELETE_ROW, deleteSaga),
    //  yield takeLatest(ActionTypes.DELETE_DATA, setDeleteDataSaga),
  ]);
}
