import { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Form } from "react-bootstrap";
import { fetchVehicle } from "../../store/actions/vehicle";
import { showDeleteDialog } from "../../store/actions/dialog";
import {
  CORE_MODULE,
  VEHICLE_ENTITY,
  CUSTOMER_ENTITY,
  VEHICLE_CUSTOMER_NOTE_ENTITY,
  VEHICLE_NOTE_ENTITY,
  VEHICLE_MAINTENANCE_ENTITY,
  VEHICLE_FILE_ENTITY,
  DELETE_PERMISSION,
  MODIFY_PERMISSION,
  CREATE_PERMISSION,
} from "../../utils";
import moment from "moment";
import { HasAccess } from "../../components/Access";
import ColumnText from "../../components/Column";

class VehicleDetailScreen extends Component {
  constructor(props) {
    super(props);
    this.addNoteHandler = this.addNoteHandler.bind(this);
    this.editVehicleHandler = this.editVehicleHandler.bind(this);
    this.deleteVehicleHandler = this.deleteVehicleHandler.bind(this);
    this.viewNoteHandler = this.viewNoteHandler.bind(this);
    this.viewDocumentHandler = this.viewDocumentHandler.bind(this);
    this.viewMaintenanceHandler = this.viewMaintenanceHandler.bind(this);
  }

  componentDidMount() {
    let data = {};
    data.conds = { "me.id": this.props.match.params.id };
    data.attrs = {};
    data.attrs["prefetch"] = [
      "core__vehicle_maintenance__vehicle",
      { owning_customer: "owning_client" },
    ];

    this.props.dispatch(fetchVehicle(data));
  }

  addNoteHandler = () => {
    let url =
      "/admin/vehicles/" + this.props.match.params.id + "/vehicle-notes/add";
    this.props.history.push(url);
  };

  editVehicleHandler = () => {
    let url = "/admin/vehicles/" + this.props.match.params.id + "/edit";
    this.props.history.push(url);
  };

  viewNoteHandler = () => {
    let url =
      "/admin/vehicles/" + this.props.match.params.id + "/vehicle-notes";
    this.props.history.push(url);
  };

  viewDocumentHandler = () => {
    let url = "/admin/vehicles/" + this.props.match.params.id + "/documents";
    this.props.history.push(url);
  };

  viewMaintenanceHandler = () => {
    let url = "/admin/vehicles/" + this.props.match.params.id + "/maintenance";
    this.props.history.push(url);
  };

  deleteVehicleHandler = () => {
    let vehicle_data =
      typeof this.props.vehicleData.rows.data[0] !== "undefined"
        ? this.props.vehicleData.rows.data[0]
        : null;
    let rowId =
      vehicle_data !== null && typeof vehicle_data.id !== "undefined"
        ? vehicle_data.id
        : null;
    let successor =
      vehicle_data !== null && typeof vehicle_data.successor !== "undefined"
        ? vehicle_data.successor
        : null;
    let rowData = { post: { successor: null } };
    if (rowId !== null) {
      rowData["id"] = rowId;
    }
    if (successor !== null) {
      rowData["post"]["successor"] = successor;
    }
    rowData["url"] = "/data/core/vehicle_note/" + rowId + "/transitions/delete";
    rowData["redirect_to"] = "/admin/vehicles";
    if (vehicle_data !== null && typeof rowId !== "undefined") {
      this.props.dispatch(showDeleteDialog(rowData));
    }
  };

  render() {
    let vehicle_data =
      typeof this.props.vehicleData.rows.data[0] !== "undefined"
        ? this.props.vehicleData.rows.data[0]
        : null;
    let owning_customer =
      vehicle_data !== null &&
        typeof vehicle_data.owning_customer !== "undefined"
        ? vehicle_data.owning_customer
        : null;
    let owning_client =
      owning_customer !== null &&
        typeof owning_customer.owning_client !== "undefined" &&
        owning_customer.owning_client !== null
        ? owning_customer.owning_client
        : null;

    let customer_type =
      owning_customer !== null &&
        typeof owning_customer.is_client !== "undefined" &&
        owning_customer.is_client === 1
        ? 1
        : 2;

    let customer_name;
    if (customer_type === 1) {
      let customer_first_name =
        owning_client !== null && typeof owning_client.first_name !== "undefined"
          ? owning_client.first_name
          : "-";
      let customer_last_name =
        owning_client !== null && typeof owning_client.last_name !== "undefined"
          ? owning_client.last_name
          : "-";
      customer_name = customer_first_name + " " + customer_last_name;
    } else {
      customer_name = owning_customer !== null && typeof owning_customer.name !== "undefined" ? owning_customer.name : "-";
    }

    let vehicle_id =
      vehicle_data !== null && typeof vehicle_data.vin !== "undefined"
        ? vehicle_data.vin
        : "-";
    let first_seen =
      vehicle_data !== null &&
        typeof vehicle_data.first_seen !== "undefined" &&
        vehicle_data.first_seen !== null
        ? moment(vehicle_data.first_seen).format("DD.MM.YYYY")
        : "-";
    let licence_plate =
      vehicle_data !== null && typeof vehicle_data.licence_plate !== "undefined"
        ? vehicle_data.licence_plate
        : "-";
    let brand =
      vehicle_data !== null && typeof vehicle_data.brand !== "undefined"
        ? vehicle_data.brand
        : "-";
    let model =
      vehicle_data !== null && typeof vehicle_data.model !== "undefined"
        ? vehicle_data.model
        : "-";
    let country =
      vehicle_data !== null && typeof vehicle_data.country !== "undefined"
        ? vehicle_data.country
        : "-";
    let reowned_id =
      vehicle_data !== null && typeof vehicle_data.reowned_id !== "undefined"
        ? vehicle_data.reowned_id
        : null;
    let maintenance_data =
      vehicle_data !== null &&
        typeof vehicle_data.core__vehicle_maintenance__vehicle !== "undefined"
        ? vehicle_data.core__vehicle_maintenance__vehicle
        : null;
    let vehicle_type =
      vehicle_data !== null && typeof vehicle_data.type !== "undefined"
        ? vehicle_data.type
        : "-";
    // console.log(owning_client.is_client);
    let service_date = "-";
    if (maintenance_data !== null) {
      if (maintenance_data.length > 0) {
        let total = maintenance_data.length;
        let last = maintenance_data[total - 1];
        service_date =
          typeof last.date !== "undefined"
            ? moment(last.date).format("DD.MM.YYYY")
            : "";
      }
    }

    return (
      <div className="wrapper">
        <div className="container-header">
          <h4>
            Details zur{" "}
            <ColumnText Module={CORE_MODULE} Entity={VEHICLE_ENTITY} />
          </h4>
          <div className="btngroup">
            <HasAccess
              Module={CORE_MODULE}
              Entity={VEHICLE_CUSTOMER_NOTE_ENTITY}
              Label={CREATE_PERMISSION}
            >
              <Button
                type="button"
                className="btn btn-dark"
                onClick={() => this.addNoteHandler()}
              >
                {" "}
                <ColumnText
                  Module={CORE_MODULE}
                  Entity={VEHICLE_CUSTOMER_NOTE_ENTITY}
                />{" "}
                hinzufügen
              </Button>
            </HasAccess>
            <HasAccess
              Module={CORE_MODULE}
              Entity={VEHICLE_ENTITY}
              Label={MODIFY_PERMISSION}
            >
              <Button
                type="button"
                className="btn btn-dark"
                onClick={() => this.editVehicleHandler()}
              >
                Bearbeiten
              </Button>
            </HasAccess>
            <HasAccess
              Module={CORE_MODULE}
              Entity={VEHICLE_ENTITY}
              Label={DELETE_PERMISSION}
            >
              <Button
                type="button"
                className="btn btn-dark"
                onClick={() => this.deleteVehicleHandler()}
              >
                Delete
              </Button>
            </HasAccess>
          </div>
        </div>
        <div className="sub_main">
          <Row>
            <Col lg={6}>
              <Form>
                <Form.Group as={Row}>
                  <Col sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={CUSTOMER_ENTITY}
                      Label={"is_client"}
                    />
                  </Col>

                  <Col
                    sm="8"
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Form.Check
                      custom
                      inline
                      label="Kunde"
                      type={"radio"}
                      id={`custom-inline-radio-1`}
                      checked={customer_type === 1 ? true : false}
                      disabled={customer_type !== 1 ? true : false}
                    />
                    <Form.Check
                      custom
                      inline
                      readOnly
                      label="Firma"
                      type={"radio"}
                      id={`custom-inline-radio-2`}
                      checked={customer_type === 2 ? true : false}
                      disabled={customer_type !== 2 ? true : false}
                    />
                  </Col>
                </Form.Group>
              </Form>
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={CUSTOMER_ENTITY}
                    Label={"name"}
                  />
                </div>
                <div className="col-sm-8">{customer_name}</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={VEHICLE_ENTITY}
                    Label={"vin"}
                  />
                </div>
                <div className="col-sm-8">{vehicle_id}</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={VEHICLE_ENTITY}
                    Label={"first_seen"}
                  />
                </div>
                <div className="col-sm-8">{first_seen}</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={VEHICLE_ENTITY}
                    Label={"licence_plate"}
                  />
                </div>
                <div className="col-sm-8">{licence_plate}</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={VEHICLE_ENTITY}
                    Label={"brand"}
                  />
                </div>
                <div className="col-sm-8">{brand}</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={VEHICLE_ENTITY}
                    Label={"model"}
                  />
                </div>
                <div className="col-sm-8">{model}</div>
              </div>

              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={VEHICLE_ENTITY}
                    Label={"type"}
                  />
                </div>
                <div className="col-sm-8">{vehicle_type}</div>
              </div>
            </Col>

            <Col lg={6}>
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={VEHICLE_ENTITY}
                    Label={"country"}
                  />
                </div>
                <div className="col-sm-8">{country}</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={VEHICLE_MAINTENANCE_ENTITY}
                    Label={"date"}
                  />
                </div>
                <div className="col-sm-8">{service_date}</div>
              </div>
              {reowned_id !== null &&
                <div className="form-group row">
                  <div className="col-sm-4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_ENTITY}
                      Label={"reowned_id"}
                    />
                  </div>
                  <div className="col-sm-8">{reowned_id}</div>
                </div>}
            </Col>
          </Row>
        </div>
        <Row className="container-footer">
          <div className="btngroup">
            <HasAccess Module={CORE_MODULE} Entity={VEHICLE_NOTE_ENTITY}>
              <Button
                type="button"
                className="btn btn-dark"
                onClick={() => this.viewNoteHandler()}
              >
                <ColumnText
                  Module={CORE_MODULE}
                  Entity={VEHICLE_CUSTOMER_NOTE_ENTITY}
                />
              </Button>
            </HasAccess>

            <HasAccess Module={CORE_MODULE} Entity={VEHICLE_FILE_ENTITY}>
              <Button
                type="button"
                className="btn btn-dark"
                onClick={() => this.viewDocumentHandler()}
              >
                <ColumnText Module={CORE_MODULE} Entity={VEHICLE_FILE_ENTITY} />
              </Button>
            </HasAccess>
            <Button
              type="button"
              className="btn btn-dark"
              onClick={() => this.viewMaintenanceHandler()}
            >
              <ColumnText
                Module={CORE_MODULE}
                Entity={VEHICLE_MAINTENANCE_ENTITY}
              />
            </Button>
          </div>
        </Row>
      </div>
    );
  }
}

VehicleDetailScreen.propTypes = {};
VehicleDetailScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  vehicleData: state.Vehicle.vehicleData,
  localeData: state.Locale.localeData,
  documentData: state.Vehicle.documentData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleDetailScreen);
