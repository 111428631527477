import { call, all, put, takeLatest } from "redux-saga/effects";
import { authHeader } from "../../../utils";
import request from "../../../services/request";
import * as ActionTypes from "../../actions/constants";
import {
  fetchVehicleMaintenanceSuccess,
  fetchVehicleMaintenanceError,
  updateVehicleMaintenanceSuccess,
  updateVehicleMaintenanceError,
  createVehicleMaintenanceSuccess,
  createVehicleMaintenanceError,
} from "../../actions/vehicle";

function* maintenanceSaga({ data }) {
  try {
    const requestURL = "data/core/vehicle_maintenance/query";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data,
    });
    yield put(fetchVehicleMaintenanceSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(fetchVehicleMaintenanceError(error));
    }
  }
}

function* updateSaga({ data }) {
  try {
    let maintenance_id =
      typeof data.maintenance_id !== "undefined" && data.maintenance_id !== null
        ? data.maintenance_id
        : null;
    let maintenanceData =
      typeof data.maintenance !== "undefined" && data.maintenance !== null
        ? data.maintenance
        : null;
    const requestURL =
      "/data/core/vehicle_maintenance/" +
      `${maintenance_id}` +
      "/transitions/modify";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: maintenanceData,
    });
    yield put(updateVehicleMaintenanceSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(updateVehicleMaintenanceError(error));
    }
  }
}

function* createSaga({ data }) {
  try {
    //let maintenance_id = ((typeof(data.maintenance_id) !== 'undefined')&&(data.maintenance_id !== null))?data.maintenance_id:null;
    let maintenanceData =
      typeof data.maintenance !== "undefined" && data.maintenance !== null
        ? data.maintenance
        : null;
    const requestURL = "/data/core/vehicle_maintenance/transitions/add";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: maintenanceData,
    });
    yield put(createVehicleMaintenanceSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(createVehicleMaintenanceError(error));
    }
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(ActionTypes.GET_VEHICLE_MAINTENANCE, maintenanceSaga),
    yield takeLatest(ActionTypes.UPDATE_VEHICLE_MAINTENANCE, updateSaga),
    yield takeLatest(ActionTypes.CREATE_VEHICLE_MAINTENANCE, createSaga),
  ]);
}
