import { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import { fetchMaintenanceComponent } from "../../store/actions/vehicle";
import { showDeleteDialog } from "../../store/actions/dialog";
import {
  CORE_MODULE,
  VEHICLE_MAINTENANCE_COMPONENT_ENTITY,
  VEHICLE_ENTITY,
  MODIFY_PERMISSION,
  REMOVE_PERMISSION,
} from "../../utils";
import moment from "moment";
import { HasAccess } from "../../components/Access";
import ColumnText from "../../components/Column";

class MaintenanceComponentDetailScreen extends Component {
  constructor(props) {
    super(props);

    this.editHandler = this.editHandler.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
  }

  componentDidMount() {
    let data = {};
    data.conds = { "me.id": this.props.match.params.id };
    data.attrs = {};
    data.attrs["prefetch"] = [
      "owning_customer",
      "vehicle_maintenance",
      "vehicle_component",
    ];
    this.props.dispatch(fetchMaintenanceComponent(data));
  }

  /*downloadInvoice(url,mimeType,filename){
     downloadFile(url,filename,mimeType);
  }
 
  
  addMaintenanceComponentHandler(){
    let url =
        "/admin/maintenance/" +
        this.props.match.params.mid +
        "/components/add";
    this.props.history.push(url);
  }
  editMaintenanceHandler(){
    let url ="/admin/maintenance/edit/"+this.props.match.params.id;
    this.props.history.push(url);
  }*/

  /* showMaintenanceComponentHandler(){
    let url =
        "/admin/maintenance/" +
        this.props.match.params.mid +
        "/components/view/" + this.props.match.params.id;
    this.props.history.push(url);
  }

  showMaintenanceNoteHandler(){
    let url ="/admin/maintenance-note";
    this.props.history.push(url);
  }*/
  editHandler() {
    let url =
      "/admin/maintenance/" +
      this.props.match.params.mid +
      "/components/edit/" +
      this.props.match.params.id;
    this.props.history.push(url);
  }

  deleteHandler() {
    let rowId = this.props.match.params.id;
    let maintenance_data =
      typeof this.props.maintenanceData.rows.data[0] !== "undefined"
        ? this.props.maintenanceData.rows.data[0]
        : null;
    let successor =
      maintenance_data !== null ? maintenance_data.successor : null;
    let rowData = { post: { successor: null } };
    if (rowId !== null) {
      rowData["id"] = rowId;
    }
    if (successor !== null) {
      rowData["post"]["successor"] = successor;
    }
    rowData["url"] =
      "/data/core/vehicle_maintenance/" + rowId + "/transitions/remove";
    rowData["redirect_to"] =
      "/admin/maintenance/" + this.props.match.params.mid + "/components";
    if (maintenance_data !== null && typeof rowId !== "undefined") {
      this.props.dispatch(showDeleteDialog(rowData));
    }
  }

  render() {
    let mntID = this.props.match.params.id;
    let customer_data =
      typeof this.props.maintenanceData.rows.data[0] !== "undefined"
        ? this.props.maintenanceData.rows.data[0]
        : null;
    let vehicle_data =
      customer_data !== null && typeof customer_data.vehicle !== "undefined"
        ? customer_data.vehicle
        : null;
    let owning_customer =
      customer_data !== null &&
      typeof customer_data.owning_customer !== "undefined"
        ? customer_data.owning_customer
        : null;
    let vehicle_component =
      customer_data !== null &&
      typeof customer_data.vehicle_component !== "undefined"
        ? customer_data.vehicle_component
        : null;
    let vehicle_maintenance =
      customer_data !== null &&
      typeof customer_data.vehicle_maintenance !== "undefined"
        ? customer_data.vehicle_maintenance
        : null;

    let mnt_id = mntID;
    let creation_date =
      customer_data !== null && typeof customer_data.created !== "undefined"
        ? moment(customer_data.created).format("YYYY.MM.DD")
        : "-";
    let customer_name =
      owning_customer !== null && typeof owning_customer.name !== "undefined"
        ? owning_customer.name
        : "-";
    let service_date =
      customer_data !== null && typeof customer_data.review_date !== "undefined"
        ? moment(customer_data.review_date).format("YYYY.MM.DD")
        : "-";
    let service_note =
      customer_data !== null && typeof customer_data.note !== "undefined"
        ? customer_data.note
        : "-";

    let component =
      vehicle_component !== null &&
      typeof vehicle_component.name !== "undefined"
        ? vehicle_component.name
        : "-";
    let vehicle_id =
      vehicle_maintenance !== null &&
      typeof vehicle_maintenance.vehicle !== "undefined"
        ? vehicle_maintenance.vehicle.vin
        : "-";

    return (
      <div className="wrapper">
        <div className="container-header">
          <h4>Vehicle Maintenance Component</h4>
          <div className="btngroup">
            <HasAccess
              Module={CORE_MODULE}
              Entity={VEHICLE_MAINTENANCE_COMPONENT_ENTITY}
              Label={MODIFY_PERMISSION}
            >
              <Button
                type="button"
                className="btn btn-dark"
                onClick={() => {
                  this.editHandler();
                }}
              >
                Bearbeiten
              </Button>
            </HasAccess>
            <HasAccess
              Module={CORE_MODULE}
              Entity={VEHICLE_MAINTENANCE_COMPONENT_ENTITY}
              Label={REMOVE_PERMISSION}
            >
              <Button
                type="button"
                className="btn btn-dark"
                onClick={() => {
                  this.deleteHandler();
                }}
              >
                Delete
              </Button>
            </HasAccess>
          </div>
        </div>
        <div className="sub_main">
          <Row>
            <Col lg={6}>
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={VEHICLE_MAINTENANCE_COMPONENT_ENTITY}
                    Label={"vehicle_maintenance"}
                  />
                </div>
                <div className="col-sm-8">{mnt_id}</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={VEHICLE_MAINTENANCE_COMPONENT_ENTITY}
                    Label={"created"}
                  />
                </div>
                <div className="col-sm-8">{creation_date}</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={VEHICLE_MAINTENANCE_COMPONENT_ENTITY}
                    Label={"owning_customer"}
                  />
                </div>
                <div className="col-sm-8">{customer_name}</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={VEHICLE_ENTITY}
                    Label={"vin"}
                  />
                </div>
                <div className="col-sm-8">{vehicle_id}</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={VEHICLE_MAINTENANCE_COMPONENT_ENTITY}
                    Label={"review_date"}
                  />
                </div>
                <div className="col-sm-8">{service_date}</div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={VEHICLE_MAINTENANCE_COMPONENT_ENTITY}
                    Label={"vehicle_component"}
                  />
                </div>
                <div className="col-sm-8">{component}</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={VEHICLE_MAINTENANCE_COMPONENT_ENTITY}
                    Label={"note"}
                  />
                </div>
                <div className="col-sm-8">{service_note}</div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

MaintenanceComponentDetailScreen.propTypes = {};
MaintenanceComponentDetailScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  maintenanceData: state.MComponent.maintenanceData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaintenanceComponentDetailScreen);
