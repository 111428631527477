import { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import { fetchVehicle, fetchDocument } from "../../store/actions/vehicle";
import {
  downloadFile,
  CORE_MODULE,
  VEHICLE_ENTITY,
  VEHICLE_FILE_ENTITY,
  CREATE_PERMISSION,
} from "../../utils";
import { Link } from "react-router-dom";
import moment from "moment";
import { HasAccess } from "../../components/Access";
import ColumnText from "../../components/Column";

class VehicleDocumentDetailScreen extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let data = {};
    data.conds = { "me.id": this.props.match.params.id };
    data.attrs = {};
    data.attrs["prefetch"] = [
      "core__vehicle_maintenance__vehicle",
      "owning_customer",
      "core__vehicle_file__vehicle",
    ];
    this.props.dispatch(fetchVehicle(data));
    let doc_data = {};
    doc_data.attrs = {};
    doc_data.conds = {};
    this.props.dispatch(fetchDocument(doc_data));
  }

  downloadDocuments(url, mimeType, filename) {
    downloadFile(url, filename, mimeType);
  }

  addHandler = () => {
    let url =
      "/admin/vehicles/" + this.props.match.params.vid + "/documents/add";
    this.props.history.push(url);
  };

  render() {
    // let core_module = "core";
    // let vehicle_entity = "vehicle";
    // let maintenance_entity = "vehicle_maintenance";
    // let customer_entity = "customer";

    /*  let vehicle_locale_data = ( (typeof(this.props.localeData.rows) !== 'undefined') && (typeof(this.props.localeData.rows.model) !== 'undefined') && (typeof(this.props.localeData.rows.model[core_module]) !== 'undefined') && typeof(this.props.localeData.rows.model[core_module]['entities'][vehicle_entity]) !== 'undefined' )? this.props.localeData.rows.model[core_module]['entities'][vehicle_entity]:null;
    let veh_attrs  = ((vehicle_locale_data !== null)&&(vehicle_locale_data['attributes']))?(vehicle_locale_data['attributes']):null;
   
   let label_vehicle_id = ((veh_attrs !== null) && (typeof(veh_attrs.vin) !== 'undefined') &&  (typeof(veh_attrs.vin.label) !== 'undefined'))?veh_attrs.vin.label:'';
    let label_mileage = ((veh_attrs !== null) && (typeof(veh_attrs.mileage) !== 'undefined') &&  (typeof(veh_attrs.mileage.label) !== 'undefined'))?veh_attrs.mileage.label:'';
    let label_first_seen = ((veh_attrs !== null) && (typeof(veh_attrs.first_seen) !== 'undefined') &&  (typeof(veh_attrs.first_seen.label) !== 'undefined'))?veh_attrs.first_seen.label:'';
    let label_licence_plate = ((veh_attrs !== null) && (typeof(veh_attrs.licence_plate) !== 'undefined') &&  (typeof(veh_attrs.licence_plate.label) !== 'undefined'))?veh_attrs.licence_plate.label:'';
    let label_brand = ((veh_attrs !== null) && (typeof(veh_attrs.brand) !== 'undefined') &&  (typeof(veh_attrs.brand.label) !== 'undefined'))?veh_attrs.brand.label:'';
    let label_model = ((veh_attrs !== null) && (typeof(veh_attrs.model) !== 'undefined') &&  (typeof(veh_attrs.model.label) !== 'undefined'))?veh_attrs.model.label:'';
    let label_country = ((veh_attrs !== null) && (typeof(veh_attrs.country) !== 'undefined') &&  (typeof(veh_attrs.country.label) !== 'undefined'))?veh_attrs.country.label:'';
 
    
    let maintenance_locale_data = ( (typeof(this.props.localeData.rows) !== 'undefined') && (typeof(this.props.localeData.rows.model) !== 'undefined') && (typeof(this.props.localeData.rows.model[core_module]) !== 'undefined') && typeof(this.props.localeData.rows.model[core_module]['entities'][maintenance_entity]) !== 'undefined' )? this.props.localeData.rows.model[core_module]['entities'][maintenance_entity]:null;
    let mnt_attrs  = ((maintenance_locale_data !== null)&&(maintenance_locale_data['attributes']))?(maintenance_locale_data['attributes']):null;
    let label_service_date = ((mnt_attrs !== null) && (typeof(mnt_attrs.date) !== 'undefined') &&  (typeof(mnt_attrs.date.label) !== 'undefined'))?mnt_attrs.date.label:'';
    
    let customer_locale_data = ( (typeof(this.props.localeData.rows) !== 'undefined') && (typeof(this.props.localeData.rows.model) !== 'undefined') && (typeof(this.props.localeData.rows.model[core_module]) !== 'undefined') && typeof(this.props.localeData.rows.model[core_module]['entities'][customer_entity]) !== 'undefined' )? this.props.localeData.rows.model[core_module]['entities'][customer_entity]:null;
    let cust_attrs  = ((customer_locale_data !== null)&&(customer_locale_data['attributes']))?(customer_locale_data['attributes']):null;
    let label_customer_name = ((cust_attrs !== null) && (typeof(cust_attrs.name) !== 'undefined') &&  (typeof(cust_attrs.name.label) !== 'undefined'))?cust_attrs.name.label:'';
*/

    let vehicle_data =
      typeof this.props.vehicleData.rows.data[0] !== "undefined"
        ? this.props.vehicleData.rows.data[0]
        : null;
    let owning_customer =
      vehicle_data !== null &&
      typeof vehicle_data.owning_customer !== "undefined"
        ? vehicle_data.owning_customer
        : null;
    let customer_name =
      owning_customer !== null && typeof owning_customer.name !== "undefined"
        ? owning_customer.name
        : "-";
    let chasis_number =
      vehicle_data !== null && typeof vehicle_data.vin !== "undefined"
        ? vehicle_data.vin
        : "-";
    let mileage =
      vehicle_data !== null && typeof vehicle_data.mileage !== "undefined"
        ? vehicle_data.mileage
        : "-";
    let licence_plate =
      vehicle_data !== null && typeof vehicle_data.licence_plate !== "undefined"
        ? vehicle_data.licence_plate
        : "-";
    let brand =
      vehicle_data !== null && typeof vehicle_data.brand !== "undefined"
        ? vehicle_data.brand
        : "-";
    let model =
      vehicle_data !== null && typeof vehicle_data.model !== "undefined"
        ? vehicle_data.model
        : "-";
    let type =
      vehicle_data !== null && typeof vehicle_data.type !== "undefined"
        ? vehicle_data.type
        : "-";
    let vehicle_files =
      vehicle_data !== null &&
      typeof vehicle_data.core__vehicle_file__vehicle !== "undefined"
        ? vehicle_data.core__vehicle_file__vehicle
        : [];

    //console.log(item);
    const option_html = vehicle_files.map((item, index) => {
      let cell = item.file !== null ? JSON.parse(item.file) : {};
      //let url = (cell !== {})?cell.path:'';
      let mime_type = cell !== {} ? cell.mime_type : "";
      let name = cell !== {} ? cell.original_name : "";
      let url = "data/core/vehicle_file/" + item.id + "/download/file";
      let documents =
        typeof this.props.documentData !== "undefined" &&
        typeof this.props.documentData.rows !== "undefined" &&
        typeof this.props.documentData.rows.data !== "undefined"
          ? this.props.documentData.rows.data
          : [];
      var result = documents.filter((obj) => {
        return obj.id === item.vehicle_document_type;
      });
      let document_type =
        result !== null &&
        typeof result[0] !== "undefined" &&
        typeof result[0].name !== "undefined"
          ? result[0].name
          : "";

      if (url !== "") {
        return (
          <>
            {" "}
            <tr>
              <td>{index + 1}</td>
              <td>{document_type}</td>
              <td>{moment(item.date).format("DD.MM.YYYY")}</td>
              <td>
                <Link
                  to="#"
                  onClick={() => this.downloadDocuments(url, mime_type, name)}
                >
                  {name}
                </Link>
              </td>
            </tr>{" "}
          </>
        );
      } else {
        return (
          <>
            {" "}
            <tr>
              <td>{index + 1}</td>
              <td>{document_type}</td>
              <td>{moment(item.date).format("DD.MM.YYYY")}</td>
              <td>{name}</td>
            </tr>{" "}
          </>
        );
      }
    });

    return (
      <div className="wrapper">
        <div className="container-header">
          <h4>Vehicle Document</h4>
          <div className="btngroup">
            <HasAccess
              Module={CORE_MODULE}
              Entity={VEHICLE_FILE_ENTITY}
              Label={CREATE_PERMISSION}
            >
              <Button
                type="button"
                className="btn btn-dark"
                onClick={() => {
                  this.addHandler();
                }}
              >
                {" "}
                <ColumnText
                  Module={CORE_MODULE}
                  Entity={VEHICLE_FILE_ENTITY}
                />{" "}
                hinzufügen
              </Button>
            </HasAccess>
          </div>
        </div>
        <div className="sub_main">
          <Row>
            <Col lg={6}>
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={VEHICLE_FILE_ENTITY}
                    Label={"owning_customer"}
                  />
                </div>
                <div className="col-sm-8">{customer_name}</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={VEHICLE_FILE_ENTITY}
                    Label={"licence_plate"}
                  />
                </div>
                <div className="col-sm-8">{licence_plate}</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={VEHICLE_ENTITY}
                    Label={"vin"}
                  />
                </div>
                <div className="col-sm-8">{chasis_number}</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={VEHICLE_ENTITY}
                    Label={"model"}
                  />
                </div>
                <div className="col-sm-8">{model}</div>
              </div>
            </Col>

            <Col lg={6}>
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={VEHICLE_ENTITY}
                    Label={"type"}
                  />
                </div>
                <div className="col-sm-8">{type}</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={VEHICLE_ENTITY}
                    Label={"brand"}
                  />
                </div>
                <div className="col-sm-8">{brand}</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={VEHICLE_ENTITY}
                    Label={"mileage"}
                  />
                </div>
                <div className="col-sm-8">{mileage}</div>
              </div>
            </Col>
          </Row>
          <Row>
            <table className="Table">
              <thead>
                <th key="heading">
                  sl.
                  <br />
                  No.
                </th>
                <th key="heading">Document Type</th>
                <th key="heading">Date</th>
                <th key="heading">Attachment</th>
              </thead>
              <tbody>{option_html}</tbody>
            </table>
          </Row>
        </div>
      </div>
    );
  }
}

VehicleDocumentDetailScreen.propTypes = {};
VehicleDocumentDetailScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  vehicleData: state.Vehicle.vehicleData,
  localeData: state.Locale.localeData,
  documentData: state.Document.documentData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleDocumentDetailScreen);
