import { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import { fetchCustomer } from "../../store/actions/customer";
import {
  fetchVehicle,
  updateVehicleOwnership,
  createVehicleNote,
  resetUpdateVehicle,
} from "../../store/actions/vehicle";
import { Config } from "../../config";
import {
  inputErrorHandler,
  signalTransitionSuccess,
  signalTransitionError,
  removeEmptyValues,
  CORE_MODULE,
  VEHICLE_ENTITY,
  CUSTOMER_ENTITY,
} from "../../utils";
import moment from "moment";
import ColumnText from "../../components/Column";
import AutoRecommend from "../../components/AutoSuggest";

import "font-awesome/css/font-awesome.css";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

class VehicleEditOwnerScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        vehicle_id: "",
        customer_id: "",
        chasis_number: "",
        mileage: "",
        first_inspection: "",
        licence_plate: "",
        brand: "",
        model: "",
        country: "",
        type: "",
        customer_type: "1",
      },
      reset: {
        vehicle_id: "",
        customer_id: "",
        chasis_number: "",
        mileage: "",
        first_inspection: "",
        licence_plate: "",
        brand: "",
        model: "",
        country: "",
        type: "",
        customer_type: "1",
      },
      errors: {
        customer_id: "",
        chasis_number: "",
        mileage: "",
        first_inspection: "",
        licence_plate: "",
        brand: "",
        model: "",
        country: "",
        type: "",
      },
      value: "",
      suggestions: [],
    };
    this.datePickerHandler = this.datePickerHandler.bind(this);
  }

  componentDidMount() {
    let data = {};

    data.conds = {
      "-or": [
        { is_client: 0 },
        {
          "-and": [
            { "system__assignment__assigned_client.agenda": 9 },
            { "owning_client.locked": 0 },
          ],
        },
      ],
    };
    data.attrs = {};
    data.attrs["prefetch"] = [
      { owning_client: "system__assignment__assigned_client" },
    ];

    this.props.dispatch(fetchCustomer(data));

    let vdata = {};
    vdata.attrs = {};
    vdata.attrs["prefetch"] = [
      "core__vehicle_maintenance__vehicle",
      { owning_customer: "owning_client" },
    ];
    vdata.conds = { "me.id": this.props.match.params.vid };
    this.props.dispatch(fetchVehicle(vdata));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.vehicleData) !==
      JSON.stringify(this.props.vehicleData)
    ) {
      let vehicle = {};
      let vehicle_data =
        typeof this.props.vehicleData.rows !== "undefined" &&
        typeof this.props.vehicleData.rows.data[0] !== "undefined"
          ? this.props.vehicleData.rows.data[0]
          : null;
      let client_data =
        vehicle_data !== null &&
        typeof vehicle_data.owning_customer !== "undefined" &&
        vehicle_data.owning_customer !== null
          ? vehicle_data.owning_customer
          : null;
      let customer_data =
        client_data !== null &&
        typeof client_data.owning_client !== "undefined" &&
        client_data.owning_client !== null
          ? client_data.owning_client
          : null;
      let vehicle_id =
        vehicle_data !== null &&
        typeof vehicle_data.id !== "undefined" &&
        vehicle_data.id !== null
          ? vehicle_data.id
          : "";
      let customer_id =
        client_data !== null &&
        typeof client_data.id !== "undefined" &&
        vehicle_data.id !== null
          ? client_data.id
          : ""; //==========
      let chasis_number =
        vehicle_data !== null &&
        typeof vehicle_data.vin !== "undefined" &&
        vehicle_data.vin !== null
          ? vehicle_data.vin
          : "";
      let mileage =
        vehicle_data !== null &&
        typeof vehicle_data.mileage !== "undefined" &&
        vehicle_data.mileage !== null
          ? vehicle_data.mileage
          : "";
      let first_inspection =
        vehicle_data !== null &&
        typeof vehicle_data.first_seen !== "undefined" &&
        vehicle_data.first_seen !== null
          ? vehicle_data.first_seen
          : "";
      let licence_plate =
        vehicle_data !== null &&
        typeof vehicle_data.licence_plate !== "undefined" &&
        vehicle_data.licence_plate !== null
          ? vehicle_data.licence_plate
          : "";
      let brand =
        vehicle_data !== null &&
        typeof vehicle_data.brand !== "undefined" &&
        vehicle_data.brand !== null
          ? vehicle_data.brand
          : "";
      let model =
        vehicle_data !== null &&
        typeof vehicle_data.model !== "undefined" &&
        vehicle_data.model !== null
          ? vehicle_data.model
          : "";
      let country =
        vehicle_data !== null &&
        typeof vehicle_data.country !== "undefined" &&
        vehicle_data.country !== null
          ? vehicle_data.country
          : "";
      let type =
        vehicle_data !== null &&
        typeof vehicle_data.type !== "undefined" &&
        vehicle_data.type !== null
          ? vehicle_data.type
          : "";
      let customer_type =
        client_data !== null &&
        typeof client_data.is_client !== "undefined" &&
        vehicle_data.is_client !== null
          ? client_data.is_client
          : 0;

      vehicle["vehicle_id"] = vehicle_id;
      vehicle["customer_id"] = customer_id;
      vehicle["chasis_number"] = chasis_number;
      vehicle["mileage"] = mileage;
      vehicle["first_inspection"] = moment(
        first_inspection.replace("+00", "")
      ).format("YYYY-MM-DD");
      vehicle["licence_plate"] = licence_plate;
      vehicle["brand"] = brand;
      vehicle["model"] = model;
      vehicle["country"] = country;
      vehicle["type"] = type;
      vehicle["customer_type"] = customer_type;
      if (parseInt(customer_type) === 1) {
        var first_name = "";
        var last_name = "";
        if (
          customer_data !== null &&
          typeof customer_data.first_name !== "undefined"
        ) {
          first_name = customer_data.first_name;
        }
        if (
          customer_data !== null &&
          typeof customer_data.last_name !== "undefined"
        ) {
          last_name = customer_data.last_name;
        }
        vehicle["customer_name"] = first_name + " " + last_name;
      } else {
        if (client_data !== null && typeof client_data.name !== "undefined") {
          vehicle["customer_name"] = client_data.name;
        }
      }

      this.setState({ value: vehicle["customer_name"] });
      this.setState({ fields: vehicle });
      this.setState({ reset: vehicle });
      //console.log(vehicle);
    }
    if (
      JSON.stringify(prevProps.customerData) !==
      JSON.stringify(this.props.customerData)
    ) {
      let customers =
        typeof this.props.customerData !== "undefined" &&
        typeof this.props.customerData.rows !== "undefined" &&
        typeof this.props.customerData.rows.data !== "undefined"
          ? this.props.customerData.rows.data
          : [];
      let data = [];
      let suggestions = this.state.suggestions;
      for (let i = 0; i < suggestions.length; i++) {
        let obj = {};
        obj.id = suggestions.id;
        obj.name = suggestions.name;
        data.push(obj);
      }
      for (let i = 0; i < customers.length; i++) {
        const clientData = customers[i].owning_client;
        const customerType = customers[i].is_client;

        let obj = {};
        if (parseInt(customerType) === 1) {
          let firstName = "";
          let lastName = "";
          if (
            clientData !== null &&
            typeof clientData.first_name !== "undefined"
          ) {
            firstName = clientData.first_name;
          }
          if (
            clientData !== null &&
            typeof clientData.last_name !== "undefined"
          ) {
            lastName = clientData.last_name;
          }
          obj.name = firstName + " " + lastName;
        } else {
          obj.name = customers[i].name;
        }
        obj.id = customers[i].id;

        data.push(obj);
      }
      this.setState({ suggestions: data });
    }

    if (this.props.updateData.success) {
      this.props.dispatch(resetUpdateVehicle());
      signalTransitionSuccess("Update Success");
      let url = "/admin/vehicles";
      this.props.history.push(url);
    } else if (this.props.updateData.error) {
      this.props.dispatch(resetUpdateVehicle());
      signalTransitionError("Update Failed");
    }
  }

  removeEmptyValues(obj) {
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName].length === 0
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }

  resetHandler(evt) {
    this.setState({ fields: this.state.reset });
  }

  submitHandler(evt) {
    evt.preventDefault();
    // get our new errors
    let fields = this.state.fields;
    let newErrors = {};
    newErrors["customer_id"] = inputErrorHandler("", fields.customer_id, true);
    newErrors["chasis_number"] = inputErrorHandler(
      "",
      fields.chasis_number,
      true
    );
    newErrors["mileage"] = inputErrorHandler("", fields.mileage, false);
    newErrors["first_inspection"] = inputErrorHandler(
      "",
      fields.first_inspection,
      false
    );
    newErrors["licence_plate"] = inputErrorHandler(
      "",
      fields.licence_plate,
      false
    );
    newErrors["brand"] = inputErrorHandler("", fields.brand, false);
    newErrors["model"] = inputErrorHandler("", fields.model, false);
    newErrors["country"] = inputErrorHandler("", fields.country, false);
    newErrors["type"] = inputErrorHandler("", fields.type, false);
    newErrors["country"] = inputErrorHandler("", fields.country, false);

    if (fields.customer_name !== this.state.value) {
      newErrors["customer_id"] = "Please select valid customer name";
    }

    let errors = removeEmptyValues(newErrors);
    // Conditional logic:
    if (Object.keys(errors).length > 0) {
      this.setState({ errors: errors });
    } else {
      let vehicleData = {
        vehicle_id: fields.vehicle_id,
        customer_id: fields.customer_id,
      };
      this.props.dispatch(updateVehicleOwnership(vehicleData));
      vehicleData = { vehicle: {} };
      vehicleData.note_type = "INTERNAL";
      vehicleData.vehicle.vehicle = fields.vehicle_id;
      vehicleData.vehicle.content =
        Config.INTERNAL_OWNERSHIP_CHANGE + fields.customer_id;
      // this.props.dispatch(createVehicleNote(vehicleData));
    }
  }

  changeHandler(evt, name) {
    let value = evt.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: null,
      },
    }));
  }

  datePickerHandler(value) {
    let items = this.state.fields;
    items["first_inspection"] = value;
    this.setState({ fields: items });

    let errors = this.state.errors;
    errors["first_inspection"] = "";
    this.setState({ errors: errors });
  }

  onValueChange = (newValue) => {
    this.setState({
      value: newValue,
    });
    let errors = this.state.errors;
    errors["customer_id"] = "";
    this.setState({ errors: errors });
  };

  onSuggestionsFetchRequested = (value) => {
    this.loadSuggestions(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  loadSuggestions(value) {
    const customerType = this.state.fields.customer_type || 0;
    if (value.trim() !== "") {
      let cdata = {};
      cdata.attrs = {};
      cdata.attrs["prefetch"] = {
        owning_client: "system__assignment__assigned_client",
      };
      if (parseInt(customerType) === 0) {
        cdata.conds = {
          "me.is_client": 0,
          "me.name": { "-ilike": "%" + value + "%" },
        };
      } else {
        cdata.conds = {
          "owning_client.locked": 0,
          "system__assignment__assigned_client.agenda": 9,
          "me.is_client": 1,
          "-or": [
            { first_name: { "-ilike": "%" + value + "%" } },
            { last_name: { "-ilike": "%" + value + "%" } },
          ],
        };
      }
      this.props.dispatch(fetchCustomer(cdata));
    }
  }

  loadMoreReferences = () => {
    let meta_data =
      typeof this.props.customerData !== "undefined" &&
      typeof this.props.customerData.rows !== "undefined" &&
      typeof this.props.customerData.rows.meta !== "undefined"
        ? this.props.customerData.rows.meta
        : [];

    let cdata = {};
    cdata.attrs = meta_data.attrs;
    cdata.conds = meta_data.conds;
    cdata.page = meta_data.page + 1;
    cdata.page_size = meta_data.page_size;
    this.props.dispatch(fetchCustomer(cdata));
  };

  onSelect = (suggestionValue) => {
    const reference = this.state.suggestions.find(
      (r) => r["name"] === suggestionValue //.replace('/Maintenance - /i',''),
    );
    let fields = this.state.fields;
    fields["customer_id"] = reference.id;
    fields["customer_name"] = reference.name;
    this.setState({ value: reference.name });
    this.setState({ fields: fields });
  };

  onClientTypeChange = (evt) => {
    let value = evt.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        customer_type: value,
      },
    }));
    this.loadSuggestions(this.state.value);
  };

  render() {
    let customer_data =
      typeof this.props.customerData.rows !== "undefined" &&
      typeof this.props.customerData.rows.data[0] !== "undefined"
        ? this.props.customerData.rows.data[0]
        : null;

    let customers =
      typeof this.props.customerData !== "undefined" &&
      typeof this.props.customerData.rows !== "undefined" &&
      typeof this.props.customerData.rows.data !== "undefined"
        ? this.props.customerData.rows.data
        : [];
    let data = [];
    for (let i = 0; i < customers.length; i++) {
      let owning_client = customers[i].owning_client;
      let first_name = owning_client !== null ? owning_client.first_name : "";
      let last_name = owning_client !== null ? owning_client.last_name : "";
      let name = first_name + " " + last_name;
      if (name.trim() !== "") {
        data.push({
          name: name,
          id: customers[i].id,
        });
      }
    }

    return (
      <div className="wrapper">
        <div className="main d-flex align-items-start flex-column">
          <h4>
            {" "}
            <ColumnText Module={CORE_MODULE} Entity={VEHICLE_ENTITY} />{" "}
            bearbeiten
          </h4>
        </div>
        <div className="sub_main">
          <Form>
            <Row>
              <Col lg={6}>
                <Form.Group as={Row} controlId="chasis_number">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_ENTITY}
                      Label={"vin"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Chasis Number"
                      name="chasis_number"
                      value={this.state.fields.chasis_number}
                      disabled
                      onChange={(evt) =>
                        this.changeHandler(evt, "chasis_number")
                      }
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.chasis_number}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.chasis_number}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={CUSTOMER_ENTITY}
                      Label={"is_client"}
                    />
                  </Form.Label>
                  <Col
                    sm="8"
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Form.Check
                      custom
                      inline
                      label="Kunde"
                      type={"radio"}
                      id={`custom-inline-radio-1`}
                      name={"isClient"}
                      value={1}
                      onChange={this.onClientTypeChange}
                      checked={
                        parseInt(this.state.fields.customer_type) === 1
                          ? true
                          : false
                      }
                    />
                    <Form.Check
                      custom
                      inline
                      label="Firma"
                      type={"radio"}
                      id={`custom-inline-radio-2`}
                      name={"isClient"}
                      value={0}
                      onChange={this.onClientTypeChange}
                      checked={
                        parseInt(this.state.fields.customer_type) === 0
                          ? true
                          : false
                      }
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="customer_id">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_ENTITY}
                      Label={"owning_customer"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <AutoRecommend
                      value={this.state.value}
                      onValueChange={this.onValueChange}
                      suggestions={this.state.suggestions}
                      onSuggestionsClearRequested={
                        this.onSuggestionsClearRequested
                      }
                      fetchSuggestions={this.onSuggestionsFetchRequested}
                      onSelect={this.onSelect}
                      placeholder="Kunde wählen"
                      onScrollEnd={this.loadMoreReferences}
                      inputClasses={
                        typeof this.state.errors.customer_id !== "undefined" &&
                        this.state.errors.customer_id !== ""
                          ? "is-invalid form-control"
                          : "form-control"
                      }
                    />
                    {typeof this.state.errors.customer_id !== "undefined" &&
                    this.state.errors.customer_id !== "" ? (
                      <div className="has-error">
                        {this.state.errors.customer_id}
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
                <div className="form-btn">
                  <Button
                    type="Button"
                    className="btn btn-dark Button"
                    onClick={(evt) => this.submitHandler(evt)}
                  >
                    OK
                  </Button>
                  <Button
                    type="Button"
                    className="btn btn-dark"
                    onClick={(evt) => this.resetHandler(evt)}
                  >
                    Abbrechen
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

VehicleEditOwnerScreen.propTypes = {};
VehicleEditOwnerScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  customerData: state.Customer.customerData,
  vehicleData: state.Vehicle.vehicleData,
  localeData: state.Locale.localeData,
  updateData: state.Vehicle.updateData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleEditOwnerScreen);
