import { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import { fetchVehicleMaintenance } from "../../store/actions/vehicle";
import { showDeleteDialog } from "../../store/actions/dialog";
import {
  downloadFile,
  CORE_MODULE,
  VEHICLE_MAINTENANCE_NOTE_ENTITY,
  VEHICLE_MAINTENANCE_ENTITY,
  ADD_PERMISSION,
  REMOVE_PERMISSION,
  MODIFY_PERMISSION,
  VEHICLE_MAINTENANCE_COMPONENT_ENTITY,
} from "../../utils";
import { Link } from "react-router-dom";
import moment from "moment";
import { HasAccess } from "../../components/Access";
import ColumnText from "../../components/Column";

class MaintenanceDetailScreen extends Component {
  constructor(props) {
    super(props);
    this.downloadInvoice = this.downloadInvoice.bind(this);
    this.addMaintenanceComponentHandler =
      this.addMaintenanceComponentHandler.bind(this);
    this.editMaintenanceHandler = this.editMaintenanceHandler.bind(this);
    this.showMaintenanceComponentHandler =
      this.showMaintenanceComponentHandler.bind(this);
  }

  componentDidMount() {
    let data = {};
    data.conds = { "me.id": this.props.match.params.id };
    data.attrs = {};
    data.attrs["prefetch"] = [{ owning_customer: "owning_client" }, "vehicle"];
    // ["vehicle", {owning_customer: "owning_client"}]
    this.props.dispatch(fetchVehicleMaintenance(data));
  }

  downloadInvoice(url, mimeType, filename) {
    downloadFile(url, filename, mimeType);
  }

  addMaintenanceComponentHandler() {
    let url =
      "/admin/maintenance/" + this.props.match.params.id + "/components/add";
    this.props.history.push(url);
  }

  showMaintenanceComponentHandler() {
    let url =
      "/admin/maintenance/" + this.props.match.params.id + "/components";
    this.props.history.push(url);
  }

  showMaintenanceNoteHandler() {
    let url = "/admin/maintenance/" + this.props.match.params.id + "/notes";
    this.props.history.push(url);
  }
  editMaintenanceHandler() {
    let url = "/admin/maintenance/" + this.props.match.params.id + "/edit";
    this.props.history.push(url);
  }

  deleteMaintenanceHandler() {
    let rowId = this.props.match.params.id;
    let maintenance_data =
      typeof this.props.maintenanceData.rows.data[0] !== "undefined"
        ? this.props.maintenanceData.rows.data[0]
        : null;
    let successor =
      maintenance_data !== null ? maintenance_data.successor : null;
    let rowData = { post: { successor: null } };
    if (rowId !== null) {
      rowData["id"] = rowId;
    }
    if (successor !== null) {
      rowData["post"]["successor"] = successor;
    }
    rowData["url"] =
      "/data/core/vehicle_maintenance/" + rowId + "/transitions/remove";
    rowData["redirect_to"] = "/admin/maintenance";
    if (maintenance_data !== null && typeof rowId !== "undefined") {
      this.props.dispatch(showDeleteDialog(rowData));
    }
  }

  render() {
    let mntID = this.props.match.params.id;
    let customer_data =
      typeof this.props.maintenanceData.rows.data[0] !== "undefined"
        ? this.props.maintenanceData.rows.data[0]
        : null;
    let vehicle_data =
      customer_data !== null && typeof customer_data.vehicle !== "undefined"
        ? customer_data.vehicle
        : null;
    let owning_customer =
      customer_data !== null &&
        typeof customer_data.owning_customer !== "undefined"
        ? customer_data.owning_customer
        : null;

    let mnt_id = mntID;

    let vehicle_id =
      vehicle_data !== null && typeof vehicle_data.vin !== "undefined"
        ? vehicle_data.vin
        : "-";

    let customer_type =
      owning_customer !== null &&
        typeof owning_customer.is_client !== "undefined" &&
        owning_customer.is_client === 1
        ? 1
        : 2;

    let customer_name;

    if (customer_type === 1) {
      let owning_client =
        owning_customer !== null && typeof owning_customer.owning_client !== "undefined"
          ? owning_customer.owning_client
          : null;
      let customer_first_name =
        owning_client !== null && typeof owning_client.first_name !== "undefined"
          ? owning_client.first_name
          : "-";
      let customer_last_name =
        owning_client !== null && typeof owning_client.last_name !== "undefined"
          ? owning_client.last_name
          : "-";
      customer_name = customer_first_name + " " + customer_last_name;
    } else {
      customer_name = owning_customer !== null && typeof owning_customer.name !== "undefined" ? owning_customer.name : "-";
    }
    let service_date =
      customer_data !== null && typeof customer_data.date !== "undefined"
        ? moment(customer_data.date).format("DD.MM.YYYY")
        : "-";
    let service_note =
      customer_data !== null && typeof customer_data.note !== "undefined"
        ? customer_data.note
        : "-";
    let mileage =
      customer_data !== null && typeof customer_data.mileage !== "undefined"
        ? customer_data.mileage
        : "-";
    let service_invoice =
      customer_data !== null &&
        typeof customer_data.invoice !== "undefined" &&
        customer_data.invoice !== null
        ? JSON.parse(customer_data.invoice)
        : "-";

    let mime_type = service_invoice !== null ? service_invoice.mime_type : "";
    let name = service_invoice !== null ? service_invoice.original_name : "";
    let url = "data/core/vehicle_maintenance/" + mntID + "/download/invoice";

    return (
      <div className="wrapper">
        <div className="container-header">
          <h4>
            Details zur{" "}
            <ColumnText
              Module={CORE_MODULE}
              Entity={VEHICLE_MAINTENANCE_ENTITY}
            />
          </h4>
          <div className="btngroup">
            <HasAccess
              Module={CORE_MODULE}
              Entity={VEHICLE_MAINTENANCE_ENTITY}
              Label={ADD_PERMISSION}
            >
              <Button
                type="button"
                className="btn btn-dark"
                onClick={() => {
                  this.addMaintenanceComponentHandler();
                }}
              >
                <ColumnText
                  Module={CORE_MODULE}
                  Entity={VEHICLE_MAINTENANCE_COMPONENT_ENTITY}
                />{" "}
                hinzufügen
              </Button>
            </HasAccess>
            <HasAccess
              Module={CORE_MODULE}
              Entity={VEHICLE_MAINTENANCE_ENTITY}
              Label={MODIFY_PERMISSION}
            >
              <Button
                type="button"
                className="btn btn-dark"
                onClick={() => {
                  this.editMaintenanceHandler();
                }}
              >
                Bearbeiten
              </Button>
            </HasAccess>
            <HasAccess
              Module={CORE_MODULE}
              Entity={VEHICLE_MAINTENANCE_ENTITY}
              Label={REMOVE_PERMISSION}
            >
              <Button
                type="button"
                className="btn btn-dark"
                onClick={() => {
                  this.deleteMaintenanceHandler();
                }}
              >
                Delete
              </Button>
            </HasAccess>
          </div>
        </div>
        <div className="sub_main">
          <Row>
            <Col lg={6}>
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={VEHICLE_MAINTENANCE_ENTITY}
                    Label={"id"}
                  />
                </div>
                <div className="col-sm-8">{mnt_id}</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={VEHICLE_MAINTENANCE_ENTITY}
                    Label={"owning_customer"}
                  />
                </div>
                <div className="col-sm-8">{customer_name}</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={VEHICLE_MAINTENANCE_ENTITY}
                    Label={"vehicle"}
                  />
                </div>
                <div className="col-sm-8">{vehicle_id}</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={VEHICLE_MAINTENANCE_ENTITY}
                    Label={"date"}
                  />
                </div>
                <div className="col-sm-8">{service_date}</div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={VEHICLE_MAINTENANCE_ENTITY}
                    Label={"note"}
                  />
                </div>
                <div className="col-sm-8">{service_note}</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={VEHICLE_MAINTENANCE_ENTITY}
                    Label={"mileage"}
                  />
                </div>
                <div className="col-sm-8">{mileage}</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={VEHICLE_MAINTENANCE_ENTITY}
                    Label={"invoice"}
                  />
                </div>
                <div className="col-sm-8">
                  {url !== "" ? (
                    <Link
                      to="#"
                      onClick={() => this.downloadInvoice(url, mime_type, name)}
                    >
                      {name}
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Row className="container-footer">
          <div className="btngroup">
            <Button
              type="button"
              className="btn btn-dark"
              onClick={() => {
                this.showMaintenanceComponentHandler();
              }}
            >
              <ColumnText
                Module={CORE_MODULE}
                Entity={VEHICLE_MAINTENANCE_COMPONENT_ENTITY}
              />
            </Button>
            <HasAccess
              Module={CORE_MODULE}
              Entity={VEHICLE_MAINTENANCE_NOTE_ENTITY}
            >
              <Button
                type="button"
                className="btn btn-dark"
                onClick={() => {
                  this.showMaintenanceNoteHandler();
                }}
              >
                <ColumnText
                  Module={CORE_MODULE}
                  Entity={VEHICLE_MAINTENANCE_NOTE_ENTITY}
                />
              </Button>
            </HasAccess>
          </div>
        </Row>
      </div>
    );
  }
}

MaintenanceDetailScreen.propTypes = {};
MaintenanceDetailScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  maintenanceData: state.Maintenance.maintenanceData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaintenanceDetailScreen);
