import { Component } from "react";
import { connect } from "react-redux";
import { Form, Button, ButtonGroup } from "react-bootstrap";
import {
  updatePassword,
  resetUpdatePassword,
} from "../../store/actions/client";
import {
  inputErrorHandler,
  signalTransitionSuccess,
  signalTransitionError,
  removeEmptyValues,
} from "../../utils";

class ResetPasswordScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        old_password: "",
        new_password: "",
        confirm_password: "",
      },
      reset: {
        old_password: "",
        new_password: "",
        confirm_password: "",
      },
      errors: {
        old_password: "",
        new_password: "",
        confirm_password: "",
      },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.passwordData) !==
      JSON.stringify(this.props.passwordData)
    ) {
      let user =
        typeof this.props.passworData !== "undefined" &&
        typeof this.props.passworData.rows !== "undefined"
          ? this.props.passworData.rows
          : [];
      if (user.success === false) {
        let errors = this.state.errors;
        errors["old_password"] = "Bitte geben Sie das richtige Passwort ein";
        this.setState({ errors: errors });
      } else {
        if (this.props.passwordData.success) {
          signalTransitionSuccess("Update Success");
          this.props.dispatch(resetUpdatePassword());
          let url = "/admin/customer/";
          this.props.history.push(url);
        } else if (this.props.passwordData.error) {
          this.props.dispatch(resetUpdatePassword());
          signalTransitionError("Update Failed");
        }
      }
    }
  }

  resetHandler(evt) {
    this.setState({ fields: this.state.reset });
  }

  submitHandler(evt) {
    evt.preventDefault();
    let fields = this.state.fields;
    let newErrors = {};
    newErrors["old_password"] = inputErrorHandler(
      "",
      fields.old_password,
      true
    );
    newErrors["new_password"] = inputErrorHandler(
      "",
      fields.new_password,
      true
    );
    newErrors["confirm_password"] = inputErrorHandler(
      "",
      fields.confirm_password,
      true
    );
    if (
      fields.new_password !== fields.confirm_password &&
      newErrors["new_password"] === ""
    ) {
      newErrors["new_password"] =
        "Passwort & Passwort bestätigen sollten gleich sein";
    }

    let errors = removeEmptyValues(newErrors);
    // Conditional logic:
    if (Object.keys(errors).length > 0) {
      this.setState({ errors: errors });
    } else {
      let ClientData = {};
      ClientData.oldPassword = fields.old_password;
      ClientData.newPassword = fields.new_password;
      this.props.dispatch(updatePassword(ClientData));
    }
  }

  changeHandler(evt, name) {
    let value = evt.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: null,
      },
    }));
  }

  render() {
    // let customer_type = 2;

    // let core_module = "core";
    // let vehicle_entity = "vehicle";
    // let maintenance_entity = "vehicle_maintenance";
    // let customer_entity = "customer";
    // let system_module = "system";
    // let client_entity = "client";

    return (
      <div className="outer">
        <div className="inner">
          <h4>Passwort zurücksetzen</h4>
          <Form>
            <Form.Group controlId="old_password">
              <Form.Label>Altes Passwort</Form.Label>
              <Form.Control
                type="password"
                placeholder="Altes Passwort"
                name="old_password"
                value={this.state.fields.old_password}
                onChange={(evt) => this.changeHandler(evt, "old_password")}
                onKeyPress={this.onKeyPress}
                isInvalid={!!this.state.errors.old_password}
              />
              <Form.Control.Feedback type="invalid">
                {this.state.errors.old_password}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="new_password">
              <Form.Label>Neues Passwort</Form.Label>
              <Form.Control
                type="password"
                placeholder="Neues Passwort"
                name="new_password"
                value={this.state.fields.new_password}
                onChange={(evt) => this.changeHandler(evt, "new_password")}
                onKeyPress={this.onKeyPress}
                isInvalid={!!this.state.errors.new_password}
              />
              <Form.Control.Feedback type="invalid">
                {this.state.errors.new_password}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="confirm_password">
              <Form.Label>Passwort bestätigen</Form.Label>
              <Form.Control
                type="password"
                placeholder="Passwort bestätigen"
                name="confirm_password"
                value={this.state.fields.confirm_password}
                onChange={(evt) => this.changeHandler(evt, "confirm_password")}
                onKeyPress={this.onKeyPress}
                isInvalid={!!this.state.errors.confirm_password}
              />
              <Form.Control.Feedback type="invalid">
                {this.state.errors.confirm_password}
              </Form.Control.Feedback>
            </Form.Group>
            <ButtonGroup
              style={{ justifyContent: "flex-end", paddingTop: "10px" }}
            >
              <Button
                type="Button"
                className="btn btn-dark Button"
                onClick={(evt) => this.submitHandler(evt)}
              >
                OK
              </Button>
              <Button
                type="Button"
                className="btn btn-dark"
                onClick={(evt) => this.resetHandler(evt)}
              >
                Abbrechen
              </Button>
            </ButtonGroup>
          </Form>
        </div>
      </div>
    );
  }
}

ResetPasswordScreen.propTypes = {};
ResetPasswordScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  localeData: state.Locale.localeData,
  passwordData: state.Client.updatePasswordData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordScreen);
