import { call, all, put, takeLatest } from "redux-saga/effects";
import { authHeader } from "../../../utils";
import request from "../../../services/request";
import * as ActionTypes from "../../actions/constants/types";
import { logoutSuccess, logoutError } from "../../actions/logout";

function* logoutSaga({ data }) {
  try {
    const requestURL = "/plugins/logout";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data,
    });
    yield put(logoutSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(logoutError(error));
    }
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(ActionTypes.LOGOUT, logoutSaga)]);
}
