import * as Types from "../../actions/constants";

const initialState = {
  
  componentData:{
    loading: false,
    error: false,
    success: false,
    rows: { data: [], meta: [] }, 
  },
  indexData:{
    loading: false,
    error: false,
    success: false,
    rows: { data: [], meta: [] }, 
  },
  createData:{
    loading: false,
    error: false,
    success: false,
    rows: { data: [], meta: [] }, 
  },
  updateData:{
    loading: false,
    error: false,
    success: false,
    rows: { data: [], meta: [] }, 
  }
  
};

export default (state = initialState, action) => {
  console.log(action);
  switch (action.type) {
     case Types.GET_COMPONENT:
        return {
          ...state,
          componentData: {
            loading: true,
            error: false,
            success: false,
            rows: { data: [], meta: [] },
          },
        };
      case Types.COMPONENT_SUCCESS:
        return {
          ...state,
          componentData: {
            loading: false,
            error: false,
            success: true,
            rows: action.response.data,
          },
        };
      case Types.COMPONENT_ERROR:
        return {
          ...state,
          componentData: {
            loading: false,
            error: true,
            success: false,
            rows: action.response,
          },
        };
        case Types.GET_COMPONENT_BY_ID:
          return {
            ...state,
            indexData: {
              loading: true,
              error: false,
              success: false,
              rows: { data: [], meta: [] },
            },
          };
        case Types.COMPONENT_BY_ID_SUCCESS:
          return {
            ...state,
            indexData: {
              loading: false,
              error: false,
              success: true,
              rows: action.response.data,
            },
          };
        case Types.COMPONENT_BY_ID_ERROR:
          return {
            ...state,
            indexData: {
              loading: false,
              error: true,
              success: false,
              rows: action.response,
            },
          };  


        case Types.CREATE_COMPONENT:
          return {
            ...state,
            createData: {
              loading: true,
              error: false,
              success: false,
              rows: { data: [], meta: [] },
            },
          };
        case Types.CREATE_COMPONENT_SUCCESS:
          return {
            ...state,
            createData: {
              loading: false,
              error: false,
              success: true,
              rows: action.response.data,
            },
          };
        case Types.CREATE_COMPONENT_ERROR:
          return {
            ...state,
            createData: {
              loading: false,
              error: true,
              success: false,
              rows: action.error.error,
            },
          };  
          case Types.RESET_CREATE_COMPONENT:
            return {
              ...state,
              createData: {
                loading: false,
                error: false,
                success: false,
                rows: { data: [], meta: [] },
              },
            };      








            case Types.UPDATE_COMPONENT:
              return {
                ...state,
                updateData: {
                  loading: true,
                  error: false,
                  success: false,
                  rows: { data: [], meta: [] },
                },
              };
            case Types.UPDATE_COMPONENT_SUCCESS:
              return {
                ...state,
                updateData: {
                  loading: false,
                  error: false,
                  success: true,
                  rows: action.response.data,
                },
              };
            case Types.UPDATE_COMPONENT_ERROR:
              return {
                ...state,
                updateData: {
                  loading: false,
                  error: true,
                  success: false,
                  rows: action.error.error,
                },
              };  
              case Types.RESET_UPDATE_COMPONENT:
                return {
                  ...state,
                  updateData: {
                    loading: false,
                    error: false,
                    success: false,
                    rows: { data: [], meta: [] },
                  },
                };      
    






    default:
      return state;
  }
};
