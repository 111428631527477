import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Button, ButtonGroup } from "react-bootstrap";
import { setVehicleParams } from "../../store/actions/search";
import { DateTimePicker } from "react-tempusdominus-bootstrap";
import { removeEmptyValues } from "../../utils";
import moment from "moment";

import "font-awesome/css/font-awesome.css";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

class VehicleSearchScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        licence_plate: "",
        brand_name: "",
        model: "",
        customer_name: "",
        service_date: "",
        service_period_from: "",
        service_period_to: "",
      },
      errors: {
        licence_plate: "",
        brand_name: "",
        model: "",
        customer_name: "",
        service_date: "",
        service_period_from: "",
        service_period_to: "",
      },
      reset: {
        fields: {
          licence_plate: "",
          brand_name: "",
          model: "",
          customer_name: "",
          service_date: "",
          service_period_from: "",
          service_period_to: "",
        },
        errors: {
          licence_plate: "",
          brand_name: "",
          model: "",
          customer_name: "",
          service_date: "",
          service_period_from: "",
          service_period_to: "",
        },
      },
    };
    this.service_field = React.createRef();
    this.service_period_from_field = React.createRef();
    this.service_period_to_field = React.createRef();
    this.datePickerHandler = this.datePickerHandler.bind(this);
  }

  setServiceField = () => {
    if (
      typeof this.service_field.current[0] !== "undefined" &&
      typeof this.service_field.current[0].children[0] !== "undefined"
    ) {
      this.service_field.current[0].children[0].setAttribute(
        "placeholder",
        "Datum"
      );
    }
  };

  setPeriodFrom = () => {
    if (
      typeof this.service_period_from_field.current[0] !== "undefined" &&
      typeof this.service_period_from_field.current[0].children[0] !==
        "undefined"
    ) {
      this.service_period_from_field.current[0].children[0].setAttribute(
        "placeholder",
        "Datum"
      );
    }
  };

  setPeriodTo = () => {
    if (
      typeof this.service_period_to_field.current[0] !== "undefined" &&
      typeof this.service_period_to_field.current[0].children[0] !== "undefined"
    ) {
      this.service_period_to_field.current[0].children[0].setAttribute(
        "placeholder",
        "Datum"
      );
    }
  };

  resetHandler(evt) {
    this.setState({ fields: this.state.reset.fields });
    this.setState({ errors: this.state.reset.errors });
  }

  submitHandler(evt) {
    evt.preventDefault();
    let fields = this.state.fields;
    let newErrors = {};
    if (
      (fields.service_period_from !== "" || fields.service_period_to !== "") &&
      (fields.service_period_from === "" || fields.service_period_to === "")
    ) {
      if (fields.service_period_from === "") {
        newErrors["service_period_from"] = "Required parameter";
      }
      if (fields.service_period_to === "") {
        newErrors["service_period_to"] = "Required parameter";
      }
    }
    let errors = removeEmptyValues(newErrors);
    if (fields.service_date !== "") {
      fields.service_date = fields.service_date.format("YYYY-MM-DD");
    }
    if (fields.service_period_from !== "") {
      fields.service_period_from =
        fields.service_period_from.format("YYYY-MM-DD");
    }
    if (fields.service_period_to !== "") {
      fields.service_period_to = fields.service_period_to.format("YYYY-MM-DD");
    }
    if (Object.keys(errors).length > 0) {
      this.setState({ errors: errors });
    } else {
      this.props.dispatch(setVehicleParams(fields));
      let url = "/admin/vehicles";
      this.props.historyRef.push(url);
    }
  }

  changeHandler(evt, name) {
    let value = evt.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: null,
      },
    }));
  }

  datePickerHandler(value, name) {
    if (name === "service_period_from") {
      this.service_period_to_field.current.datetimepicker("minDate", value);
    } else if (name === "service_period_to") {
      this.service_period_from_field.current.datetimepicker("maxDate", value);
    }
    let items = this.state.fields;
    items[name] = value;
    this.setState({ fields: items });
    let errors = this.state.errors;
    errors[name] = "";
    this.setState({ errors: errors });
  }

  render() {
    // let customer_type = 2;

    // let core_module = "core";
    // let vehicle_entity = "vehicle";
    // let maintenance_entity = "vehicle_maintenance";
    // let customer_entity = "customer";
    // let system_module = "system";
    // let client_entity = "client";

    return (
      <Form>
        <Row>
          <Col lg={3}>
            <Form.Group controlId="licence_plate">
              <Form.Label>Fahrgestellnummer suche</Form.Label>
              <Form.Control
                type="text"
                placeholder="Fahrgestellnummer"
                name="licence_plate"
                value={this.state.fields.licence_plate}
                onChange={(evt) => this.changeHandler(evt, "licence_plate")}
                onKeyPress={this.onKeyPress}
                isInvalid={!!this.state.errors.licence_plate}
              />
              <Form.Control.Feedback type="invalid">
                {this.state.errors.licence_plate}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg={3}>
            <Form.Group controlId="brand_name">
              <Form.Label>Marke Suche</Form.Label>
              <Form.Control
                type="text"
                placeholder="Marke"
                name="brand_name"
                value={this.state.fields.brand_name}
                onChange={(evt) => this.changeHandler(evt, "brand_name")}
                onKeyPress={this.onKeyPress}
                isInvalid={!!this.state.errors.brand_name}
              />
              <Form.Control.Feedback type="invalid">
                {this.state.errors.brand_name}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg={3}>
            <Form.Group controlId="model">
              <Form.Label>Modell Suche</Form.Label>
              <Form.Control
                type="text"
                placeholder="Modell"
                name="model"
                value={this.state.fields.model}
                onChange={(evt) => this.changeHandler(evt, "model")}
                onKeyPress={this.onKeyPress}
                isInvalid={!!this.state.errors.model}
              />
              <Form.Control.Feedback type="invalid">
                {this.state.errors.model}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg={3}>
            <Form.Group controlId="customer_name">
              <Form.Label>Kundensuche</Form.Label>
              <Form.Control
                type="text"
                placeholder="Kundenname"
                name="customer_name"
                value={this.state.fields.customer_name}
                onChange={(evt) => this.changeHandler(evt, "customer_name")}
                onKeyPress={this.onKeyPress}
                isInvalid={!!this.state.errors.customer_name}
              />
              <Form.Control.Feedback type="invalid">
                {this.state.errors.customer_name}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col lg={3}>
            <Form.Group controlId="service_date">
              <Form.Label>Letzte Wartung</Form.Label>
              <DateTimePicker
                date={this.state.fields.service_date}
                pickerRef={this.service_field}
                callback={() => this.setServiceField()}
                format="YYYY-MM-DD"
                onChange={(e) => this.datePickerHandler(e.date, "service_date")}
                // maxDate={moment().format("YYYY-MM-DD")}
              />
              {typeof this.state.errors.service_date !== "undefined" &&
              this.state.errors.service_date !== "" ? (
                <div className="has-error">
                  {this.state.errors.service_date}
                </div>
              ) : (
                ""
              )}
            </Form.Group>
          </Col>
          <Col lg={3}>
            <Form.Group controlId="service_period_from">
              <Form.Label>Letzte Wartung(von)</Form.Label>
              <DateTimePicker
                date={this.state.fields.service_period_from}
                format="YYYY-MM-DD"
                pickerRef={this.service_period_from_field}
                callback={() => this.setPeriodFrom()}
                onChange={(e) =>
                  this.datePickerHandler(e.date, "service_period_from")
                }
              />
              {typeof this.state.errors.service_period_from !== "undefined" &&
              this.state.errors.service_period_from !== "" ? (
                <div className="has-error">
                  {this.state.errors.service_period_from}
                </div>
              ) : (
                ""
              )}
            </Form.Group>
          </Col>
          <Col lg={3}>
            <Form.Group controlId="service_period_to">
              <Form.Label>Letzte Wartung(bis)</Form.Label>
              <DateTimePicker
                date={this.state.fields.service_period_to}
                format="YYYY-MM-DD"
                pickerRef={this.service_period_to_field}
                callback={() => this.setPeriodTo()}
                onChange={(e) =>
                  this.datePickerHandler(e.date, "service_period_to")
                }
              />
              {typeof this.state.errors.service_period_to !== "undefined" &&
              this.state.errors.service_period_to !== "" ? (
                <div className="has-error">
                  {this.state.errors.service_period_to}
                </div>
              ) : (
                ""
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row className={"search-btn-row"}>
          <div className="form-search-btn">
            <Button
              type="Button"
              className="btn btn-dark Button"
              onClick={(evt) => this.submitHandler(evt)}
            >
              OK
            </Button>
            <Button
              type="Button"
              className="btn btn-dark"
              onClick={(evt) => this.resetHandler(evt)}
            >
              Abbrechen
            </Button>
          </div>
        </Row>
      </Form>
    );
  }
}

VehicleSearchScreen.propTypes = {};
VehicleSearchScreen.defaultProps = { historyRef: "" };

const mapStateToProps = (state) => ({
  customerData: state.Customer.customerData,
  vehicleData: state.Vehicle.vehicleData,
  localeData: state.Locale.localeData,
  updateData: state.Vehicle.updateData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleSearchScreen);
