import * as Types from "../constants/types";

// login actions
export function login(data) {
    return {
        type: Types.LOGIN,
        data
    };
};
export function loginSuccess(response) {
    return {
        type: Types.LOGIN_SUCCESS,
        response
    };
};

export function loginError(error) {
    return {
        type: Types.LOGIN_ERROR,
        error
    };
};


/**
 * Abbrechen login form error
 *
 * @return {object}    An action object with a type of LOGIN_RESET
 */
 export function resetAlert() {
    return {
        type: Types.RESET_ALERT,
    };
}
