import { Component } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { fetchCustomerNoteById } from "../../store/actions/customer";
import { hideModalDialog, showDeleteDialog } from "../../store/actions/dialog";
import {
  CloseBtn,
  downloadFile,
  DelBtn,
  EditBtn,
  CORE_MODULE,
  CUSTOMER_NOTE_ENTITY,
  EDIT_PERMISSION,
  DELETE_PERMISSION,
} from "../../utils";
import moment from "moment";
import { Link } from "react-router-dom";
import { HasAccess } from "../../components/Access";
import ColumnText from "../../components/Column";

class CustomerNoteDetailScreen extends Component {
  constructor(props) {
    super(props);
    this.downloadAttachment = this.downloadAttachment.bind(this);
    this.closeHandler = this.closeHandler.bind(this);
    this.editNoteHandler = this.editNoteHandler.bind(this);
    this.deleteNoteHandler = this.deleteNoteHandler.bind(this);
  }

  componentDidMount() { }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.noteId !== "" &&
      JSON.stringify(prevProps.noteId) !== JSON.stringify(this.props.noteId)
    ) {
      let data = {};
      data.conds = { "me.id": this.props.noteId };
      data.attrs = {};
      this.props.dispatch(fetchCustomerNoteById(data));
    }
  }
  closeHandler = () => {
    this.props.dispatch(hideModalDialog());
    //this.props.dispatch(resetDialog)
  };
  downloadAttachment(url, mimeType, filename) {
    downloadFile(url, filename, mimeType);
  }

  editNoteHandler = (id) => {
    let url = "/admin/customers/" + this.props.customerId + "/notes/" + id + "/edit";
    this.props.pushRef.push(url);
  };

  deleteNoteHandler = () => {
    let note_data =
      typeof this.props.indexData.rows.data[0] !== "undefined"
        ? this.props.indexData.rows.data[0]
        : null;
    let note_id =
      typeof note_data !== "undefined" &&
        typeof note_data.id !== "undefined" &&
        note_data.id !== null
        ? note_data.id
        : null;

    let successor = note_data !== null ? note_data.successor : null;
    let rowData = {};
    if (note_id !== null) {
      rowData["id"] = note_id;
    }
    if (successor !== null) {
      rowData["successor"] = successor;
    }
    rowData["url"] =
      "/data/core/customer_note/" + note_id + "/transitions/delete";
    rowData["redirect_to"] = "/admin/customers/" + this.props.customerId + "/notes";
    if (note_data !== null && typeof note_id !== "undefined") {
      this.props.dispatch(showDeleteDialog(rowData));
    }
  };

  render() {
    let note_data =
      typeof this.props.indexData.rows.data[0] !== "undefined"
        ? this.props.indexData.rows.data[0]
        : null;
    let creation_date =
      note_data !== null &&
        typeof note_data.modification_time !== "undefined" &&
        note_data.modification_time !== null
        ? moment(note_data.modification_time).format("YYYY.MM.DD")
        : "-";
    let content =
      note_data !== null &&
        typeof note_data.content !== "undefined" &&
        note_data.content !== null
        ? note_data.content
        : "-";
    let cell =
      note_data !== null &&
        typeof note_data.attachment !== "undefined" &&
        note_data.attachment !== null
        ? JSON.parse(note_data.attachment)
        : null;
    let mime_type = cell !== null ? cell.mime_type : "";
    let name = cell !== null ? cell.original_name : "";
    let url =
      "data/core/customer_note/" + this.props.noteId + "/download/attachment";

    return (
      <div
        id="flyoutMenu"
        className={
          this.props.dialogData.modalDialog &&
            this.props.dialogData.modalName === "Customer-Note"
            ? "show"
            : "hide"
        }
      >
        <Button
          variant="link"
          onClick={() => {
            this.closeHandler();
          }}
        >
          <CloseBtn />
        </Button>
        <h5><ColumnText Module={CORE_MODULE} Entity={CUSTOMER_NOTE_ENTITY} /></h5>
        <div className="sub_main">
          <div className="form-group row">
            <div className="col-sm-12">
              <ColumnText
                Module={CORE_MODULE}
                Entity={CUSTOMER_NOTE_ENTITY}
                Label={"modification_time"}
              />:
            </div>
            <div className="col-sm-12">{creation_date}</div>
          </div>
          <div className="form-group row">
            <div className="col-sm-12">
              <ColumnText
                Module={CORE_MODULE}
                Entity={CUSTOMER_NOTE_ENTITY}
                Label={"content"}
              />:
            </div>
            <div className="col-sm-12">{content}</div>
          </div>
          <div className="form-group row">
            <div className="col-sm-4">
              <ColumnText
                Module={CORE_MODULE}
                Entity={CUSTOMER_NOTE_ENTITY}
                Label={"attachment"}
              />:
            </div>
            <div className="col-sm-8">
              {url !== "" ? (
                <Link
                  to="#"
                  onClick={() => this.downloadAttachment(url, mime_type, name)}
                >
                  {name}
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
          <HasAccess
            Module={CORE_MODULE}
            Entity={CUSTOMER_NOTE_ENTITY}
            Label={EDIT_PERMISSION}
          >
            <Button
              variant="link"
              onClick={() => {
                this.editNoteHandler(this.props.noteId);
              }}
            >
              <EditBtn />
            </Button>
          </HasAccess>
          <HasAccess
            Module={CORE_MODULE}
            Entity={CUSTOMER_NOTE_ENTITY}
            Label={DELETE_PERMISSION}
          >
            <Button
              variant="link"
              onClick={() => {
                this.deleteNoteHandler();
              }}
            >
              <DelBtn />
            </Button>
          </HasAccess>
        </div>
      </div>
    );
  }
}

CustomerNoteDetailScreen.propTypes = {};
CustomerNoteDetailScreen.defaultProps = {
  noteId: "",
  pushRef: "",
};

const mapStateToProps = (state) => ({
  indexData: state.CNote.indexData,
  //noteData: state.CNote.noteData,
  dialogData: state.Dialog.dialogData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerNoteDetailScreen);
