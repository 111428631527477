import { Component } from "react";
import { connect } from "react-redux";

import ComponentScreen from "../Vehicle/ComponentScreen";
import DocumentScreen from "../Vehicle/DocumentScreen";
import ClientScreen from "../Client/ClientScreen";

class SettingScreen extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="wrapper_datatable">
        <h4>Einstellungen</h4>
        <hr />
        <ComponentScreen />
        <hr />
        <DocumentScreen />
        <hr />
        <ClientScreen historyRef={this.props.history} />
      </div>
    );
  }
}

SettingScreen.propTypes = {};
SettingScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  localeData: state.Locale.localeData,
  componentData: state.Component.componentData,
  documentData: state.Document.documentData,
  clientData: state.Client.clientData,
  dialogData: state.Dialog.dialogData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(mapStateToProps, mapDispatchToProps)(SettingScreen);
