import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  PagingOptions,
  NoDataIndication,
  DelBtn,
  EditBtn,
  downloadFile,
  CORE_MODULE,
  CUSTOMER_NOTE_ENTITY,
  DELETE_PERMISSION,
  CREATE_PERMISSION,
  NO_DATA,
  EDIT_PERMISSION,
} from "../../utils";
import { fetchCustomerNote } from "../../store/actions/customer";
import { showDeleteDialog, showModalDialog } from "../../store/actions/dialog";
import CustomerNoteDetail from "./CustomerNoteDetailScreen";

import moment from "moment";
import { Link } from "react-router-dom";
import { HasAccess } from "../../components/Access";

import ColumnText from "../../components/Column";

class CustomerNoteScreen extends Component {
  customerId = null;

  constructor(props) {
    super(props);
    this.state = {
      sort: [
        {
          dataField: "id",
          order: "desc",
        },
      ],
      id: "",
    };
    this.customerId = props.match.params.cid;
    this.handleTableChange = this.handleTableChange.bind(this);
    this.editNoteHandler = this.editNoteHandler.bind(this);
    this.deleteNoteHandler = this.deleteNoteHandler.bind(this);
    this.addNoteHandler = this.addNoteHandler.bind(this);
    this.viewNoteHandler = this.viewNoteHandler.bind(this);
  }
  renderColumns = () => {
    return [
      {
        dataField: "modification_time",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={CUSTOMER_NOTE_ENTITY}
            Label={"modification_time"}
          />
        ),
        sort: true,
        headerClasses: "cell-header cell-cnote-modification_time-header",
        classes: "cell-cnote-modification_time",
        formatter: (cellContent, row) => {
          let modification_time =
            cellContent !== null && typeof cellContent !== "undefined"
              ? moment(cellContent).format("DD.MM.YYYY")
              : "";
          return <>{modification_time}</>;
        },
      },
      {
        dataField: "content",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={CUSTOMER_NOTE_ENTITY}
            Label={"content"}
          />
        ),
        sort: true,
        headerClasses: "cell-header cell-cnote-content-header",
        classes: "cell-cnote-content",
        formatter: (cellContent, row) => {
          return (
            <>
              {" "}
              <Link to="#" onClick={() => this.viewNoteHandler(row)}>
                {cellContent}
              </Link>
            </>
          );
        },
      },
      {
        dataField: "attachment",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={CUSTOMER_NOTE_ENTITY}
            Label={"attachment"}
          />
        ),
        sort: true,
        headerClasses: "cell-header cell-cnote-attachment-header",
        classes: "cell-cnote-attachment",
        formatter: (cellContent, row) => {
          let cell =
            cellContent !== null && typeof cellContent !== "undefined"
              ? JSON.parse(cellContent)
              : {};
          //let url = (cell !== {})?cell.path:'';
          let mime_type = cell !== {} ? cell.mime_type : "";
          let name = cell !== {} ? cell.original_name : "";
          let url =
            "data/core/customer_note/" + row.id + "/download/attachment";
          if (url !== "") {
            return (
              <>
                {" "}
                <Link
                  to="#"
                  onClick={() => this.downloadAttachment(url, mime_type, name)}
                >
                  {name}
                </Link>
              </>
            );
          }
        },
      },
      {
        dataField: "id",
        text: "",
        sort: false,
        headerClasses:
          "cell-header cell-actions-header cell-cnote-actions-header",
        classes: "cell-cnote-actions",
        formatter: (cellContent, row) => {
          return (
            <div className="svg">
              <HasAccess
                Module={CORE_MODULE}
                Entity={CUSTOMER_NOTE_ENTITY}
                Label={EDIT_PERMISSION}
              >
                <Link
                  to={"#"}
                  onClick={() => {
                    this.editNoteHandler(row);
                  }}
                >
                  <EditBtn />
                </Link>
              </HasAccess>
              <HasAccess
                Module={CORE_MODULE}
                Entity={CUSTOMER_NOTE_ENTITY}
                Label={DELETE_PERMISSION}
              >
                <Link
                  to={"#"}
                  onClick={() => {
                    this.deleteNoteHandler(row);
                  }}
                >
                  <DelBtn />
                </Link>
              </HasAccess>
            </div>
          );
        },
      },
    ];
  };
  componentDidMount() {
    let defaultOrder = this.state.sort;
    let sortOrderstring = "-" + defaultOrder[0]["order"];
    let data = {};
    data.attrs = {};
    data.attrs["order_by"] = {};
    data.attrs.order_by[sortOrderstring] = "me." + defaultOrder[0]["dataField"];
    data.conds = {};
    if (this.customerId !== null) {
      data.conds["me.customer"] = this.customerId;
    }
    data.page = 1;
    data.page_size = PagingOptions.sizePerPage;
    this.props.dispatch(fetchCustomerNote(data));
  }

  handleTableChange(
    type,
    { filters, page, sortField, sortOrder, sizePerPage }
  ) {
    let defaultOrder = this.state.sort;
    let sortOrderstring =
      typeof sortOrder !== "undefined"
        ? "-" + sortOrder
        : "-" + defaultOrder[0]["order"];
    let data = {};
    data.attrs = {};
    data.attrs["order_by"] = {};
    data.attrs.order_by[sortOrderstring] =
      sortField !== null
        ? "me." + sortField
        : "me." + defaultOrder[0]["dataField"];

    data.conds = {};
    if (this.customerId !== null) {
      data.conds["me.customer"] = this.customerId;
    }
    data.page = page;
    data.page_size = sizePerPage;

    this.props.dispatch(fetchCustomerNote(data));
  }
  downloadAttachment(url, mimeType, filename) {
    downloadFile(url, filename, mimeType);
  }

  addNoteHandler() {
    let url = "/admin/customers/" + this.props.match.params.cid + "/notes/add";
    this.props.history.push(url);
  }

  editNoteHandler(row) {
    let url =
      "/admin/customers/" +
      this.props.match.params.cid +
      "/notes/" +
      row.id +
      "/edit";
    this.props.history.push(url);
  }

  viewNoteHandler(row) {
    this.state.id = row.id;
    this.props.dispatch(showModalDialog("Customer-Note"));
  }

  deleteNoteHandler(row) {
    let rowId = row.id;
    let note_data =
      typeof this.props.noteData.rows.data[0] !== "undefined"
        ? this.props.noteData.rows.data[0]
        : null;
    let successor = note_data !== null ? note_data.successor : null;
    let rowData = {};
    if (rowId !== null) {
      rowData["id"] = rowId;
    }
    if (successor !== null) {
      rowData["successor"] = successor;
    }
    rowData["url"] =
      "/data/core/customer_note/" + rowId + "/transitions/delete";
    rowData["redirect_to"] =
      "/admin/customers/" + this.props.match.params.cid + "/notes";
    if (note_data !== null && typeof rowId !== "undefined") {
      this.props.dispatch(showDeleteDialog(rowData));
    }
  }

  render() {
    let options = PagingOptions;
    options.totalSize =
      typeof this.props.noteData !== "undefined" &&
      typeof this.props.noteData.rows !== "undefined" &&
      typeof this.props.noteData.rows.meta.filtered !== "undefined"
        ? this.props.noteData.rows.meta.filtered
        : "";
    options.sizePerPage =
      typeof this.props.noteData !== "undefined" &&
      typeof this.props.noteData.rows !== "undefined" &&
      typeof this.props.noteData.rows.meta.page_size !== "undefined"
        ? this.props.noteData.rows.meta.page_size
        : 10;
    let data =
      typeof this.props.noteData !== "undefined" &&
      typeof this.props.noteData.rows !== "undefined" &&
      typeof this.props.noteData.rows.data !== "undefined"
        ? this.props.noteData.rows.data
        : [];
    let columns = this.renderColumns();

    return (
      <div>
        <div className="wrapper_datatable">
          <div className="table_main">
            <h4>
              <ColumnText Module={CORE_MODULE} Entity={CUSTOMER_NOTE_ENTITY} />
            </h4>
            <HasAccess
              Module={CORE_MODULE}
              Entity={CUSTOMER_NOTE_ENTITY}
              Label={CREATE_PERMISSION}
            >
              <button
                type="button"
                className="btn btn-dark"
                onClick={() => {
                  this.addNoteHandler();
                }}
              >
                {" "}
                <ColumnText
                  Module={CORE_MODULE}
                  Entity={CUSTOMER_NOTE_ENTITY}
                />{" "}
                hinzufügen
              </button>
            </HasAccess>
          </div>
          <BootstrapTable
            remote={{ pagination: true }}
            bootstrap4
            keyField="id"
            data={data}
            columns={columns}
            bordered={false}
            rowClasses={"table-row"}
            defaultSorted={this.state.sorted}
            pagination={paginationFactory(options)}
            onTableChange={this.handleTableChange}
            noDataIndication={() =>
              this.props.noteData.loading ? <NoDataIndication /> : NO_DATA
            }
          />
          <CustomerNoteDetail
            customerId={this.props.match.params.cid}
            noteId={this.state.id}
            pushRef={this.props.history}
          />
        </div>
      </div>
    );
  }
}

CustomerNoteScreen.propTypes = {};
CustomerNoteScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  noteData: state.CNote.noteData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(mapStateToProps, mapDispatchToProps)(CustomerNoteScreen);
