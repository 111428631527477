import React, { Component } from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  PagingOptions,
  NoDataIndication,
  DelBtn,
  EditBtn,
  downloadFile,
  CORE_MODULE,
  VEHICLE_FILE_ENTITY,
  DELETE_PERMISSION,
  CREATE_PERMISSION,
  NO_DATA,
  EDIT_PERMISSION,
} from "../../utils";
import { fetchVehicleDocument } from "../../store/actions/vehicle";
import moment from "moment";
import { Link } from "react-router-dom";
import { showDeleteDialog } from "../../store/actions/dialog";
import { HasAccess } from "../../components/Access";
import ColumnText from "../../components/Column";

class VehicleDocumentScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: [
        {
          dataField: "id",
          order: "desc",
        },
      ],
    };
    this.addHandler = this.addHandler.bind(this);
    this.downloadDocument = this.downloadDocument.bind(this);
    this.editHandler = this.editHandler.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.vehicleDetailHandler = this.vehicleDetailHandler.bind(this);
  }
  renderColumns() {
    return [
      {
        dataField: "vehicle.vin",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={VEHICLE_FILE_ENTITY}
            Label={"vehicle"}
          />
        ),
        sort: true,
        headerClasses: "cell-header cell-doc-vehicle-header",
        classes: "cell-doc-vehicle",
        formatter: (cellContent, row) => {
          return (
            <>
              <Link
                to={"#"}
                onClick={() => {
                  this.vehicleDetailHandler(row);
                }}
              >
                {cellContent}
              </Link>
            </>
          );
        },
      },
      {
        dataField: "vehicle_document_type.name",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={VEHICLE_FILE_ENTITY}
            Label={"vehicle_document_type"}
          />
        ),
        sort: true,
        headerClasses: "cell-header cell-doc-type-header",
        classes: "cell-doc-type",
      },
      {
        dataField: "file",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={VEHICLE_FILE_ENTITY}
            Label={"file"}
          />
        ),
        sort: true,
        headerClasses: "cell-header cell-doc-file-header",
        classes: "cell-doc-file",
        formatter: (cellContent, row) => {
          let cell =
            cellContent !== null && typeof cellContent !== "undefined"
              ? JSON.parse(cellContent)
              : {};
          let mime_type = cell !== {} ? cell.mime_type : "";
          let name = cell !== {} ? cell.original_name : "";
          let url = "data/core/vehicle_file/" + row.id + "/download/file";
          if (url !== "") {
            return (
              <>
                {" "}
                <Link
                  to="#"
                  onClick={() => this.downloadDocument(url, mime_type, name)}
                >
                  {name}
                </Link>
              </>
            );
          }
        },
      },
      {
        dataField: "date",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={VEHICLE_FILE_ENTITY}
            Label={"date"}
          />
        ),
        sort: true,
        headerClasses: "cell-header cell-doc-document_date-header",
        classes: "cell-doc-document_date",
        formatter: (cellContent, row) => {
          if (
            cellContent !== "" &&
            cellContent !== null &&
            typeof cellContent !== "undefined"
          ) {
            return <>{moment(cellContent).format("YYYY.MM.DD")}</>;
          }
        },
      },

      {
        dataField: "id",
        text: "",
        sort: false,
        headerClasses:
          "cell-header cell-actions-header cell-doc-actions-header",
        classes: "cell-actions cell-doc-actions",
        formatter: (cellContent, row) => {
          return (
            <div className="svg">
              <HasAccess
                Module={CORE_MODULE}
                Entity={VEHICLE_FILE_ENTITY}
                Label={EDIT_PERMISSION}
              >
                <Link
                  to={"#"}
                  onClick={() => {
                    this.editHandler(row);
                  }}
                >
                  <EditBtn />
                </Link>
              </HasAccess>
              <HasAccess
                Module={CORE_MODULE}
                Entity={VEHICLE_FILE_ENTITY}
                Label={DELETE_PERMISSION}
              >
                <Link
                  to={"#"}
                  onClick={() => {
                    this.deleteHandler(row);
                  }}
                >
                  <DelBtn />
                </Link>
              </HasAccess>
            </div>
          );
        },
      },
    ];
  }
  componentDidMount() {
    // let defaultOrder = this.state.sort;
    // let sortOrder = "-" + defaultOrder[0]["order"];
    // let sortField = "me." + defaultOrder[0]["dataField"];
    // let data = {};
    // data.attrs = {};
    // data.attrs['prefetch'] = ["vehicle","vehicle_document_type",{"owning_customer":"owning_client"}];
    // data.attrs["order_by"] = {};
    // data.attrs.order_by[sortOrder] = sortField;
    // data.conds = {};
    // if(this.props.match.params.vid !== null) {
    //   data.conds["vehicle.id"] = this.props.match.params.vid;
    // }
    // data.page = 1;
    // data.page_size = PagingOptions.sizePerPage;
    // this.props.dispatch(fetchVehicleDocument(data));
  }

  handleTableChange(
    type,
    { filters, page, sortField, sortOrder, sizePerPage }
  ) {
    let defaultOrder = this.state.sort;
    let sortOrderstring =
      typeof sortOrder !== "undefined"
        ? "-" + sortOrder
        : "-" + defaultOrder[0]["order"];
    let sort_field =
      defaultOrder[0]["dataField"].indexOf(".") === -1
        ? "me." + defaultOrder[0]["dataField"]
        : defaultOrder[0]["dataField"];
    if (sortField !== null) {
      sort_field =
        sortField.indexOf(".") === -1 ? "me." + sortField : sortField;
    }
    let data = {};
    data.attrs = {};
    data.attrs["prefetch"] = [
      "vehicle",
      "vehicle_document_type",
      { owning_customer: "owning_client" },
    ];
    data.attrs["order_by"] = {};
    data.attrs.order_by[sortOrderstring] = sort_field;

    data.conds = {};
    if (this.props.match.params.vid !== null) {
      data.conds["vehicle.id"] = this.props.match.params.vid;
    }
    data.page = page;
    data.page_size = sizePerPage;
    this.props.dispatch(fetchVehicleDocument(data));
  }

  downloadDocument(url, mimeType, filename) {
    downloadFile(url, filename, mimeType);
  }

  addHandler = () => {
    let url =
      "/admin/vehicles/" + this.props.match.params.vid + "/documents/add";
    this.props.history.push(url);
  };
  editHandler = (row) => {
    let url =
      "/admin/vehicles/" + row.vehicle.id + "/documents/" + row.id + "/edit";
    this.props.history.push(url);
  };

  deleteHandler = (row) => {
    let rowId = row.id;
    let successor = row !== null ? row.successor : null;
    let rowData = { post: { successor: null } };
    if (rowId !== null) {
      rowData["id"] = rowId;
    }
    if (successor !== null) {
      rowData["post"]["successor"] = successor;
    }
    rowData["url"] = "/data/core/vehicle_file/" + rowId + "/transitions/delete";
    rowData["redirect_to"] = "/admin/vehicles/" + row.vehicle.id + "/documents";
    if (row !== null && typeof rowId !== "undefined") {
      this.props.dispatch(showDeleteDialog(rowData));
    }
  };
  vehicleDetailHandler = (row) => {
    let url = "/admin/vehicles/view/" + row.vehicle.id;
    this.props.history.push(url);
  };
  customerDetailHandler = (row) => {
    let url = "/admin/customers/" + row.owning_customer.id;
    this.props.history.push(url);
  };

  render() {
    let options = PagingOptions;
    options.totalSize =
      typeof this.props.documentData !== "undefined" &&
        typeof this.props.documentData.rows !== "undefined" &&
        typeof this.props.documentData.rows.meta.filtered !== "undefined"
        ? this.props.documentData.rows.meta.filtered
        : "";
    options.sizePerPage =
      typeof this.props.documentData !== "undefined" &&
        typeof this.props.documentData.rows !== "undefined" &&
        typeof this.props.documentData.rows.meta.page_size !== "undefined"
        ? this.props.documentData.rows.meta.page_size
        : 10;
    let vehicle_data =
      typeof this.props.documentData !== "undefined" &&
        typeof this.props.documentData.rows !== "undefined" &&
        typeof this.props.documentData.rows.data !== "undefined"
        ? this.props.documentData.rows.data
        : [];
    let columns = this.renderColumns();

    return (
      <div className="wrapper_datatable">
        <div className="table_main">
          <h4>
            <ColumnText Module={CORE_MODULE} Entity={VEHICLE_FILE_ENTITY} />
          </h4>

          <div className="btngroup">
            <HasAccess
              Module={CORE_MODULE}
              Entity={VEHICLE_FILE_ENTITY}
              Label={CREATE_PERMISSION}
            >
              <button
                type="button"
                className="btn btn-dark"
                onClick={() => {
                  this.addHandler();
                }}
              >
                {" "}
                <ColumnText
                  Module={CORE_MODULE}
                  Entity={VEHICLE_FILE_ENTITY}
                />{" "}
                hinzufügen
              </button>
            </HasAccess>
          </div>
        </div>
        <BootstrapTable
          remote={{ pagination: true }}
          bootstrap4
          keyField="id"
          data={vehicle_data}
          columns={columns}
          bordered={false}
          rowClasses={"table-row"}
          defaultSorted={this.state.sort}
          pagination={paginationFactory(options)}
          onTableChange={this.handleTableChange}
          noDataIndication={() =>
            this.props.documentData.loading ? <NoDataIndication /> : NO_DATA
          }
        />
      </div>
    );
  }
}

VehicleDocumentScreen.propTypes = {};
VehicleDocumentScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  documentData: state.VDocument.documentData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleDocumentScreen);
