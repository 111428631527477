import { call, all, put, takeLatest } from "redux-saga/effects";
import { authHeader } from "../../../utils";
import request from "../../../services/request";
import * as ActionTypes from "../../actions/constants";
import {
  updateVehicleNoteSuccess,
  updateVehicleNoteError,
  createVehicleNoteSuccess,
  createVehicleNoteError,
  fetchVehicleNoteByIdSuccess,
  fetchVehicleNoteByIdError,
  fetchGeneralVehicleNoteSuccess,
  fetchGeneralVehicleNoteError,
  fetchInternalVehicleNoteSuccess,
  fetchInternalVehicleNoteError,
} from "../../actions/vehicle";

function* generalSaga({ data }) {
  try {
    const requestURL = "/data/core/vehicle_customer_note/query";

    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data,
    });
    yield put(fetchGeneralVehicleNoteSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(fetchGeneralVehicleNoteError(error));
    }
  }
}

function* internalSaga({ data }) {
  try {
    const requestURL = "/data/core/vehicle_note/query";

    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data,
    });
    yield put(fetchInternalVehicleNoteSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(fetchInternalVehicleNoteError(error));
    }
  }
}

function* indexSaga({ data }) {
  try {
    let note_type =
      typeof data.note_type !== "undefined" && data.note_type !== null
        ? data.note_type
        : "0";
    let post =
      typeof data.post !== "undefined" && data.post !== null ? data.post : {};
    let requestURL = "/data/core/vehicle_note/query";
    if (note_type === "GENERAL") {
      requestURL = "/data/core/vehicle_customer_note/query";
    }
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: post,
    });
    yield put(fetchVehicleNoteByIdSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(fetchVehicleNoteByIdError(error));
    }
  }
}

function* updateSaga({ data }) {
  try {
    let note_id =
      typeof data.note_id !== "undefined" && data.note_id !== null
        ? data.note_id
        : null;
    let noteData =
      typeof data.vehicle !== "undefined" && data.vehicle !== null
        ? data.vehicle
        : null;
    let note_type =
      typeof data.note_type !== "undefined" && data.note_type !== null
        ? data.note_type
        : "0";
    let requestURL = "/data/core/vehicle_note/" + note_id + "/transitions/edit";
    if (note_type === "GENERAL") {
      requestURL =
        "/data/core/vehicle_customer_note/" + note_id + "/transitions/edit";
    }

    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: noteData,
    });
    yield put(updateVehicleNoteSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(updateVehicleNoteError(error));
    }
  }
}

function* createSaga({ data }) {
  try {
    //let maintenance_id = ((typeof(data.maintenance_id) !== 'undefined')&&(data.maintenance_id !== null))?data.maintenance_id:null;
    let vehicleData =
      typeof data.vehicle !== "undefined" && data.vehicle !== null
        ? data.vehicle
        : null;
    let note_type =
      typeof data.note_type !== "undefined" && data.note_type !== null
        ? data.note_type
        : "0";
    let requestURL = "/data/core/vehicle_note/transitions/create";
    if (note_type === "GENERAL") {
      requestURL = "/data/core/vehicle_customer_note/transitions/admin_insert";
    }
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: vehicleData,
    });
    yield put(createVehicleNoteSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(createVehicleNoteError(error));
    }
  }
}

export default function* pageSaga() {
  yield all([
    // yield takeLatest(ActionTypes.GET_VEHICLE_NOTE,noteSaga),
    yield takeLatest(ActionTypes.GET_GENERAL_VEHICLE_NOTE, generalSaga),
    yield takeLatest(ActionTypes.GET_INTERNAL_VEHICLE_NOTE, internalSaga),
    yield takeLatest(ActionTypes.GET_VEHICLE_NOTE_BY_ID, indexSaga),
    yield takeLatest(ActionTypes.CREATE_VEHICLE_NOTE, createSaga),
    yield takeLatest(ActionTypes.UPDATE_VEHICLE_NOTE, updateSaga),
  ]);
}
