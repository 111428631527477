import { call, all, put, takeLatest } from "redux-saga/effects";
import { authHeader } from "../../../utils";
import request from "../../../services/request";
import * as ActionTypes from "../../actions/constants";
import {
  fetchDocumentSuccess,
  fetchDocumentError,
  fetchDocumentByIdSuccess,
  fetchDocumentByIdError,
  createDocumentSuccess,
  createDocumentError,
  updateDocumentSuccess,
  updateDocumentError,
} from "../../actions/vehicle";

function* documentSaga({ data }) {
  try {
    const requestURL = "data/core/vehicle_document_type/query";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data,
    });
    yield put(fetchDocumentSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(fetchDocumentError(error));
    }
  }
}

function* indexSaga({ data }) {
  try {
    const requestURL = "data/core/vehicle_document_type/query";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data,
    });
    yield put(fetchDocumentByIdSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined"
    ) {
      yield put(fetchDocumentByIdError(error.data.error));
    }
  }
}

function* createSaga({ data }) {
  try {
    let documentData =
      typeof data.document !== "undefined" && data.document !== null
        ? data.document
        : null;
    const requestURL = "data/core/vehicle_document_type/transitions/create";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: documentData,
    });
    yield put(createDocumentSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined"
    ) {
      yield put(createDocumentError(error.data.error));
    }
  }
}

function* updateSaga({ data }) {
  try {
    let document_id =
      typeof data.id !== "undefined" && data.id !== null ? data.id : null;
    let documentData =
      typeof data.document !== "undefined" && data.document !== null
        ? data.document
        : null;
    const requestURL =
      "data/core/vehicle_document_type/" + document_id + "/transitions/edit";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: documentData,
    });
    yield put(updateDocumentSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined"
    ) {
      yield put(updateDocumentError(error.data.error));
    }
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(ActionTypes.GET_DOCUMENT, documentSaga),
    yield takeLatest(ActionTypes.GET_DOCUMENT_BY_ID, indexSaga),
    yield takeLatest(ActionTypes.CREATE_DOCUMENT, createSaga),
    yield takeLatest(ActionTypes.UPDATE_DOCUMENT, updateSaga),
  ]);
}
