import axios from 'axios';
import { Config } from '../config'

import {destroyToken} from '../utils';
const client = axios.create({
  baseURL: Config.API_URL,
});

/**
 * Request Wrapper with default success/error actions
 */
const request = (options) => {
 
  const onSuccess = (response) => {
    console.log('Request Successful!', response);
    return response;
  };

  const onError = (error) => {
    console.log('Request Failed:', error.config);
    if (error.response) {
      console.log('Status:', error.response.status);
      console.log('Data:', error.response.data);
      console.log('Headers:', error.response.headers);

      if ((error.response.status === 403) && ((error.response.data.error.message === "authorization header missing") || ( error.response.data.error.message === "authentication failed - check bearer token or api key") )) {
            destroyToken();
        if (window.location.href.indexOf('login') === -1) {
            window.location.href = '/login';
        }
      }
    } else {
      console.log('Error Message:', error.message);
    }
    return Promise.reject(error.response || error.message);
  };

  return client(options)
    .then(onSuccess)
    .catch(onError);
  };

export default request;