import { call, all, put, takeLatest } from "redux-saga/effects";
import { authHeader } from "../../../utils";
import request from "../../../services/request";
import * as ActionTypes from "../../actions/constants";
import {
  fetchCustomerSuccess,
  fetchCustomerError,
  fetchCustomerDetailSuccess,
  fetchCustomerDetailError,
  updateCustomerSuccess,
  updateCustomerError,
  createCustomerSuccess,
  createCustomerError,
} from "../../actions/customer";

function* customerSaga({ data }) {
  try {
    const requestURL = "/data/core/customer/query";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data,
    });
    yield put(fetchCustomerSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(fetchCustomerError(error));
    }
  }
}

function* updateSaga({ data }) {
  try {
    let client_id =
      typeof data.client_id !== "undefined" && data.client_id !== null
        ? data.client_id
        : null;
    let customer_id =
      typeof data.customer_id !== "undefined" && data.customer_id !== null
        ? data.customer_id
        : null;
    let clientData =
      typeof data.client !== "undefined" && data.client !== null
        ? data.client
        : null;
    let customerData =
      typeof data.customer !== "undefined" && data.customer !== null
        ? data.customer
        : null;
    let customer_type =
    data !== null &&
        typeof data.is_client !== "undefined" &&
        data.is_client === 1
        ? 1
        : 0;
    if (customer_type === 1) {
      const requestURL = `/data/system/client/${client_id}/transitions/edit`;
      const response = yield call(request, {
        url: requestURL,
        method: "POST",
        headers: {
          Authorization: authHeader(),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: clientData,
      });
    }
    let clientRequestURL = `data/core/customer/${customer_id}/transitions/admin_modify_client`;
    if (customer_type === 0) {
      clientRequestURL = `/data/core/customer/${customer_id}/transitions/admin_modify_company`;
    }
    const clientResponse = yield call(request, {
      url: clientRequestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: customerData,
    });

    yield put(updateCustomerSuccess(clientResponse));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(updateCustomerError(error));
    }
  }
}

function* createSaga({ data }) {
  try {
    let clientData =
      typeof data !== "undefined" ? data : null;
    var is_client = clientData.is_client;
    var requestURL;
    if (clientData.is_client === false) {
      requestURL = "/plugins/customer_company_create";
    } else {
      requestURL = "/plugins/customer_client_create";
    }
    delete clientData.is_client;

    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: clientData,
    });
    if (is_client === true) {
      var clientCond = {
        "conds": {
          "me.email": clientData.email
        }
      }
      const client = yield call(request, {
        url: "/data/system/client/query",
        method: "POST",
        headers: {
          Authorization: authHeader(),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: clientCond,
      });

      if (client.data.data instanceof Array && client.data.data.length === 1) {
        let clientAssignmentURL = "/data/system/assignment/transitions/create";
        let assignmentData = {
          "agenda": 9,
          "assigned_client": client.data.data[0].id
        };

        const response = yield call(request, {
          url: clientAssignmentURL,
          method: "POST",
          headers: {
            Authorization: authHeader(),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data: assignmentData
        });
        yield put(createCustomerSuccess(response));
      } else {
        const response = "Duplicate user email";
        yield put(createCustomerError(response));
      }
    } else {
      yield put(createCustomerSuccess(response));
    }
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(createCustomerError(error));
    }
  }
}
/*
function* deleteSaga({ data }) {
    try {
        
        let id = ((typeof(data.id) !== 'undefined')||(data.id !== null))?data.id:null;
        let postData = {};
        let successor = ((typeof(data.successor) !== 'undefined')||(data.successor !== null))?data.successor:null;
            postData['successor'] = successor;
        const requestURL = '/data/core/customer/'+`${id}`+'/transistion/delete';
        if(id !== null){
                const response = yield call(request, {
                    url:requestURL,
                    method: 'POST',
                    headers: {
                        Authorization: authHeader(),
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    postData,
                });
                yield put(fetchDeleteSuccess(response));
         }        
    } catch (error) {
                if((typeof(error) !== 'undefined') && (typeof(error.data) !== 'undefined') && (typeof(error.data.error) !== 'undefined')&& (typeof(error.data.error.message) !== 'undefined')){
                    yield put(fetchDeleteError(error));
                } 
       
       
    }
}
*/

function* customerDetailSaga({ data }) {
  try {
    const requestURL = "/data/core/customer/query";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data,
    });
    yield put(fetchCustomerDetailSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(fetchCustomerDetailError(error));
    }
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(ActionTypes.GET_CUSTOMER, customerSaga),
    yield takeLatest(ActionTypes.GET_CUSTOMER_DETAIL, customerDetailSaga),
    //yield takeLatest(ActionTypes.DELETE_RECORD, deleteSaga),
    yield takeLatest(ActionTypes.UPDATE_CUSTOMER, updateSaga),
    yield takeLatest(ActionTypes.CREATE_CUSTOMER, createSaga),
  ]);
}
