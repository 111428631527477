import * as Types from "../constants";

// maintenance actions
export function createCustomerNote(data) {
    return {
        type: Types.CREATE_CUSTOMER_NOTE,
        data
    };
};
export function createCustomerNoteSuccess(response) {
    return {
        type: Types.CREATE_CUSTOMER_NOTE_SUCCESS,
        response
    };
};
export function createCustomerNoteError(error) {
    return {
        type: Types.CREATE_CUSTOMER_NOTE_ERROR,
        error
    };
};
export function resetCreateCustomerNote() {
    return {
        type: Types.RESET_CREATE_CUSTOMER_NOTE
        
    };
};


export function updateCustomerNote(data) {
    return {
        type: Types.UPDATE_CUSTOMER_NOTE,
        data
    };
};
export function updateCustomerNoteSuccess(response) {
    return {
        type: Types.UPDATE_CUSTOMER_NOTE_SUCCESS,
        response
    };
};
export function updateCustomerNoteError(error) {
    return {
        type: Types.UPDATE_CUSTOMER_NOTE_ERROR,
        error
    };
};
export function resetUpdateCustomerNote() {
    return {
        type: Types.RESET_UPDATE_CUSTOMER_NOTE
        
    };
};




// customerNote actions
export function fetchCustomerNote(data) {
    return {
        type: Types.GET_CUSTOMER_NOTE,
        data
    };
};
export function fetchCustomerNoteSuccess(response) {
    return {
        type: Types.CUSTOMER_NOTE_SUCCESS,
        response
    };
};
export function fetchCustomerNoteError(error) {
    return {
        type: Types.CUSTOMER_NOTE_ERROR,
        error
    };
};



// customerNote actions
export function fetchCustomerNoteById(data) {
    return {
        type: Types.GET_CUSTOMER_NOTE_BY_ID,
        data
    };
};
export function fetchCustomerNoteByIdSuccess(response) {
    return {
        type: Types.CUSTOMER_NOTE_BY_ID_SUCCESS,
        response
    };
};
export function fetchCustomerNoteByIdError(error) {
    return {
        type: Types.CUSTOMER_NOTE_BY_ID_ERROR,
        error
    };
};


