import * as Types from "../../actions/constants/types";

const initialState = {
  indexData: {
    loading: false,
    error: false,
    success: false,
    msg: null,
  },
  resetData: {
    loading: false,
    error: false,
    success: false,
    msg: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.FORGOT:
      return {
        ...state,
        indexData: {
          loading: true,
          error: false,
          success: false,
          msg: null,
        },
      };
    case Types.FORGOT_SUCCESS:
      return {
        ...state,
        indexData: {
          loading: false,
          error: false,
          success: true,
          msg: action.response.data.message,
        },
      };
    case Types.FORGOT_ERROR:
      return {
        ...state,
        indexData: {
          loading: false,
          error: true,
          success: false,
          msg:
            typeof action.error !== "undefined" &&
            typeof action.error.data !== "undefined" &&
            typeof action.error.data.error !== "undefined" &&
            typeof action.error.data.error.message != "undefined"
              ? action.error.data.error.message
              : "",
        },
      };

    case Types.RESET_LOGIN_PASSWORD:
      return {
        ...state,
        resetData: {
          loading: true,
          error: false,
          success: false,
          msg: null,
        },
      };
    case Types.RESET_LOGIN_PASSWORD_SUCCESS:
      return {
        ...state,
        resetData: {
          loading: false,
          error: false,
          success: true,
          msg: action.response.data.password,
        },
      };
    case Types.RESET_LOGIN_PASSWORD_ERROR:
      return {
        ...state,
        resetData: {
          loading: false,
          error: true,
          success: false,
          msg:
            typeof action.error !== "undefined" &&
            typeof action.error.data !== "undefined" &&
            typeof action.error.data.error !== "undefined" &&
            typeof action.error.data.error.message != "undefined"
              ? action.error.data.error.message
              : "",
        },
      };
    case Types.RESET_FORGOT:
      return {
        ...state,
        indexData: {
          loading: false,
          error: false,
          success: false,
          msg: null,
        },
      };

    default:
      return state;
  }
};
