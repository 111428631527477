export const GET_CUSTOMER_NOTE = 'constants/customer/GET_CUSTOMER_NOTE';
export const CUSTOMER_NOTE_SUCCESS = 'constants/customer/CUSTOMER_NOTE_SUCCESS';
export const CUSTOMER_NOTE_ERROR = 'constants/customer/CUSTOMER_NOTE_ERROR';

export const CREATE_CUSTOMER_NOTE = 'constants/customer/CREATE_CUSTOMER_NOTE';
export const CREATE_CUSTOMER_NOTE_SUCCESS = 'constants/customer/CREATE_CUSTOMER_NOTE_SUCCESS';
export const CREATE_CUSTOMER_NOTE_ERROR = 'constants/customer/CREATE_CUSTOMER_NOTE_ERROR';
export const RESET_CREATE_CUSTOMER_NOTE = 'constants/customer/RESET_CREATE_CUSTOMER_NOTE';

export const UPDATE_CUSTOMER_NOTE = 'constants/customer/UPDATE_CUSTOMER_NOTE';
export const UPDATE_CUSTOMER_NOTE_SUCCESS = 'constants/customer/UPDATE_CUSTOMER_NOTE_SUCCESS';
export const UPDATE_CUSTOMER_NOTE_ERROR = 'constants/customer/UPDATE_CUSTOMER_NOTE_ERROR';
export const RESET_UPDATE_CUSTOMER_NOTE = 'constants/customer/RESET_UPDATE_CUSTOMER_NOTE';

export const GET_CUSTOMER_NOTE_BY_ID = 'constants/customer/GET_CUSTOMER_NOTE_BY_ID';
export const CUSTOMER_NOTE_BY_ID_SUCCESS = 'constants/customer/CUSTOMER_NOTE_BY_ID_SUCCESS';
export const CUSTOMER_NOTE_BY_ID_ERROR = 'constants/customer/CUSTOMER_NOTE_BY_ID_ERROR';