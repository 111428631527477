import { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import AutoRecommend from "../../components/AutoSuggest";

import {
  fetchCustomer,
  resetUpdateCustomerNote,
  updateCustomerNote,
  fetchCustomerNote,
} from "../../store/actions/customer";

import { showAlertDialog } from "../../store/actions/dialog";
import {
  inputErrorHandler,
  signalTransitionSuccess,
  signalTransitionError,
  removeEmptyValues,
  getFileContent,
  checkFileType,
  getMaxBase64StringSize,
  FILE_TOO_LARGE_MESSAGE,
  FILE_NAME_SEPARATOR,
  FILE_TYPE_MESSAGE,
  CORE_MODULE,
  CUSTOMER_NOTE_ENTITY,
} from "../../utils";

import bsCustomFileInput from "bs-custom-file-input";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "font-awesome/css/font-awesome.css";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";
import ColumnText from "../../components/Column";

class CustomerNoteEditScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        note_id: "",
        customer_id: "",
        customer_name: "",
        note: "",
        files: "",
      },
      reset: {
        fields: {
          note_id: "",
          customer_id: "",
          customer_name: "",
          note: "",
          files: "",
        },
        errors: {
          customer_id: "",
          note: "",
          files: "",
        },
      },
      errors: {
        customer_id: "",
        note: "",
        files: "",
      },
      fileName: "No File Choosen",
      defaultName: "No File Choosen",
      value: "",
      suggestions: [],
    };

    this.changeFileHandler = this.changeFileHandler.bind(this);
  }

  componentDidMount() {
    bsCustomFileInput.init();
    let cdata = {};
    cdata.attrs = {};
    cdata.attrs["prefetch"] = {
      owning_client: "system__assignment__assigned_client",
    };
    cdata.conds = {
      "me.id": this.props.match.params.cid,
    };
    this.props.dispatch(fetchCustomer(cdata));
    let ndata = {};
    ndata.attrs = {};
    ndata.attrs["prefetch"] = ["customer"];
    ndata.conds = { "me.id": this.props.match.params.id };
    this.props.dispatch(fetchCustomerNote(ndata));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.customerData) !==
      JSON.stringify(this.props.customerData)
    ) {
      let customer_data =
        typeof this.props.customerData !== "undefined" &&
        typeof this.props.customerData.rows !== "undefined" &&
        typeof this.props.customerData.rows.data !== "undefined" &&
        typeof this.props.customerData.rows.data[0] !== "undefined"
          ? this.props.customerData.rows.data[0]
          : null;
      let data = [];
      let suggestions = this.state.suggestions;
      for (let i = 0; i < suggestions.length; i++) {
        let obj = {};
        obj.id = suggestions.id;
        obj.name = suggestions.name;
        data.push(obj);
      }

      let client_data =
        customer_data !== null &&
        typeof customer_data.owning_client !== "undefined"
          ? customer_data.owning_client
          : "";
      let customer_name = "";

      let isClient =
        customer_data !== null && typeof customer_data.is_client !== "undefined"
          ? customer_data.is_client
          : 0;
      if (parseInt(isClient)) {
        let first_name =
          client_data !== null && typeof client_data.first_name !== "undefined"
            ? client_data.first_name
            : "";
        let last_name =
          client_data !== null && typeof client_data.last_name !== "undefined"
            ? client_data.last_name
            : "";
        customer_name = first_name + " " + last_name;
      } else {
        customer_name =
          customer_data !== null && typeof customer_data.name !== "undefined"
            ? customer_data.name
            : "";
      }
      this.setState({ value: customer_name });
    }
    if (
      JSON.stringify(prevProps.noteData) !== JSON.stringify(this.props.noteData)
    ) {
      let note_data =
        typeof this.props.noteData.rows.data[0] !== "undefined"
          ? this.props.noteData.rows.data[0]
          : null;
      let customer_data =
        note_data !== null && typeof note_data.customer !== "undefined"
          ? note_data.customer
          : null;

      let note_id =
        note_data !== null && typeof note_data.id !== "undefined"
          ? note_data.id
          : "";
      let content =
        note_data !== null && typeof note_data.content !== "undefined"
          ? note_data.content
          : "";

      let file_name =
        note_data !== null && note_data.attachment !== null
          ? JSON.parse(note_data.attachment).original_name
          : "";

      let customer_id =
        customer_data !== null && typeof customer_data.id !== "undefined"
          ? customer_data.id
          : "";

      let note = {};
      note["note_id"] = note_id;
      note["note"] = content;
      note["customer_id"] = customer_id;
      note["files"] = "";

      if (file_name) {
        this.setState({ fileName: file_name });
      }

      this.setState({ fields: note });
      let reset = this.state.reset;
      reset.fields = note;
      this.setState({ reset: reset });
    }

    if (this.props.updateData.success) {
      this.props.dispatch(resetUpdateCustomerNote());
      signalTransitionSuccess("Update Success");
      let url = "/admin/customers/" + this.props.match.params.cid + "/notes";
      this.props.history.push(url);
    } else if (this.props.updateData.error) {
      this.props.dispatch(resetUpdateCustomerNote());
      signalTransitionError("Update Failed");
    }
  }

  resetHandler(evt) {
    this.setState({ fields: this.state.reset.fields });
    this.setState({ errors: this.state.reset.errors });
  }

  submitHandler(evt) {
    evt.preventDefault();
    // get our new errors

    let fields = this.state.fields;
    let newErrors = {};
    newErrors["customer_id"] = inputErrorHandler("", fields.customer_id, true);
    newErrors["note"] = inputErrorHandler("", fields.note, true);

    let errors = removeEmptyValues(newErrors);

    if (Object.keys(errors).length > 0) {
      this.setState({ errors: errors });
    } else {
      let noteData = { note: {}, note_id: "" };
      noteData.note_id = fields.note_id;
      if (fields.files !== "") {
        noteData.note.attachment = fields.files;
      }
      noteData.note.content = fields.note;
      noteData.note.customer = fields.customer_id;
      //console.log(maintenanceData);
      this.props.dispatch(updateCustomerNote(noteData));
    }
  }

  changeHandler(evt, name) {
    let value = evt.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: null,
      },
    }));
  }

  changeFileHandler(evt) {
    let errors = this.state.errors;
    errors["files"] = "";
    this.setState({ errors: errors });
    if (
      typeof evt.target.files[0] !== "undefined" &&
      typeof evt.target.files[0].name !== "undefined"
    ) {
      this.setState({ fileName: evt.target.files[0].name });
      if (checkFileType(evt.target.files)) {
        getFileContent(evt.target.files)
          .then((data) => {
            if (data.length > getMaxBase64StringSize()) {
              this.props.dispatch(showAlertDialog(FILE_TOO_LARGE_MESSAGE));
              this.setState({ fileName: this.state.defaultName });
              let fields = this.state.fields;
              fields["files"] = "";
              this.setState({ fields: fields });
            } else {
              //console.log(`${this.state.fileName}${FILE_NAME_SEPARATOR}${data}`);
              let fields = this.state.fields;
              fields[
                "files"
              ] = `${this.state.fileName}${FILE_NAME_SEPARATOR}${data}`;
              this.setState({ fields: fields });
            }
          })
          .catch((e) => console.log("Error: ", e.message));
      } else {
        this.props.dispatch(showAlertDialog(FILE_TYPE_MESSAGE));
        this.setState({ fileName: this.state.defaultName });
        let fields = this.state.fields;
        fields["files"] = "";
        this.setState({ fields: fields });
      }
    }
  }

  onValueChange = (newValue) => {
    this.setState({
      value: newValue,
    });
    let errors = this.state.errors;
    errors["customer_id"] = "";
    this.setState({ errors: errors });
  };

  onSuggestionsFetchRequested = (value) => {
    this.loadSuggestions(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  loadSuggestions(value) {
    // if (value !== "") {
    //   let cdata = {};
    //   cdata.attrs = {};
    //   cdata.attrs["prefetch"] = {
    //     "owning_client": "system__assignment__assigned_client"
    //   };
    //   cdata.conds = {
    //     "system__assignment__assigned_client.agenda": 9,
    //     "-or": [
    //       { first_name: { "-like": "%" + value + "%" } },
    //       { last_name: { "-like": "%" + value + "%" } },
    //     ],
    //   };
    //   this.props.dispatch(fetchCustomer(cdata));
    // }
  }

  loadMoreReferences = () => {
    let meta_data =
      typeof this.props.customerData !== "undefined" &&
      typeof this.props.customerData.rows !== "undefined" &&
      typeof this.props.customerData.rows.meta !== "undefined"
        ? this.props.customerData.rows.meta
        : [];

    let vdata = {};
    vdata.attrs = {};
    vdata.conds = meta_data.conds;
    vdata.page = meta_data.page + 1;
    vdata.page_size = meta_data.page_size;
    this.props.dispatch(fetchCustomer(vdata));
  };

  onSelect = (suggestionValue) => {
    const reference = this.state.suggestions.find(
      (r) => r["name"] === suggestionValue
    );
    let fields = this.state.fields;
    fields["customer_id"] = reference.id;
    fields["customer_name"] = reference.name;
    this.setState({ fields: fields });
    this.setState({ value: reference.name });
  };

  render() {
    // let customer_type = 2;

    // let core_module = "core";
    // let vehicle_entity = "vehicle";
    // let maintenance_entity = "vehicle_maintenance";
    // let customer_entity = "customer";
    // let system_module = "system";
    // let client_entity = "client";

    return (
      <div className="wrapper">
        <div className="main d-flex align-items-start flex-column">
          <h4>
            {" "}
            <ColumnText
              Module={CORE_MODULE}
              Entity={CUSTOMER_NOTE_ENTITY}
            />{" "}
            bearbeiten
          </h4>
        </div>
        <div className="sub_main">
          <Form>
            <Row>
              <Col lg={6}>
                <Form.Group as={Row} controlId="customer_id">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={CUSTOMER_NOTE_ENTITY}
                      Label={"customer"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <AutoRecommend
                      value={this.state.value}
                      disabled={true}
                      onValueChange={this.onValueChange}
                      suggestions={this.state.suggestions}
                      onSuggestionsClearRequested={
                        this.onSuggestionsClearRequested
                      }
                      fetchSuggestions={this.onSuggestionsFetchRequested}
                      onSelect={this.onSelect}
                      placeholder="Kunde wählen"
                      onScrollEnd={this.loadMoreReferences}
                      inputClasses={
                        this.state.errors.customer_id !== "" &&
                        typeof this.state.errors.customer_id !== "undefined"
                          ? "is-invalid form-control"
                          : "form-control"
                      }
                    />
                    {this.state.errors.customer_id !== "" ? (
                      <div className="has-error">
                        {this.state.errors.customer_id}
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="note">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={CUSTOMER_NOTE_ENTITY}
                      Label={"content"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Note"
                      name="note"
                      value={this.state.fields.note}
                      onChange={(evt) => this.changeHandler(evt, "note")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.note}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.note}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="files">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={CUSTOMER_NOTE_ENTITY}
                      Label={"attachment"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.File
                      type="file"
                      // className={(this.state.errors.files !== "")?"is-invalid custom-file-label":"form-control custom-file-label"}
                      id="inputGroupFile01"
                      label={this.state.fileName}
                      onChange={(evt) => this.changeFileHandler(evt)}
                      accept="application/pdf, image/*"
                      custom
                      isInvalid={!!this.state.errors.files}
                    />
                    {this.state.errors.files !== "" ? (
                      <div className="has-error">{this.state.errors.files}</div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>

                <div className="form-btn">
                  <Button
                    type="Button"
                    className="btn btn-dark Button"
                    onClick={(evt) => this.submitHandler(evt)}
                  >
                    OK
                  </Button>
                  <Button
                    type="Button"
                    className="btn btn-dark"
                    onClick={(evt) => this.resetHandler(evt)}
                  >
                    Abbrechen
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

CustomerNoteEditScreen.propTypes = {};
CustomerNoteEditScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  customerData: state.Customer.customerData,
  localeData: state.Locale.localeData,
  updateData: state.CNote.updateData,
  noteData: state.CNote.noteData,
  dialogData: state.Dialog.dialogData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerNoteEditScreen);
