import * as Types from "../constants";

// maintenance actions
export function fetchVehicleMaintenance(data) {
    return {
        type: Types.GET_VEHICLE_MAINTENANCE,
        data
    };
};
export function fetchVehicleMaintenanceSuccess(response) {
    return {
        type: Types.VEHICLE_MAINTENANCE_SUCCESS,
        response
    };
};
export function fetchVehicleMaintenanceError(error) {
    return {
        type: Types.VEHICLE_MAINTENANCE_ERROR,
        error
    };
};


// maintenance actions
export function updateVehicleMaintenance(data) {
    return {
        type: Types.UPDATE_VEHICLE_MAINTENANCE,
        data
    };
};
export function updateVehicleMaintenanceSuccess(response) {
    return {
        type: Types.UPDATE_VEHICLE_MAINTENANCE_SUCCESS,
        response
    };
};
export function updateVehicleMaintenanceError(error) {
    return {
        type: Types.UPDATE_VEHICLE_MAINTENANCE_ERROR,
        error
    };
};
export function resetUpdateVehicleMaintenance() {
    return {
        type: Types.RESET_UPDATE_VEHICLE_MAINTENANCE
        
    };
};





// maintenance actions
export function createVehicleMaintenance(data) {
    return {
        type: Types.CREATE_VEHICLE_MAINTENANCE,
        data
    };
};
export function createVehicleMaintenanceSuccess(response) {
    return {
        type: Types.CREATE_VEHICLE_MAINTENANCE_SUCCESS,
        response
    };
};
export function createVehicleMaintenanceError(error) {
    return {
        type: Types.CREATE_VEHICLE_MAINTENANCE_ERROR,
        error
    };
};
export function resetCreateVehicleMaintenance() {
    return {
        type: Types.RESET_CREATE_VEHICLE_MAINTENANCE
        
    };
};


