import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from "redux-logger";
import rootReducer from "./reducers";
import sagas from "./sagas";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

if (process.env.NODE_ENV !== "production") {
  middlewares.push(createLogger());
}

const enhancers = [applyMiddleware(...middlewares)];

const store = createStore(
    rootReducer,
    compose(...enhancers)
);

sagaMiddleware.run(sagas);

export default store;