import * as Types from "../constants";

// customer actions
export function fetchBindingEntity(data) {
    return {
        type: Types.GET_BINDING_ENTITY,
        data
    };
};
export function fetchBindingEntitySuccess(response) {
    return {
        type: Types.BINDING_ENTITY_SUCCESS,
        response
    };
};
export function fetchBindingEntityError(error) {
    return {
        type: Types.BINDING_ENTITY_ERROR,
        error
    };
};

