import * as Types from "../constants";

// vehicle actions
export function fetchVehicle(data) {
    return {
        type: Types.GET_VEHICLE,
        data
    };
};
export function fetchVehicleSuccess(response) {
    return {
        type: Types.VEHICLE_SUCCESS,
        response
    };
};
export function fetchVehicleError(error) {
    return {
        type: Types.VEHICLE_ERROR,
        error
    };
};

// vehicle actions
export function fetchVehicleById(data) {
    return {
        type: Types.GET_VEHICLE_BY_ID,
        data
    };
};
export function fetchVehicleByIdSuccess(response) {
    return {
        type: Types.VEHICLE_BY_ID_SUCCESS,
        response
    };
};
export function fetchVehicleByIdError(error) {
    return {
        type: Types.VEHICLE_BY_ID_ERROR,
        error
    };
};

/** update Actions */
export function updateVehicle(data) {
    return {
        type: Types.UPDATE_VEHICLE,
        data,
    };
}
export function updateVehicleSuccess(response) {
    return {
        type: Types.UPDATE_VEHICLE_SUCCESS,
        response,
    };
}
export function updateVehicleError(error) {
    return {
        type: Types.UPDATE_VEHICLE_ERROR,
        error,
    };
}

export function resetUpdateVehicle() {
    return {
        type: Types.RESET_UPDATE_VEHICLE,
        
    };
}


/** create Actions */
export function createVehicle(data) {
    return {
        type: Types.CREATE_VEHICLE,
        data,
    };
}
export function createVehicleSuccess(response) {
    return {
        type: Types.CREATE_VEHICLE_SUCCESS,
        response,
    };
}
export function createVehicleError(error) {
    return {
        type: Types.CREATE_VEHICLE_ERROR,
        error,
    };
}

export function resetCreateVehicle() {
    return {
        type: Types.RESET_CREATE_VEHICLE,
        
    };
}

/** update Vehicle Ownership Actions */
export function updateVehicleOwnership(data) {
    return {
        type: Types.UPDATE_VEHICLE_OWNERSHIP,
        data,
    };
}
export function updateVehicleOwnershipSuccess(response) {
    return {
        type: Types.UPDATE_VEHICLE_OWNERSHIP_SUCCESS,
        response,
    };
}
export function updateVehicleOwnershipError(error) {
    return {
        type: Types.UPDATE_VEHICLE_OWNERSHIP_ERROR,
        error,
    };
}

export function resetUpdateVehicleOwnership() {
    return {
        type: Types.RESET_UPDATE_VEHICLE_OWNERSHIP,
        
    };
}

