export const CREATE_VEHICLE_DOCUMENT = 'constants/vehicle/CREATE_VEHICLE_DOCUMENT';
export const CREATE_VEHICLE_DOCUMENT_SUCCESS = 'constants/vehicle/CREATE_VEHICLE_DOCUMENT_SUCCESS';
export const CREATE_VEHICLE_DOCUMENT_ERROR = 'constants/vehicle/CREATE_VEHICLE_DOCUMENT_ERROR';
export const RESET_CREATE_VEHICLE_DOCUMENT = 'constants/vehicle/RESET_CREATE_VEHICLE_DOCUMENT';

export const GET_VEHICLE_DOCUMENT = 'constants/vehicle/GET_VEHICLE_DOCUMENT';
export const VEHICLE_DOCUMENT_SUCCESS = 'constants/vehicle/VEHICLE_DOCUMENT_SUCCESS';
export const VEHICLE_DOCUMENT_ERROR = 'constants/vehicle/VEHICLE_DOCUMENT_ERROR';


export const GET_VEHICLE_DOCUMENT_BY_ID = 'constants/vehicle/GET_VEHICLE_DOCUMENT_BY_ID';
export const VEHICLE_DOCUMENT_BY_ID_SUCCESS = 'constants/vehicle/VEHICLE_DOCUMENT_BY_ID_SUCCESS';
export const VEHICLE_DOCUMENT_BY_ID_ERROR = 'constants/vehicle/VEHICLE_DOCUMENT_BY_ID_ERROR';


export const UPDATE_VEHICLE_DOCUMENT = 'constants/vehicle/UPDATE_VEHICLE_DOCUMENT';
export const UPDATE_VEHICLE_DOCUMENT_SUCCESS = 'constants/vehicle/UPDATE_VEHICLE_DOCUMENT_SUCCESS';
export const UPDATE_VEHICLE_DOCUMENT_ERROR = 'constants/vehicle/UPDATE_VEHICLE_DOCUMENT_ERROR';
export const RESET_UPDATE_VEHICLE_DOCUMENT = 'constants/vehicle/RESET_UPDATE_VEHICLE_DOCUMENT';