import { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import { createClient, resetCreateClient } from "../../store/actions/client";
import { fetchRole } from "../../store/actions/role";

import {
  inputErrorHandler,
  signalTransitionSuccess,
  signalTransitionError,
  removeEmptyValues,
  getSalutaions,
} from "../../utils";

class ClientAddScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        password: "",
        confirm_password: "",
        salutation: "",
        first_name: "",
        last_name: "",
        email: "",
        role_id: "",
        role_name: "",
        binding_entity: "",
      },
      errors: {
        salutation: "",
        password: "",
        first_name: "",
        last_name: "",
        email: "",
        role_id: "",
      },
      reset: {
        fields: {
          password: "",
          confirm_password: "",
          salutation: "",
          first_name: "",
          last_name: "",
          email: "",
          role_id: "",
          role_name: "",
          binding_entity: "",
        },
        errors: {
          password: "",
          confirm_password: "",
          salutation: "",
          first_name: "",
          last_name: "",
          email: "",
          role_id: "",
        },
      },
      value: "",
      suggestions: [],
    };
  }

  componentDidMount() {
    let vdata = {};
    vdata.attrs = {};
    vdata.conds = { name: { "-in": ["admin", "employee"] } };
    this.props.dispatch(fetchRole(vdata));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.roleData) !== JSON.stringify(this.props.roleData)
    ) {
      let roles =
        typeof this.props.roleData !== "undefined" &&
        typeof this.props.roleData.rows !== "undefined" &&
        typeof this.props.roleData.rows.data !== "undefined"
          ? this.props.roleData.rows.data
          : [];
      let data = [];
      let suggestions = this.state.suggestions;
      for (let i = 0; i < suggestions.length; i++) {
        let obj = {};
        obj.id = suggestions.id;
        obj.name = suggestions.name;
        obj.binding_entity = suggestions.binding_entity;
        data.push(obj);
      }
      for (let i = 0; i < roles.length; i++) {
        let obj = {};
        //if(roles[i]['binding_entity'] !== null){
        obj.id = roles[i].id;
        obj.name = roles[i].description;
        obj.binding_entity = roles[i]["binding_entity"];
        data.push(obj);
        //}
      }
      this.setState({ suggestions: data });
    }

    if (this.props.createData.success) {
      signalTransitionSuccess("Erfolgreich hinzugefügt");
      this.props.dispatch(resetCreateClient());
      let url = "/admin/settings";
      this.props.history.push(url);
    } else if (this.props.createData.error) {
      this.props.dispatch(resetCreateClient());
      if (
        typeof this.props.createData.rows !== "undefined" &&
        typeof this.props.createData.rows.errorType !== "undefined" &&
        this.props.createData.rows.errorType === "UNIQUENESS"
      ) {
        let newErrors = {};
        this.setState({ errors: newErrors });
      } else {
        signalTransitionError("Hinzufügen fehlgeschlagen");
      }
    }
  }

  resetHandler(evt) {
    this.setState({ fields: this.state.reset.fields });
    this.setState({ errors: this.state.reset.errors });
  }

  submitHandler(evt) {
    evt.preventDefault();
    // get our new errors
    let fields = this.state.fields;

    let newErrors = {};
    newErrors["first_name"] = inputErrorHandler("", fields.first_name, true);
    newErrors["last_name"] = inputErrorHandler("", fields.last_name, true);
    newErrors["password"] = inputErrorHandler("", fields.password, true);
    newErrors["confirm_password"] = inputErrorHandler(
      "",
      fields.confirm_password,
      true
    );
    newErrors["email"] = inputErrorHandler("email", fields.email, true);
    newErrors["salutation"] = inputErrorHandler("", fields.salutation, true);
    if (fields.password !== fields.confirm_password) {
      newErrors["confirm_password"] =
        "Passwort & Passwort bestätigen sollten gleich sein";
    }

    if (fields.role_id === "") {
      newErrors["role_id"] = "Required Parameter";
    }
    let errors = removeEmptyValues(newErrors);
    // Conditional logic:
    if (Object.keys(errors).length > 0) {
      this.setState({ errors: errors });
    } else {
      let clientData = {};
      clientData.locked = false;
      clientData.email = fields.email;
      clientData.first_name = fields.first_name;
      clientData.last_name = fields.last_name;
      clientData.identifier = fields.email;
      // clientData.client.language = 1;
      clientData.password = fields.password;
      clientData.salutation = fields.salutation;
      if (fields.role_id == 10) {
        clientData.is_admin = true;
      } else {
        clientData.is_employee = true;
      }

      this.props.dispatch(createClient(clientData));
    }
  }

  changeHandler(evt, name) {
    let value = evt.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: null,
      },
    }));
  }

  render() {
    let salutations = getSalutaions();

    return (
      <div className="wrapper">
        <div className="main d-flex align-items-start flex-column">
          <h4>neuen Benutzer anlegen</h4>
        </div>
        <div className="sub_main">
          <Form>
            <Row>
              <Col lg={6}>
                <Form.Group as={Row} controlId="email">
                  <Form.Label column sm="4">
                    Email
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={this.state.fields.email}
                      onChange={(evt) => this.changeHandler(evt, "email")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.email}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="password">
                  <Form.Label column sm="4">
                    Passwort
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="password"
                      placeholder="Passwort"
                      name="password"
                      value={this.state.fields.password}
                      onChange={(evt) => this.changeHandler(evt, "password")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.password}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="confirm_password">
                  <Form.Label column sm="4">
                    Passwort bestätigen
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="password"
                      placeholder="Passwort bestätigen"
                      name="password"
                      value={this.state.fields.confirm_password}
                      onChange={(evt) =>
                        this.changeHandler(evt, "confirm_password")
                      }
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.confirm_password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.confirm_password}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="salutation">
                  <Form.Label column sm="4">
                    Anrede
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      as="select"
                      placeholder="Anrede"
                      name="salutation"
                      value={this.state.fields.salutation}
                      onChange={(evt) => this.changeHandler(evt, "salutation")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.salutation}
                    >
                      <option value="">Anrede</option>
                      {salutations.map((item) => (
                        <option
                          value={item.value}
                          selected={
                            this.state.fields.salutation === item.value
                              ? true
                              : false
                          }
                        >
                          {item.text}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.salutation}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="first_name">
                  <Form.Label column sm="4">
                    First Name
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Vorname"
                      name="first_name"
                      value={this.state.fields.first_name}
                      onChange={(evt) => this.changeHandler(evt, "first_name")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.first_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.first_name}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="last_name">
                  <Form.Label column sm="4">
                    Last Name
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Familienname"
                      name="last_name"
                      value={this.state.fields.last_name}
                      onChange={(evt) => this.changeHandler(evt, "last_name")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.last_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.last_name}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="role_id">
                  <Form.Label column sm="4">
                    User Role
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      as="select"
                      placeholder="role_id"
                      name="role_id"
                      value={this.state.fields.role_id}
                      onChange={(evt) => this.changeHandler(evt, "role_id")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.role_id}
                    >
                      <option value="">Wählen Sie Rolle</option>
                      {this.state.suggestions.map((item) => (
                        <option
                          value={item.id}
                          selected={
                            this.state.fields.role_id === item.id ? true : false
                          }
                        >
                          {item.name}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.role_id}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <div className="form-btn">
                  <Button
                    type="Button"
                    className="btn btn-dark Button"
                    onClick={(evt) => this.submitHandler(evt)}
                  >
                    OK
                  </Button>
                  <Button
                    type="Button"
                    className="btn btn-dark"
                    onClick={(evt) => this.resetHandler(evt)}
                  >
                    Abbrechen
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

ClientAddScreen.propTypes = {};
ClientAddScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  customerData: state.Customer.customerData,
  localeData: state.Locale.localeData,
  createData: state.Client.createData,
  roleData: state.Role.roleData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(mapStateToProps, mapDispatchToProps)(ClientAddScreen);
