import * as Types from "../../actions/constants/types";

const initialState = {
  logoutData: {
    loading: false,
    error: false,
    success: false,
    msg: null,
  },
};

export default (state = initialState, action) => {
  //console.log(action.error);
  switch (action.type) {
    case Types.LOGOUT:
      return {
        ...state,
        logoutData: {
          loading: true,
          error: false,
          success: false,
          msg: null,
        },
      };
    case Types.LOGOUT_SUCCESS:
      return {
        ...state,
        logoutData: {
          loading: false,
          success: true,
          error: false,
          msg: action.response,
        },
      };
    case Types.LOGOUT_ERROR:
      return {
        ...state,
        logoutData: {
          loading: false,
          success: false,
          error: true,
          msg:
            typeof action.error !== "undefined" &&
            typeof action.error.data !== "undefined" &&
            typeof action.error.data.error !== "undefined" &&
            typeof action.error.data.error.message != "undefined"
              ? action.error.data.error.message
              : "",
        },
      };

    default:
      return state;
  }
};
