import * as Types from "../constants";

// vehicle actions
export function fetchDocument(data) {
    return {
        type: Types.GET_DOCUMENT,
        data
    };
};
export function fetchDocumentSuccess(response) {
    return {
        type: Types.DOCUMENT_SUCCESS,
        response
    };
};
export function fetchDocumentError(error) {
    return {
        type: Types.DOCUMENT_ERROR,
        error
    };
};




// fetch Document actions
export function fetchDocumentById(data) {
    return {
        type: Types.GET_DOCUMENT_BY_ID,
        data
    };
};
export function fetchDocumentByIdSuccess(response) {
    return {
        type: Types.DOCUMENT_BY_ID_SUCCESS,
        response
    };
};
export function fetchDocumentByIdError(error) {
    return {
        type: Types.DOCUMENT_BY_ID_ERROR,
        error
    };
};



// create Document actions
export function createDocument(data) {
    return {
        type: Types.CREATE_DOCUMENT,
        data
    };
};
export function createDocumentSuccess(response) {
    return {
        type: Types.CREATE_DOCUMENT_SUCCESS,
        response
    };
};
export function createDocumentError(error) {
    return {
        type: Types.CREATE_DOCUMENT_ERROR,
        error
    };
};

export function resetCreateDocument(error) {
    return {
        type: Types.RESET_CREATE_DOCUMENT,
        error
    };
};



// update Document actions
export function updateDocument(data) {
    return {
        type: Types.UPDATE_DOCUMENT,
        data
    };
};
export function updateDocumentSuccess(response) {
    return {
        type: Types.UPDATE_DOCUMENT_SUCCESS,
        response
    };
};
export function updateDocumentError(error) {
    return {
        type: Types.UPDATE_DOCUMENT_ERROR,
        error
    };
};

export function resetUpdateDocument(error) {
    return {
        type: Types.RESET_UPDATE_DOCUMENT,
        error
    };
};
