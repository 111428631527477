export const CREATE_VEHICLE_NOTE = 'constants/vehicle/CREATE_VEHICLE_NOTE';
export const CREATE_VEHICLE_NOTE_SUCCESS = 'constants/vehicle/CREATE_VEHICLE_NOTE_SUCCESS';
export const CREATE_VEHICLE_NOTE_ERROR = 'constants/vehicle/CREATE_VEHICLE_NOTE_ERROR';
export const RESET_CREATE_VEHICLE_NOTE = 'constants/vehicle/RESET_CREATE_VEHICLE_NOTE';

export const GET_VEHICLE_NOTE = 'constants/vehicle/GET_VEHICLE_NOTE';
export const VEHICLE_NOTE_SUCCESS = 'constants/vehicle/VEHICLE_NOTE_SUCCESS';
export const VEHICLE_NOTE_ERROR = 'constants/vehicle/VEHICLE_NOTE_ERROR';


export const GET_VEHICLE_NOTE_BY_ID = 'constants/vehicle/GET_VEHICLE_NOTE_BY_ID';
export const VEHICLE_NOTE_BY_ID_SUCCESS = 'constants/vehicle/VEHICLE_NOTE_BY_ID_SUCCESS';
export const VEHICLE_NOTE_BY_ID_ERROR = 'constants/vehicle/VEHICLE_NOTE_BY_ID_ERROR';


export const UPDATE_VEHICLE_NOTE = 'constants/vehicle/UPDATE_VEHICLE_NOTE';
export const UPDATE_VEHICLE_NOTE_SUCCESS = 'constants/vehicle/UPDATE_VEHICLE_NOTE_SUCCESS';
export const UPDATE_VEHICLE_NOTE_ERROR = 'constants/vehicle/UPDATE_VEHICLE_NOTE_ERROR';
export const RESET_UPDATE_VEHICLE_NOTE = 'constants/vehicle/RESET_UPDATE_VEHICLE_NOTE';


export const GET_INTERNAL_VEHICLE_NOTE = 'constants/vehicle/GET_INTERNAL_VEHICLE_NOTE';
export const INTERNAL_VEHICLE_NOTE_SUCCESS = 'constants/vehicle/INTERNAL_VEHICLE_NOTE_SUCCESS';
export const INTERNAL_VEHICLE_NOTE_ERROR = 'constants/vehicle/INTERNAL_VEHICLE_NOTE_ERROR';

export const GET_GENERAL_VEHICLE_NOTE = 'constants/vehicle/GET_GENERAL_VEHICLE_NOTE';
export const GENERAL_VEHICLE_NOTE_SUCCESS = 'constants/vehicle/GENERAL_VEHICLE_NOTE_SUCCESS';
export const GENERAL_VEHICLE_NOTE_ERROR = 'constants/vehicle/GENERAL_VEHICLE_NOTE_ERROR';