import React, { Component } from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  PagingOptions,
  NoDataIndication,
  DelBtn,
  EditBtn,
  downloadFile,
  CORE_MODULE,
  VEHICLE_MAINTENANCE_NOTE_ENTITY,
  DELETE_PERMISSION,
  EDIT_PERMISSION,
  CREATE_PERMISSION,
  NO_DATA,
} from "../../utils";
import { fetchMaintenanceNote } from "../../store/actions/vehicle";
import { showDeleteDialog } from "../../store/actions/dialog";
import { Link } from "react-router-dom";
import { HasAccess } from "../../components/Access";
import ColumnText from "../../components/Column";

class MaintenanceNoteScreen extends Component {
  maintenanceId = null;

  constructor(props) {
    super(props);
    this.state = {
      sort: [
        {
          dataField: "id",
          order: "desc",
        },
      ],
    };
    this.maintenanceId = props.match.params.mid;
    this.handleTableChange = this.handleTableChange.bind(this);
    this.viewHandler = this.viewHandler.bind(this);
    this.listHandler = this.listHandler.bind(this);
    this.editHandler = this.editHandler.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
    this.addHandler = this.addHandler.bind(this);
    this.addMaintenanceNoteHandler = this.addMaintenanceNoteHandler.bind(this);
    this.vehicleDetailHandler = this.vehicleDetailHandler.bind(this);
    this.maintenanceDetailHandler = this.maintenanceDetailHandler.bind(this);
    this.downloadInvoice = this.downloadInvoice.bind(this);
  }
  renderColumns() {
    return [
      {
        dataField: "vehicle_maintenance.vehicle.vin",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={VEHICLE_MAINTENANCE_NOTE_ENTITY}
            Label={"vehicle"}
          />
        ),
        sort: true,
        headerClasses: "Cell Cell-header",
      },
      {
        dataField: "content",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={VEHICLE_MAINTENANCE_NOTE_ENTITY}
            Label={"content"}
          />
        ),
        sort: true,
        headerClasses: "Cell Cell-header",
      },
      {
        dataField: "attachment",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={VEHICLE_MAINTENANCE_NOTE_ENTITY}
            Label={"attachment"}
          />
        ),
        sort: true,
        headerClasses: "Cell Cell-header",
        formatter: (cellContent, row) => {
          let cell =
            cellContent !== null && typeof cellContent !== "undefined"
              ? JSON.parse(cellContent)
              : {};
          //let url = (cell !== {})?cell.path:'';
          let mime_type = cell !== {} ? cell.mime_type : "";
          let name = cell !== {} ? cell.original_name : "";

          let url =
            "data/core/vehicle_maintenance_note/" +
            row.id +
            "/download/attachment";
          if (url !== "") {
            return (
              <>
                {" "}
                <Link
                  to="#"
                  onClick={() => this.downloadInvoice(url, mime_type, name)}
                >
                  {name}
                </Link>
              </>
            );
          }
        },
      },
      {
        dataField: "auto_ident",
        text: "",
        sort: false,
        headerClasses: "Cell Cell-header",
        formatter: (cellContent, row) => {
          return (
            <div className="svg">
              <HasAccess
                Module={CORE_MODULE}
                Entity={VEHICLE_MAINTENANCE_NOTE_ENTITY}
                Label={EDIT_PERMISSION}
              >
                <Link
                  to={"#"}
                  onClick={() => {
                    this.editHandler(row);
                  }}
                >
                  <EditBtn />
                </Link>
              </HasAccess>
              <HasAccess
                Module={CORE_MODULE}
                Entity={VEHICLE_MAINTENANCE_NOTE_ENTITY}
                Label={DELETE_PERMISSION}
              >
                <Link
                  to={"#"}
                  onClick={() => {
                    this.deleteHandler(row);
                  }}
                >
                  <DelBtn />
                </Link>
              </HasAccess>
            </div>
          );
        },
      },
    ];
  }

  componentDidMount() {
    let defaultOrder = this.state.sort;
    let sortOrder = "-" + defaultOrder[0]["order"];
    let sortField = "me." + defaultOrder[0]["dataField"];
    let data = {};
    data.attrs = {};
    data.attrs["prefetch"] = [{ vehicle_maintenance: "vehicle" }];
    data.attrs["order_by"] = {};
    data.attrs.order_by[sortOrder] = sortField;
    data.conds = {};
    if (this.maintenanceId !== null) {
      data.conds["vehicle_maintenance.id"] = this.maintenanceId;
    }
    data.page = 1;
    data.page_size = PagingOptions.sizePerPage;
    this.props.dispatch(fetchMaintenanceNote(data));
  }

  handleTableChange(
    type,
    { filters, page, sortField, sortOrder, sizePerPage }
  ) {
    let defaultOrder = this.state.sort;
    let sortOrderstring =
      typeof sortOrder !== "undefined"
        ? "-" + sortOrder
        : "-" + defaultOrder[0]["order"];
    let data = {};
    data.attrs = {};
    data.attrs["order_by"] = {};
    data.attrs.order_by[sortOrderstring] =
      sortField !== null
        ? "me." + sortField
        : "me." + defaultOrder[0]["dataField"];
    data.attrs["prefetch"] = ["vehicle_maintenance"];
    data.conds = {};
    if (this.maintenanceId !== null) {
      data.conds["vehicle_maintenance.id"] = this.maintenanceId;
    }
    data.page = page;
    data.page_size = sizePerPage;
    this.props.dispatch(fetchMaintenanceNote(data));
  }

  downloadInvoice(url, mimeType, filename) {
    downloadFile(url, filename, mimeType);
  }

  viewHandler(row) {
    alert("hello");
    console.log(row);
  }

  listHandler(row) {
    console.log(row);
  }
  editHandler(row) {
    let url =
      "/admin/maintenance/" +
      this.props.match.params.mid +
      "/notes/" +
      row.id +
      "/edit";
    this.props.history.push(url);
  }
  addHandler() {
    let url =
      "/admin/maintenance/" + this.props.match.params.mid + "/notes/add";
    this.props.history.push(url);
  }
  deleteHandler(row) {
    let rowId = row.id;
    let successor = row !== null ? row.successor : null;
    let rowData = { post: { successor: null } };
    if (rowId !== null) {
      rowData["id"] = rowId;
    }
    if (successor !== null) {
      rowData["post"]["successor"] = successor;
    }
    rowData["url"] =
      "/data/core/vehicle_maintenance_note/" + rowId + "/transitions/delete";
    rowData["redirect_to"] =
      "/admin/maintenance/" + this.props.match.params.mid + "/notes";
    if (row !== null && typeof rowId !== "undefined") {
      this.props.dispatch(showDeleteDialog(rowData));
    }
  }

  addMaintenanceNoteHandler() {
    let url =
      "/admin/maintenance/" + this.props.match.params.mid + "/notes/add";
    this.props.history.push(url);
  }
  maintenanceDetailHandler(row) {
    let url = "/admin/maintenance/view/" + row.id;
    this.props.history.push(url);
  }
  vehicleDetailHandler(row) {
    if (typeof row.vehicle.id !== "undefined") {
      let url = "/admin/vehicles/view/" + row.vehicle.id;
      this.props.history.push(url);
    }
  }
  customerDetailHandler(row) {
    if (typeof row.owning_customer.id !== "undefined") {
      let url = "/admin/customers/" + row.owning_customer.id;
      this.props.history.push(url);
    }
  }

  render() {
    let options = PagingOptions;
    options.totalSize =
      typeof this.props.maintenanceNoteData !== "undefined" &&
      typeof this.props.maintenanceNoteData.rows !== "undefined" &&
      typeof this.props.maintenanceNoteData.rows.meta.filtered !== "undefined"
        ? this.props.maintenanceNoteData.rows.meta.filtered
        : "";
    options.sizePerPage =
      typeof this.props.maintenanceNoteData !== "undefined" &&
      typeof this.props.maintenanceNoteData.rows !== "undefined" &&
      typeof this.props.maintenanceNoteData.rows.meta.page_size !== "undefined"
        ? this.props.maintenanceNoteData.rows.meta.page_size
        : 10;
    let maintenance_note_data =
      typeof this.props.maintenanceNoteData !== "undefined" &&
      typeof this.props.maintenanceNoteData.rows !== "undefined" &&
      typeof this.props.maintenanceNoteData.rows.data !== "undefined"
        ? this.props.maintenanceNoteData.rows.data
        : [];
    let columns = this.renderColumns();

    return (
      <div className="wrapper_datatable">
        <div className="table_main">
          <h4><ColumnText
              Module={CORE_MODULE}
              Entity={VEHICLE_MAINTENANCE_NOTE_ENTITY}
            /></h4>
          <div className="btngroup">
            <HasAccess
              Module={CORE_MODULE}
              Entity={VEHICLE_MAINTENANCE_NOTE_ENTITY}
              Label={CREATE_PERMISSION}
            >
              <button
                type="button"
                className="btn btn-dark"
                onClick={() => this.addMaintenanceNoteHandler()}
              >
                {" "}
                <ColumnText
                  Module={CORE_MODULE}
                  Entity={VEHICLE_MAINTENANCE_NOTE_ENTITY}
                />{" "}
                hinzufügen
              </button>
            </HasAccess>
          </div>
        </div>
        <BootstrapTable
          remote={{ pagination: true }}
          bootstrap4
          keyField="id"
          data={maintenance_note_data}
          columns={columns}
          bordered={false}
          rowClasses={"table-row"}
          defaultSorted={this.state.sort}
          pagination={paginationFactory(options)}
          onTableChange={this.handleTableChange}
          noDataIndication={() =>
            this.props.maintenanceNoteData.loading ? (
              <NoDataIndication />
            ) : (
              NO_DATA
            )
          }
        />
      </div>
    );
  }
}

MaintenanceNoteScreen.propTypes = {};
MaintenanceNoteScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  maintenanceNoteData: state.MNote.maintenanceData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaintenanceNoteScreen);
