import { call, all, put, takeLatest } from "redux-saga/effects";
import { authHeader } from "../../../utils";
import request from "../../../services/request";
import * as ActionTypes from "../../actions/constants";
import {
  updateMaintenanceNoteSuccess,
  updateMaintenanceNoteError,
  createMaintenanceNoteSuccess,
  createMaintenanceNoteError,
  fetchMaintenanceNoteSuccess,
  fetchMaintenanceNoteError,
  fetchMaintenanceNoteByIdSuccess,
  fetchMaintenanceNoteByIdError,
} from "../../actions/vehicle";

function* maintenanceSaga({ data }) {
  try {
    const requestURL = "data/core/vehicle_maintenance_note/query";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data,
    });
    yield put(fetchMaintenanceNoteSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(fetchMaintenanceNoteError(error));
    }
  }
}

function* indexSaga({ data }) {
  try {
    const requestURL = "data/core/vehicle_maintenance_note/query";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data,
    });
    yield put(fetchMaintenanceNoteByIdSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(fetchMaintenanceNoteByIdError(error));
    }
  }
}

function* updateSaga({ data }) {
  try {
    let maintenance_id =
      typeof data.maintenance_id !== "undefined" && data.maintenance_id !== null
        ? data.maintenance_id
        : null;
    let maintenanceData =
      typeof data.note !== "undefined" && data.note !== null ? data.note : null;
    const requestURL =
      "/data/core/vehicle_maintenance_note/" +
      `${maintenance_id}` +
      "/transitions/edit";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: maintenanceData,
    });
    yield put(updateMaintenanceNoteSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(updateMaintenanceNoteError(error));
    }
  }
}

function* createSaga({ data }) {
  try {
    //let maintenance_id = ((typeof(data.maintenance_id) !== 'undefined')&&(data.maintenance_id !== null))?data.maintenance_id:null;
    let noteData =
      typeof data.note !== "undefined" && data.note !== null ? data.note : null;
    const requestURL = "/data/core/vehicle_maintenance_note/transitions/create";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: noteData,
    });
    yield put(createMaintenanceNoteSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(createMaintenanceNoteError(error));
    }
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(ActionTypes.GET_MAINTENANCE_NOTE, maintenanceSaga),
    yield takeLatest(ActionTypes.GET_MAINTENANCE_NOTE_BY_ID, indexSaga),
    yield takeLatest(ActionTypes.CREATE_MAINTENANCE_NOTE, createSaga),
    yield takeLatest(ActionTypes.UPDATE_MAINTENANCE_NOTE, updateSaga),
  ]);
}
