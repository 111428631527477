import React,{Component} from 'react';
import {Button,Modal} from 'react-bootstrap';
import PropTypes from "prop-types";


class DialogContent extends Component {
		render(){
			
			return(
				<Modal 
				    show = {true}
					onHide={this.props.onDeny}
					backdrop="static"
					centered
					
					 >
					<Modal.Header closeButton>
					<Modal.Title>{this.props.title}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
					<p>{this.props.content}</p>
					</Modal.Body>
					<Modal.Footer>
						
							<Button onClick={this.props.onDeny} variant="secondary">{this.props.closeBtnTitle}</Button>
						{this.props.showConfirmButton ?
						<Button onClick={this.props.onConfirm} variant="primary">{this.props.confirmBtnTitle}</Button>:""	}
					</Modal.Footer>
			    </Modal>);
		}

}

DialogContent.propTypes = {
	
	onDeny: PropTypes.func.isRequired,
	onConfirm:PropTypes.func.isRequired,
	showConfirmButton:PropTypes.bool.isRequired,
	title:PropTypes.string.isRequired,
	content:PropTypes.string.isRequired,
	closeBtnTitle:PropTypes.string.isRequired,
	confirmBtnTitle:PropTypes.string.isRequired

};
DialogContent.defaultProps = {showConfirmButton:true,closeBtnTitle:"Close",confirmBtnTitle:"Confirm"};

export default DialogContent;
