export const GET_DOCUMENT = 'constants/vehicle/GET_DOCUMENT';
export const DOCUMENT_SUCCESS = 'constants/vehicle/DOCUMENT_SUCCESS';
export const DOCUMENT_ERROR = 'constants/vehicle/DOCUMENT_ERROR';

export const GET_DOCUMENT_BY_ID = 'constants/vehicle/GET_DOCUMENT_BY_ID';
export const DOCUMENT_BY_ID_SUCCESS = 'constants/vehicle/DOCUMENT_BY_ID_SUCCESS';
export const DOCUMENT_BY_ID_ERROR = 'constants/vehicle/DOCUMENT_BY_ID_ERROR';

export const CREATE_DOCUMENT = 'constants/vehicle/CREATE_DOCUMENT';
export const CREATE_DOCUMENT_SUCCESS = 'constants/vehicle/CREATE_DOCUMENT_SUCCESS';
export const CREATE_DOCUMENT_ERROR = 'constants/vehicle/CREATE_DOCUMENT_ERROR';
export const RESET_CREATE_DOCUMENT = 'constants/vehicle/RESET_CREATE_DOCUMENT';

export const UPDATE_DOCUMENT = 'constants/vehicle/UPDATE_DOCUMENT';
export const UPDATE_DOCUMENT_SUCCESS = 'constants/vehicle/UPDATE_DOCUMENT_SUCCESS';
export const UPDATE_DOCUMENT_ERROR = 'constants/vehicle/UPDATE_DOCUMENT_ERROR';
export const RESET_UPDATE_DOCUMENT = 'constants/vehicle/RESET_UPDATE_DOCUMENT';
