import * as Types from "../constants";

// customer actions
export function fetchClient(data) {
    return {
        type: Types.GET_CLIENT,
        data
    };
};
export function fetchClientSuccess(response) {
    return {
        type: Types.CLIENT_SUCCESS,
        response
    };
};
export function fetchClientError(error) {
    return {
        type: Types.CLIENT_ERROR,
        error
    };
};



// customer actions
export function updatePassword(data) {
    return {
        type: Types.UPDATE_PASSWORD,
        data
    };
};
export function updatePasswordSuccess(response) {
    return {
        type: Types.UPDATE_PASSWORD_SUCCESS,
        response
    };
};
export function updatePasswordError(error) {
    return {
        type: Types.UPDATE_PASSWORD_ERROR,
        error
    };
};
export function resetUpdatePassword() {
    return {
        type: Types.UPDATE_PASSWORD_ERROR
    };
};


export function createClient(data) {
    return {
        type: Types.CREATE_CLIENT,
        data
    };
};
export function createClientSuccess(response) {
    return {
        type: Types.CREATE_CLIENT_SUCCESS,
        response
    };
};
export function createClientError(error) {
    return {
        type: Types.CREATE_CLIENT_ERROR,
        error
    };
};
export function resetCreateClient() {
    return {
        type: Types.RESET_CREATE_CLIENT
    };
};




export function updateClient(data) {
    return {
        type: Types.UPDATE_CLIENT,
        data
    };
};
export function updateClientSuccess(response) {
    return {
        type: Types.UPDATE_CLIENT_SUCCESS,
        response
    };
};
export function updateClientError(error) {
    return {
        type: Types.UPDATE_CLIENT_ERROR,
        error
    };
};
export function resetUpdateClient() {
    return {
        type: Types.RESET_UPDATE_CLIENT
    };
};


