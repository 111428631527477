export const CREATE_MAINTENANCE_NOTE = 'constants/vehicle/CREATE_MAINTENANCE_NOTE';
export const CREATE_MAINTENANCE_NOTE_SUCCESS = 'constants/vehicle/CREATE_MAINTENANCE_NOTE_SUCCESS';
export const CREATE_MAINTENANCE_NOTE_ERROR = 'constants/vehicle/CREATE_MAINTENANCE_NOTE_ERROR';
export const RESET_CREATE_MAINTENANCE_NOTE = 'constants/vehicle/RESET_CREATE_MAINTENANCE_NOTE';

export const GET_MAINTENANCE_NOTE = 'constants/vehicle/GET_MAINTENANCE_NOTE';
export const MAINTENANCE_NOTE_SUCCESS = 'constants/vehicle/MAINTENANCE_NOTE_SUCCESS';
export const MAINTENANCE_NOTE_ERROR = 'constants/vehicle/MAINTENANCE_NOTE_ERROR';


export const GET_MAINTENANCE_NOTE_BY_ID = 'constants/vehicle/GET_MAINTENANCE_NOTE_BY_ID';
export const MAINTENANCE_NOTE_BY_ID_SUCCESS = 'constants/vehicle/MAINTENANCE_NOTE_BY_ID_SUCCESS';
export const MAINTENANCE_NOTE_BY_ID_ERROR = 'constants/vehicle/MAINTENANCE_NOTE_BY_ID_ERROR';


export const UPDATE_MAINTENANCE_NOTE = 'constants/vehicle/UPDATE_MAINTENANCE_NOTE';
export const UPDATE_MAINTENANCE_NOTE_SUCCESS = 'constants/vehicle/UPDATE_MAINTENANCE_NOTE_SUCCESS';
export const UPDATE_MAINTENANCE_NOTE_ERROR = 'constants/vehicle/UPDATE_MAINTENANCE_NOTE_ERROR';
export const RESET_UPDATE_MAINTENANCE_NOTE = 'constants/vehicle/RESET_UPDATE_MAINTENANCE_NOTE';