import React, { Component } from "react";
import { Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { resetLoginPassword } from "../../store/actions/forgot";
import { queryString } from "query-string";
// const queryString = require("query-string");
class ResetPasswordScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        user_password: "",
      },
      errors: {
        user_password: "",
        userMsg: "",
      },
    };
  }
  componentDidMount() {
    let res = queryString.parse(this.props.location.search);
    if (typeof res["token"] !== "undefined") {
      this.props.dispatch(resetLoginPassword(res["token"].trim()));
    }
  }

  render() {
    return (
      <>
        {this.props.resetData.error && (
          <Alert variant="danger">
            <p>{this.props.resetData.msg}</p>
          </Alert>
        )}

        {this.props.resetData.success && (
          <p>Ihr temporäres Passwort ist {this.props.resetData.msg}</p>
        )}
      </>
    );
  }
}
ResetPasswordScreen.propTypes = {};
ResetPasswordScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  loginData: state.Login.loginData,
  resetData: state.Forgot.resetData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordScreen);
