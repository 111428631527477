import React, { Component } from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  PagingOptions,
  NoDataIndication,
  DelBtn,
  EditBtn,
  ViewBtn,
  downloadFile,
  CORE_MODULE,
  VEHICLE_MAINTENANCE_ENTITY,
  VEHICLE_MAINTENANCE_NOTE_ENTITY,
  REMOVE_PERMISSION,
  MODIFY_PERMISSION,
  ADD_PERMISSION,
  CREATE_PERMISSION,
  NO_DATA,
} from "../../utils";
import { fetchVehicleMaintenance } from "../../store/actions/vehicle";
import moment from "moment";
import { Link } from "react-router-dom";
import { showDeleteDialog } from "../../store/actions/dialog";
import { HasAccess } from "../../components/Access";
import ColumnText from "../../components/Column";

class MaintenanceScreen extends Component {
  customerId = null;
  vehicleId = null;

  constructor(props) {
    super(props);
    this.state = {
      sort: [
        {
          dataField: "id",
          order: "desc",
        },
      ],
    };
    this.customerId = props.match.params.cid;
    this.vehicleId = props.match.params.vid;
    this.handleTableChange = this.handleTableChange.bind(this);
    this.viewHandler = this.viewHandler.bind(this);
    //this.listHandler = this.listHandler.bind(this);
    this.editHandler = this.editHandler.bind(this);
    this.addHandler = this.addHandler.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
    this.addMaintenanceNoteHandler = this.addMaintenanceNoteHandler.bind(this);
    this.vehicleDetailHandler = this.vehicleDetailHandler.bind(this);
    this.maintenanceDetailHandler = this.maintenanceDetailHandler.bind(this);
    this.downloadInvoice = this.downloadInvoice.bind(this);
  }

  renderColumns = () => {
    return [
      {
        dataField: "id",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={VEHICLE_MAINTENANCE_ENTITY}
            Label={"id"}
          />
        ),
        sort: true,
        headerClasses: "cell-header cell-mnt-id-header",
        classes: "cell-mnt-id",
        formatter: (cellContent, row) => {
          if (cellContent !== "") {
            return (
              <>
                <Link
                  to={"#"}
                  onClick={() => {
                    this.maintenanceDetailHandler(row);
                  }}
                >
                  {cellContent}
                </Link>
              </>
            );
          }
        },
      },
      {
        dataField: "vehicle",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={VEHICLE_MAINTENANCE_ENTITY}
            Label={"vehicle"}
          />
        ),
        sort: true,
        headerClasses: "cell-header cell-mnt-vehicle-header",
        classes: "cell-mnt-vehicle",
        formatter: (cellContent, row) => {
          var obj = cellContent;
          var vehicle_id =
            obj !== null &&
            typeof obj !== "undefined" &&
            typeof obj.vin !== "undefined"
              ? obj.vin
              : "";
          return (
            <>
              <Link
                to={"#"}
                onClick={() => {
                  this.vehicleDetailHandler(row);
                }}
              >
                {vehicle_id}
              </Link>
            </>
          );
        },
      },
      {
        dataField: "owning_customer",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={VEHICLE_MAINTENANCE_ENTITY}
            Label={"owning_customer"}
          />
        ),
        sort: true,
        headerClasses: "cell-header cell-mnt-owning-client-header",
        classes: "cell-mnt-owning-client",
        formatter: (cellContent, row) => {
          let customer_name = "";
          if (cellContent.is_client === 1) {
            var obj = cellContent.owning_client;
            var first_name =
              obj !== null &&
              typeof obj !== "undefined" &&
              typeof obj.first_name !== "undefined"
                ? obj.first_name
                : "";
            var last_name =
              obj !== null &&
              typeof obj !== "undefined" &&
              typeof obj.last_name !== "undefined"
                ? obj.last_name
                : "";
            customer_name = first_name + " " + last_name;
          } else {
            customer_name = cellContent.name;
          }
          return (
            <>
              <Link
                to={"#"}
                onClick={() => {
                  this.customerDetailHandler(row);
                }}
              >
                {customer_name}
              </Link>
            </>
          );
        },
      },
      {
        dataField: "date",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={VEHICLE_MAINTENANCE_ENTITY}
            Label={"date"}
          />
        ),
        sort: true,
        headerClasses: "cell-header cell-mnt-date-header",
        classes: "cell-mnt-date",
        formatter: (cellContent, row) => {
          let service_date =
            cellContent !== null && typeof cellContent !== "undefined"
              ? moment(cellContent).format("DD.MM.YYYY")
              : "";
          return <>{service_date}</>;
        },
      },
      {
        dataField: "invoice",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={VEHICLE_MAINTENANCE_ENTITY}
            Label={"invoice"}
          />
        ),
        sort: true,
        headerClasses: "cell-header cell-mnt-invoice-header",
        classes: "cell-mnt-invoice",
        formatter: (cellContent, row) => {
          let cell =
            cellContent !== null && typeof cellContent !== "undefined"
              ? JSON.parse(cellContent)
              : {};
          //let url = (cell !== {})?cell.path:'';
          let mime_type = cell !== {} ? cell.mime_type : "";
          let name = cell !== {} ? cell.original_name : "";
          let url =
            "data/core/vehicle_maintenance/" + row.id + "/download/invoice";
          if (url !== "") {
            return (
              <>
                {" "}
                <Link
                  to="#"
                  onClick={() => this.downloadInvoice(url, mime_type, name)}
                >
                  {name}
                </Link>
              </>
            );
          }
        },
      },

      {
        dataField: "instance_entity",
        text: "",
        sort: false,
        headerClasses: "cell-header cell-actions cell-actions-header",
        classes: "cell-mnt-actions",
        formatter: (cellContent, row) => {
          return (
            <div className="svg">
              <Link
                to={"#"}
                onClick={() => {
                  this.viewHandler(row);
                }}
              >
                <ViewBtn />
              </Link>
              <HasAccess
                Module={CORE_MODULE}
                Entity={VEHICLE_MAINTENANCE_ENTITY}
                Label={MODIFY_PERMISSION}
              >
                <Link
                  to={"#"}
                  onClick={() => {
                    this.editHandler(row);
                  }}
                >
                  <EditBtn />
                </Link>
              </HasAccess>
              <HasAccess
                Module={CORE_MODULE}
                Entity={VEHICLE_MAINTENANCE_ENTITY}
                Label={REMOVE_PERMISSION}
              >
                <Link
                  to={"#"}
                  onClick={() => {
                    this.deleteHandler(row);
                  }}
                >
                  <DelBtn />
                </Link>
              </HasAccess>
            </div>
          );
        },
      },
    ];
  };
  componentDidMount() {
    let defaultOrder = this.state.sort;
    let sortOrder = "-" + defaultOrder[0]["order"];
    let sortField = "me." + defaultOrder[0]["dataField"];
    let data = {};
    data.attrs = {};
    data.attrs["order_by"] = {};
    data.attrs["prefetch"] = ["vehicle", { owning_customer: "owning_client" }];
    data.attrs.order_by[sortOrder] = sortField;
    data.conds = {};
    if (this.customerId !== null) {
      data.conds["owning_customer.id"] = this.customerId;
    }
    if (this.vehicleId !== null) {
      data.conds["vehicle.id"] = this.vehicleId;
    }
    data.page = 1;
    data.page_size = PagingOptions.sizePerPage;
    // console.log("fetchVehicleMaintenance(data)----------------------");
    // this.props.dispatch(fetchVehicleMaintenance(data));
  }

  handleTableChange(
    type,
    { filters, page, sortField, sortOrder, sizePerPage }
  ) {
    let defaultOrder = this.state.sort;
    let sortOrderstring =
      typeof sortOrder !== "undefined"
        ? "-" + sortOrder
        : "-" + defaultOrder[0]["order"];
    let sort_field =
      defaultOrder[0]["dataField"].indexOf(".") === -1
        ? "me." + defaultOrder[0]["dataField"]
        : defaultOrder[0]["dataField"];
    if (sortField !== null) {
      sort_field =
        sortField.indexOf(".") === -1 ? "me." + sortField : sortField;
    }
    let data = {};
    data.attrs = {};
    data.attrs["order_by"] = {};
    data.attrs["prefetch"] = ["vehicle", { owning_customer: "owning_client" }];
    data.attrs.order_by[sortOrderstring] = sort_field;

    data.conds = {};
    if (this.customerId !== null) {
      data.conds["owning_customer.id"] = this.customerId;
    }
    if (this.vehicleId !== null) {
      data.conds["vehicle.id"] = this.vehicleId;
    }
    data.page = page;
    data.page_size = sizePerPage;
    this.props.dispatch(fetchVehicleMaintenance(data));
  }

  downloadInvoice(url, mimeType, filename) {
    downloadFile(url, filename, mimeType);
  }

  viewHandler(row) {
    let url = "/admin/maintenance/view/" + row.id;
    this.props.history.push(url);
  }

  deleteHandler(row) {
    let rowId = row.id;
    let successor = row !== null ? row.successor : null;
    let rowData = { post: { successor: null } };
    if (rowId !== null) {
      rowData["id"] = rowId;
    }
    if (successor !== null) {
      rowData["post"]["successor"] = successor;
    }
    rowData["url"] =
      "/data/core/vehicle_maintenance/" + rowId + "/transitions/remove";
    rowData["redirect_to"] = "/admin/maintenance";
    if (row !== null && typeof rowId !== "undefined") {
      this.props.dispatch(showDeleteDialog(rowData));
    }
  }
  editHandler(row) {
    let url = "/admin/maintenance/" + row.id + "/edit";
    this.props.history.push(url);
  }
  addHandler() {
    let url = "/admin/maintenance/add/";
    if(this.vehicleId) {
      url = "/admin/vehicles/" + this.vehicleId + "/maintenance/add/";
    }
    this.props.history.push(url);
  }

  addMaintenanceNoteHandler() {
    let url = "/admin/maintenance-notes/add/";
    if(this.vehicleId) {
      url = "/admin/vehicles/" + this.vehicleId + "/maintenance-notes/add/";
    }
    this.props.history.push(url);
  }
  maintenanceDetailHandler(row) {
    let url = "/admin/maintenance/view/" + row.id;
    this.props.history.push(url);
  }
  vehicleDetailHandler(row) {
    if (typeof row.vehicle.id !== "undefined") {
      let url = "/admin/vehicles/view/" + row.vehicle.id;
      this.props.history.push(url);
    }
  }
  customerDetailHandler(row) {
    if (typeof row.owning_customer.id !== "undefined") {
      let url = "/admin/customers/" + row.owning_customer.id;
      this.props.history.push(url);
    }
  }

  render() {
    let options = PagingOptions;
    options.totalSize =
      typeof this.props.maintenanceData !== "undefined" &&
      typeof this.props.maintenanceData.rows !== "undefined" &&
      typeof this.props.maintenanceData.rows.meta.filtered !== "undefined"
        ? this.props.maintenanceData.rows.meta.filtered
        : "";
    options.sizePerPage =
      typeof this.props.maintenanceData !== "undefined" &&
      typeof this.props.maintenanceData.rows !== "undefined" &&
      typeof this.props.maintenanceData.rows.meta.page_size !== "undefined"
        ? this.props.maintenanceData.rows.meta.page_size
        : 10;
    let vehicle_data =
      typeof this.props.maintenanceData !== "undefined" &&
      typeof this.props.maintenanceData.rows !== "undefined" &&
      typeof this.props.maintenanceData.rows.data !== "undefined"
        ? this.props.maintenanceData.rows.data
        : [];

    let columns = this.renderColumns();
    return (
      <div className="wrapper_datatable">
        <div className="table_main">
          <h4>
            <ColumnText
              Module={CORE_MODULE}
              Entity={VEHICLE_MAINTENANCE_ENTITY}
            />
          </h4>
          <div className="btngroup">
            <HasAccess
              Module={CORE_MODULE}
              Entity={VEHICLE_MAINTENANCE_ENTITY}
              Label={ADD_PERMISSION}
            >
              <button
                type="button"
                className="btn btn-dark"
                onClick={() => {
                  this.addHandler();
                }}
              >
                {" "}
                <ColumnText
                  Module={CORE_MODULE}
                  Entity={VEHICLE_MAINTENANCE_ENTITY}
                />{" "}
                hinzufügen
              </button>
            </HasAccess>
          </div>
        </div>
        <BootstrapTable
          remote={{ pagination: true }}
          bootstrap4
          keyField="id"
          data={vehicle_data}
          columns={columns}
          bordered={false}
          rowClasses={"table-row"}
          defaultSorted={this.state.sort}
          pagination={paginationFactory(options)}
          onTableChange={this.handleTableChange}
          noDataIndication={() =>
            this.props.maintenanceData.loading ? <NoDataIndication /> : NO_DATA
          }
        />
      </div>
    );
  }
}

MaintenanceScreen.propTypes = {};
MaintenanceScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  maintenanceData: state.Maintenance.maintenanceData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceScreen);
