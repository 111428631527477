import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  PagingOptions,
  NoDataIndication,
  DelBtn,
  EditBtn,
  ListBtn,
  ViewBtn,
  NO_DATA,
  CUSTOMER_ENTITY,
  CORE_MODULE
} from "../../utils";

import { fetchCustomer } from "../../store/actions/customer";
import { showDeleteDialog } from "../../store/actions/dialog";
import moment from "moment";
import ColumnText from "../../components/Column";

class CustomerScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true,
        },
        {
          dataField: "is_client",
          text: "Type",
          sort: true,
          headerClasses: "Cell Cell-header",
          formatter: (cellContent, row) => {
            if (cellContent === 1) {
              return <>{"Kunde"}</>;
            } else {
              return <>{"Firma"}</>;
            }
          },
        },
        {
          dataField: "owning_client",
          text: "Cust Name",
          sort: true,
          headerClasses: "Cell Cell-header",
          formatter: (cellContent, row) => {
            var obj = cellContent;
            var first_name =
              obj !== null &&
              typeof obj !== "undefined" &&
              typeof obj.first_name !== "undefined"
                ? obj.first_name
                : "";
            var last_name =
              obj !== null &&
              typeof obj !== "undefined" &&
              typeof obj.last_name !== "undefined"
                ? obj.last_name
                : "";
            var customer_name = first_name + " " + last_name;
            return (
              <>
                <Button
                  variant="link"
                  onClick={() => {
                    this.viewHandler(row);
                  }}
                >
                  {customer_name}
                </Button>
              </>
            );
          },
        },
        {
          dataField: "owning_client.email",
          text: "Email",
          sort: true,
          headerClasses: "Cell Cell-header",
        },
        {
          dataField: "core__vehicle_maintenance__owning_customer",
          text: "Last Service",
          sort: true,
          headerClasses: "Cell Cell-header",
          formatter: (cellContent, row) => {
            let service_date = "";
            if (cellContent !== null && typeof cellContent !== "undefined") {
              if (cellContent.length > 0) {
                let total = cellContent.length;
                let last = cellContent[total - 1];
                let mant_id =
                  typeof last.id !== "undefined" && last.id !== null
                    ? last.id
                    : "";
                service_date =
                  typeof last.date !== "undefined"
                    ? moment(last.date).format("DD.MM.YYYY")
                    : "";
                if (service_date !== "" && mant_id !== "") {
                  return (
                    <>
                      <Button
                        variant="link"
                        onClick={() => {
                          this.showHandler(mant_id);
                        }}
                      >
                        {service_date}
                      </Button>
                    </>
                  );
                }
              }
            }
            return <>{service_date}</>;
          },
        },
        {
          dataField: "addresses",
          text: "City",
          sort: true,
          headerClasses: "Cell Cell-header",
          formatter: (cellContent, row) => {
            var obj = JSON.parse(cellContent);
            var city =
              obj !== null &&
              typeof obj !== "undefined" &&
              typeof obj.city !== "undefined"
                ? obj.city
                : "";
            return <>{city}</>;
          },
        },
        {
          dataField: "addresses",
          text: "Zip",
          sort: true,
          headerClasses: "Cell Cell-header",
          formatter: (cellContent, row) => {
            var obj = JSON.parse(cellContent);
            var zip =
              obj !== null &&
              typeof obj !== "undefined" &&
              typeof obj.zip !== "undefined"
                ? obj.zip
                : "";
            return <>{zip}</>;
          },
        },
        {
          dataField: "core__vehicle_maintenance__owning_customer",
          text: "Vehicle",
          sort: true,
          headerClasses: "Cell Cell-header",
          formatter: (cellContent, row) => {
            let vehicle = "";
            if (cellContent !== null && typeof cellContent !== "undefined") {
              if (cellContent.length > 0) {
                let total = cellContent.length;
                let last = cellContent[total - 1];
                // console.log(last);
                vehicle =
                  typeof last.vehicle !== "undefined" ? last.vehicle : "";
              }
            }
            return (
              <>
                <Button
                  variant="link"
                  onClick={() => {
                    this.showVehicleHandler(row, vehicle);
                  }}
                >
                  {vehicle}
                </Button>
              </>
            );
          },
        },
        {
          dataField: "phonenumbers",
          text: "Mobile",
          sort: false,
          headerClasses: "Cell Cell-header",
          formatter: (cellContent, row) => {
            var obj = JSON.parse(cellContent);
            var mobile =
              obj !== null &&
              typeof obj !== "undefined" &&
              typeof obj.mobile !== "undefined"
                ? obj.mobile
                : "";
            return <>{mobile}</>;
          },
        },
        {
          dataField: "phonenumbers",
          text: "Phone",
          sort: false,
          headerClasses: "Cell Cell-header",
          formatter: (cellContent, row) => {
            var obj = JSON.parse(cellContent);
            var phone =
              obj !== null &&
              typeof obj !== "undefined" &&
              typeof obj.phone !== "undefined"
                ? obj.phone
                : "";
            return <>{phone}</>;
          },
        },
        {
          dataField: "price",
          text: "Actions",
          sort: false,
          headerClasses: "Cell Cell-header",
          formatter: (cellContent, row) => {
            return (
              <div className="svg">
                <Button
                  variant="link"
                  onClick={() => {
                    this.listHandler(row);
                  }}
                >
                  <ListBtn />
                </Button>
                <Button
                  variant="link"
                  onClick={() => {
                    this.viewHandler(row);
                  }}
                >
                  <ViewBtn />
                </Button>
                <Button
                  variant="link"
                  onClick={() => {
                    this.editHandler(row);
                  }}
                >
                  <EditBtn />
                </Button>
                <Button
                  variant="link"
                  onClick={() => {
                    this.deleteHandler(row);
                  }}
                >
                  <DelBtn />
                </Button>
              </div>
            );
          },
        },
      ],
      sort: [
        {
          dataField: "id",
          order: "desc",
        },
      ],
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.viewHandler = this.viewHandler.bind(this);
    this.listHandler = this.listHandler.bind(this);
    this.showHanlder = this.showHandler.bind(this);
    this.editHandler = this.editHandler.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
    this.addHandler = this.addHandler.bind(this);
  }

  componentDidMount() {

  }

  handleTableChange(
    type,
    { filters, page, sortField, sortOrder, sizePerPage }
  ) {
    let defaultOrder = this.state.sort;
    let sortOrderstring =
      typeof sortOrder !== "undefined"
        ? "-" + sortOrder
        : "-" + defaultOrder[0]["order"];
    let data = {};
    data.attrs = {};
    data.attrs["prefetch"] = [
      {
        "owning_client": "system__assignment__assigned_client"
      },
      "core__vehicle_maintenance__owning_customer",
    ];
    data.attrs["order_by"] = {};
    data.attrs.order_by[sortOrderstring] =
      sortField !== null
        ? "me." + sortField
        : "me." + defaultOrder[0]["dataField"];
    data.auto_ident_depth = 2;
    data.conds = {"system__assignment__assigned_client.agenda": 9};
    data.page = page;
    data.page_size = sizePerPage;
    console.log(data);
    this.props.dispatch(fetchCustomer(data));
  }

  viewHandler(row) {
    let url = "/admin/customers/" + row.id;
    this.props.history.push(url);
  }

  editHandler(row) {
    let url = "/admin/customers/" + row.id + "/edit";
    this.props.history.push(url);
  }

  showHandler(id) {
    let url = "/admin/maintenance/view/" + id;
    this.props.history.push(url);
  }

  listHandler(row) {
    let url = "/admin/customers/" + row.id + "/notes";
    this.props.history.push(url);
  }
  addHandler() {
    this.props.history.push("/admin/customers/add");
  }

  deleteHandler(row) {
    let rowId = row.owning_client.id;
    let customer_data =
      typeof this.props.customerData.rows.data[0] !== "undefined"
        ? this.props.customerData.rows.data[0]
        : null;
    // let successor = (customer_data !== null)?customer_data.successor:null;
    let rowData = { post: { locked: true } };
    rowData["url"] = "/data/system/client/" + rowId + "/transitions/edit";
    rowData["redirect_to"] = "/admin/customers";
    if (customer_data !== null && typeof rowId !== "undefined") {
      this.props.dispatch(showDeleteDialog(rowData));
    }
  }
  showVehicleHandler(row, vehicleId) {
    let url = "/admin/customers/" + row.id + "/vehicles/view" + vehicleId;
    this.props.history.push(url);
  }

  render() {
    console.log(this.props);
    let options = PagingOptions;
    options.totalSize =
      typeof this.props.customerData !== "undefined" &&
      typeof this.props.customerData.rows !== "undefined" &&
      typeof this.props.customerData.rows.meta.filtered !== "undefined"
        ? this.props.customerData.rows.meta.filtered
        : "";
    options.sizePerPage =
      typeof this.props.customerData !== "undefined" &&
      typeof this.props.customerData.rows !== "undefined" &&
      typeof this.props.customerData.rows.meta.page_size !== "undefined"
        ? this.props.customerData.rows.meta.page_size
        : 10;
    let data =
      typeof this.props.customerData !== "undefined" &&
      typeof this.props.customerData.rows !== "undefined" &&
      typeof this.props.customerData.rows.data !== "undefined"
        ? this.props.customerData.rows.data
        : [];

    return (
      <div className="wrapper_datatable">
        <div className="table_main">
          <h4><ColumnText Module={CORE_MODULE} Entity={CUSTOMER_ENTITY} /></h4>
          <button
            type="button"
            className="btn btn-dark"
            onClick={() => {
              this.addHandler();
            }}
          >
            {" "}
            <ColumnText Module={CORE_MODULE} Entity={CUSTOMER_ENTITY} />{" "}
            hinzufügen
          </button>
        </div>
        <BootstrapTable
          remote={{ pagination: true }}
          bootstrap4
          keyField="id"
          data={data}
          columns={this.state.columns}
          bordered={false}
          rowClasses={"table-row"}
          defaultSorted={this.state.sort}
          pagination={paginationFactory(options)}
          onTableChange={this.handleTableChange}
          noDataIndication={() =>
            this.props.maintenanceData.loading ? <NoDataIndication /> : NO_DATA
          }
        />
      </div>
    );
  }
}

CustomerScreen.propTypes = {};
CustomerScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  customerData: state.Customer.customerData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(mapStateToProps, mapDispatchToProps)(CustomerScreen);
