import React, { Component } from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  PagingOptions,
  NoDataIndication,
  DelBtn,
  EditBtn,
  ListBtn,
  ViewBtn,
  removeEmptyValues,
  CORE_MODULE,
  SYSTEM_MODULE,
  CUSTOMER_ENTITY,
  CLIENT_ENTITY,
  CUSTOMER_NOTE_ENTITY,
  VEHICLE_MAINTENANCE_ENTITY,
  CREATE_PERMISSION,
  ADMIN_MODIFY_CLIENT_PERMISSION,
  ADMIN_MODIFY_COMPANY_PERMISSION,
  NO_DATA,
} from "../../utils";
import { fetchCustomer } from "../../store/actions/customer";
import { showDeleteDialog } from "../../store/actions/dialog";
import moment from "moment";
import { resetCustomerParams } from "../../store/actions/search";
import { HasAccess } from "../../components/Access";
import ColumnText from "../../components/Column";
import { Link } from "react-router-dom";

class CustomerScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAll: true,
      sort: [
        {
          dataField: "id",
          order: "desc",
        },
      ],
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.viewHandler = this.viewHandler.bind(this);
    this.listHandler = this.listHandler.bind(this);
    this.showHanlder = this.showHandler.bind(this);
    this.editHandler = this.editHandler.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
    this.addHandler = this.addHandler.bind(this);
  }
  renderColumns = () => {
    return [
      {
        dataField: "id",
        text: "ID",
        hidden: true,
      },
      {
        dataField: "is_client",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={CUSTOMER_ENTITY}
            Label={"is_client"}
          />
        ),
        sort: true,
        headerClasses: "cell-header cell-customer-type-header",
        classes: "cell-customer-type",
        formatter: (cellContent, row) => {
          if (cellContent === 1) {
            return <>{"Kunde"}</>;
          } else {
            return <>{"Firma"}</>;
          }
        },
      },
      {
        dataField: "identifier",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={CUSTOMER_ENTITY}
            Label={"identifier"}
          />
        ),
        sort: true,
        headerClasses: "cell-header cell-customer-identifier-header",
        classes: "cell-customer-identifier",
      },
      {
        dataField: "owning_client",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={CUSTOMER_ENTITY}
            Label={"name"}
          />
        ),
        sort: true,
        headerClasses: "cell-header cell-customer-header",
        classes: "cell-customer",
        formatter: (cellContent, row) => {
          let customer_name = "";
          if (row.is_client === 1) {
            var obj = cellContent;
            var first_name =
              obj !== null &&
              typeof obj !== "undefined" &&
              typeof obj.first_name !== "undefined"
                ? obj.first_name
                : "";
            var last_name =
              obj !== null &&
              typeof obj !== "undefined" &&
              typeof obj.last_name !== "undefined"
                ? obj.last_name
                : "";
            customer_name = first_name + " " + last_name;
          } else {
            customer_name = row.name;
          }
          return (
            <>
              <Link
                to={"#"}
                onClick={() => {
                  this.viewHandler(row);
                }}
              >
                {customer_name}
              </Link>
            </>
          );
        },
      },
      {
        dataField: "owning_client.email",
        text: (
          <ColumnText
            Module={SYSTEM_MODULE}
            Entity={CLIENT_ENTITY}
            Label={"email"}
          />
        ),
        sort: true,
        headerClasses: "cell-header cell-customer-email-header",
        classes: "cell-customer-email",
      },

      {
        dataField: "addresses",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={CUSTOMER_ENTITY}
            Label={"addresses"}
          />
        ),
        sort: true,
        headerClasses: "Cell Cell-customer-address-header",
        classes: "cell-customer-address",
        formatter: (cellContent, row) => {
          var obj = JSON.parse(cellContent);
          var city =
            obj !== null &&
            typeof obj[0] !== "undefined" &&
            typeof obj[0].Stadt !== "undefined" ? (
              <p>{obj[0].Stadt}</p>
            ) : (
              ""
            );
          var zip =
            obj !== null &&
            typeof obj[0] !== "undefined" &&
            typeof obj[0].PLZ !== "undefined" ? (
              <p>{obj[0].PLZ}</p>
            ) : (
              ""
            );
          return (
            <>
              {city}
              {zip}
            </>
          );
        },
      },
      {
        dataField: "core__vehicle_maintenance__owning_customer",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={VEHICLE_MAINTENANCE_ENTITY}
            Label={"date"}
          />
        ),
        sort: false,
        headerClasses: "cell cell-service-date-header",
        classes: "cell-service-date",
        formatter: (cellContent, row) => {
          let service_date = "";
          if (cellContent !== null && typeof cellContent !== "undefined") {
            if (cellContent.length > 0) {
              let total = cellContent.length;
              let last = cellContent[total - 1];
              let mant_id =
                typeof last.id !== "undefined" && last.id !== null
                  ? last.id
                  : "";
              service_date =
                typeof last.date !== "undefined"
                  ? moment(last.date).format("DD.MM.YYYY")
                  : "";
              if (service_date !== "" && mant_id !== "") {
                return (
                  <>
                    <Link
                      to={"#"}
                      onClick={() => {
                        this.showHandler(mant_id);
                      }}
                    >
                      {service_date}
                    </Link>
                  </>
                );
              }
            }
          }
          return <>{service_date}</>;
        },
      },
      {
        dataField: "phonenumbers",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={CUSTOMER_ENTITY}
            Label={"phonenumbers"}
          />
        ),
        sort: false,
        headerClasses: "cell cell-phonenumbers-header",
        classes: "cell-phonenumbers",
        formatter: (cellContent, row) => {
          var obj = JSON.parse(cellContent);
          var mobile =
            obj !== null &&
            obj[0] !== null &&
            typeof obj[0] !== "undefined" &&
            obj[0].Name === "Mobil" &&
            typeof obj[0].Nummer !== undefined ? (
              <p>M: {obj[0].Nummer}</p>
            ) : (
              ""
            );
          var phone =
            obj !== null &&
            obj[1] !== null &&
            typeof obj[1] !== "undefined" &&
            obj[1].Name === "Telefonnummer" &&
            typeof obj[1].Nummer !== undefined ? (
              <p>P: {obj[1].Nummer}</p>
            ) : (
              ""
            );
          return (
            <>
              {mobile}
              {phone}
            </>
          );
        },
      },
      {
        dataField: "created",
        text: "",
        sort: false,
        headerClasses: "cell cell-actions cell-customer-actions-header",
        classes: "cell-actions cell-customer-actions",
        formatter: (cellContent, row) => {
          if (row !== null) {
            return (
              <div className="svg">
                <HasAccess Module={CORE_MODULE} Entity={CUSTOMER_NOTE_ENTITY}>
                  <Link
                    to={"#"}
                    onClick={() => {
                      this.listHandler(row);
                    }}
                  >
                    <ListBtn />
                  </Link>
                </HasAccess>
                <Link
                  to={"#"}
                  onClick={() => {
                    this.viewHandler(row);
                  }}
                >
                  <ViewBtn />
                </Link>
                {row.is_client === 1 ? (
                  <HasAccess
                    Module={CORE_MODULE}
                    Entity={CUSTOMER_ENTITY}
                    Label={ADMIN_MODIFY_CLIENT_PERMISSION}
                  >
                    <Link
                      to={"#"}
                      onClick={() => {
                        this.editHandler(row);
                      }}
                    >
                      <EditBtn />
                    </Link>
                  </HasAccess>
                ) : (
                  <HasAccess
                    Module={CORE_MODULE}
                    Entity={CUSTOMER_ENTITY}
                    Label={ADMIN_MODIFY_COMPANY_PERMISSION}
                  >
                    <Link
                      to={"#"}
                      onClick={() => {
                        this.editHandler(row);
                      }}
                    >
                      <EditBtn />
                    </Link>
                  </HasAccess>
                )}
                {row.is_client === 1 ? (
                  <HasAccess
                    Module={CORE_MODULE}
                    Entity={CUSTOMER_ENTITY}
                    Label={ADMIN_MODIFY_CLIENT_PERMISSION}
                  >
                    <Link
                      to={"#"}
                      onClick={() => {
                        this.deleteHandler(row);
                      }}
                    >
                      <DelBtn />
                    </Link>
                  </HasAccess>
                ) : (
                  ""
                  //   <HasAccess
                  //     Module={CORE_MODULE}
                  //     Entity={CUSTOMER_ENTITY}
                  //     Label={ADMIN_MODIFY_COMPANY_PERMISSION}
                  //   >
                  //     <Link
                  //       to={"#"}
                  //       onClick={() => {
                  //         this.deleteHandler(row);
                  //       }}
                  //     >
                  //       <DelBtn />
                  //     </Link>
                  //   </HasAccess>
                  //
                )}
              </div>
            );
          }
        },
      },
    ];
  };
  getSearchParams = (clearSearchParams) => {
    let cond_common = {
      "-or": [
        { is_client: 0 },
        {
          "-and": [
            { "system__assignment__assigned_client.agenda": 9 },
            { "owning_client.locked": 0 },
          ],
        },
      ],
    };
    if (clearSearchParams) {
      this.props.dispatch(resetCustomerParams());
      return cond_common;
    }
    let fields = removeEmptyValues(this.props.searchData.data);

    if (fields && Object.keys(fields).length === 0) {
      this.setState({ showAll: true });
    } else {
      this.setState({ showAll: false });
    }

    let client_name =
      fields !== null && typeof fields.client_name !== "undefined"
        ? fields.client_name
        : "";
    let company_name =
      fields !== null && typeof fields.company_name !== "undefined"
        ? fields.company_name
        : "";
    let phone_number =
      fields !== null && typeof fields.phone_number !== "undefined"
        ? fields.phone_number
        : "";
    let service_date =
      fields !== null && typeof fields.service_date !== "undefined"
        ? fields.service_date
        : "";
    let service_date_from =
      fields !== null && typeof fields.service_period_from !== "undefined"
        ? fields.service_period_from
        : "";
    let service_date_to =
      fields !== null && typeof fields.service_period_to !== "undefined"
        ? fields.service_period_to
        : "";
    let search_cond = {};
    if (fields !== "" && fields !== null) {
      let conds = [];
      if (company_name !== "") {
        let cond_1 = {
          "me.name": { "-ilike": "%" + company_name + "%" },
        };
        conds.push(cond_1);
      }

      if (client_name !== "") {
        let cond_2 = {
          "-or": [
            { first_name: { "-ilike": "%" + client_name + "%" } },
            { last_name: { "-ilike": "%" + client_name + "%" } },
          ],
        };

        conds.push(cond_2);
      }

      if (phone_number !== "") {
        let cond_3 = {
          "-or": [
            {
              "me.phonenumbers": {
                "@>": {
                  mobile: phone_number,
                },
              },
            },
            {
              "me.phonenumbers": {
                "@>": {
                  phone: phone_number,
                },
              },
            },
          ],
        };
        conds.push(cond_3);
      }
      let service_conds = [];
      if (service_date !== "") {
        let cond_4 = {
          "core__vehicle_maintenance__owning_customer.date": service_date,
        };
        service_conds.push(cond_4);
      }

      if (service_date_from !== "" && service_date_to !== "") {
        let cond_5 = {
          "core__vehicle_maintenance__owning_customer.date": {
            "-between": [service_date_from, service_date_to],
          },
        };
        service_conds.push(cond_5);
      }
      if (service_conds.length > 1) {
        conds.push({ "-or": [service_conds] });
      } else if (service_conds.length > 0) {
        conds.push(service_conds);
      }

      if (conds.length > 1) {
        search_cond["-and"] = conds;
      } else if (conds.length > 0) {
        search_cond = conds;
      } else {
        search_cond = {};
      }

      if (conds.length > 0) {
        var all = [];
        all.push(cond_common);
        all.push(search_cond);
        cond_common = {};
        cond_common["-and"] = all;
        // cond_common.push({ "-and": [search_cond] })
      }
    }
    // this.props.dispatch(resetCustomerParams());
    return cond_common;
  };

  componentDidMount() {
    // this.loadCustomers();
  }

  loadCustomers(clearSearchParams) {
    let search_cond = this.getSearchParams(clearSearchParams);
    let defaultOrder = this.state.sort;
    let sortOrderstring = "-" + defaultOrder[0]["order"];
    let data = {};
    data.attrs = {};
    data.attrs["prefetch"] = [
      { owning_client: "system__assignment__assigned_client" },
      "core__vehicle_maintenance__owning_customer",
    ];

    data.attrs["order_by"] = {};
    data.attrs.order_by[sortOrderstring] =
      defaultOrder[0]["dataField"].indexOf(".") === -1
        ? "me." + defaultOrder[0]["dataField"]
        : defaultOrder[0]["dataField"];
    data.conds = search_cond;

    data.page = 1;
    data.page_size = PagingOptions.sizePerPage;
    this.props.dispatch(fetchCustomer(data));
  }

  handleTableChange(
    type,
    { filters, page, sortField, sortOrder, sizePerPage }
  ) {
    // let conds = {
    //   "-or": [
    //     { "is_client": 0 },
    //     {
    //       "-and": [
    //         { "system__assignment__assigned_client.agenda": 9 },
    //         { "owning_client.locked": 0 }
    //       ]
    //     }
    //   ]
    // };
    let conds = this.getSearchParams(false);
    let defaultOrder = this.state.sort;
    let sortOrderstring =
      typeof sortOrder !== "undefined"
        ? "-" + sortOrder
        : "-" + defaultOrder[0]["order"];
    let sort_field =
      defaultOrder[0]["dataField"].indexOf(".") === -1
        ? "me." + defaultOrder[0]["dataField"]
        : defaultOrder[0]["dataField"];
    if (sortField !== null) {
      sort_field =
        sortField.indexOf(".") === -1 ? "me." + sortField : sortField;
    }
    let data = {};
    data.attrs = {};
    data.attrs["prefetch"] = [
      {
        owning_client: "system__assignment__assigned_client",
      },
      "core__vehicle_maintenance__owning_customer",
    ];
    data.attrs["order_by"] = {};
    data.attrs.order_by[sortOrderstring] = sort_field;
    data.conds = conds;
    data.page = page;
    data.page_size = sizePerPage;
    this.props.dispatch(fetchCustomer(data));
  }

  viewHandler(row) {
    let url = "/admin/customers/" + row.id;
    this.props.history.push(url);
  }

  editHandler(row) {
    let url = "/admin/customers/" + row.id + "/edit";
    this.props.history.push(url);
  }

  showHandler(id) {
    let url = "/admin/maintenance/" + id;
    this.props.history.push(url);
  }

  listHandler(row) {
    let url = "/admin/customers/" + row.id + "/notes";
    this.props.history.push(url);
  }
  addHandler() {
    this.props.history.push("/admin/customers/add");
  }

  deleteHandler(row) {
    let rowId = row.owning_client.id;
    let customer_data =
      typeof this.props.customerData.rows.data[0] !== "undefined"
        ? this.props.customerData.rows.data[0]
        : null;
    // let successor = (customer_data !== null)?customer_data.successor:null;
    let rowData = { post: { locked: true } };
    rowData["url"] = "/data/system/client/" + rowId + "/transitions/edit";
    rowData["redirect_to"] = "/admin/customers";
    if (customer_data !== null && typeof rowId !== "undefined") {
      this.props.dispatch(showDeleteDialog(rowData));
    }
  }
  showVehicleHandler(row, vehicleId) {
    let url = "/admin/customers/" + row.id + "/vehicles/view" + vehicleId;
    this.props.history.push(url);
  }

  render() {
    let options = PagingOptions;
    options.totalSize =
      typeof this.props.customerData !== "undefined" &&
      typeof this.props.customerData.rows !== "undefined" &&
      typeof this.props.customerData.rows.meta.filtered !== "undefined"
        ? this.props.customerData.rows.meta.filtered
        : "";
    options.sizePerPage =
      typeof this.props.customerData !== "undefined" &&
      typeof this.props.customerData.rows !== "undefined" &&
      typeof this.props.customerData.rows.meta.page_size !== "undefined"
        ? this.props.customerData.rows.meta.page_size
        : 10;
    let data =
      typeof this.props.customerData !== "undefined" &&
      typeof this.props.customerData.rows !== "undefined" &&
      typeof this.props.customerData.rows.data !== "undefined"
        ? this.props.customerData.rows.data
        : [];
    let columns = this.renderColumns();

    return (
      <div className="wrapper_datatable">
        <div className="table_main">
          <h4>
            <ColumnText Module={CORE_MODULE} Entity={CUSTOMER_ENTITY} />
          </h4>
          {!this.state.showAll && (
            <button
              type="button"
              className="btn btn-dark pull-right"
              onClick={() => {
                this.loadCustomers(true);
                this.setState({ showAll: true });
              }}
            >
              Show All
            </button>
          )}
          <HasAccess
            Module={CORE_MODULE}
            Entity={CUSTOMER_ENTITY}
            Label={CREATE_PERMISSION}
          >
            <button
              type="button"
              className="btn btn-dark"
              onClick={() => {
                this.addHandler();
              }}
            >
              {" "}
              <ColumnText Module={CORE_MODULE} Entity={CUSTOMER_ENTITY} />{" "}
              hinzufügen
            </button>
          </HasAccess>
        </div>
        <BootstrapTable
          remote={{ pagination: true }}
          bootstrap4
          keyField="id"
          data={data}
          columns={columns}
          bordered={false}
          rowClasses={"table-row"}
          defaultSorted={this.state.sort}
          pagination={paginationFactory(options)}
          onTableChange={this.handleTableChange}
          noDataIndication={() =>
            this.props.customerData.loading ? <NoDataIndication /> : NO_DATA
          }
        />
      </div>
    );
  }
}

CustomerScreen.propTypes = {};
CustomerScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  customerData: state.Customer.customerData,
  searchData: state.Search.customerData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(mapStateToProps, mapDispatchToProps)(CustomerScreen);
