import { Config } from "../config";
export const FILE_NAME_SEPARATOR = ';';
export const FILE_TOO_LARGE_MESSAGE =
  "Fehler: Datei zu groß! " + Config.FILE_MAX_SIZE_KB + " KB";
export const FILE_TYPE_MESSAGE = 'Fehler: Bilder hochladen (JPG, JPEG, PNG, GIF) oder PDF!';

export const NO_DATA = "Keine Daten";

export const CORE_MODULE = 'core';
export const SYSTEM_MODULE = 'system';


export const VEHICLE_ENTITY = 'vehicle';
export const CUSTOMER_ENTITY = 'customer';
export const CUSTOMER_NOTE_ENTITY = 'customer_note';
export const VEHICLE_COMPONENT_ENTITY = 'vehicle_component';
export const VEHICLE_MAINTENANCE_NOTE_ENTITY = 'vehicle_maintenance_note';
export const VEHICLE_CUSTOMER_NOTE_ENTITY = 'vehicle_customer_note';
export const VEHICLE_NOTE_ENTITY = 'vehicle_note';
export const VEHICLE_MAINTENANCE_COMPONENT_ENTITY = 'vehicle_maintenance_component';
export const VEHICLE_MAINTENANCE_COMPONENT_NOTE_ENTITY = 'vehicle_maintenance_component_note';
export const VEHICLE_MAINTENANCE_ENTITY = 'vehicle_maintenance';

export const VEHICLE_DOCUMENT_TYPE_ENTITY = 'vehicle_document_type';
export const VEHICLE_FILE_ENTITY = 'vehicle_file';
export const CLIENT_ENTITY = 'client';



export const DELETE_PERMISSION = "delete";
export const EDIT_PERMISSION = "edit";
export const MODIFY_PERMISSION = "modify";
export const ADMIN_MODIFY_CLIENT_PERMISSION = "admin_modify_client";
export const ADMIN_MODIFY_COMPANY_PERMISSION = "admin_modify_company";
export const INSERT_PERMISSION = "insert";
export const CREATE_PERMISSION = "create";
export const ADD_PERMISSION = "add";
export const REMOVE_PERMISSION = "remove";
export const VIEW_PERMISSION = "read";
export const VIEW_SCREEN_PERMISSION = "screen";





