import { call, all, put, takeLatest } from "redux-saga/effects";
import { authHeader } from "../../../utils";
import request from "../../../services/request";
import * as ActionTypes from "../../actions/constants";
import {
  fetchVehicleSuccess,
  fetchVehicleError,
  updateVehicleSuccess,
  updateVehicleError,
  updateVehicleOwnershipSuccess,
  updateVehicleOwnershipError,
  createVehicleSuccess,
  createVehicleError
} from "../../actions/vehicle";

function* vehicleSaga({ data }) {
  try {
    const requestURL = "data/core/vehicle/query";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data,
    });
    yield put(fetchVehicleSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(fetchVehicleError(error));
    }
  }
}
function* updateSaga({ data }) {
  try {
    let vehicle_id =
      typeof data.vehicle_id !== "undefined" && data.vehicle_id !== null
        ? data.vehicle_id
        : null;
    let vehicleData =
      typeof data.vehicle !== "undefined" && data.vehicle !== null
        ? data.vehicle
        : null;
    const requestURL =
      "/data/core/vehicle/" + vehicle_id + "/transitions/modify";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: vehicleData,
    });
    yield put(updateVehicleSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(updateVehicleError(error));
    }
  }
}

function* updateOwnershipSaga({ data }) {
  try {
    let vehicle_id =
      typeof data.vehicle_id !== "undefined" && data.vehicle_id !== null
        ? data.vehicle_id
        : null;
    let customer_id =
      typeof data.customer_id !== "undefined" && data.customer_id !== null
        ? data.customer_id
        : null;
    const requestURL =
      "/plugins/vehicle_reown";
    if (vehicle_id && customer_id) {
      const response = yield call(request, {
        url: requestURL,
        method: "POST",
        headers: {
          Authorization: authHeader(),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: data,
      });
      yield put(updateVehicleOwnershipSuccess(response));
    }
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(updateVehicleOwnershipError(error));
    }
  }
}

function* createSaga({ data }) {
  try {
    let vehicleData =
      typeof data.vehicle !== "undefined" && data.vehicle !== null
        ? data.vehicle
        : null;
    const requestURL = "/data/core/vehicle/transitions/insert";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: vehicleData,
    });
    yield put(createVehicleSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(createVehicleError(error));
    }
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(ActionTypes.GET_VEHICLE, vehicleSaga),
    yield takeLatest(ActionTypes.UPDATE_VEHICLE, updateSaga),
    yield takeLatest(ActionTypes.UPDATE_VEHICLE_OWNERSHIP, updateOwnershipSaga),
    yield takeLatest(ActionTypes.CREATE_VEHICLE, createSaga),
  ]);
}
