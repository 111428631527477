import * as Types from "../../actions/constants/types";

const initialState = {
  deleteData: {
    loading: false,
    error: false,
    success: false,
    rows: null
  }
 
  
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.DELETE_ROW:
      return {
        ...state,
        deleteData: {
          loading: true,
          error: false,
          success:false,
          rows: null,
        },
      };
    case Types.DELETE_SUCCESS:
      return {
        ...state,
        deleteData: {
          loading: false,
          error: false,
          success:true,
          rows: action.response,
        },
      };
    case Types.DELETE_ERROR:
      return {
        ...state,
        deleteData: {
          loading: false,
          error: true,
          success:false,
          rows: action.response,
        },
      };
    case Types.DELETE_RESET:
        return {
          ...state,
          deleteData: {
            loading: false,
            error: false,
            success:false,
            data:null
          },
        };

    case Types.DELETE_DATA:
      return {
        ...state,
            deleteData: {
                loading: false,
                error: false,
                success:false,
                rows: null,
            },
      };
   
   
    default:
      return state;
  }
};
