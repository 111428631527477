export const GET_MAINTENANCE_COMPONENT = 'constants/vehicle/GET_MAINTENANCE_COMPONENT';
export const MAINTENANCE_COMPONENT_SUCCESS = 'constants/vehicle/MAINTENANCE_COMPONENT_SUCCESS';
export const MAINTENANCE_COMPONENT_ERROR = 'constants/vehicle/MAINTENANCE_COMPONENT_ERROR';

export const GET_MAINTENANCE_COMPONENT_BY_ID = 'constants/vehicle/GET_MAINTENANCE_COMPONENT_BY_ID';
export const MAINTENANCE_COMPONENT_BY_ID_SUCCESS = 'constants/vehicle/MAINTENANCE_COMPONENT_BY_ID_SUCCESS';
export const MAINTENANCE_COMPONENT_BY_ID_ERROR = 'constants/vehicle/MAINTENANCE_COMPONENT_BY_ID_ERROR';

export const CREATE_MAINTENANCE_COMPONENT = 'constants/vehicle/CREATE_MAINTENANCE_COMPONENT';
export const CREATE_MAINTENANCE_COMPONENT_SUCCESS = 'constants/vehicle/CREATE_MAINTENANCE_COMPONENT_SUCCESS';
export const CREATE_MAINTENANCE_COMPONENT_ERROR = 'constants/vehicle/CREATE_MAINTENANCE_COMPONENT_ERROR';
export const RESET_CREATE_MAINTENANCE_COMPONENT = 'constants/vehicle/RESET_CREATE_MAINTENANCE_COMPONENT';

export const UPDATE_MAINTENANCE_COMPONENT = 'constants/vehicle/UPDATE_MAINTENANCE_COMPONENT';
export const UPDATE_MAINTENANCE_COMPONENT_SUCCESS = 'constants/vehicle/UPDATE_MAINTENANCE_COMPONENT_SUCCESS';
export const UPDATE_MAINTENANCE_COMPONENT_ERROR = 'constants/vehicle/UPDATE_MAINTENANCE_COMPONENT_ERROR';
export const RESET_UPDATE_MAINTENANCE_COMPONENT = 'constants/vehicle/RESET_UPDATE_MAINTENANCE_COMPONENT';