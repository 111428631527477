import { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import { fetchCustomer } from "../../store/actions/customer";
import {
  fetchVehicle,
  updateVehicle,
  resetUpdateVehicle,
} from "../../store/actions/vehicle";
import {
  inputErrorHandler,
  signalTransitionSuccess,
  signalTransitionError,
  removeEmptyValues,
  CORE_MODULE,
  VEHICLE_ENTITY,
  CUSTOMER_ENTITY,
  INSERT_PERMISSION,
} from "../../utils";
import { DateTimePicker } from "react-tempusdominus-bootstrap";
import moment from "moment";
import { HasAccess } from "../../components/Access";
import ColumnText from "../../components/Column";
import AutoRecommend from "../../components/AutoSuggest";

import "font-awesome/css/font-awesome.css";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

class VehicleEditScreen extends Component {
  customerId = null;
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        vehicle_id: "",
        customer_name: "",
        chasis_number: "",
        first_inspection: "",
        licence_plate: "",
        brand: "",
        model: "",
        country: "",
        type: "",
        customer_type: "1",
      },
      reset: {
        vehicle_id: "",
        customer_name: "",
        chasis_number: "",
        first_inspection: "",
        licence_plate: "",
        brand: "",
        model: "",
        country: "",
        type: "",
        customer_type: "1",
      },
      errors: {
        customer_name: "",
        chasis_number: "",
        first_inspection: "",
        licence_plate: "",
        brand: "",
        model: "",
        country: "",
        type: "",
      },
      value: "",
      suggestions: [],
    };
    this.datePickerHandler = this.datePickerHandler.bind(this);
  }

  componentDidMount() {
    let data = {};
    data.conds = { "system__assignment__assigned_client.agenda": 9 };
    data.attrs = {};
    data.attrs["prefetch"] = {
      owning_client: "system__assignment__assigned_client",
    };
    this.props.dispatch(fetchCustomer(data));

    let vdata = {};
    vdata.attrs = {};
    vdata.attrs["prefetch"] = [
      "core__vehicle_maintenance__vehicle",
      { owning_customer: "owning_client" },
    ];
    vdata.conds = { "me.id": this.props.match.params.id };
    this.props.dispatch(fetchVehicle(vdata));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.vehicleData) !==
      JSON.stringify(this.props.vehicleData)
    ) {
      let vehicle = {};
      let vehicle_data =
        typeof this.props.vehicleData.rows !== "undefined" &&
        typeof this.props.vehicleData.rows.data[0] !== "undefined"
          ? this.props.vehicleData.rows.data[0]
          : null;
      let client_data =
        vehicle_data !== null &&
        typeof vehicle_data.owning_customer !== "undefined" &&
        vehicle_data.owning_customer !== null
          ? vehicle_data.owning_customer
          : null;
      let customer_data =
        client_data !== null &&
        typeof client_data.owning_client !== "undefined" &&
        client_data.owning_client !== null
          ? client_data.owning_client
          : null;
      let vehicle_id =
        vehicle_data !== null &&
        typeof vehicle_data.id !== "undefined" &&
        vehicle_data.id !== null
          ? vehicle_data.id
          : "";
      let customer_name =
        client_data !== null &&
        typeof client_data.id !== "undefined" &&
        vehicle_data.id !== null
          ? client_data.id
          : "";
      let chasis_number =
        vehicle_data !== null &&
        typeof vehicle_data.vin !== "undefined" &&
        vehicle_data.vin !== null
          ? vehicle_data.vin
          : "";

      let first_inspection =
        vehicle_data !== null &&
        typeof vehicle_data.first_seen !== "undefined" &&
        vehicle_data.first_seen !== null
          ? vehicle_data.first_seen
          : "";
      let licence_plate =
        vehicle_data !== null &&
        typeof vehicle_data.licence_plate !== "undefined" &&
        vehicle_data.licence_plate !== null
          ? vehicle_data.licence_plate
          : "";
      let brand =
        vehicle_data !== null &&
        typeof vehicle_data.brand !== "undefined" &&
        vehicle_data.brand !== null
          ? vehicle_data.brand
          : "";
      let model =
        vehicle_data !== null &&
        typeof vehicle_data.model !== "undefined" &&
        vehicle_data.model !== null
          ? vehicle_data.model
          : "";
      let country =
        vehicle_data !== null &&
        typeof vehicle_data.country !== "undefined" &&
        vehicle_data.country !== null
          ? vehicle_data.country
          : "";
      let type =
        vehicle_data !== null &&
        typeof vehicle_data.type !== "undefined" &&
        vehicle_data.type !== null
          ? vehicle_data.type
          : "";
      let customer_type =
        client_data !== null &&
        typeof client_data.is_client !== "undefined" &&
        vehicle_data.is_client !== null
          ? client_data.is_client
          : 0;

      vehicle["vehicle_id"] = vehicle_id;
      vehicle["customer_name"] = customer_name;
      vehicle["chasis_number"] = chasis_number;

      vehicle["first_inspection"] = moment(
        first_inspection.replace("+00", "")
      ).format("YYYY-MM-DD");
      vehicle["licence_plate"] = licence_plate;
      vehicle["brand"] = brand;
      vehicle["model"] = model;
      vehicle["country"] = country;
      vehicle["type"] = type;
      vehicle["customer_type"] = customer_type;
      if (customer_type === 1) {
        var first_name = "";
        var last_name = "";
        if (
          customer_data !== null &&
          typeof customer_data.first_name !== "undefined"
        ) {
          first_name = customer_data.first_name;
        }
        if (
          customer_data !== null &&
          typeof customer_data.last_name !== "undefined"
        ) {
          last_name = customer_data.last_name;
        }
        vehicle["customer_id"] = first_name + " " + last_name;
      } else {
        if (client_data !== null && typeof client_data.name !== "undefined") {
          vehicle["customer_id"] = client_data.name;
        }
      }

      this.setState({ value: vehicle["customer_id"] });
      this.setState({ fields: vehicle });
      this.setState({ reset: vehicle });
      //console.log(vehicle);
    }
    if (
      JSON.stringify(prevProps.customerData) !==
      JSON.stringify(this.props.customerData)
    ) {
      let customers =
        typeof this.props.customerData !== "undefined" &&
        typeof this.props.customerData.rows !== "undefined" &&
        typeof this.props.customerData.rows.data !== "undefined"
          ? this.props.customerData.rows.data
          : [];
      let data = [];
      let suggestions = this.state.suggestions;
      for (let i = 0; i < suggestions.length; i++) {
        let obj = {};
        obj.id = suggestions.id;
        obj.name = suggestions.name;
        data.push(obj);
      }
      for (let i = 0; i < customers.length; i++) {
        let obj = {};
        obj.id = customers[i].id;
        obj.name = customers[i].identifier;
        data.push(obj);
      }
      this.setState({ suggestions: data });
    }

    if (this.props.updateData.success) {
      this.props.dispatch(resetUpdateVehicle());
      signalTransitionSuccess("Update Success");
      let url = "/admin/vehicles";
      if (typeof this.customerId !== "undefined" && this.customerId !== null) {
        url = "/admin/customers/" + this.customerId + "/vehicles";
      }
      this.props.history.push(url);
    } else if (this.props.updateData.error) {
      this.props.dispatch(resetUpdateVehicle());
      signalTransitionError("Update Failed");
    }
  }

  removeEmptyValues(obj) {
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName].length === 0
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }

  resetHandler(evt) {
    this.setState({ fields: this.state.reset });
  }

  submitHandler(evt) {
    evt.preventDefault();
    // get our new errors
    let fields = this.state.fields;
    let newErrors = {};
    newErrors["customer_name"] = inputErrorHandler(
      "",
      fields.customer_name,
      true
    );
    newErrors["chasis_number"] = inputErrorHandler(
      "",
      fields.chasis_number,
      true
    );

    newErrors["first_inspection"] = inputErrorHandler(
      "",
      fields.first_inspection,
      true
    );
    newErrors["licence_plate"] = inputErrorHandler(
      "",
      fields.licence_plate,
      true
    );
    newErrors["brand"] = inputErrorHandler("", fields.brand, true);
    newErrors["model"] = inputErrorHandler("", fields.model, true);
    newErrors["country"] = inputErrorHandler("", fields.country, true);
    newErrors["type"] = inputErrorHandler("", fields.type, true);
    newErrors["country"] = inputErrorHandler("", fields.country, true);

    if (fields.customer_name === "" || fields.customer_id === "") {
      newErrors["customer_name"] = "Required Parameter";
    } else if (fields.customer_id !== this.state.value) {
      newErrors["customer_name"] = "Please select valid Customer name";
    }

    let errors = removeEmptyValues(newErrors);
    // Conditional logic:
    if (Object.keys(errors).length > 0) {
      this.setState({ errors: errors });
    } else {
      let vehicleData = { vehicle_id: fields.vehicle_id, vehicle: {} };

      vehicleData.vehicle.owning_customer = fields.customer_name;
      vehicleData.vehicle.first_seen = fields.first_inspection;
      vehicleData.vehicle.licence_plate = fields.licence_plate;
      vehicleData.vehicle.brand = fields.brand;
      vehicleData.vehicle.type = fields.type;
      vehicleData.vehicle.model = fields.model;
      vehicleData.vehicle.country = fields.country;
      this.customerId = fields.customer_name;
      this.props.dispatch(updateVehicle(vehicleData));
      //console.log(ClientData);
    }
  }

  changeHandler(evt, name) {
    let value = evt.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: null,
      },
    }));
  }

  datePickerHandler(value) {
    let items = this.state.fields;
    items["first_inspection"] = value;
    this.setState({ fields: items });

    let errors = this.state.errors;
    errors["first_inspection"] = "";
    this.setState({ errors: errors });
  }

  onValueChange = (newValue) => {
    this.setState({
      value: newValue,
    });
    let errors = this.state.errors;
    errors["customer_name"] = "";
    this.setState({ errors: errors });
  };

  onSuggestionsFetchRequested = (value) => {
    this.loadSuggestions(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  loadSuggestions(value) {
    if (value.trim() !== "") {
      let cdata = {};
      cdata.attrs = {};
      cdata.attrs["prefetch"] = {
        owning_client: "system__assignment__assigned_client",
      };
      cdata.conds = {
        "system__assignment__assigned_client.agenda": 9,
        "-or": [
          { first_name: { "-like": "%" + value + "%" } },
          { last_name: { "-like": "%" + value + "%" } },
        ],
      };
      this.props.dispatch(fetchCustomer(cdata));
    }
  }

  loadMoreReferences = () => {
    let meta_data =
      typeof this.props.customerData !== "undefined" &&
      typeof this.props.customerData.rows !== "undefined" &&
      typeof this.props.customerData.rows.meta !== "undefined"
        ? this.props.customerData.rows.meta
        : [];

    let cdata = {};
    cdata.attrs = meta_data.attrs;
    cdata.conds = meta_data.conds;
    cdata.page = meta_data.page + 1;
    cdata.page_size = meta_data.page_size;
    this.props.dispatch(fetchCustomer(cdata));
  };

  onSelect = (suggestionValue) => {
    const reference = this.state.suggestions.find(
      (r) => r["name"] === suggestionValue //.replace('/Maintenance - /i',''),
    );
    let fields = this.state.fields;
    fields["customer_name"] = reference.id;
    fields["customer_id"] = reference.name;
    this.setState({ value: reference.name });
    this.setState({ fields: fields });
  };

  editVehicleOwnerHandler = () => {
    let url = "/admin/vehicles/" + this.props.match.params.id + "/edit-owner";
    this.props.history.push(url);
  };

  render() {
    // let customer_type = 2;

    // let core_module = "core";
    // let vehicle_entity = "vehicle";
    // let maintenance_entity = "vehicle_maintenance";
    // let customer_entity = "customer";
    // let system_module = "system";
    // let client_entity = "client";

    /*let client_locale_data = ( (typeof(this.props.localeData.rows) !== 'undefined') && (typeof(this.props.localeData.rows.model) !== 'undefined') && (typeof(this.props.localeData.rows.model[system_module]) !== 'undefined') && typeof(this.props.localeData.rows.model[system_module]['entities'][client_entity]) !== 'undefined' )? this.props.localeData.rows.model[core_module]['entities'][customer_entity]:null;
    let cust_attrs  = ((customer_locale_data !== null)&&(customer_locale_data['attributes']))?(customer_locale_data['attributes']):null;
    let label_customer_name = ((cust_attrs !== null) && (typeof(cust_attrs.name) !== 'undefined') &&  (typeof(cust_attrs.name.label) !== 'undefined'))?cust_attrs.name.label:'';



    let customer_locale_data = ( (typeof(this.props.localeData.rows) !== 'undefined') && (typeof(this.props.localeData.rows.model) !== 'undefined') && (typeof(this.props.localeData.rows.model[core_module]) !== 'undefined') && typeof(this.props.localeData.rows.model[core_module]['entities'][customer_entity]) !== 'undefined' )? this.props.localeData.rows.model[core_module]['entities'][customer_entity]:null;
    let cust_attrs  = ((customer_locale_data !== null)&&(customer_locale_data['attributes']))?(customer_locale_data['attributes']):null;
    let label_customer_name = ((cust_attrs !== null) && (typeof(cust_attrs.name) !== 'undefined') &&  (typeof(cust_attrs.name.label) !== 'undefined'))?cust_attrs.name.label:'';
*/

    let customer_data =
      typeof this.props.customerData.rows !== "undefined" &&
      typeof this.props.customerData.rows.data[0] !== "undefined"
        ? this.props.customerData.rows.data[0]
        : null;
    let username =
      customer_data !== null && typeof customer_data.login_name !== "undefined"
        ? customer_data.login_name
        : "";

    let customers =
      typeof this.props.customerData !== "undefined" &&
      typeof this.props.customerData.rows !== "undefined" &&
      typeof this.props.customerData.rows.data !== "undefined"
        ? this.props.customerData.rows.data
        : [];
    let data = [];
    for (let i = 0; i < customers.length; i++) {
      let owning_client = customers[i].owning_client;
      let first_name = owning_client !== null ? owning_client.first_name : "";
      let last_name = owning_client !== null ? owning_client.last_name : "";
      let name = first_name + " " + last_name;
      if (name.trim() !== "") {
        data.push({
          name: name,
          id: customers[i].id,
        });
      }
    }
    const option_html = data.map((item, index) => (
      <option value={item.id}>{item.name}</option>
    ));
    return (
      <div className="wrapper">
        <div className="container-header">
          <h4>
            {" "}
            <ColumnText Module={CORE_MODULE} Entity={VEHICLE_ENTITY} />{" "}
            bearbeiten
          </h4>
          <div className="btngroup">
            <HasAccess
              Module={CORE_MODULE}
              Entity={VEHICLE_ENTITY}
              Label={INSERT_PERMISSION}
            >
              <Button
                type="button"
                className="btn btn-dark"
                onClick={() => this.editVehicleOwnerHandler()}
              >
                Bearbeiten <ColumnText Module={CORE_MODULE} Entity={VEHICLE_ENTITY} />{" "}
                Owner
              </Button>
            </HasAccess>
          </div>
        </div>
        <div className="sub_main">
          <Form>
            <Row>
              <Col lg={6}>
                <Form.Group as={Row}>
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={CUSTOMER_ENTITY}
                      Label={"is_client"}
                    />
                  </Form.Label>
                  <Col
                    sm="8"
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Form.Check
                      custom
                      inline
                      label="Kunde"
                      type={"radio"}
                      id={`custom-inline-radio-1`}
                      checked={
                        this.state.fields.customer_type === 1 ? true : false
                      }
                      disabled={
                        this.state.fields.customer_type !== 1 ? true : false
                      }
                    />
                    <Form.Check
                      custom
                      inline
                      readOnly
                      label="Firma"
                      type={"radio"}
                      id={`custom-inline-radio-2`}
                      checked={
                        this.state.fields.customer_type === 0 ? true : false
                      }
                      disabled={
                        this.state.fields.customer_type !== 0 ? true : false
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group as={Row} controlId="customer_name">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_ENTITY}
                      Label={"owning_customer"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <AutoRecommend
                      value={this.state.value}
                      onValueChange={this.onValueChange}
                      suggestions={this.state.suggestions}
                      onSuggestionsClearRequested={
                        this.onSuggestionsClearRequested
                      }
                      fetchSuggestions={this.onSuggestionsFetchRequested}
                      onSelect={this.onSelect}
                      placeholder="Kunde wählen"
                      onScrollEnd={this.loadMoreReferences}
                      disabled
                      inputClasses={
                        typeof this.state.errors.customer_name !==
                          "undefined" && this.state.errors.customer_name !== ""
                          ? "is-invalid form-control"
                          : "form-control"
                      }
                    />
                    {typeof this.state.errors.customer_name !== "undefined" &&
                    this.state.errors.customer_name !== "" ? (
                      <div className="has-error">
                        {this.state.errors.customer_name}
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="chasis_number">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_ENTITY}
                      Label={"vin"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Fahrgestellnummer"
                      name="chasis_number"
                      value={this.state.fields.chasis_number}
                      onChange={(evt) =>
                        this.changeHandler(evt, "chasis_number")
                      }
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.chasis_number}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.chasis_number}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="first_inspection">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_ENTITY}
                      Label={"first_seen"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <DateTimePicker
                      date={this.state.fields.first_inspection}
                      format="YYYY-MM-DD"
                      onChange={(e) => this.datePickerHandler(e.date)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.first_inspection}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="licence_plate">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_ENTITY}
                      Label={"licence_plate"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Kennzeichen"
                      name="licence_plate"
                      value={this.state.fields.licence_plate}
                      onChange={(evt) =>
                        this.changeHandler(evt, "licence_plate")
                      }
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.licence_plate}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.email}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="brand">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_ENTITY}
                      Label={"brand"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Marke"
                      name="brand"
                      value={this.state.fields.brand}
                      onChange={(evt) => this.changeHandler(evt, "brand")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.brand}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.brand}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="model">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_ENTITY}
                      Label={"model"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Baujahr"
                      name="model"
                      value={this.state.fields.model}
                      onChange={(evt) => this.changeHandler(evt, "model")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.model}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.model}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group as={Row} controlId="type">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_ENTITY}
                      Label={"type"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Modell"
                      name="type"
                      value={this.state.fields.type}
                      onChange={(evt) => this.changeHandler(evt, "type")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.type}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.type}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="country">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_ENTITY}
                      Label={"country"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Land"
                      name="country"
                      value={this.state.fields.country}
                      onChange={(evt) => this.changeHandler(evt, "country")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.country}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.country}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <div className="form-btn">
                  <Button
                    type="Button"
                    className="btn btn-dark Button"
                    onClick={(evt) => this.submitHandler(evt)}
                  >
                    OK
                  </Button>
                  <Button
                    type="Button"
                    className="btn btn-dark"
                    onClick={(evt) => this.resetHandler(evt)}
                  >
                    Abbrechen
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

VehicleEditScreen.propTypes = {};
VehicleEditScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  customerData: state.Customer.customerData,
  vehicleData: state.Vehicle.vehicleData,
  localeData: state.Locale.localeData,
  updateData: state.Vehicle.updateData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(mapStateToProps, mapDispatchToProps)(VehicleEditScreen);
