import * as Types from "../../actions/constants";

const initialState = {
  dialogData: {
    deleteDialog: false,
    logoutDialog: false,
    modalDialog: false,
    alertDialog: false,
    content: null,
    data: null,
    modalName: null,
  },
};

const dialog = (state = initialState, action) => {
  switch (action.type) {
    case Types.DELETE_DIALOG_SHOW:
      return {
        ...state,
        dialogData: {
          deleteDialog: true,
          logoutDialog: false,
          alertDialog: false,
          modalDialog: false,
          content: null,
          modalName: null,
          data: action.data,
        },
      };
    case Types.DELETE_DIALOG_HIDE:
      return {
        ...state,
        dialogData: {
          deleteDialog: false,
          logoutDialog: false,
          alertDialog: false,
          modalDialog: false,
          content: null,
          modalName: null,
          data: null,
        },
      };

    case Types.LOGOUT_DIALOG_SHOW:
      return {
        ...state,
        dialogData: {
          deleteDialog: false,
          logoutDialog: true,
          alertDialog: false,
          modalDialog: false,
          content: null,
          modalName: null,
          data: null,
        },
      };
    case Types.LOGOUT_DIALOG_HIDE:
      return {
        ...state,
        dialogData: {
          deleteDialog: false,
          logoutDialog: false,
          alertDialog: false,
          modalDialog: false,
          content: null,
          modalName: null,
          data: null,
        },
      };
    case Types.ALERT_DIALOG_SHOW:
      return {
        ...state,
        dialogData: {
          deleteDialog: false,
          logoutDialog: false,
          alertDialog: true,
          modalDialog: false,
          content: action.msg,
          modalName: null,
          data: null,
        },
      };
    case Types.ALERT_DIALOG_HIDE:
      return {
        ...state,
        dialogData: {
          deleteDialog: false,
          logoutDialog: false,
          alertDialog: false,
          modalDialog: false,
          content: null,
          modalName: null,
          data: null,
        },
      };
    case Types.RESET_DIALOG:
      return {
        ...state,
        dialogData: {
          deleteDialog: false,
          logoutDialog: false,
          alertDialog: false,
          modalDialog: false,
          content: null,
          modalName: null,
          data: null,
        },
      };

    case Types.MODAL_DIALOG_SHOW:
      return {
        ...state,
        dialogData: {
          deleteDialog: false,
          logoutDialog: false,
          alertDialog: false,
          modalDialog: true,
          content: null,
          modalName: action.data,
          data: null,
        },
      };
    case Types.MODAL_DIALOG_HIDE:
      return {
        ...state,
        dialogData: {
          deleteDialog: false,
          logoutDialog: false,
          alertDialog: false,
          modalDialog: false,
          content: null,
          modalName: null,
          data: null,
        },
      };

    default:
      return state;
  }
};

export default dialog;
