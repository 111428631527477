import { Component } from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  PagingOptions,
  NoDataIndication,
  DelBtn,
  EditBtn,
  SYSTEM_MODULE,
  CLIENT_ENTITY,
  CREATE_PERMISSION,
  EDIT_PERMISSION,
  NO_DATA,
} from "../../utils";

import { fetchClient } from "../../store/actions/client";

import { HasAccess } from "../../components/Access";
import ColumnText from "../../components/Column";
import _ from "lodash";
import { Link } from "react-router-dom";
import { showDeleteDialog } from "../../store/actions/dialog";

class ClientScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userColumns: [
        {
          dataField: "id",
          text: "ID",
          sort: true,
          headerClasses: "Cell Cell-header",
          hidden: true,
        },
        {
          dataField: "email",
          text: (
            <ColumnText
              Module={SYSTEM_MODULE}
              Entity={CLIENT_ENTITY}
              Label={"email"}
            />
          ),
          sort: true,
          headerClasses: "Cell Cell-header",
        },
        {
          dataField: "first_name",
          text: (
            <ColumnText
              Module={SYSTEM_MODULE}
              Entity={CLIENT_ENTITY}
              Label={"first_name"}
            />
          ),
          sort: true,
          headerClasses: "Cell Cell-header",
        },
        {
          dataField: "last_name",
          text: (
            <ColumnText
              Module={SYSTEM_MODULE}
              Entity={CLIENT_ENTITY}
              Label={"last_name"}
            />
          ),
          sort: true,
          headerClasses: "Cell Cell-header",
        },
        {
          dataField: "instance_entity.id",
          text: "",
          sort: false,
          headerClasses: "Cell Cell-header",
          formatter: (cellContent, row) => {
            return (
              <div className="svg">
                <HasAccess
                  Module={SYSTEM_MODULE}
                  Entity={CLIENT_ENTITY}
                  Label={EDIT_PERMISSION}
                >
                  <Link
                    to={"#"}
                    onClick={() => {
                      this.editUserHandler(row);
                    }}
                  >
                    <EditBtn />
                  </Link>
                </HasAccess>
                <HasAccess
                  Module={SYSTEM_MODULE}
                  Entity={CLIENT_ENTITY}
                  Label={EDIT_PERMISSION}
                >
                  <Link
                    to={"#"}
                    onClick={() => {
                      this.deleteUserHandler(row);
                    }}
                  >
                    <DelBtn />
                  </Link>
                </HasAccess>
              </div>
            );
          },
        },
      ],

      userSort: [
        {
          dataField: "id",
          order: "asc",
        },
      ],
      id: "",
    };

    this.userHandler = this.userHandler.bind(this);

    this.addUserHandler = this.addUserHandler.bind(this);
    this.editUserHandler = this.editUserHandler.bind(this);
    this.deleteUserHandler = this.deleteUserHandler.bind(this);
  }

  componentDidMount() {
    //   /** company Users */
    //   let user_defaultOrder = this.state.userSort;
    //   let user_SortString = "-" + user_defaultOrder[0]["order"];
    //   let user_data = {};
    //   user_data.attrs = {};
    //  // user_data.attrs["prefetch"] = ["assigned_client",{"binding_instance_of_customer": "instance_entity"}];
    //   user_data.attrs["order_by"] = {};
    //   user_data.attrs.order_by[user_SortString] = "me."+user_defaultOrder[0]["dataField"];
    //   //user_data.conds = {"binding_instance_of_customer.identifier": {"-ilike": "%test%"}};
    //   user_data.page = 1;
    //   user_data.page_size = PagingOptions.sizePerPage;
    //   this.props.dispatch(fetchClient(user_data));
  }

  userHandler = (
    type,
    { filters, page, sortField, sortOrder, sizePerPage }
  ) => {
    let defaultOrder = this.state.compSort;
    let sortOrderstring =
      typeof sortOrder !== "undefined"
        ? "-" + sortOrder
        : "-" + defaultOrder[0]["order"];
    let data = {};
    data.attrs = {};
    data.attrs["join"] = "system__assignment__assigned_client";
    data.attrs["order_by"] = {};
    data.attrs.order_by[sortOrderstring] =
      sortField !== null
        ? "me." + sortField
        : "me." + defaultOrder[0]["dataField"];
    data.conds = {
      "system__assignment__assigned_client.agenda": {
        "-in": [10, 11],
      },
      "-or": [
        {
          locked: null,
        },
        {
          locked: 0,
        },
      ],
    };
    data.page = page;
    data.page_size = sizePerPage;
    this.props.dispatch(fetchClient(data));
  };

  addUserHandler = () => {
    let url = "/admin/users/add";
    this.props.historyRef.push(url);
  };
  editUserHandler = (row) => {
    let url = "/admin/users/" + row.id + "/edit";
    this.props.historyRef.push(url);
  };
  deleteUserHandler = (row) => {
    let rowId = row.id;
    let customer_data =
      typeof this.props.clientData.rows.data[0] !== "undefined"
        ? this.props.clientData.rows.data[0]
        : null;
    // let successor = (customer_data !== null)?customer_data.successor:null;
    let rowData = { post: { locked: true } };
    rowData["url"] = "/data/system/client/" + rowId + "/transitions/edit";
    rowData["redirect_to"] = "/admin/settings";
    if (customer_data !== null && typeof rowId !== "undefined") {
      this.props.dispatch(showDeleteDialog(rowData));
    }
  };

  render() {
    let user_options = PagingOptions;
    user_options.totalSize =
      typeof this.props.clientData !== "undefined" &&
      typeof this.props.clientData.rows !== "undefined" &&
      typeof this.props.clientData.rows.meta.filtered !== "undefined"
        ? this.props.clientData.rows.meta.filtered
        : "";
    user_options.sizePerPage =
      typeof this.props.clientData !== "undefined" &&
      typeof this.props.clientData.rows !== "undefined" &&
      typeof this.props.clientData.rows.meta.page_size !== "undefined"
        ? this.props.clientData.rows.meta.page_size
        : 10;

    let user_data =
      typeof this.props.clientData !== "undefined" &&
      typeof this.props.clientData.rows !== "undefined" &&
      typeof this.props.clientData.rows.data !== "undefined"
        ? this.props.clientData.rows.data
        : [];

    return (
      <div>
        <div className="table_main">
          <h5>Benutzer</h5>
          <HasAccess
            Module={SYSTEM_MODULE}
            Entity={CLIENT_ENTITY}
            Label={CREATE_PERMISSION}
          >
            <button
              type="button"
              className="btn btn-dark"
              onClick={(evt) => this.addUserHandler(evt)}
            >
              neuen Benutzer anlegen
            </button>
          </HasAccess>
        </div>
        <BootstrapTable
          remote={{ pagination: true }}
          bootstrap4
          keyField="id"
          data={user_data}
          columns={this.state.userColumns}
          bordered={false}
          rowClasses={"table-row"}
          defaultSorted={this.state.userSort}
          pagination={paginationFactory(user_options)}
          onTableChange={this.userHandler}
          noDataIndication={() =>
            this.props.clientData.loading ? <NoDataIndication /> : NO_DATA
          }
        />
      </div>
    );
  }
}

ClientScreen.propTypes = {};
ClientScreen.defaultProps = { historyRef: "" };

const mapStateToProps = (state) => ({
  localeData: state.Locale.localeData,
  clientData: state.Client.clientData,
  dialogData: state.Dialog.dialogData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(mapStateToProps, mapDispatchToProps)(ClientScreen);
