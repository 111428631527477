import * as Types from "../constants";

// customer actions
export function fetchRole(data) {
    return {
        type: Types.GET_ROLE,
        data
    };
};
export function fetchRoleSuccess(response) {
    return {
        type: Types.ROLE_SUCCESS,
        response
    };
};
export function fetchRoleError(error) {
    return {
        type: Types.ROLE_ERROR,
        error
    };
};



// customer actions
export function fetchRoleById(data) {
    return {
        type: Types.GET_ROLE_BY_ID,
        data
    };
};
export function fetchRoleByIdSuccess(response) {
    return {
        type: Types.ROLE_BY_ID_SUCCESS,
        response
    };
};
export function fetchRoleByIdError(error) {
    return {
        type: Types.ROLE_BY_ID_ERROR,
        error
    };
};


