import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class CheckPermission extends Component {
  render() {
    let module = this.props.Module;
    let entity = this.props.Entity;
    let HyperTag = this.props.HyperTag;
    let label = this.props.Label;
    if (label !== "") {
      let transition_data =
        typeof this.props.localeData.rows !== "undefined" &&
        typeof this.props.localeData.rows.model !== "undefined" &&
        typeof this.props.localeData.rows.model[module] !== "undefined" &&
        typeof this.props.localeData.rows.model[module]["entities"][entity] !==
          "undefined" &&
        typeof this.props.localeData.rows.model[module]["entities"][entity][
          "transitions"
        ] !== "undefined"
          ? this.props.localeData.rows.model[module]["entities"][entity][
              "transitions"
            ]
          : null;
      if (
        transition_data !== null &&
        typeof transition_data[label] !== "undefined"
      ) {
        return this.props.children;
      } else if (HyperTag) {
        var anchorTagsRemoved = "";
        return (anchorTagsRemoved = this.props.children.replace(
          /<a.*?>.*?<\/a>/gi,
          ""
        ));
      }
      return false;
    } else {
      let transition_data =
        typeof this.props.localeData.rows !== "undefined" &&
        typeof this.props.localeData.rows.model !== "undefined" &&
        typeof this.props.localeData.rows.model[module] !== "undefined" &&
        typeof this.props.localeData.rows.model[module]["entities"][entity] !==
          "undefined" &&
        typeof this.props.localeData.rows.model[module]["entities"][entity][
          "transitions"
        ] !== "undefined"
          ? this.props.localeData.rows.model[module]["entities"][entity][
              "transitions"
            ]
          : null;
      if (transition_data !== null) {
        return this.props.children;
      } else if (HyperTag) {
        var anchorTagsRemoved = this.props.children.replace(
          /<a.*?>.*?<\/a>/gi,
          ""
        );
      } else {
        return false;
      }
    }
  }
}

CheckPermission.propTypes = {
  HyperTag: PropTypes.bool,
  Module: PropTypes.string.isRequired,
  Entity: PropTypes.string.isRequired,
  // Label: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

CheckPermission.defaultProps = { HyperLink: false, Label: "" };

const mapStateToProps = (state) => ({
  localeData: state.Locale.localeData,
});

export const HasAccess = connect(mapStateToProps)(CheckPermission);
