import { call, all, put, takeLatest } from "redux-saga/effects";
import { authHeader } from "../../../utils";
import request from "../../../services/request";
import * as ActionTypes from "../../actions/constants";
import {
  fetchCustomerNoteSuccess,
  fetchCustomerNoteError,
  fetchCustomerNoteByIdSuccess,
  fetchCustomerNoteByIdError,
  createCustomerNoteSuccess,
  createCustomerNoteError,
  updateCustomerNoteSuccess,
  updateCustomerNoteError,
} from "../../actions/customer";

function* customerNoteSaga({ data }) {
  try {
    const requestURL = "data/core/customer_note/query";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data,
    });
    yield put(fetchCustomerNoteSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(fetchCustomerNoteError(error));
    }
  }
}

function* indexSaga({ data }) {
  try {
    const requestURL = "data/core/customer_note/query";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data,
    });
    yield put(fetchCustomerNoteByIdSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(fetchCustomerNoteByIdError(error));
    }
  }
}

function* createSaga({ data }) {
  try {
    let noteData =
      typeof data.note !== "undefined" && data.note !== null ? data.note : null;
    const requestURL = "data/core/customer_note/transitions/create";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: noteData,
    });
    yield put(createCustomerNoteSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(createCustomerNoteError(error));
    }
  }
}

function* updateSaga({ data }) {
  try {
    let note_id =
      typeof data.note_id !== "undefined" && data.note_id !== null
        ? data.note_id
        : null;
    let noteData =
      typeof data.note !== "undefined" && data.note !== null ? data.note : null;
    const requestURL =
      "data/core/customer_note/" + note_id + "/transitions/edit";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: noteData,
    });
    yield put(updateCustomerNoteSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(updateCustomerNoteError(error));
    }
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(ActionTypes.GET_CUSTOMER_NOTE, customerNoteSaga),
    yield takeLatest(ActionTypes.GET_CUSTOMER_NOTE_BY_ID, indexSaga),
    yield takeLatest(ActionTypes.CREATE_CUSTOMER_NOTE, createSaga),
    yield takeLatest(ActionTypes.UPDATE_CUSTOMER_NOTE, updateSaga),
  ]);
}
