import * as Types from "../../actions/constants";

const initialState = {
  companyData: {
    loading: false,
    error: false,
    success: false,
    rows: { data: [], meta: [] },
  },
  updateData: {
    loading: false,
    error: false,
    success: false,
    data: null,
  },
  createData: {
    loading: false,
    error: false,
    success: false,
    data: null,
  },
  deleteData: {
    loading: false,
    error: false,
    success: false,
    rows: { data: [], meta: [] },
  },

  indexData: {
    loading: false,
    error: false,
    success: false,
    rows: { data: [], meta: [] },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_COMPANY_USER:
      return {
        ...state,
        companyData: {
          loading: true,
          error: false,
          success: false,
          rows: { data: [], meta: [] },
        },
      };
    case Types.COMPANY_USER_SUCCESS:
      return {
        ...state,
        companyData: {
          loading: false,
          error: false,
          success: true,
          rows: action.response.data,
        },
      };
    case Types.COMPANY_USER_ERROR:
      return {
        ...state,
        companyData: {
          loading: false,
          error: true,
          success: false,
          rows: action.error.data.error,
        },
      };
    case Types.UPDATE_COMPANY_USER:
      return {
        ...state,
        updateData: {
          loading: false,
          error: false,
          success: false,
          data: null,
        },
      };
    case Types.UPDATE_COMPANY_USER_SUCCESS:
      return {
        ...state,
        updateData: {
          loading: false,
          error: false,
          success: true,
          data: action.response,
        },
      };
    case Types.UPDATE_COMPANY_USER_ERROR:
      return {
        ...state,
        updateData: {
          loading: false,
          error: true,
          success: false,
          data: action.error.data.error,
        },
      };
    case Types.RESET_UPDATE_COMPANY_USER:
      return {
        ...state,
        updateData: {
          loading: false,
          error: false,
          success: false,
          data: { data: [], meta: [] },
        },
      };

    case Types.CREATE_COMPANY_USER:
      return {
        ...state,
        createData: {
          loading: true,
          error: false,
          success: false,
          data: null,
        },
      };
    case Types.CREATE_COMPANY_USER_SUCCESS:
      return {
        ...state,
        createData: {
          loading: false,
          error: false,
          success: true,
          data: action.response,
        },
      };
    case Types.CREATE_COMPANY_USER_ERROR:
      return {
        ...state,
        createData: {
          loading: false,
          error: true,
          success: false,
          data: action.error.data.error,
        },
      };
    case Types.RESET_CREATE_COMPANY_USER:
      return {
        ...state,
        createData: {
          loading: false,
          error: false,
          success: false,
          data: action.response,
        },
      };

    case Types.GET_COMPANY_USER_BY_ID:
      return {
        ...state,
        indexData: {
          loading: true,
          error: false,
          success: false,
          rows: { data: [], meta: [] },
        },
      };
    case Types.COMPANY_USER_BY_ID_SUCCESS:
      return {
        ...state,
        indexData: {
          loading: false,
          error: false,
          success: true,
          rows: action.response.data,
        },
      };
    case Types.COMPANY_USER_BY_ID_ERROR:
      return {
        ...state,
        indexData: {
          loading: false,
          error: true,
          success: false,
          rows: action.error.data.error,
        },
      };

    default:
      return state;
  }
};
