/*
 *
 * constants
 *
 */

export const LOGIN = 'login/LOGIN';
export const LOGIN_SUCCESS = 'login/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'login/LOGIN_ERROR';
export const RESET_ALERT = 'login/RESET_ALERT';



export const FORGOT = 'forgot/FORGOT';
export const FORGOT_SUCCESS = 'forgot/FORGOT_SUCCESS';
export const FORGOT_ERROR = 'forgot/FORGOT_ERROR';
export const RESET_FORGOT = 'forgot/RESET_FORGOT';

export const RESET_LOGIN_PASSWORD = 'forgot/RESET_LOGIN_PASSWORD';
export const RESET_LOGIN_PASSWORD_SUCCESS = 'forgot/RESET_LOGIN_PASSWORD_SUCCESS';
export const RESET_LOGIN_PASSWORD_ERROR = 'forgot/RESET_LOGIN_PASSWORD_ERROR';

export const LOGOUT = 'logout/LOGOUT';
export const LOGOUT_SUCCESS = 'logout/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'logout/LOGOUT_ERROR';

export const LOCALE = 'universe/LOCALE';
export const LOCALE_SUCCESS = 'universe/LOCALE_SUCCESS';
export const LOCALE_ERROR = 'universe/LOCALE_ERROR';

export const DELETE_ERROR   = 'delete/DELETE_ERROR';
export const DELETE_SUCCESS = 'delete/DELETE_SUCCESS';
export const DELETE_ROW     = 'delete/DELETE_ROW';
export const DELETE_RESET   = 'delete/DELETE_RESET';   
export const DELETE_DATA   = 'delete/DELETE_DATA';

