import * as Types from "../../actions/constants";

const initialState = {
   maintenanceData: {
    loading: false,
    error: false,
    success:false,
    rows: { data: [], meta: [] },
  },
  updateData: {
    loading: false,
    error: false,
    success:false,
    rows: { data: [], meta: [] },
  },
  createData: {
    loading: false,
    error: false,
    success:false,
    rows: { data: [], meta: [] },
  },

  indexData: {
    loading: false,
    error: false,
    success:false,
    rows: { data: [], meta: [] },
  },
  
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_MAINTENANCE_COMPONENT:
      return {
        ...state,
        maintenanceData: {
          loading: true,
          error: false,
          success: false,
          rows: { data: [], meta: [] },
        },
      };
    case Types.MAINTENANCE_COMPONENT_SUCCESS:
      return {
        ...state,
        maintenanceData: {
          loading: false,
          error: false,
          success: true,
          rows: action.response.data,
        },
      };
    case Types.MAINTENANCE_COMPONENT_ERROR:
      return {
        ...state,
        maintenanceData: {
          loading: false,
          error: true,
          success: false,
          rows: action.response,
        },
      };


    case Types.GET_MAINTENANCE_COMPONENT_BY_ID:
      return {
        ...state,
        indexData: {
          loading: true,
          error: false,
          success: false,
          rows: { data: [], meta: [] },
        },
      };
    case Types.MAINTENANCE_COMPONENT_BY_ID_SUCCESS:
      return {
        ...state,
        indexData: {
          loading: false,
          error: false,
          success: true,
          rows: action.response.data,
        },
      };
    case Types.MAINTENANCE_COMPONENT_BY_ID_ERROR:
      return {
        ...state,
        indexData: {
          loading: false,
          error: true,
          success: false,
          rows: action.response,
        },
      };






      case Types.CREATE_MAINTENANCE_COMPONENT:
      return {
        ...state,
        createData: {
          loading: true,
          error: false,
          success: false,
          rows: { data: [], meta: [] },
        },
      };
    case Types.CREATE_MAINTENANCE_COMPONENT_SUCCESS:
      return {
        ...state,
        createData: {
          loading: false,
          error: false,
          success: true,
          rows: action.response.data,
        },
      };
    case Types.CREATE_MAINTENANCE_COMPONENT_ERROR:
      return {
        ...state,
        createData: {
          loading: false,
          error: true,
          success: false,
          rows: action.response,
        },
      };

      case Types.RESET_CREATE_MAINTENANCE_COMPONENT:
      return {
        ...state,
        createData: {
          loading: false,
          error: false,
          success: false,
          rows: { data: [], meta: [] },
        },
      };

      case Types.UPDATE_MAINTENANCE_COMPONENT:
        return {
          ...state,
          updateData: {
            loading: true,
            error: false,
            success: false,
            rows: { data: [], meta: [] },
          },
        };
      case Types.UPDATE_MAINTENANCE_COMPONENT_SUCCESS:
        return {
          ...state,
          updateData: {
            loading: false,
            error: false,
            success: true,
            rows: action.response.data,
          },
        };
      case Types.UPDATE_MAINTENANCE_COMPONENT_ERROR:
        return {
          ...state,
          updateData: {
            loading: false,
            error: true,
            success: false,
            rows: action.response,
          },
        };
  
        case Types.RESET_UPDATE_MAINTENANCE_COMPONENT:
        return {
          ...state,
          updateData: {
            loading: false,
            error: false,
            success: false,
            rows: { data: [], meta: [] },
          },
        };

    default:
      return state;
  }
};
