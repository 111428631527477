import * as Types from "../../actions/constants";

const initialState = {
    customerData: {
      data:[]
    },
    vehicleData:{
      data:[]
    }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_CUSTOMER_SEARCH_PARAMS:
      return {
        ...state,
        customerData: {
          data:action.data
        },
    };
    case Types.SET_VEHICLE_SEARCH_PARAMS:
      return {
        ...state,
        vehicleData: {
          data:action.data
        },
    };
    case Types.RESET_CUSTOMER_SEARCH_PARAMS:
      return {
        ...state,
        customerData: {
          data:[]
        },
    };
    case Types.RESET_VEHICLE_SEARCH_PARAMS:
      return {
        ...state,
        vehicleData: {
          data:[]
        },
    };
    default:
      return state;
  }
};
