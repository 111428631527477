const validateEmailFormat = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
};

const getURLErrorMessage = (value, required) => {
  if (!value) {
    return required ? "Required parameter" : "";
  }

  const invalidEmail = !validateURLFormat(value);
  if (invalidEmail) {
    return "URL is invalid";
  }
  return "";
};

const validateURLFormat = (value) => {
  const re =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  return re.test(value);
};

const inputErrorHandler = (dataType, value, required) => {
  switch (dataType) {
    case "email":
      return getEmailErrorMessage(value, required);

    case "url":
      return getURLErrorMessage(value, required);

    default:
      return required && !value ? "Required parameter" : "";
  }
};

// email
const getEmailErrorMessage = (value, required) => {
  if (!value) {
    return required ? "Required parameter" : "";
  }

  const invalidEmail = !validateEmailFormat(value);
  if (invalidEmail) {
    return "Email is invalid";
  }
  return "";
};

export { validateURLFormat, inputErrorHandler };
