import * as Types from "../constants/types";


export function fetchLocale(data) {
    return {
        type: Types.LOCALE,
        data
    };
};

export function setLocaleSuccess(response) {
    return {
        type: Types.LOCALE_SUCCESS,
        response
    };
};

export function setLocaleError(error) {
    return {
        type: Types.LOCALE_ERROR,
        error
    };
};

