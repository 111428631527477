import { all, fork } from "redux-saga/effects";
import LoginSaga from "./login";
import CustomerSaga from "./customer";
import CustomerNoteSaga from "./customer/customer_note";
import VehicleSaga from "./vehicle";
import LogoutSaga from "./logout";
import LocaleSaga from "./universe";
import DialogSaga from "./dialog";
import ClientSaga from "./client";
import DeleteSaga from "./delete";
import RoleSaga from "./role";
import ComponentSaga from "./vehicle/component";
import MaintenanceComponentSaga from "./vehicle/maintenance_component";
import VehicleNoteSaga from "./vehicle/vehicle_note";
import MaintenanceNoteSaga from "./vehicle/maintenance_note";
import VehicleDocumentSaga from "./vehicle/vehicle_document";
import MaintenanceSaga from "./vehicle/maintenance";
import DocumentSaga from "./vehicle/document";
import CompanyUserSaga from "./company/company_user";
import BindingSaga from "./binding";
import ForgotSaga from "./forgot";
export default function* rootSaga() {
  yield all([
    fork(LoginSaga),
    fork(CustomerSaga),
    fork(VehicleSaga),
    fork(LogoutSaga),
    fork(LocaleSaga),
    fork(DialogSaga),
    fork(ClientSaga),
    fork(DeleteSaga),
    fork(ComponentSaga),
    fork(MaintenanceComponentSaga),
    fork(MaintenanceNoteSaga),
    fork(MaintenanceSaga),
    fork(DocumentSaga),
    fork(CompanyUserSaga),
    fork(CustomerNoteSaga),
    fork(RoleSaga),
    fork(VehicleNoteSaga),
    fork(VehicleDocumentSaga),
    fork(ForgotSaga),
    fork(BindingSaga),
  ]);
}
