import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import { fetchCustomer } from "../../store/actions/customer";
import {
  fetchVehicle,
  createVehicleDocument,
  resetCreateVehicleDocument,
  fetchDocument,
} from "../../store/actions/vehicle";
import {
  inputErrorHandler,
  signalTransitionSuccess,
  signalTransitionError,
  removeEmptyValues,
  getFileContent,
  checkFileType,
  getMaxBase64StringSize,
  FILE_TOO_LARGE_MESSAGE,
  FILE_NAME_SEPARATOR,
  FILE_TYPE_MESSAGE,
  CORE_MODULE,
  VEHICLE_FILE_ENTITY,
} from "../../utils";
import { showAlertDialog } from "../../store/actions/dialog";
import { DateTimePicker } from "react-tempusdominus-bootstrap";
import AutoRecommend from "../../components/AutoSuggest";
import "font-awesome/css/font-awesome.css";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";
import ColumnText from "../../components/Column";

class VehicleDocumentAddScreen extends Component {
  vehicleId = null;
  constructor(props) {
    super(props);
    this.vehicleId = props.match.params.vid;
    this.state = {
      fields: {
        document_id: "",
        document_name: "",
        owner_name: "",
        document_date: "",
        vehicle_id: "",
        vehicle_name: "",
        files: "",
      },
      reset: {
        fields: {
          document_id: "",
          document_name: "",
          owner_name: "",
          document_date: "",
          vehicle_id: "",
          vehicle_name: "",
          files: "",
        },
        errors: {
          document_id: "",
          expirty_date: "",
          vehicle_id: "",
          files: "",
        },
      },
      errors: {
        document_id: "",
        expirty_date: "",
        vehicle_id: "",
        files: "",
      },
      fileName: "No File Choosen",
      defaultName: "No File Choosen",
      document_value: "",
      owner_value: "",
      vehicle_value: "",
      owner_suggestions: [],
      vehicle_suggestions: [],
      document_suggestions: [],
    };
    this.field = React.createRef();
    this.field_created = React.createRef();
    this.changeFileHandler = this.changeFileHandler.bind(this);
  }

  componentDidMount() {
    let cdata = {};
    cdata.conds = { "system__assignment__assigned_client.agenda": 9 };
    cdata.attrs = {};
    cdata.attrs["prefetch"] = {
      owning_client: "system__assignment__assigned_client",
    };
    this.props.dispatch(fetchCustomer(cdata));

    let vdata = {};
    vdata.attrs = {};
    vdata.attrs["prefetch"] = [
      "core__vehicle_maintenance__vehicle",
      { owning_customer: "owning_client" },
    ];
    vdata.conds = { "me.id": this.vehicleId };
    this.props.dispatch(fetchVehicle(vdata));

    let data = {};
    data.conds = {};
    data.attrs = {};
    this.props.dispatch(fetchDocument(data));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.customerData) !==
      JSON.stringify(this.props.customerData)
    ) {
      let customers =
        typeof this.props.customerData !== "undefined" &&
        typeof this.props.customerData.rows !== "undefined" &&
        typeof this.props.customerData.rows.data !== "undefined"
          ? this.props.customerData.rows.data
          : [];
      let data = [];
      let suggestions = this.state.owner_suggestions;
      for (let i = 0; i < suggestions.length; i++) {
        let obj = {};
        obj.id = suggestions.id;
        obj.name = suggestions.name;
        data.push(obj);
      }
      for (let i = 0; i < customers.length; i++) {
        let obj = {};
        if (
          typeof customers[i]["owning_client"] !== "undefined" &&
          customers[i]["owning_client"] !== null
        ) {
          obj.id =
            typeof customers[i]["id"] !== "undefined"
              ? customers[i]["owning_client"]["id"]
              : "";
          obj.name =
            customers[i]["owning_client"]["first_name"] +
            " " +
            customers[i]["owning_client"]["last_name"];
          obj.customer_id =
            typeof customers[i]["id"] !== "undefined" ? customers[i]["id"] : "";
          this.setState({ fields: { customer_id: obj.customer_id } });
          data.push(obj);
        }
      }
      console.log(data);
      this.setState({ owner_suggestions: data });
    }

    if (
      JSON.stringify(prevProps.vehicleData) !==
      JSON.stringify(this.props.vehicleData)
    ) {
      let vehicles =
        typeof this.props.vehicleData !== "undefined" &&
        typeof this.props.vehicleData.rows !== "undefined" &&
        typeof this.props.vehicleData.rows.data !== "undefined"
          ? this.props.vehicleData.rows.data
          : [];
      let data = [];
      let suggestions = this.state.vehicle_suggestions;
      for (let i = 0; i < suggestions.length; i++) {
        let obj = {};
        obj.id = suggestions.id;
        obj.name = suggestions.name;
        data.push(obj);
      }
      for (let i = 0; i < vehicles.length; i++) {
        let obj = {};
        if (typeof vehicles[i]["id"] !== "undefined") {
          obj.id =
            typeof vehicles[i]["id"] !== "undefined" ? vehicles[i]["id"] : "";
          obj.name = vehicles[i]["vin"];
          this.setState({ fields: { vehicle_id: obj.id } });
          this.onVehicleChange(obj.name);
          data.push(obj);
        }
      }
      this.setState({ vehicle_suggestions: data });
    }

    if (
      JSON.stringify(prevProps.documentData) !==
      JSON.stringify(this.props.documentData)
    ) {
      let documents =
        typeof this.props.documentData !== "undefined" &&
        typeof this.props.documentData.rows !== "undefined" &&
        typeof this.props.documentData.rows.data !== "undefined"
          ? this.props.documentData.rows.data
          : [];
      let data = [];
      let suggestions = this.state.document_suggestions;
      for (let i = 0; i < suggestions.length; i++) {
        let obj = {};
        obj.id = suggestions.id;
        obj.name = suggestions.name;
        data.push(obj);
      }
      for (let i = 0; i < documents.length; i++) {
        let obj = {};
        if (typeof documents[i]["id"] !== "undefined") {
          obj.id =
            typeof documents[i]["id"] !== "undefined" ? documents[i]["id"] : "";
          obj.name = documents[i]["name"];
          data.push(obj);
        }
      }
      this.setState({ document_suggestions: data });
    }

    if (this.props.createData.success) {
      this.props.dispatch(resetCreateVehicleDocument());
      signalTransitionSuccess("Erfolgreich hinzugefügt");
      let url = "/admin/vehicles/" + this.props.match.params.vid + "/documents";
      this.props.history.push(url);
    } else if (this.props.createData.error) {
      this.props.dispatch(resetCreateVehicleDocument());
      signalTransitionError("Hinzufügen fehlgeschlagen");
    }
  }

  changeFileHandler(evt) {
    let errors = this.state.errors;
    errors["files"] = "";
    this.setState({ errors: errors });
    if (
      typeof evt.target.files[0] !== "undefined" &&
      typeof evt.target.files[0].name !== "undefined"
    ) {
      this.setState({ fileName: evt.target.files[0].name });
      if (checkFileType(evt.target.files)) {
        getFileContent(evt.target.files)
          .then((data) => {
            if (data.length > getMaxBase64StringSize()) {
              this.props.dispatch(showAlertDialog(FILE_TOO_LARGE_MESSAGE));
              this.setState({ fileName: this.state.defaultName });
              let fields = this.state.fields;
              fields["files"] = "";
              this.setState({ fields: fields });
            } else {
              //console.log(`${this.state.fileName}${FILE_NAME_SEPARATOR}${data}`);
              let fields = this.state.fields;
              fields[
                "files"
              ] = `${this.state.fileName}${FILE_NAME_SEPARATOR}${data}`;
              this.setState({ fields: fields });
            }
          })
          .catch((e) => console.log("Error: ", e.message));
      } else {
        this.props.dispatch(showAlertDialog(FILE_TYPE_MESSAGE));
        this.setState({ fileName: this.state.defaultName });
        let fields = this.state.fields;
        fields["files"] = "";
        this.setState({ fields: fields });
      }
    }
  }

  onDocumentChange = (newValue) => {
    this.setState({
      document_value: newValue,
    });
    let errors = this.state.errors;
    errors["document_id"] = "";
    this.setState({ errors: errors });
  };

  onDocumentFetchRequested = (value) => {
    this.loadDocumentSuggestions(value);
  };

  onDocumentClearRequested = () => {
    this.setState({
      document_suggestions: [],
    });
  };

  loadDocumentSuggestions(value) {
    if (value.trim() !== "") {
      let vdata = {};
      vdata.attrs = {};
      vdata.conds = { name: { "-like": "%" + value + "%" } };
      this.props.dispatch(fetchDocument(vdata));
    }
  }

  loadMoreDocumentReferences = () => {
    let meta_data =
      typeof this.props.vehicleData !== "undefined" &&
      typeof this.props.vehicleData.rows !== "undefined" &&
      typeof this.props.vehicleData.rows.meta !== "undefined"
        ? this.props.vehicleData.rows.meta
        : [];

    let vdata = {};
    vdata.attrs = meta_data.attrs;
    vdata.conds = meta_data.conds;
    vdata.page = meta_data.page + 1;
    vdata.page_size = meta_data.page_size;
    this.props.dispatch(fetchDocument(vdata));
  };

  onDocumentSelect = (suggestionValue) => {
    const reference = this.state.document_suggestions.find(
      (r) => r["name"] === suggestionValue //.replace('/Maintenance - /i',''),
    );
    let fields = this.state.fields;
    fields["document_id"] = reference.id;
    fields["document_name"] = reference.name;
    this.setState({ fields: fields });
  };

  loadMoreOwnerReferences = () => {
    let meta_data =
      typeof this.props.customerData !== "undefined" &&
      typeof this.props.customerData.rows !== "undefined" &&
      typeof this.props.customerData.rows.meta !== "undefined"
        ? this.props.customerData.rows.meta
        : [];

    let cdata = {};
    cdata.attrs = meta_data.attrs;
    cdata.conds = meta_data.conds;
    cdata.page = meta_data.page + 1;
    cdata.page_size = meta_data.page_size;
    this.props.dispatch(fetchCustomer(cdata));
  };

  onOwnerSelect = (suggestionValue) => {
    const reference = this.state.owner_suggestions.find(
      (r) => r["name"] === suggestionValue //.replace('/Maintenance - /i',''),
    );
    let fields = this.state.fields;
    fields["owner_name"] = reference.name;
    fields["customer_id"] = reference.customer_id;
    this.setState({ fields: fields });
  };

  onVehicleChange = (newValue) => {
    this.setState({
      vehicle_value: newValue,
    });
    let errors = this.state.errors;
    errors["vehicle_id"] = "";
    this.setState({ errors: errors });
  };

  onVehicleFetchRequested = (value) => {
    this.loadVehicleSuggestions(value);
  };

  onVehicleClearRequested = () => {
    this.setState({
      vehicle_suggestions: [],
    });
  };

  loadVehicleSuggestions(value) {
    if (value !== "") {
      let vdata = {};
      vdata.attrs = {};
      vdata.attrs["prefetch"] = [
        "core__vehicle_maintenance__vehicle",
        { owning_customer: "owning_client" },
      ];
      vdata.conds = {
        vin: { "-like": "%" + value + "%" },
        "owning_customer.id": this.state.fields.customer_id,
      };
      this.props.dispatch(fetchVehicle(vdata));
    }
  }

  loadMoreVehicleReferences = () => {
    let meta_data =
      typeof this.props.vehicleData !== "undefined" &&
      typeof this.props.vehicleData.rows !== "undefined" &&
      typeof this.props.vehicleData.rows.meta !== "undefined"
        ? this.props.vehicleData.rows.meta
        : [];

    let vdata = {};
    vdata.attrs = meta_data.attrs;
    vdata.conds = meta_data.conds;
    vdata.page = meta_data.page + 1;
    vdata.page_size = meta_data.page_size;
    this.props.dispatch(fetchVehicle(vdata));
  };

  onVehicleSelect = (suggestionValue) => {
    const reference = this.state.vehicle_suggestions.find(
      (r) => r["name"] === suggestionValue
    );
    let fields = this.state.fields;
    fields["vehicle_id"] = reference.id;
    fields["vehicle_name"] = reference.name;
    this.setState({ fields: fields });
  };

  resetHandler(evt) {
    this.setState({ fields: this.state.reset.fields });
    this.setState({ errors: this.state.reset.errors });
    this.setState({ document_value: "" });
    this.setState({ owner_value: "" });
    this.setState({ vehicle_value: "" });
  }

  submitHandler(evt) {
    evt.preventDefault();
    // get our new errors
    let fields = this.state.fields;
    let newErrors = {};
    newErrors["document_id"] = inputErrorHandler("", fields.document_id, true);
    // newErrors["owner_id"] = inputErrorHandler("", fields.owner_id, true);
    newErrors["document_date"] = inputErrorHandler(
      "",
      fields.document_date,
      true
    );
    newErrors["vehicle_id"] = inputErrorHandler("", fields.vehicle_id, true);
    newErrors["files"] = inputErrorHandler("", fields.files, true);

    let errors = removeEmptyValues(newErrors);
    if (Object.keys(errors).length > 0) {
      this.setState({ errors: errors });
    } else {
      let documentData = { document: {} };
      documentData.document.vehicle = fields.vehicle_id;
      documentData.document.date = fields.document_date;
      documentData.document.vehicle_document_type = fields.document_id;
      documentData.document.file = fields.files;
      this.props.dispatch(createVehicleDocument(documentData));
    }
  }

  changeHandler(evt, name) {
    let value = evt.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: null,
      },
    }));
  }

  expiryHandler = (value) => {
    let items = this.state.fields;
    items["document_date"] = value;
    this.setState({ fields: items });

    let errors = this.state.errors;
    errors["document_date"] = "";
    this.setState({ errors: errors });
  };
  setFieldPlaceholder = () => {
    if (
      typeof this.field.current[0] !== "undefined" &&
      typeof this.field.current[0].children[0] !== "undefined"
    ) {
      this.field.current[0].children[0].setAttribute("placeholder", "Datum");
    }
  };

  render() {
    return (
      <div className="wrapper">
        <div className="main d-flex align-items-start flex-column">
          <h4>
            {" "}
            <ColumnText
              Module={CORE_MODULE}
              Entity={VEHICLE_FILE_ENTITY}
            />{" "}
            hinzufügen
          </h4>
        </div>
        <div className="sub_main">
          <Form>
            <Row>
              <Col lg={6}></Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group as={Row} controlId="vehicle_id">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_FILE_ENTITY}
                      Label={"vehicle"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <AutoRecommend
                      value={this.state.vehicle_value}
                      onValueChange={this.onVehicleChange}
                      suggestions={this.state.vehicle_suggestions}
                      onSuggestionsClearRequested={this.onVehicleClearRequested}
                      fetchSuggestions={this.onVehicleFetchRequested}
                      onSelect={this.onVehicleSelect}
                      disabled
                      placeholder="Wählen Sie Fahrzeug"
                      onScrollEnd={this.loadMoreVehicleReferences}
                      inputClasses={
                        typeof this.state.errors.vehicle_id !== "undefined" &&
                        this.state.errors.vehicle_id !== ""
                          ? "is-invalid form-control"
                          : "form-control"
                      }
                    />
                    {typeof this.state.errors.vehicle_id !== "undefined" &&
                    this.state.errors.vehicle_id !== "" ? (
                      <div className="has-error">
                        {this.state.errors.vehicle_id}
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="document_id">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_FILE_ENTITY}
                      Label={"vehicle_document_type"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <AutoRecommend
                      value={this.state.document_value}
                      onValueChange={this.onDocumentChange}
                      suggestions={this.state.document_suggestions}
                      onSuggestionsClearRequested={
                        this.onDocumentClearRequested
                      }
                      fetchSuggestions={this.onDocumentFetchRequested}
                      onSelect={this.onDocumentSelect}
                      placeholder="Wählen Sie Dokumententyp"
                      onScrollEnd={this.loadMoreDocumentReferences}
                      inputClasses={
                        typeof this.state.errors.document_id !== "undefined" &&
                        this.state.errors.document_id !== ""
                          ? "is-invalid form-control"
                          : "form-control"
                      }
                    />
                    {typeof this.state.errors.document_id !== "undefined" &&
                    this.state.errors.document_id !== "" ? (
                      <div className="has-error">
                        {this.state.errors.document_id}
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="document_date">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_FILE_ENTITY}
                      Label={"date"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <DateTimePicker
                      pickerRef={this.field}
                      callback={() => this.setFieldPlaceholder()}
                      date={this.state.fields.document_date}
                      defaultDate={this.state.fields.document_date}
                      format="YYYY.MM.DD"
                      onChange={(e) => this.expiryHandler(e.date)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.document_date}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group as={Row} controlId="attachment">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_FILE_ENTITY}
                      Label={"file"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.File
                      type="file"
                      // className={(this.state.errors.files !== "")?"is-invalid custom-file-label":"form-control custom-file-label"}
                      id="inputGroupFile01"
                      label={this.state.fileName}
                      onChange={(evt) => this.changeFileHandler(evt)}
                      accept="application/pdf, image/*"
                      custom
                      isInvalid={!!this.state.errors.files}
                    />
                    {this.state.errors.files !== "" ? (
                      <div className="has-error">{this.state.errors.files}</div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
                <div className="form-btn">
                  <Button
                    type="Button"
                    className="btn btn-dark Button"
                    onClick={(evt) => this.submitHandler(evt)}
                  >
                    OK
                  </Button>
                  <Button
                    type="Button"
                    className="btn btn-dark"
                    onClick={(evt) => this.resetHandler(evt)}
                  >
                    Abbrechen
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

VehicleDocumentAddScreen.propTypes = {};
VehicleDocumentAddScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  customerData: state.Customer.customerData,
  vehicleData: state.Vehicle.vehicleData,
  documentData: state.Document.documentData,
  localeData: state.Locale.localeData,
  createData: state.VDocument.createData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleDocumentAddScreen);
