import * as Types from "../../actions/constants/types";

const initialState = {
  localeData: {
    loading: false,
    error: false,
    success: false,
    rows:{data:[],meta:[]},
    msg:null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.LOCALE:
      return {
        ...state,
        localeData: {
          loading: true,
          error: false,
          success: false,
          rows:{},
        },
      };
    case Types.LOCALE_SUCCESS:
      return {
        ...state,
        localeData: {
          loading: false,
          success: true,
          error: false,
          rows: action.response.data,
          msg:null,
        },
      };
    case Types.LOCALE_ERROR:
      return {
        ...state,
        localeData: {
          loading: false,
          success: false,
          error: true,
          rows:{},
          msg: action.error,
        },
      };

    default:
      return state;
  }
};
