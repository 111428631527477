import * as Types from "../constants";

// maintenance actions
export function fetchMaintenanceComponent(data) {
    return {
        type: Types.GET_MAINTENANCE_COMPONENT,
        data
    };
};
export function fetchMaintenanceComponentSuccess(response) {
    return {
        type: Types.MAINTENANCE_COMPONENT_SUCCESS,
        response
    };
};
export function fetchMaintenanceComponentError(error) {
    return {
        type: Types.MAINTENANCE_COMPONENT_ERROR,
        error
    };
};



// maintenance actions
export function fetchMaintenanceComponentById(data) {
    return {
        type: Types.GET_MAINTENANCE_COMPONENT_BY_ID,
        data
    };
};
export function fetchMaintenanceComponentByIdSuccess(response) {
    return {
        type: Types.MAINTENANCE_COMPONENT_BY_ID_SUCCESS,
        response
    };
};
export function fetchMaintenanceComponentByIdError(error) {
    return {
        type: Types.MAINTENANCE_COMPONENT_BY_ID_ERROR,
        error
    };
};



// maintenance actions
export function createMaintenanceComponent(data) {
    return {
        type: Types.CREATE_MAINTENANCE_COMPONENT,
        data
    };
};
export function createMaintenanceComponentSuccess(response) {
    return {
        type: Types.CREATE_MAINTENANCE_COMPONENT_SUCCESS,
        response
    };
};

export function createMaintenanceComponentError(error) {
    return {
        type: Types.CREATE_MAINTENANCE_COMPONENT_ERROR,
        error
    };
};
export function resetCreateMaintenanceComponent(error) {
    return {
        type: Types.RESET_CREATE_MAINTENANCE_COMPONENT,
        error
    };
};




// maintenance actions
export function updateMaintenanceComponent(data) {
    return {
        type: Types.UPDATE_MAINTENANCE_COMPONENT,
        data
    };
};
export function updateMaintenanceComponentSuccess(response) {
    return {
        type: Types.UPDATE_MAINTENANCE_COMPONENT_SUCCESS,
        response
    };
};

export function updateMaintenanceComponentError(error) {
    return {
        type: Types.UPDATE_MAINTENANCE_COMPONENT_ERROR,
        error
    };
};
export function resetUpdateMaintenanceComponent(error) {
    return {
        type: Types.RESET_UPDATE_MAINTENANCE_COMPONENT,
        error
    };
};