import * as Types from "../constants";

// customer actions
export function fetchCustomer(data) {
    return {
        type: Types.GET_CUSTOMER,
        data
    };
};
export function fetchCustomerSuccess(response) {
    console.log(response);
    return {
        type: Types.CUSTOMER_SUCCESS,
        response
    };
};
export function fetchCustomerError(error) {
    return {
        type: Types.CUSTOMER_ERROR,
        error
    };
};

/** update Actions */
export function updateCustomer(data) {
    return {
        type: Types.UPDATE_CUSTOMER,
        data,
    };
}
export function updateCustomerSuccess(response) {
    return {
        type: Types.UPDATE_CUSTOMER_SUCCESS,
        response,
    };
}
export function updateCustomerError(error) {
    return {
        type: Types.UPDATE_CUSTOMER_ERROR,
        error,
    };
}

export function resetUpdateCustomer() {
    return {
        type: Types.RESET_UPDATE_CUSTOMER
        
    };
}

/** Add Actions */
export function createCustomer(data) {
    return {
        type: Types.CREATE_CUSTOMER,
        data,
    };
}
export function createCustomerSuccess(response) {
    return {
        type: Types.CREATE_CUSTOMER_SUCCESS,
        response,
    };
}
export function createCustomerError(error) {
    return {
        type: Types.CREATE_CUSTOMER_ERROR,
        error,
    };
}
export function resetCreateCustomer() {
    return {
        type: Types.RESET_CREATE_CUSTOMER
    };
}

// customer actions
/*export function deleteRecord(data) {
    return {
        type: Types.DELETE_RECORD,
        data
    };
};
export function fetchDeleteSuccess(response) {
    return {
        type: Types.DELETE_SUCCESS,
        response
    };
};
export function fetchDeleteError(error) {
    return {
        type: Types.DELETE_ERROR,
        error
    };
}; */






// customer actions
export function fetchCustomerDetail(data) {
    return {
        type: Types.GET_CUSTOMER_DETAIL,
        data
    };
};
export function fetchCustomerDetailSuccess(response) {
    return {
        type: Types.CUSTOMER_DETAIL_SUCCESS,
        response
    };
};
export function fetchCustomerDetailError(error) {
    return {
        type: Types.CUSTOMER_DETAIL_ERROR,
        error
    };
};





export {
  createCustomerNote,
  createCustomerNoteSuccess,
  createCustomerNoteError,
  resetCreateCustomerNote,
  updateCustomerNote,
  updateCustomerNoteSuccess,
  updateCustomerNoteError,
  resetUpdateCustomerNote,
  fetchCustomerNote,
  fetchCustomerNoteSuccess,
  fetchCustomerNoteError,
  fetchCustomerNoteById,
  fetchCustomerNoteByIdSuccess,
  fetchCustomerNoteByIdError 
} from "./customer_note";


