import * as Types from "../constants";

// maintenance actions
export function createVehicleDocument(data) {
    return {
        type: Types.CREATE_VEHICLE_DOCUMENT,
        data
    };
};
export function createVehicleDocumentSuccess(response) {
    return {
        type: Types.CREATE_VEHICLE_DOCUMENT_SUCCESS,
        response
    };
};
export function createVehicleDocumentError(error) {
    return {
        type: Types.CREATE_VEHICLE_DOCUMENT_ERROR,
        error
    };
};
export function resetCreateVehicleDocument() {
    return {
        type: Types.RESET_CREATE_VEHICLE_DOCUMENT
        
    };
};


// maintenance actions
export function updateVehicleDocument(data) {
    return {
        type: Types.UPDATE_VEHICLE_DOCUMENT,
        data
    };
};
export function updateVehicleDocumentSuccess(response) {
    return {
        type: Types.UPDATE_VEHICLE_DOCUMENT_SUCCESS,
        response
    };
};
export function updateVehicleDocumentError(error) {
    return {
        type: Types.UPDATE_VEHICLE_DOCUMENT_ERROR,
        error
    };
};
export function resetUpdateVehicleDocument() {
    return {
        type: Types.RESET_UPDATE_VEHICLE_DOCUMENT
        
    };
};


// maintenance actions
export function fetchVehicleDocument(data) {
    return {
        type: Types.GET_VEHICLE_DOCUMENT,
        data
    };
};
export function fetchVehicleDocumentSuccess(response) {
    return {
        type: Types.VEHICLE_DOCUMENT_SUCCESS,
        response
    };
};
export function fetchVehicleDocumentError(error) {
    return {
        type: Types.VEHICLE_DOCUMENT_ERROR,
        error
    };
};


// maintenance actions
export function fetchVehicleDocumentById(data) {
    return {
        type: Types.GET_VEHICLE_DOCUMENT_BY_ID,
        data
    };
};
export function fetchVehicleDocumentByIdSuccess(response) {
    return {
        type: Types.VEHICLE_DOCUMENT_BY_ID_SUCCESS,
        response
    };
};
export function fetchVehicleDocumentByIdError(error) {
    return {
        type: Types.VEHICLE_DOCUMENT_BY_ID_ERROR,
        error
    };
};
