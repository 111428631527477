import { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import { fetchCustomerDetail } from "../../store/actions/customer";
import { fetchCompanyUser } from "../../store/actions/company";

import moment from "moment";
import {
  CORE_MODULE,
  SYSTEM_MODULE,
  CUSTOMER_ENTITY,
  CLIENT_ENTITY,
  CUSTOMER_NOTE_ENTITY,
  CREATE_PERMISSION,
  ADMIN_MODIFY_CLIENT_PERMISSION,
  ADMIN_MODIFY_COMPANY_PERMISSION,
  VEHICLE_MAINTENANCE_ENTITY,
  VEHICLE_ENTITY,
} from "../../utils";
import { HasAccess } from "../../components/Access";
import ColumnText from "../../components/Column";

class CustomerDetailScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalCount: 0,
    };
    this.customerNoteHandler = this.customerNoteHandler.bind(this);
    this.editCustomerHandler = this.editCustomerHandler.bind(this);
    this.showCustomerNotes = this.showCustomerNotes.bind(this);
    this.showVehicleHandler = this.showVehicleHandler.bind(this);
    this.showMaintenanceHandler = this.showMaintenanceHandler.bind(this);
    this.viewUsersHandler = this.viewUsersHandler.bind(this);
  }

  componentDidMount() {
    let data = {};
    data.conds = { "me.id": this.props.match.params.id };
    data.attrs = {};
    data.attrs["prefetch"] = [
      {
        "owning_client": "system__assignment__assigned_client"
      },
      "core__vehicle_maintenance__owning_customer",
    ];
    this.props.dispatch(fetchCustomerDetail(data));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.customerData) !==
      JSON.stringify(this.props.customerData)
    ) {
      let customer_data =
        typeof this.props.customerData.rows.data[0] !== "undefined"
          ? this.props.customerData.rows.data[0]
          : null;
      let cust_id =
        customer_data !== null && typeof customer_data.id !== "undefined"
          ? customer_data.id
          : null;
      if (cust_id !== null && cust_id !== "") {
        let data = {};
        data.attrs = {};
        data.attrs["prefetch"] = ["assigned_client"];
        data.conds = { binding_instance_of_customer: cust_id };
        this.props.dispatch(fetchCompanyUser(data));
      }
    }
    if (
      JSON.stringify(prevProps.companyData) !==
      JSON.stringify(this.props.companyData)
    ) {
      let customer_meta =
        typeof this.props.companyData.rows !== "undefined" &&
          typeof this.props.companyData.rows.meta !== "undefined" &&
          typeof this.props.companyData.rows.meta.filtered !== "undefined"
          ? this.props.companyData.rows.meta.filtered
          : null;
      this.setState({ totalCount: customer_meta });
    }
  }

  customerNoteHandler = () => {
    let url = "/admin/customers/" + this.props.match.params.id + "/notes/add";
    this.props.history.push(url);
  };
  editCustomerHandler = () => {
    let url = "/admin/customers/" + this.props.match.params.id + "/edit";
    this.props.history.push(url);
  };

  showCustomerNotes = () => {
    let url = "/admin/customers/" + this.props.match.params.id + "/notes";
    this.props.history.push(url);
  };

  showVehicleHandler = () => {
    let url = "/admin/customers/" + this.props.match.params.id + "/vehicles";
    this.props.history.push(url);
  };

  showMaintenanceHandler = () => {
    let url = "/admin/customers/" + this.props.match.params.id + "/maintenance";
    this.props.history.push(url);
  };
  viewUsersHandler = (id) => {
    let url = "/admin/company/" + id + "/users";
    this.props.history.push(url);
  };

  render() {
    let customer_data =
      typeof this.props.customerData.rows.data[0] !== "undefined"
        ? this.props.customerData.rows.data[0]
        : null;

    let owning_client =
      customer_data !== null &&
        typeof customer_data.owning_client !== "undefined"
        ? customer_data.owning_client
        : null;
    let customer_type =
      customer_data !== null && typeof customer_data.is_client !== "undefined"
        ? customer_data.is_client
        : 0;
    let identifier =
      customer_data !== null && typeof customer_data.identifier !== "undefined"
        ? customer_data.identifier
        : "-";
    let id =
      customer_data !== null && typeof customer_data.id !== "undefined"
        ? customer_data.id
        : "-";
    let salutation =
      customer_data !== null && typeof customer_data.salutation !== "undefined"
        ? customer_data.salutation
        : "-";
    let company_name =
      customer_data !== null && typeof customer_data.name !== "undefined"
        ? customer_data.name
        : "-";
    let first_name =
      owning_client !== null && typeof owning_client.first_name !== "undefined"
        ? owning_client.first_name
        : "-";
    let last_name =
      owning_client !== null && typeof owning_client.last_name !== "undefined"
        ? owning_client.last_name
        : "-";

    let addresses =
      customer_data !== null &&
        typeof customer_data.addresses !== "undefined" &&
        customer_data.addresses !== null
        ? JSON.parse(customer_data.addresses)
        : null;
    let phonenumbers =
      customer_data !== null &&
        typeof customer_data.phonenumbers !== "undefined" &&
        customer_data.phonenumbers !== null
        ? JSON.parse(customer_data.phonenumbers)
        : null;

    let mobile =
      phonenumbers !== null && phonenumbers[0] !== null && typeof phonenumbers[0] !== "undefined" && phonenumbers[0].Name === "Mobil"
        && typeof phonenumbers[0].Nummer !== undefined ?
        phonenumbers[0].Nummer : "-";

    let phone =
      phonenumbers !== null && phonenumbers[1] !== null && typeof phonenumbers[1] !== "undefined" && phonenumbers[1].Name === "Telefonnummer" &&
        typeof phonenumbers[1].Nummer !== undefined ?
        phonenumbers[1].Nummer : "-";

    let address = addresses != null && typeof addresses[0] !== "undefined"
      ? addresses[0] : null
    let street =
      address !== null && typeof address.Straße !== "undefined"
        ? address.Straße
        : "-";
    let city =
      address !== null && typeof address.Stadt !== "undefined"
        ? address.Stadt
        : "-";
    let zip =
      address !== null && typeof address.PLZ !== "undefined"
        ? address.PLZ
        : "-";
    let country =
      address !== null && typeof address.Land !== "undefined"
        ? address.Land
        : "-";
    let email =
      owning_client !== null &&
        customer_data.addresses !== null &&
        typeof owning_client.email !== "undefined"
        ? owning_client.email
        : "-";

    return (
      <div className="wrapper">
        <div className="container-header">
          <h4>
            Details zur{" "}
            <ColumnText Module={CORE_MODULE} Entity={CUSTOMER_ENTITY} />
          </h4>
          <div className="btngroup">
            <HasAccess
              Module={CORE_MODULE}
              Entity={CUSTOMER_NOTE_ENTITY}
              Label={CREATE_PERMISSION}
            >
              <button
                type="button"
                className="btn btn-dark"
                onClick={() => {
                  this.customerNoteHandler();
                }}
              >
                {" "}
                <ColumnText
                  Module={CORE_MODULE}
                  Entity={CUSTOMER_NOTE_ENTITY}
                />{" "}hinzufügen
              </button>
            </HasAccess>
            {customer_type === 1 ? (
              <HasAccess
                Module={CORE_MODULE}
                Entity={CUSTOMER_ENTITY}
                Label={ADMIN_MODIFY_CLIENT_PERMISSION}
              >
                <button
                  type="button"
                  className="btn btn-dark"
                  onClick={() => {
                    this.editCustomerHandler();
                  }}
                >
                  Bearbeiten
                </button>
              </HasAccess>
            ) : (
              <HasAccess
                Module={CORE_MODULE}
                Entity={CUSTOMER_ENTITY}
                Label={ADMIN_MODIFY_COMPANY_PERMISSION}
              >
                <button
                  type="button"
                  className="btn btn-dark"
                  onClick={() => {
                    this.editCustomerHandler();
                  }}
                >
                  Bearbeiten
                </button>
              </HasAccess>
            )}
            {/* <button type="button" className="btn btn-dark">
              Archive
            </button> */}
          </div>
        </div>
        <div className="sub_main">
          <Row>
            <Col lg={6}>
              <Form>
                <Form.Group as={Row}>
                  <Col sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={CUSTOMER_ENTITY}
                      Label={"is_client"}
                    />
                  </Col>

                  <Col
                    sm="8"
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Form.Check
                      custom
                      inline
                      label="Kunde"
                      type={"radio"}
                      id={`custom-inline-radio-1`}
                      checked={parseInt(customer_type) === 1 ? true : false}
                      disabled={parseInt(customer_type) !== 1 ? true : false}
                    />
                    <Form.Check
                      custom
                      inline
                      readOnly
                      label="Firma"
                      type={"radio"}
                      id={`custom-inline-radio-2`}
                      checked={parseInt(customer_type) === 0 ? true : false}
                      disabled={parseInt(customer_type) !== 0 ? true : false}
                    />
                  </Col>
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              {/* <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={CUSTOMER_ENTITY}
                    Label={"created"}
                  />
                </div>
                <div className="col-sm-8">{created}</div>
              </div> */}
              <div className="form-group row">
                <div className="col-sm-4">
                  <ColumnText
                    Module={CORE_MODULE}
                    Entity={CUSTOMER_ENTITY}
                    Label={"identifier"}
                  />
                </div>
                <div className="col-sm-8">{identifier}</div>
              </div>
              {parseInt(customer_type) === 1 ? (
                <>
                  <div className="form-group row">
                    <div className="col-sm-4">
                      <ColumnText
                        Module={CORE_MODULE}
                        Entity={CUSTOMER_ENTITY}
                        Label={"salutation"}
                      />
                    </div>
                    <div className="col-sm-8">{salutation}</div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-4">
                      <ColumnText
                        Module={SYSTEM_MODULE}
                        Entity={CLIENT_ENTITY}
                        Label={"first_name"}
                      />
                    </div>
                    <div className="col-sm-8">{first_name}</div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-4">
                      <ColumnText
                        Module={SYSTEM_MODULE}
                        Entity={CLIENT_ENTITY}
                        Label={"last_name"}
                      />
                    </div>
                    <div className="col-sm-8">{last_name}</div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-4">
                      <ColumnText
                        Module={SYSTEM_MODULE}
                        Entity={CLIENT_ENTITY}
                        Label={"email"}
                      />
                    </div>
                    <div className="col-sm-8">{email}</div>
                  </div>
                </>
              ) : (
                <div className="form-group row">
                  <div className="col-sm-4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={CUSTOMER_ENTITY}
                      Label={"name"}
                    />
                  </div>
                  <div className="col-sm-8">{company_name}</div>
                </div>
              )}

              <div className="form-group row">
                <div className="col-sm-4">Mobil</div>
                <div className="col-sm-8">{mobile}</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">Telefonnummer</div>
                <div className="col-sm-8">{phone}</div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group row">
                <div className="col-sm-4">Straße</div>
                <div className="col-sm-8">{street}</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">Stadt</div>
                <div className="col-sm-8">{city}</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">PLZ</div>
                <div className="col-sm-8">{zip}</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">Land</div>
                <div className="col-sm-8">{country}</div>
              </div>
              {parseInt(customer_type) === 0 &&
                id !== null &&
                <div className="form-group row">
                  <div className="col-sm-4">User account</div>
                  <div className="col-sm-8">{this.state.totalCount}</div>
                  <HasAccess
                    Module={CORE_MODULE}
                    Entity={CUSTOMER_ENTITY}
                    Label={ADMIN_MODIFY_CLIENT_PERMISSION}
                  >
                    <Button
                      variant="link"
                      onClick={() => {
                        this.viewUsersHandler(id);
                      }}
                    >
                      Benutzer Verwalten
                    </Button>
                  </HasAccess>
                </div>
              }
            </Col>
          </Row>
        </div>
        <Row className="container-footer">
          <div className="btngroup">
            <HasAccess Module={CORE_MODULE} Entity={CUSTOMER_NOTE_ENTITY}>
              <button
                type="button"
                className="btn btn-dark"
                onClick={() => {
                  this.showCustomerNotes();
                }}
              >
                <ColumnText
                  Module={CORE_MODULE}
                  Entity={CUSTOMER_NOTE_ENTITY}
                />
              </button>
            </HasAccess>
            <button
              type="button"
              className="btn btn-dark"
              onClick={() => {
                this.showVehicleHandler();
              }}
            >
              <ColumnText Module={CORE_MODULE} Entity={VEHICLE_ENTITY} />
            </button>
            <button
              type="button"
              className="btn btn-dark"
              onClick={() => {
                this.showMaintenanceHandler();
              }}
            >
              <ColumnText
                Module={CORE_MODULE}
                Entity={VEHICLE_MAINTENANCE_ENTITY}
              />
            </button>
          </div>
        </Row>
      </div>
    );
  }
}

CustomerDetailScreen.propTypes = {};
CustomerDetailScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  customerData: state.Customer.indexData,
  companyData: state.CUser.companyData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerDetailScreen);
