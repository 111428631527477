/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Loader } from '../../assets/loader.svg';

export default function NotFound() {
    return (
        <div className="spinner">
        <Loader/>
        </div>
    );
}
