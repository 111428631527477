import * as Types from "../constants";

export function showDeleteDialog(data) {
  return {
    type: Types.DELETE_DIALOG_SHOW,
    data
  };
}

export function hideDeleteDialog() {
  return {
    type: Types.DELETE_DIALOG_HIDE,
  };
}

export function showLogoutDialog() {
  return {
    type: Types.LOGOUT_DIALOG_SHOW,
  };
}

export function hideLogoutDialog() {
  return {
    type: Types.LOGOUT_DIALOG_HIDE,
  };
}

export function showAlertDialog(msg) {
    return {
      type: Types.ALERT_DIALOG_SHOW,
      msg,
    };
  }
  
export function hideAlertDialog() {
    return {
      type: Types.ALERT_DIALOG_HIDE,
    };
}
export function showModalDialog(data) {
  return {
    type: Types.MODAL_DIALOG_SHOW,
    data
  };
}

export function hideModalDialog() {
  return {
    type: Types.MODAL_DIALOG_HIDE,
  };
}

export function resetDialog() {
    return {
      type: Types.RESET_DIALOG,
    };
}
