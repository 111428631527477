import * as Types from "../constants";


export function setCustomerParams(data) {
    return {
        type: Types.SET_CUSTOMER_SEARCH_PARAMS,
        data
    };
};

export function resetCustomerParams() {
    return {
        type: Types.RESET_CUSTOMER_SEARCH_PARAMS
    };
};


export function setVehicleParams(data) {
    return {
        type: Types.SET_VEHICLE_SEARCH_PARAMS,
        data
    };
};



export function resetVehicleParams(data) {
    return {
        type: Types.RESET_VEHICLE_SEARCH_PARAMS
    };
};

