
export const GET_VEHICLE = 'constants/vehicle/GET_VEHICLE';
export const VEHICLE_SUCCESS = 'constants/vehicle/VEHICLE_SUCCESS';
export const VEHICLE_ERROR = 'constants/vehicle/VEHICLE_ERROR';

export const GET_VEHICLE_BY_ID = 'constants/vehicle/GET_VEHICLE_BY_ID';
export const VEHICLE_BY_ID_SUCCESS = 'constants/vehicle/VEHICLE_BY_ID_SUCCESS';
export const VEHICLE_BY_ID_ERROR = 'constants/vehicle/VEHICLE_BY_ID_ERROR';

export const UPDATE_VEHICLE = 'constants/vehicle/UPDATE_VEHICLE';
export const UPDATE_VEHICLE_SUCCESS = 'constants/vehicle/UPDATE_VEHICLE_SUCCESS';
export const UPDATE_VEHICLE_ERROR = 'constants/vehicle/UPDATE_VEHICLE_ERROR';
export const RESET_UPDATE_VEHICLE = 'constants/vehicle/RESET_UPDATE_VEHICLE';

export const UPDATE_VEHICLE_OWNERSHIP = 'constants/vehicle/UPDATE_VEHICLE_OWNERSHIP';
export const UPDATE_VEHICLE_OWNERSHIP_SUCCESS = 'constants/vehicle/UPDATE_VEHICLE_OWNERSHIP_SUCCESS';
export const UPDATE_VEHICLE_OWNERSHIP_ERROR = 'constants/vehicle/UPDATE_VEHICLE_OWNERSHIP_ERROR';
export const RESET_UPDATE_VEHICLE_OWNERSHIP = 'constants/vehicle/RESET_UPDATE_VEHICLE_OWNERSHIP';

export const CREATE_VEHICLE = 'constants/vehicle/CREATE_VEHICLE';
export const CREATE_VEHICLE_SUCCESS = 'constants/vehicle/CREATE_VEHICLE_SUCCESS';
export const CREATE_VEHICLE_ERROR = 'constants/vehicle/CREATE_VEHICLE_ERROR';
export const RESET_CREATE_VEHICLE = 'constants/vehicle/RESET_CREATE_VEHICLE';