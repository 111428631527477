import * as Types from "../../actions/constants";


const initialState = {
    bindingData: {
      loading: false,
      error: false,
      success:false,
      rows: { data: [], meta: [] },
    }
    
};


export default (state = initialState, action) => {
    //console.log(action.error);
    switch (action.type) {
      case Types.GET_BINDING_ENTITY:
          return {...state,
            bindingData:{
                        loading:true,
                        error:false,
                        success:false,
                        rows: { data: [], meta: [] },
                    } 
          }
      case Types.BINDING_ENTITY_SUCCESS:
          return {
                ...state,
                bindingData:{
                        loading:false,
                        error:false,
                        success:true,
                        msg:action.response.data,
                    }
      }
      case Types.BINDING_ENTITY_ERROR:
         return {...state,
            bindingData:{
                        loading:false,
                        error:true,
                        success:false,
                        rows: { data: [], meta: [] },
                    }
          }
      
      default:
          return state;
    }
};
