export const GET_VEHICLE_MAINTENANCE = 'vehicle/GET_MAINTENANCE_VEHICLE';
export const VEHICLE_MAINTENANCE_SUCCESS = 'vehicle/VEHICLE_MAINTENANCE_SUCCESS';
export const VEHICLE_MAINTENANCE_ERROR = 'vehicle/VEHICLE_MAINTENANCE_ERROR';

export const GET_VEHICLE_MAINTENANCE_BY_ID = 'vehicle/GET_MAINTENANCE_VEHICLE_BY_ID';
export const VEHICLE_MAINTENANCE_BY_ID_SUCCESS = 'vehicle/VEHICLE_MAINTENANCE_BY_ID_SUCCESS';
export const VEHICLE_MAINTENANCE_BY_ID_ERROR = 'vehicle/VEHICLE_MAINTENANCE_BY_ID_ERROR';

export const UPDATE_VEHICLE_MAINTENANCE = 'vehicle/UPDATE_MAINTENANCE_VEHICLE';
export const UPDATE_VEHICLE_MAINTENANCE_SUCCESS = 'vehicle/UPDATE_VEHICLE_MAINTENANCE_SUCCESS';
export const UPDATE_VEHICLE_MAINTENANCE_ERROR = 'vehicle/UPDATE_VEHICLE_MAINTENANCE_ERROR';
export const RESET_UPDATE_VEHICLE_MAINTENANCE = 'vehicle/RESET_UPDATE_VEHICLE_MAINTENANCE';

export const CREATE_VEHICLE_MAINTENANCE = 'vehicle/CREATE_MAINTENANCE_VEHICLE';
export const CREATE_VEHICLE_MAINTENANCE_SUCCESS = 'vehicle/CREATE_VEHICLE_MAINTENANCE_SUCCESS';
export const CREATE_VEHICLE_MAINTENANCE_ERROR = 'vehicle/CREATE_VEHICLE_MAINTENANCE_ERROR';
export const RESET_CREATE_VEHICLE_MAINTENANCE = 'vehicle/RESET_CREATE_VEHICLE_MAINTENANCE';