import * as Types from "../../actions/constants";

const initialState = {
  roleData: {
    loading: false,
    error: false,
    success:false,
    rows: { data: [], meta: [] },
  },
  indexData: {
    loading: false,
    error: false,
    success:false,
    rows: { data: [], meta: [] },
  }
  
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_ROLE:
      return {
        ...state,
        roleData: {
          loading: true,
          error: false,
          success:false,
          rows: { data: [], meta: [] },
        },
      };
    case Types.ROLE_SUCCESS:
      return {
        ...state,
        roleData: {
          loading: false,
          error: false,
          success:true,
          rows: action.response.data,
        },
      };
    case Types.ROLE_ERROR:
      return {
        ...state,
        roleData: {
          loading: false,
          error: true,
          success:false,
          rows: action.response,
        },
      };
      case Types.GET_ROLE_BY_ID:
        return {
          ...state,
          indexData: {
            loading: true,
            error: false,
            success:false,
            rows: { data: [], meta: [] },
          },
        };
      case Types.ROLE_BY_ID_SUCCESS:
        return {
          ...state,
          indexData: {
            loading: false,
            error: false,
            success:true,
            rows: action.response.data,
          },
        };
      case Types.ROLE_BY_ID_ERROR:
        return {
          ...state,
          indexData: {
            loading: false,
            error: true,
            success:false,
            rows: action.response,
          },
        }; 
           
    default:
      return state;
  }
};
