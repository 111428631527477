import React, { Component } from "react";
import { connect } from "react-redux";
import VehicleGeneralNote from "./VehicleGeneralNoteScreen";
import VehicleInternalNote from "./VehicleInternalNoteScreen";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Tab, Nav } from "react-bootstrap";
import {
  CORE_MODULE,
  VEHICLE_CUSTOMER_NOTE_ENTITY,
  VEHICLE_NOTE_ENTITY,
  CREATE_PERMISSION,
} from "../../utils";
import { HasAccess } from "../../components/Access";
import ColumnText from "../../components/Column";

class VehicleNoteScreen extends Component {
  vehicleId = null;

  constructor(props) {
    super(props);
    this.vehicleId = props.match.params.vid;
    this.state = {note_type: "general"};
    this.addHandler = this.addHandler.bind(this);
  }

  addHandler() {
    let url =
      "/admin/vehicles/" + this.props.match.params.vid + "/vehicle-notes/add/" + this.state.note_type;
    this.props.history.push(url);
  }

  setNoteType(value) {
    this.setState({note_type: value});
  }

  render() {
    return (
      <div className="wrapper_datatable">
        <div className="table_main">
          <h4>
            <ColumnText
              Module={CORE_MODULE}
              Entity={VEHICLE_CUSTOMER_NOTE_ENTITY}
            />
          </h4>
          <div className="btngroup">
            <HasAccess
              Module={CORE_MODULE}
              Entity={VEHICLE_NOTE_ENTITY}
              Label={CREATE_PERMISSION}
            >
              <button
                type="button"
                className="btn btn-dark"
                onClick={() => {
                  this.addHandler();
                }}
              >
                 {" "}
            <ColumnText
              Module={CORE_MODULE}
              Entity={VEHICLE_CUSTOMER_NOTE_ENTITY}
            />{" "}
            hinzufügen
              </button>
            </HasAccess>
          </div>
        </div>

        <Tab.Container
          className="wrapper"
          variant="tabs"
          id="vehicle_note"
          defaultActiveKey="general"
        >
          <Nav variant="pills" className="nav nav-tabs">
            <Nav.Item>
              <Nav.Link eventKey="general" onClick={() => this.setNoteType("general")}>Customer</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="internal" onClick={() => this.setNoteType("internal")}>Internal</Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="general">
              <VehicleGeneralNote
                vehicleId={this.vehicleId}
                historyRef={this.props.history}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="internal">
              <VehicleInternalNote
                vehicleId={this.vehicleId}
                historyRef={this.props.history}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    );
  }
}

VehicleNoteScreen.propTypes = {};
VehicleNoteScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  noteData: state.VNote.noteData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(mapStateToProps, mapDispatchToProps)(VehicleNoteScreen);
