import { call, all, put, takeLatest } from "redux-saga/effects";
import { authHeader } from "../../../utils";
import request from "../../../services/request";
import * as ActionTypes from "../../actions/constants";
import {
  fetchRoleSuccess,
  fetchRoleError,
  fetchRoleByIdSuccess,
  fetchRoleByIdError,
} from "../../actions/role";

function* roleSaga({ data }) {
  try {
    const requestURL = "/data/system/agenda/query";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data,
    });
    yield put(fetchRoleSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(fetchRoleError(error));
    }
  }
}

function* indexSaga({ data }) {
  try {
    const requestURL = "/data/system/assignment/query";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data,
    });
    yield put(fetchRoleByIdSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(fetchRoleByIdError(error));
    }
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(ActionTypes.GET_ROLE, roleSaga),
    yield takeLatest(ActionTypes.GET_ROLE_BY_ID, indexSaga),
  ]);
}
