import React from "react";
import PropTypes from "prop-types";
import Logo from '../../assets/abo.JPG';
import { Container }from 'react-bootstrap'

const LoginLayout = ({ children }) => {
    return (
           <div className = "login-container">
                 <Container className="d-flex main_header">
                    <img src={Logo} alt="" />
                </Container>  
                {children}
            </div>
    );
}

LoginLayout.propTypes = {
    children: PropTypes.element.isRequired,
};

export default LoginLayout;
