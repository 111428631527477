import * as Types from "../constants/types";

export function logout(data) {
    return {
        type: Types.LOGOUT,
        data
    };
};

export function logoutSuccess(response) {
    return {
        type: Types.LOGOUT_SUCCESS,
        response
    };
};

export function logoutError(error) {
    return {
        type: Types.LOGOUT_ERROR,
        error
    };
};

