import { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import { fetchCustomer } from "../../store/actions/customer";
import { createVehicle, resetCreateVehicle } from "../../store/actions/vehicle";
import {
  inputErrorHandler,
  signalTransitionSuccess,
  signalTransitionError,
  removeEmptyValues,
  CORE_MODULE,
  CUSTOMER_ENTITY,
  VEHICLE_ENTITY,
} from "../../utils";
import { DateTimePicker } from "react-tempusdominus-bootstrap";
import moment from "moment";
import _ from "lodash";

import "font-awesome/css/font-awesome.css";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

import ColumnText from "../../components/Column";
import AutoRecommend from "../../components/AutoSuggest";

class VehicleAddScreen extends Component {
  customerId = null;

  constructor(props) {
    super(props);
    this.customerId = props.match.params.cid;
    this.state = {
      fields: {
        vehicle_id: "",
        customer_name: "",
        chasis_number: "",
        first_inspection: moment(),
        licence_plate: "",
        brand: "",
        model: "",
        country: "",
        type: "",
        customer_type: -1,
      },
      reset: {
        fields: {
          vehicle_id: "",
          customer_name: "",
          chasis_number: "",
          first_inspection: moment(),
          licence_plate: "",
          brand: "",
          model: "",
          country: "",
          type: "",
          customer_type: -1,
        },
        errors: {
          customer_name: "",
          chasis_number: "",
          first_inspection: "",
          licence_plate: "",
          brand: "",
          model: "",
          country: "",
          type: "",
        },
      },
      errors: {
        customer_name: "",
        chasis_number: "",
        first_inspection: "",
        licence_plate: "",
        brand: "",
        model: "",
        country: "",
        type: "",
      },
      value: "",
      suggestions: [],
    };
    this.datePickerHandler = this.datePickerHandler.bind(this);
  }

  componentDidMount() {
    let data = {};
    if (this.customerId !== null) {
      data.conds = {};
      data.conds["me.id"] = this.customerId;
    } else {
      data.conds = {
        "-or": [
          { is_client: 0 },
          {
            "-and": [
              { "owning_client.locked": 0 },
              { "system__assignment__assigned_client.agenda": 9 },
            ],
          },
        ],
      };
    }

    data.attrs = {};
    data.attrs["prefetch"] = {
      owning_client: "system__assignment__assigned_client",
    };
    this.props.dispatch(fetchCustomer(data));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.customerData) !==
      JSON.stringify(this.props.customerData)
    ) {
      let customers =
        typeof this.props.customerData !== "undefined" &&
        typeof this.props.customerData.rows !== "undefined" &&
        typeof this.props.customerData.rows.data !== "undefined"
          ? this.props.customerData.rows.data
          : [];
      let data = [];
      let suggestions = this.state.suggestions;
      for (let i = 0; i < suggestions.length; i++) {
        let obj = {};
        obj.id = suggestions[i].id;
        obj.name = suggestions[i].name;
        obj.type = suggestions[i].type;
        data.push(obj);
      }
      for (let i = 0; i < customers.length; i++) {
        let obj = {};
        obj.id = customers[i].id;

        obj.type = customers[i].is_client;
        if (obj.type === 1) {
          obj.name =
            customers[i].owning_client.first_name +
            " " +
            customers[i].owning_client.last_name;
        } else {
          obj.name = customers[i].name;
        }
        data.push(obj);
        if (this.customerId !== null && this.customerId === "" + obj.id) {
          this.setState((prevState) => ({
            value: obj.name,
            fields: {
              ...prevState.fields,
              customer_type: customers[i].is_client,
              customer_name: obj.name,
              customer_id: obj.id,
            },
          }));
        }
      }
      data = _.uniqBy(data, "id");
      data = _.sortBy(data, "name");
      this.setState({ suggestions: data });
    }

    if (this.props.createData.success) {
      this.props.dispatch(resetCreateVehicle());
      signalTransitionSuccess("Create Success");
      let url = "/admin/vehicles";
      if (typeof this.customerId !== "undefined" && this.customerId !== null) {
        url = "/admin/customers/" + this.customerId + "/vehicles";
      }
      this.props.history.push(url);
    } else if (this.props.createData.error) {
      this.props.dispatch(resetCreateVehicle());
      if (this.props.createData.data.error.involvedAttributes.includes("vin")) {
        let newErrors = {};
        newErrors["chasis_number"] = "Please Enter a unique VIN";
        if (
          this.state.errors["chasis_number"] !== "Please Enter a unique VIN"
        ) {
          this.setState({ errors: newErrors });
        }
      } else {
        signalTransitionError("Hinzufügen fehlgeschlagen");
      }
    }
  }

  removeEmptyValues(obj) {
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName].length === 0
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }

  resetHandler(evt) {
    this.setState({ fields: this.state.reset.fields });
    this.setState({ errors: this.state.reset.errors });
  }

  submitHandler(evt) {
    evt.preventDefault();
    // get our new errors
    let fields = this.state.fields;
    let newErrors = {};
    newErrors["customer_name"] = inputErrorHandler(
      "",
      fields.customer_name,
      true
    );
    newErrors["chasis_number"] = inputErrorHandler(
      "",
      fields.chasis_number,
      true
    );
    newErrors["first_inspection"] = inputErrorHandler(
      "",
      fields.first_inspection,
      true
    );
    newErrors["licence_plate"] = inputErrorHandler(
      "",
      fields.licence_plate,
      true
    );
    newErrors["brand"] = inputErrorHandler("", fields.brand, true);
    newErrors["model"] = inputErrorHandler("", fields.model, true);
    newErrors["country"] = inputErrorHandler("", fields.country, true);
    newErrors["type"] = inputErrorHandler("", fields.type, true);
    newErrors["country"] = inputErrorHandler("", fields.country, true);

    if (fields.customer_name === "" || fields.customer_id === "") {
      newErrors["customer_name"] = "Required Parameter";
    } else if (fields.customer_name !== this.state.value) {
      newErrors["customer_name"] = "Please select valid Customer name";
    }

    let errors = removeEmptyValues(newErrors);
    // Conditional logic:
    if (Object.keys(errors).length > 0) {
      this.setState({ errors: errors });
    } else {
      let vehicleData = { vehicle: {} };

      vehicleData.vehicle.owning_customer = fields.customer_id;
      vehicleData.vehicle.first_seen = fields.first_inspection;
      vehicleData.vehicle.licence_plate = fields.licence_plate;
      vehicleData.vehicle.vin = fields.chasis_number;
      vehicleData.vehicle.brand = fields.brand;
      vehicleData.vehicle.type = fields.type;
      vehicleData.vehicle.model = fields.model;
      vehicleData.vehicle.country = fields.country;
      this.props.dispatch(createVehicle(vehicleData));
    }
  }

  changeHandler(evt, name) {
    let value = evt.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: null,
      },
    }));
  }

  datePickerHandler(value) {
    let items = this.state.fields;
    items["first_inspection"] = value;
    this.setState({ fields: items });

    let errors = this.state.errors;
    errors["first_inspection"] = "";
    this.setState({ errors: errors });
  }

  onValueChange = (newValue) => {
    this.setState({
      value: newValue,
    });
    let errors = this.state.errors;
    errors["customer_name"] = "";
    this.setState({ errors: errors });
  };

  onSuggestionsFetchRequested = (value) => {
    this.loadSuggestions(value); //TODO
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  loadSuggestions(value) {
    if (value.trim() !== "") {
      let cdata = {};
      cdata.attrs = {};
      // if (this.state.fields.customer_type === 1) {
      cdata.attrs["prefetch"] = {
        owning_client: "system__assignment__assigned_client",
      };
      cdata.conds = {
        "-or": [
          {
            "me.is_client": 1,
            "owning_client.locked": 0,
            "system__assignment__assigned_client.agenda": 9,
            "-or": [
              { "owning_client.first_name": { "-ilike": "%" + value + "%" } },
              { "owning_client.last_name": { "-ilike": "%" + value + "%" } },
            ],
          },
          {
            "me.is_client": 0,
            "me.name": { "-ilike": "%" + value + "%" },
          },
        ],
      };
      this.props.dispatch(fetchCustomer(cdata));
    }
  }

  loadMoreReferences = () => {
    let meta_data =
      typeof this.props.customerData !== "undefined" &&
      typeof this.props.customerData.rows !== "undefined" &&
      typeof this.props.customerData.rows.meta !== "undefined"
        ? this.props.customerData.rows.meta
        : [];

    let cdata = {};
    cdata.attrs = meta_data.attrs;
    cdata.conds = meta_data.conds;
    cdata.page = meta_data.page + 1;
    cdata.page_size = meta_data.page_size;
    this.props.dispatch(fetchCustomer(cdata));
  };

  onSelect = (suggestionValue) => {
    const reference = this.state.suggestions.find(
      (r) => r["name"] === suggestionValue //.replace('/Maintenance - /i',''),
    );
    let fields = this.state.fields;
    fields["customer_name"] = reference.name;
    fields["customer_id"] = reference.id;
    fields["customer_type"] = reference.type;
    this.setState({ value: reference.name });
    this.setState({ fields: fields });
  };

  onClientTypeChange = (evt) => {
    let value = evt.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        customer_type: value,
      },
    }));
    this.loadSuggestions(value);
  };

  render() {
    // let customer_type = 2;

    // let core_module = "core";
    // let vehicle_entity = "vehicle";
    // let maintenance_entity = "vehicle_maintenance";
    // let customer_entity = "customer";
    // let system_module = "system";
    // let client_entity = "client";

    /*let client_locale_data = ( (typeof(this.props.localeData.rows) != 'undefined') && (typeof(this.props.localeData.rows.model) != 'undefined') && (typeof(this.props.localeData.rows.model[system_module]) != 'undefined') && typeof(this.props.localeData.rows.model[system_module]['entities'][client_entity]) != 'undefined' )? this.props.localeData.rows.model[core_module]['entities'][customer_entity]:null;
    let cust_attrs  = ((customer_locale_data !== null)&&(customer_locale_data['attributes']))?(customer_locale_data['attributes']):null;
    let label_customer_name = ((cust_attrs != null) && (typeof(cust_attrs.name) !== 'undefined') &&  (typeof(cust_attrs.name.label) !== 'undefined'))?cust_attrs.name.label:'';
  
  
  
    let customer_locale_data = ( (typeof(this.props.localeData.rows) != 'undefined') && (typeof(this.props.localeData.rows.model) != 'undefined') && (typeof(this.props.localeData.rows.model[core_module]) != 'undefined') && typeof(this.props.localeData.rows.model[core_module]['entities'][customer_entity]) != 'undefined' )? this.props.localeData.rows.model[core_module]['entities'][customer_entity]:null;
    let cust_attrs  = ((customer_locale_data !== null)&&(customer_locale_data['attributes']))?(customer_locale_data['attributes']):null;
    let label_customer_name = ((cust_attrs != null) && (typeof(cust_attrs.name) !== 'undefined') &&  (typeof(cust_attrs.name.label) !== 'undefined'))?cust_attrs.name.label:'';
  */

    let customer_data =
      typeof this.props.customerData.rows !== "undefined" &&
      typeof this.props.customerData.rows.data[0] !== "undefined"
        ? this.props.customerData.rows.data[0]
        : null;
    let username =
      customer_data !== null && typeof customer_data.login_name !== "undefined"
        ? customer_data.login_name
        : "";

    let customers =
      typeof this.props.customerData !== "undefined" &&
      typeof this.props.customerData.rows !== "undefined" &&
      typeof this.props.customerData.rows.data !== "undefined"
        ? this.props.customerData.rows.data
        : [];
    let data = [];
    for (let i = 0; i < customers.length; i++) {
      let owning_client = customers[i].owning_client;
      let first_name = owning_client !== null ? owning_client.first_name : "";
      let last_name = owning_client !== null ? owning_client.last_name : "";
      let name = first_name + " " + last_name;
      if (name.trim() !== "") {
        data.push({
          name: name,
          id: customers[i].id,
        });
      }
    }
    const option_html = data.map((item, index) => (
      <option value={item.id}>{item.name}</option>
    ));
    return (
      <div className="wrapper">
        <div className="main d-flex align-items-start flex-column">
          <h4>
            {" "}
            <ColumnText Module={CORE_MODULE} Entity={VEHICLE_ENTITY} />{" "}
            hinzufügen
          </h4>
        </div>
        <div className="sub_main">
          <Form>
            <Row>
              <Col lg={6}>
                <Form.Group as={Row}>
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={CUSTOMER_ENTITY}
                      Label={"is_client"}
                    />
                  </Form.Label>
                  <Col
                    sm="8"
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Form.Check
                      custom
                      inline
                      label="Kunde"
                      type={"radio"}
                      id={`custom-inline-radio-1`}
                      name={"isClient"}
                      value={1}
                      disabled
                      onChange={this.onClientTypeChange}
                      checked={
                        this.state.fields.customer_type === 1 ? true : false
                      }
                    />
                    <Form.Check
                      custom
                      inline
                      label="Firma"
                      type={"radio"}
                      id={`custom-inline-radio-2`}
                      name={"isClient"}
                      value={0}
                      disabled
                      onChange={this.onClientTypeChange}
                      checked={
                        this.state.fields.customer_type === 0 ? true : false
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group as={Row} controlId="customer_name">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_ENTITY}
                      Label={"owning_customer"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <AutoRecommend
                      value={this.state.value}
                      disabled={!!this.customerId}
                      onValueChange={this.onValueChange}
                      suggestions={this.state.suggestions}
                      onSuggestionsClearRequested={
                        this.onSuggestionsClearRequested
                      }
                      fetchSuggestions={this.onSuggestionsFetchRequested}
                      onSelect={this.onSelect}
                      placeholder="Kunde wählen"
                      onScrollEnd={this.loadMoreReferences}
                      inputClasses={
                        typeof this.state.errors.customer_name !==
                          "undefined" && this.state.errors.customer_name !== ""
                          ? "is-invalid form-control"
                          : "form-control"
                      }
                    />
                    {typeof this.state.errors.customer_name !== "undefined" &&
                    this.state.errors.customer_name !== "" ? (
                      <div className="has-error">
                        {this.state.errors.customer_name}
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="chasis_number">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_ENTITY}
                      Label={"vin"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Fahrgestellnummer"
                      name="chasis_number"
                      value={this.state.fields.chasis_number}
                      onChange={(evt) =>
                        this.changeHandler(evt, "chasis_number")
                      }
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.chasis_number}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.chasis_number}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="first_inspection">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_ENTITY}
                      Label={"first_seen"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <DateTimePicker
                      date={this.state.fields.first_inspection}
                      format="YYYY-MM-DD"
                      onChange={(e) => this.datePickerHandler(e.date)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.first_inspection}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="licence_plate">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_ENTITY}
                      Label={"licence_plate"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Kennzeichen"
                      name="licence_plate"
                      value={this.state.fields.licence_plate}
                      onChange={(evt) =>
                        this.changeHandler(evt, "licence_plate")
                      }
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.licence_plate}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.email}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="brand">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_ENTITY}
                      Label={"brand"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Marke"
                      name="brand"
                      value={this.state.fields.brand}
                      onChange={(evt) => this.changeHandler(evt, "brand")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.brand}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.brand}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="model">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_ENTITY}
                      Label={"model"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="number"
                      placeholder="Baujahr"
                      name="model"
                      value={this.state.fields.model}
                      onChange={(evt) => this.changeHandler(evt, "model")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.model}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.model}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group as={Row} controlId="type">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_ENTITY}
                      Label={"type"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Modell"
                      name="type"
                      value={this.state.fields.type}
                      onChange={(evt) => this.changeHandler(evt, "type")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.type}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.type}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="country">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_ENTITY}
                      Label={"country"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Land"
                      name="country"
                      value={this.state.fields.country}
                      onChange={(evt) => this.changeHandler(evt, "country")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.country}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.country}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <div className="form-btn">
                  <Button
                    type="Button"
                    className="btn btn-dark Button"
                    onClick={(evt) => this.submitHandler(evt)}
                  >
                    OK
                  </Button>
                  <Button
                    type="Button"
                    className="btn btn-dark"
                    onClick={(evt) => this.resetHandler(evt)}
                  >
                    Abbrechen
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

VehicleAddScreen.propTypes = {};
VehicleAddScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  customerData: state.Customer.customerData,
  vehicleData: state.Vehicle.vehicleData,
  localeData: state.Locale.localeData,
  createData: state.Vehicle.createData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(mapStateToProps, mapDispatchToProps)(VehicleAddScreen);
