import { call, all, put, takeLatest } from "redux-saga/effects";
import { authHeader } from "../../../utils";
import request from "../../../services/request";
import * as ActionTypes from "../../actions/constants";
import {
  fetchClientSuccess,
  fetchClientError,
  updatePasswordSuccess,
  updatePasswordError,
  createClientSuccess,
  createClientError,
  updateClientSuccess,
  updateClientError,
} from "../../actions/client";

function* clientSaga({ data }) {
  try {
    const requestURL = "/data/system/client/query";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data,
    });
    yield put(fetchClientSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(fetchClientError(error));
    }
  }
}

function* updatePasswordSaga({ data }) {
  try {
    const requestURL = "/plugins/change_password";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data,
    });
    yield put(updatePasswordSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(updatePasswordError(error));
    }
  }
}

function* createSaga({ data }) {
  try {
    const requestURL = "/plugins/customer_client_create";

    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    });

    yield put(createClientSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined"
    ) {
      yield put(createClientError(error.data.error));
    }
  }
}

function* updateSaga({ data }) {
  try {
    let client_id =
      typeof data.client_id !== "undefined" && data.client_id !== null
        ? data.client_id
        : null;
    let customer_id =
      typeof data.customer_id !== "undefined" && data.customer_id !== null
        ? data.customer_id
        : null;
    let roleData =
      typeof data.role !== "undefined" && data.role !== null ? data.role : null;
    let previous_role =
      typeof data.predefined_roles !== "undefined" &&
        data.predefined_roles !== null
        ? data.predefined_roles
        : [];
    // let previous_role_id = ((typeof(data.previous_role_id) !== 'undefined')&&(data.previous_role_id !== null))?data.previous_role_id:null;
    //console.log('-------------------');
    //     console.log(previous_role);
    let clientData =
      typeof data.client !== "undefined" && data.client !== null
        ? data.client
        : null;
    let customerData =
      typeof data.customer !== "undefined" && data.customer !== null
        ? data.customer
        : null;

    const requestURL = "/data/system/client/" + client_id + "/transitions/edit";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: clientData,
    });

    let clientRequestURL =
      "/data/core/customer/" + customer_id + "/transitions/admin_modify_client";
    const clientResponse = yield call(request, {
      url: clientRequestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: customerData,
    });

    if (previous_role.length > 0) {
      yield all(
        previous_role.map((item) => {
          console.log(item);
          return call(deleteSaga, item);
        })
      );
    }

    let assignmentRequestURL = "/data/system/assignment/transitions/create";
    roleData.assigned_client = response.data.data.id;
    const assignmentResponse = yield call(request, {
      url: assignmentRequestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: roleData,
    });

    yield put(updateClientSuccess(assignmentResponse));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined"
    ) {
      yield put(updateClientError(error.data.error));
    }
  }

  function* deleteSaga(data) {
    try {
      if (typeof data !== "undefined") {
        let clientRequestURL =
          "/data/system/assignment/" + data.id + "/transitions/delete";

        let deleteData = {};
        deleteData["successor"] = data.successor;

        const deleteResponse = yield call(request, {
          url: clientRequestURL,
          method: "POST",
          headers: {
            Authorization: authHeader(),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data: deleteData,
        });
      }
    } catch (error) { }
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(ActionTypes.GET_CLIENT, clientSaga),
    yield takeLatest(ActionTypes.CREATE_CLIENT, createSaga),
    yield takeLatest(ActionTypes.UPDATE_CLIENT, updateSaga),
    yield takeLatest(ActionTypes.UPDATE_PASSWORD, updatePasswordSaga),
  ]);
}
