import * as Types from "../constants";

// fetch Component actions
export function fetchComponent(data) {
    return {
        type: Types.GET_COMPONENT,
        data
    };
};
export function fetchComponentSuccess(response) {
    return {
        type: Types.COMPONENT_SUCCESS,
        response
    };
};
export function fetchComponentError(error) {
    return {
        type: Types.COMPONENT_ERROR,
        error
    };
};


// fetch Component actions
export function fetchComponentById(data) {
    return {
        type: Types.GET_COMPONENT_BY_ID,
        data
    };
};
export function fetchComponentByIdSuccess(response) {
    return {
        type: Types.COMPONENT_BY_ID_SUCCESS,
        response
    };
};
export function fetchComponentByIdError(error) {
    return {
        type: Types.COMPONENT_BY_ID_ERROR,
        error
    };
};



// create Component actions
export function createComponent(data) {
    return {
        type: Types.CREATE_COMPONENT,
        data
    };
};
export function createComponentSuccess(response) {
    return {
        type: Types.CREATE_COMPONENT_SUCCESS,
        response
    };
};
export function createComponentError(error) {
    return {
        type: Types.CREATE_COMPONENT_ERROR,
        error
    };
};

export function resetCreateComponent(error) {
    return {
        type: Types.RESET_CREATE_COMPONENT,
        error
    };
};



// update Component actions
export function updateComponent(data) {
    return {
        type: Types.UPDATE_COMPONENT,
        data
    };
};
export function updateComponentSuccess(response) {
    return {
        type: Types.UPDATE_COMPONENT_SUCCESS,
        response
    };
};
export function updateComponentError(error) {
    return {
        type: Types.UPDATE_COMPONENT_ERROR,
        error
    };
};

export function resetUpdateComponent(error) {
    return {
        type: Types.RESET_UPDATE_COMPONENT,
        error
    };
};

