import React, { Component } from "react";
import PropTypes from "prop-types";

import Img1 from "../../assets/abo.JPG";
import Img2 from "../../assets/download.jpg";
import { Container } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import { showLogoutDialog } from "../../store/actions/dialog";
import {
  destroyToken,
  CORE_MODULE,
  VEHICLE_COMPONENT_ENTITY,
  CREATE_PERMISSION,
} from "../../utils";
import { connect } from "react-redux";
import { HasAccess } from "../../components/Access";

class DefaultLayout extends Component {
  constructor(props) {
    super(props);

    this.logoutHandler = this.logoutHandler.bind(this);
  }

  logoutHandler() {
    this.props.dispatch(showLogoutDialog());
    //this.setState({dialogShow:true});
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.logoutData.success === true) {
      destroyToken();
      if (window.location.href.indexOf("login") === -1) {
        window.location.href = "/login";
      }
    }
  }

  render() {
    const pathname = window.location.pathname;
    const splitLocation = pathname.split("/");
    let navPath =
      typeof splitLocation[2] !== "undefined" && splitLocation[2] !== ""
        ? splitLocation[2]
        : "";
    let navPage =
      typeof splitLocation[4] !== "undefined" && splitLocation[4] !== ""
        ? splitLocation[4]
        : "";

    let data =
      typeof this.props.localeData !== "undefined" &&
      typeof this.props.localeData.rows !== "undefined" &&
      typeof this.props.localeData.rows.clientSettings !== "undefined"
        ? this.props.localeData.rows.clientSettings
        : [];
    let first_name =
      typeof data["firstName"] !== "undefined" ? data["firstName"] : "";
    let last_name =
      typeof data["lastName"] !== "undefined" ? data["lastName"] : "";
    let username = first_name + " " + last_name;

    return (
      <>
        <div className="header">
          <Container className="d-flex">
            <div className="logo">
              <img src={Img1} alt="" />
            </div>
            <div className="dropdown ml-auto">
              <img src={Img2} alt="Profile" className="profile" />

              <div className="dropdown-content">
                <Link className={"dropdown-item user-name"} to="#">
                  {username}
                </Link>
                <HasAccess
                  Module={CORE_MODULE}
                  Entity={VEHICLE_COMPONENT_ENTITY}
                  Label={CREATE_PERMISSION}
                >
                  <NavLink
                    className={"dropdown-item"}
                    exact
                    to="/admin/settings"
                  >
                    Einstellungen
                  </NavLink>
                </HasAccess>
                <NavLink
                  className={"dropdown-item"}
                  exact
                  to="/admin/reset-password"
                >
                  Passwort ändern
                </NavLink>
                <Link onClick={() => this.logoutHandler()} to="#">
                  Abmelden
                </Link>
              </div>
            </div>
          </Container>
          <Container className="d-flex">
            <ul className="nav nav-tabs mx-auto nav-container">
              <li className="nav-item">
                <NavLink className="nav-link" exact to="/admin/search">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    fill="currentColor"
                    className="bi bi-house-door"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z" />
                  </svg>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={
                    (navPath === "customers" &&
                      !(navPage === "maintenance" || navPage === "vehicles")) ||
                    navPath === "company" ||
                    navPage === "users"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  exact
                  to="/admin/customers"
                >
                  Kunden
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={
                    (navPath === "vehicles" && navPage !== "maintenance") ||
                    navPath === "vehicle-notes" ||
                    navPath === "vehicle-documents" ||
                    navPath === "documents" ||
                    navPath === "vehicle-notes-general" ||
                    navPath === "vehicle-notes-internal" ||
                    navPage === "vehicles"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  exact
                  to="/admin/vehicles"
                >
                  Fahrzeuge
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={
                    navPath === "maintenance" ||
                    navPage === "maintenance" ||
                    navPage === "components"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  exact
                  to="/admin/maintenance"
                >
                  Wartung
                </NavLink>
              </li>
            </ul>
          </Container>
        </div>
        <Container>{this.props.children}</Container>
      </>
    );
  }
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
DefaultLayout.defaultProps = {};

const mapStateToProps = (state) => ({
  localeData: state.Locale.localeData,
  logoutData: state.Logout.logoutData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
