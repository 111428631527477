import { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Form, Button, ButtonGroup } from "react-bootstrap";
import {
  inputErrorHandler,
  signalTransitionSuccess,
  signalTransitionError,
  removeEmptyValues,
  CloseBtn,
  PagingOptions,
  CORE_MODULE,
  VEHICLE_COMPONENT_ENTITY,
} from "../../utils";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "font-awesome/css/font-awesome.css";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";
import {
  updateComponent,
  resetUpdateComponent,
  fetchComponent,
  fetchComponentById,
} from "../../store/actions/vehicle";
import { hideModalDialog } from "../../store/actions/dialog";
import ColumnText from "../../components/Column";
import { Link } from "react-router-dom";

class ComponentEditScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        component_name: "",
      },

      errors: {
        component_name: "",
      },
      reset: {
        fields: {
          component_name: "",
        },
        errors: {
          component_name: "",
        },
      },
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.compId !== "" &&
      JSON.stringify(prevProps.compId) !== JSON.stringify(this.props.compId)
    ) {
      let data = {};
      data.attrs = {};
      data.conds = { "me.id": this.props.compId };
      this.props.dispatch(fetchComponentById(data));
    }

    if (
      JSON.stringify(prevProps.indexData) !==
      JSON.stringify(this.props.indexData)
    ) {
      let component_data =
        typeof this.props.indexData.rows.data[0] != "undefined"
          ? this.props.indexData.rows.data[0]
          : null;
      let component_name =
        component_data !== null && typeof component_data.name !== "undefined"
          ? component_data.name
          : "-";
      let component = {};
      component["component_name"] = component_name;
      this.setState({ fields: component });
      let reset = this.state.reset;
      reset.fields = component;
      this.setState({ reset: reset });
    }

    if (this.props.updateData.success) {
      this.props.dispatch(resetUpdateComponent());
      let data = {};
      data.attrs = {};
      data.attrs["order_by"] = {};
      data.attrs.order_by["-desc"] = "me.id";
      data.conds = {};
      data.page = 1;
      data.page_size = PagingOptions.sizePerPage;
      this.props.dispatch(fetchComponent(data));
      this.props.dispatch(hideModalDialog());
      this.resetHandler();
      signalTransitionSuccess("Update Success");
    } else if (this.props.updateData.error) {
      this.props.dispatch(resetUpdateComponent());
      if (this.props.updateData.rows.errorType === "UNIQUENESS") {
        let newErrors = {};
        newErrors["component_name"] = "Please Enter Unique Component";
        this.setState({ errors: newErrors });
      } else {
        signalTransitionError("Update Failed");
      }
    }
  }

  resetHandler(evt) {
    this.setState({ fields: this.state.reset.fields });
    this.setState({ errors: this.state.reset.errors });
  }

  submitHandler(evt) {
    evt.preventDefault();
    // get our new errors

    let fields = this.state.fields;
    let newErrors = {};
    newErrors["component_name"] = inputErrorHandler(
      "",
      fields.component_name,
      true
    );
    let errors = removeEmptyValues(newErrors);

    if (Object.keys(errors).length > 0) {
      this.setState({ errors: errors });
    } else {
      let compData = { id: "", component: {} };
      compData.id = this.props.compId;
      compData.component.name = fields.component_name;
      this.props.dispatch(updateComponent(compData));
    }
  }

  changeHandler(evt, name) {
    let value = evt.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: null,
      },
    }));
  }

  closeHandler = () => {
    this.props.dispatch(hideModalDialog());
    //this.props.dispatch(resetDialog)
  };

  render() {
    return (
      <div
        id="flyoutMenu"
        className={
          this.props.dialogData.modalDialog === true &&
          this.props.dialogData.modalName === "Component-Edit"
            ? "show"
            : "hide"
        }
      >
        <Link
          to={"#"}
          onClick={() => {
            this.closeHandler();
          }}
        >
          <CloseBtn />
        </Link>
        <h5>Komponente bearbeiten</h5>
        <Form>
          <Form.Group controlId="component_name">
            <Form.Label>
              <ColumnText
                Module={CORE_MODULE}
                Entity={VEHICLE_COMPONENT_ENTITY}
                Label={"name"}
              />
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Komponentenname"
              name="component_name"
              value={this.state.fields.component_name}
              onChange={(evt) => this.changeHandler(evt, "component_name")}
              onKeyPress={this.onKeyPress}
              isInvalid={!!this.state.errors.component_name}
            />
            <Form.Control.Feedback type="invalid">
              {this.state.errors.component_name}
            </Form.Control.Feedback>
          </Form.Group>

          <div className="modal-frm-btn">
            <Button
              type="Button"
              className="btn btn-dark Button"
              onClick={(evt) => this.submitHandler(evt)}
            >
              OK
            </Button>
            <Button
              type="Button"
              className="btn btn-dark"
              onClick={(evt) => this.resetHandler(evt)}
            >
              Abbrechen
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

ComponentEditScreen.propTypes = {};

ComponentEditScreen.defaultProps = {
  compId: "",
};

const mapStateToProps = (state) => ({
  localeData: state.Locale.localeData,
  updateData: state.Component.updateData,
  indexData: state.Component.indexData,
  dialogData: state.Dialog.dialogData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentEditScreen);
