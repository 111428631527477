import * as React from 'react';
import {
  ToastContainer as Container,
  toast as toastFunctions,
} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const ToastContainer = () => (
	<Container
		style={{ textAlign: 'center' }}
		position={'top-center'}
		autoClose={5000}
		newestOnTop
		closeOnClick
		draggable
		pauseOnHover
	/>
);

const toast = {
	error: (text) => toastFunctions.error(text),
    success:(text) =>toastFunctions.success(text),
	info: (text) =>
		toastFunctions.info(text, {
			position: toastFunctions.POSITION.TOP_RIGHT,
		}),
};

const signalTransitionSuccess = (text) => toast.success(text);
const signalTransitionError = (text) => toast.error(text);

export { ToastContainer, toast, signalTransitionSuccess,signalTransitionError };
