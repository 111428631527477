import { combineReducers } from 'redux'
import Login from './login';
import Customer from './customer';
import CNote from './customer/customer_note';
import Vehicle from './vehicle';
import Logout from './logout';
import Locale from './universe';
import Dialog from './dialog';
import Client from './client';
import Role from './role';
import Delete from './delete';
import Component from './vehicle/component';
import Maintenance from './vehicle/maintenance';
import MComponent from './vehicle/maintenance_component';
import VDocument from './vehicle/vehicle_document';   
import MNote from './vehicle/maintenance_note';
import VNote from './vehicle/vehicle_note';
import Document from './vehicle/document';
import CUser from './company/company_users';
import Search from './search';
import Forgot from './forgot';
import Binding from './binding';
export default combineReducers({ 
   Login,
   Customer,
   Vehicle,
   Logout,
   Locale,
   Dialog,
   Client,
   Delete,
   Component,
   Document,
   CUser,
   Role,
   CNote,
   VNote,
   MComponent,
   Maintenance,
   MNote,
   VDocument,
   Search,
   Forgot,
   Binding,
})