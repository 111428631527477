
export const GET_CUSTOMER = 'constants/customer/GET_CUSTOMER';
export const CUSTOMER_SUCCESS = 'constants/customer/CUSTOMER_SUCCESS';
export const CUSTOMER_ERROR = 'constants/customer/CUSTOMER_ERROR';

export const UPDATE_CUSTOMER = 'constants/customer/UPDATE_CUSTOMER';
export const UPDATE_CUSTOMER_SUCCESS = 'constants/customer/UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_ERROR = 'constants/customer/UPDATE_CUSTOMER_ERROR';
export const RESET_UPDATE_CUSTOMER = 'constants/customer/RESET_UPDATE_CUSTOMER';

export const CREATE_CUSTOMER = 'constants/customer/CREATE_CUSTOMER';
export const CREATE_CUSTOMER_SUCCESS = 'constants/customer/CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_ERROR = 'constants/customer/CREATE_CUSTOMER_ERROR';
export const RESET_CREATE_CUSTOMER = 'constants/customer/RESET_CREATE_CUSTOMER';

export const GET_CUSTOMER_DETAIL = 'customer/GET_CUSTOMER_DETAIL';
export const CUSTOMER_DETAIL_SUCCESS = 'customer/CUSTOMER_DETAIL_SUCCESS';
export const CUSTOMER_DETAIL_ERROR = 'customer/CUSTOMER_DETAIL_ERROR';
