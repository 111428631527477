import { call, all, put, takeLatest } from "redux-saga/effects";
import { authHeader } from "../../../utils";
import request from "../../../services/request";
import * as ActionTypes from "../../actions/constants";
import {
  fetchMaintenanceComponentSuccess,
  fetchMaintenanceComponentError,
  fetchMaintenanceComponentByIdSuccess,
  fetchMaintenanceComponentByIdError,
  createMaintenanceComponentSuccess,
  createMaintenanceComponentError,
  updateMaintenanceComponentSuccess,
  updateMaintenanceComponentError,
} from "../../actions/vehicle";

function* maintenanceSaga({ data }) {
  try {
    //let maintenanceData = ((typeof(data.maintenance) !== 'undefined')&&(data.maintenance !== null))?data.maintenance:null;
    const requestURL = "data/core/vehicle_maintenance_component/query";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data,
    });
    yield put(fetchMaintenanceComponentSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(fetchMaintenanceComponentError(error));
    }
  }
}

function* indexSaga({ data }) {
  try {
    //let maintenanceData = ((typeof(data.maintenance) !== 'undefined')&&(data.maintenance !== null))?data.maintenance:null;
    const requestURL = "data/core/vehicle_maintenance_component/query";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data,
    });
    yield put(fetchMaintenanceComponentByIdSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(fetchMaintenanceComponentByIdError(error));
    }
  }
}

function* updateSaga({ data }) {
  try {
    let id =
      typeof data.id !== "undefined" && data.id !== null ? data.id : null;
    let maintenanceData =
      typeof data.maintenance !== "undefined" && data.maintenance !== null
        ? data.maintenance
        : null;
    const requestURL =
      "data/core/vehicle_maintenance_component/" + id + "/transitions/modify";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: maintenanceData,
    });
    yield put(updateMaintenanceComponentSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(updateMaintenanceComponentError(error));
    }
  }
}

function* createSaga({ data }) {
  try {
    let maintenanceData =
      typeof data.maintenance !== "undefined" && data.maintenance !== null
        ? data.maintenance
        : null;
    const requestURL =
      "data/core/vehicle_maintenance_component/transitions/add";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: maintenanceData,
    });
    yield put(createMaintenanceComponentSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(createMaintenanceComponentError(error));
    }
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(ActionTypes.GET_MAINTENANCE_COMPONENT, maintenanceSaga),
    yield takeLatest(ActionTypes.GET_MAINTENANCE_COMPONENT_BY_ID, indexSaga),
    yield takeLatest(ActionTypes.CREATE_MAINTENANCE_COMPONENT, createSaga),
    yield takeLatest(ActionTypes.UPDATE_MAINTENANCE_COMPONENT, updateSaga),
  ]);
}
