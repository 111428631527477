import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import ResetPassword from "../Customer/ResetPasswordScreen";
import Settings from "../Customer/SettingScreen";

import CompanyUser from "../Company/UserScreen";
import CompanyUserAdd from "../Company/UserAddScreen";
import CompanyUserEdit from "../Company/UserEditScreen";
import Customer from "../Customer/CustomerScreen";

import CustomerDetailScreen from "../Customer/CustomerDetailScreen";
import CustomerEdit from "../Customer/CustomerEditScreen";
import CustomerAdd from "../Customer/CustomerAddScreen";
import ClientAdd from "../Client/ClientAddScreen";
import ClientEdit from "../Client/ClientEditScreen";

import VehicleListing from "../Vehicle/VehicleScreen";
import VehicleDetail from "../Vehicle/VehicleDetailScreen";
import VehicleEdit from "../Vehicle/VehicleEditScreen";
import VehicleEditOwner from "../Vehicle/VehicleEditOwnerScreen";
import VehicleAdd from "../Vehicle/VehicleAddScreen";

import VehicleNoteAdd from "../Vehicle/VehicleNoteAddScreen";
import VehicleNoteGeneralEdit from "../Vehicle/VehicleGeneralNoteEditScreen";
import VehicleNoteInternalEdit from "../Vehicle/VehicleInternalNoteEditScreen";

import VehicleNote from "../Vehicle/VehicleNoteScreen";

import VehicleDocumentDetail from "../Vehicle/VehicleDocumentDetailScreen";

import MaintenanceListing from "../Vehicle/MaintenanceScreen";
import MaintenanceDetailScreen from "../Vehicle/MaintenanceDetailScreen";
import MaintenanceEdit from "../Vehicle/MaintenanceEditScreen";
import MaintenanceAdd from "../Vehicle/MaintenanceAddScreen";

import CustomerNoteListing from "../Customer/CustomerNoteScreen";
import CustomerNoteAdd from "../Customer/CustomerNoteAddScreen";
import CustomerNoteEdit from "../Customer/CustomerNoteEditScreen";

import MaintenanceNoteAdd from "../Vehicle/MaintenanceNoteAddScreen";
import MaintenanceNoteEdit from "../Vehicle/MaintenanceNoteEditScreen";
import MaintenanceNoteListing from "../Vehicle/MaintenanceNoteScreen";

import MaintenanceComponentAdd from "../Vehicle/MaintenanceComponentAddScreen";
import MaintenanceComponentEdit from "../Vehicle/MaintenanceComponentEditScreen";
import MaintenanceComponentListing from "../Vehicle/MaintenanceComponentScreen";
import MaintenanceComponentDetail from "../Vehicle/MaintenanceComponentDetailScreen";

import VehicleDocument from "../Vehicle/VehicleDocumentScreen";
import VehicleDocumentAdd from "../Vehicle/VehicleDocumentAddScreen";
import VehicleDocumentEdit from "../Vehicle/VehicleDocumentEditScreen";

import Search from "../Search/SearchByScreen";
import SearchCustomer from "../Search/CustomerScreen";

import NotFoundPage from "../NotFoundPage";

import { connect } from "react-redux";

import {
  CORE_MODULE,
  SYSTEM_MODULE,
  VEHICLE_ENTITY,
  CUSTOMER_ENTITY,
  CUSTOMER_NOTE_ENTITY,
  VEHICLE_MAINTENANCE_NOTE_ENTITY,
  VEHICLE_NOTE_ENTITY,
  VEHICLE_MAINTENANCE_COMPONENT_ENTITY,
  VEHICLE_MAINTENANCE_ENTITY,
  VEHICLE_FILE_ENTITY,
  VEHICLE_COMPONENT_ENTITY,
  CLIENT_ENTITY,
  EDIT_PERMISSION,
  MODIFY_PERMISSION,
  INSERT_PERMISSION,
  ADMIN_MODIFY_CLIENT_PERMISSION,
  ADMIN_MODIFY_COMPANY_PERMISSION,
  CREATE_PERMISSION,
  ADD_PERMISSION,
} from "../../utils";


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updated: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.localeData) !==
      JSON.stringify(this.props.localeData)
    ) {
      this.setState({ updated: true });
    }
  }
  doSomething() {
    this.setState({ updated: false });
  }
  componentDidMount() {
    window.addEventListener("hashchange", this.doSomething, false);
  }

  componentWillUnmount() {
    window.removeEventListener("hashchange", this.doSomething, false);
  }

  checkRoleAccess(module, entity, label = "") {
    if (label !== "") {
      let transition_data =
        typeof this.props.localeData.rows !== "undefined" &&
        typeof this.props.localeData.rows.model !== "undefined" &&
        typeof this.props.localeData.rows.model[module] !== "undefined" &&
        typeof this.props.localeData.rows.model[module]["entities"][entity] !==
          "undefined" &&
        typeof this.props.localeData.rows.model[module]["entities"][entity][
          "transitions"
        ] !== "undefined" &&
        typeof this.props.localeData.rows.model[module]["entities"][entity][
          "transitions"
        ][label] !== "undefined"
          ? true
          : false;
      return transition_data;
    } else if (label === "") {
      let transition_data =
        typeof this.props.localeData.rows !== "undefined" &&
        typeof this.props.localeData.rows.model !== "undefined" &&
        typeof this.props.localeData.rows.model[module] !== "undefined" &&
        typeof this.props.localeData.rows.model[module]["entities"][entity] !==
          "undefined"
          ? true
          : false;
      return transition_data;
    }
  }

  render() {
    let routeComponents = [];
    if (this.state.updated) {
      let routes = [
        {
          path: "/admin/search",
          component: Search,
        },
        {
          path: "/admin/search-customers",
          component: SearchCustomer,
        },
        {
          path: "/admin/reset-password",
          component: ResetPassword,
        },
      ];

      if (this.checkRoleAccess(CORE_MODULE, CUSTOMER_ENTITY)) {
        let obj = { path: "/admin/customers", component: Customer };
        routes.push(obj);
      }

      if (
        this.checkRoleAccess(
          CORE_MODULE,
          CUSTOMER_ENTITY,
          ADMIN_MODIFY_CLIENT_PERMISSION
        )
      ) {
        let obj = {
          path: "/admin/customers/:id/edit",
          component: CustomerEdit,
        };
        routes.push(obj);
      }

      if (
        this.checkRoleAccess(
          CORE_MODULE,
          CUSTOMER_ENTITY,
          ADMIN_MODIFY_COMPANY_PERMISSION
        )
      ) {
        let obj = {
          path: "/admin/customers/:id/edit",
          component: CustomerEdit,
        };
        routes.push(obj);
      }

      if (
        this.checkRoleAccess(CORE_MODULE, CUSTOMER_ENTITY, CREATE_PERMISSION)
      ) {
        let obj = { path: "/admin/customers/add", component: CustomerAdd };
        routes.push(obj);
      }

      if (this.checkRoleAccess(CORE_MODULE, CUSTOMER_ENTITY)) {
        let obj = {
          path: "/admin/customers/:id",
          component: CustomerDetailScreen,
        };
        routes.push(obj);
      }

      if (this.checkRoleAccess(CORE_MODULE, VEHICLE_ENTITY)) {
        let obj = { path: "/admin/vehicles", component: VehicleListing };
        routes.push(obj);
        obj = {
          path: "/admin/customers/:cid/vehicles",
          component: VehicleListing,
        };
        routes.push(obj);
      }

      if (this.checkRoleAccess(CORE_MODULE, VEHICLE_ENTITY)) {
        let obj = {
          path: "/admin/vehicles/view/:id",
          component: VehicleDetail,
        };
        routes.push(obj);
        obj = {
          path: "/admin/customers/:cid/vehicles/view/:id",
          component: VehicleDetail,
        };
        routes.push(obj);
      }

      if (
        this.checkRoleAccess(CORE_MODULE, VEHICLE_ENTITY, MODIFY_PERMISSION)
      ) {
        let obj = { path: "/admin/vehicles/:id/edit", component: VehicleEdit };
        routes.push(obj);
        obj = {
          path: "/admin/customers/:cid/vehicles/:id/edit",
          component: VehicleEdit,
        };
        routes.push(obj);
      }

      if (
        this.checkRoleAccess(CORE_MODULE, VEHICLE_ENTITY, INSERT_PERMISSION)
      ) {
        let obj = { path: "/admin/vehicles/add", component: VehicleAdd };
        routes.push(obj);
        obj = {
          path: "/admin/customers/:cid/vehicles/add",
          component: VehicleAdd,
        };
        routes.push(obj);
        obj = {
          path: "/admin/vehicles/:vid/edit-owner",
          component: VehicleEditOwner,
        };
        routes.push(obj);
      }

      if (this.checkRoleAccess(CORE_MODULE, CUSTOMER_NOTE_ENTITY)) {
        let obj = {
          path: "/admin/customers/:cid/notes",
          component: CustomerNoteListing,
        };
        routes.push(obj);
      }

      if (
        this.checkRoleAccess(CORE_MODULE, CUSTOMER_NOTE_ENTITY, EDIT_PERMISSION)
      ) {
        let obj = {
          path: "/admin/customers/:cid/notes/:id/edit",
          component: CustomerNoteEdit,
        };
        routes.push(obj);
      }

      if (
        this.checkRoleAccess(
          CORE_MODULE,
          CUSTOMER_NOTE_ENTITY,
          CREATE_PERMISSION
        )
      ) {
        let obj = {
          path: "/admin/customers/:cid/notes/add",
          component: CustomerNoteAdd,
        };
        routes.push(obj);
      }

      if (
        this.checkRoleAccess(
          CORE_MODULE,
          CUSTOMER_NOTE_ENTITY,
          CREATE_PERMISSION
        )
      ) {
        let obj = {
          path: "/admin/customers/:cid/notes/add",
          component: CustomerNoteAdd,
        };
        routes.push(obj);
      }

      if (
        this.checkRoleAccess(
          CORE_MODULE,
          VEHICLE_MAINTENANCE_NOTE_ENTITY,
          CREATE_PERMISSION
        )
      ) {
        let obj = {
          path: "/admin/maintenance/:mid/notes/add",
          component: MaintenanceNoteAdd,
        };
        routes.push(obj);
        obj = {
          path: "/admin/maintenance-notes/add",
          component: MaintenanceNoteAdd,
        };
        routes.push(obj);
      }

      if (
        this.checkRoleAccess(
          CORE_MODULE,
          VEHICLE_MAINTENANCE_NOTE_ENTITY,
          EDIT_PERMISSION
        )
      ) {
        let obj = {
          path: "/admin/maintenance/:mid/notes/:id/edit",
          component: MaintenanceNoteEdit,
        };
        routes.push(obj);
      }

      if (this.checkRoleAccess(CORE_MODULE, VEHICLE_MAINTENANCE_NOTE_ENTITY)) {
        let obj = {
          path: "/admin/maintenance/:mid/notes",
          component: MaintenanceNoteListing,
        };
        routes.push(obj);
      }

      if (
        this.checkRoleAccess(CORE_MODULE, VEHICLE_MAINTENANCE_COMPONENT_ENTITY)
      ) {
        let obj = {
          path: "/admin/maintenance/:mid/components",
          component: MaintenanceComponentListing,
        };
        routes.push(obj);
      }

      if (
        this.checkRoleAccess(CORE_MODULE, VEHICLE_MAINTENANCE_COMPONENT_ENTITY)
      ) {
        let obj = {
          path: "/admin/maintenance/:mid/components/view/:id",
          component: MaintenanceComponentDetail,
        };
        routes.push(obj);
      }

      if (
        this.checkRoleAccess(
          CORE_MODULE,
          VEHICLE_MAINTENANCE_COMPONENT_ENTITY,
          ADD_PERMISSION
        )
      ) {
        let obj = {
          path: "/admin/maintenance/:mid/components/add",
          component: MaintenanceComponentAdd,
        };
        routes.push(obj);
      }

      if (
        this.checkRoleAccess(
          CORE_MODULE,
          VEHICLE_MAINTENANCE_COMPONENT_ENTITY,
          MODIFY_PERMISSION
        )
      ) {
        let obj = {
          path: "/admin/maintenance/:mid/components/:id/edit",
          component: MaintenanceComponentEdit,
        };
        routes.push(obj);
      }

      if (this.checkRoleAccess(CORE_MODULE, VEHICLE_MAINTENANCE_ENTITY)) {
        let obj = { path: "/admin/maintenance", component: MaintenanceListing };
        routes.push(obj);
        obj = {
          path: "/admin/customers/:cid/maintenance",
          component: MaintenanceListing,
        };
        routes.push(obj);
        obj = {
          path: "/admin/vehicles/:vid/maintenance",
          component: MaintenanceListing,
        };
        routes.push(obj);
      }

      if (
        this.checkRoleAccess(
          CORE_MODULE,
          VEHICLE_MAINTENANCE_ENTITY,
          MODIFY_PERMISSION
        )
      ) {
        let obj = {
          path: "/admin/maintenance/:id/edit",
          component: MaintenanceEdit,
        };
        routes.push(obj);
      }

      if (
        this.checkRoleAccess(
          CORE_MODULE,
          VEHICLE_MAINTENANCE_ENTITY,
          ADD_PERMISSION
        )
      ) {
        let obj = {
          path: "/admin/maintenance/add",
          component: MaintenanceAdd,
          exact: true,
        };
        routes.push(obj);
        obj = {
          path: "/admin/vehicles/:vid/maintenance/add",
          component: MaintenanceAdd,
        };
        routes.push(obj);
      }

      if (this.checkRoleAccess(CORE_MODULE, VEHICLE_MAINTENANCE_ENTITY)) {
        let obj = {
          path: "/admin/maintenance/view/:id",
          component: MaintenanceDetailScreen,
        };
        routes.push(obj);
      }

      if (
        this.checkRoleAccess(
          CORE_MODULE,
          VEHICLE_NOTE_ENTITY,
          CREATE_PERMISSION
        )
      ) {
        let obj = {
          path: "/admin/vehicles/:vid/vehicle-notes/add/:notetype",
          component: VehicleNoteAdd,
        };
        routes.push(obj);
      }

      if (
        this.checkRoleAccess(CORE_MODULE, VEHICLE_NOTE_ENTITY, EDIT_PERMISSION)
      ) {
        let obj = {
          path: "/admin/vehicles/:vid/vehicle-notes-general/:id/edit",
          component: VehicleNoteGeneralEdit,
        };
        routes.push(obj);
      }

      if (
        this.checkRoleAccess(CORE_MODULE, VEHICLE_NOTE_ENTITY, EDIT_PERMISSION)
      ) {
        let obj = {
          path: "/admin/vehicles/:vid/vehicle-notes-internal/:id/edit",
          component: VehicleNoteInternalEdit,
        };
        routes.push(obj);
      }

      if (
        this.checkRoleAccess(CORE_MODULE, VEHICLE_NOTE_ENTITY, EDIT_PERMISSION)
      ) {
        let obj = {
          path: "/admin/vehicles/:vid/vehicle-notes",
          component: VehicleNote,
        };
        routes.push(obj);
      }

      if (this.checkRoleAccess(CORE_MODULE, VEHICLE_FILE_ENTITY)) {
        let obj = {
          path: "/admin/vehicles/:vid/documents/view/:id",
          component: VehicleDocumentDetail,
        };
        routes.push(obj);
      }

      if (this.checkRoleAccess(CORE_MODULE, VEHICLE_FILE_ENTITY)) {
        let obj = {
          path: "/admin/vehicles/:vid/documents",
          component: VehicleDocument,
        };
        routes.push(obj);
      }

      if (
        this.checkRoleAccess(CORE_MODULE, VEHICLE_FILE_ENTITY, EDIT_PERMISSION)
      ) {
        let obj = {
          path: "/admin/vehicles/:vid/documents/:id/edit",
          component: VehicleDocumentEdit,
        };
        routes.push(obj);
      }

      if (
        this.checkRoleAccess(
          CORE_MODULE,
          VEHICLE_FILE_ENTITY,
          CREATE_PERMISSION
        )
      ) {
        let obj = {
          path: "/admin/vehicles/:vid/documents/add",
          component: VehicleDocumentAdd,
        };
        routes.push(obj);
      }

      if (
        this.checkRoleAccess(SYSTEM_MODULE, CLIENT_ENTITY, CREATE_PERMISSION)
      ) {
        let obj = { path: "/admin/users/add", component: ClientAdd };
        routes.push(obj);
      }

      if (this.checkRoleAccess(SYSTEM_MODULE, CLIENT_ENTITY, EDIT_PERMISSION)) {
        let obj = { path: "/admin/users/:id/edit", component: ClientEdit };
        routes.push(obj);
      }

      if (
        this.checkRoleAccess(
          CORE_MODULE,
          CUSTOMER_ENTITY,
          ADMIN_MODIFY_COMPANY_PERMISSION
        )
      ) {
        let obj = { path: "/admin/company/:cid/users", component: CompanyUser };
        routes.push(obj);
      }

      if (
        this.checkRoleAccess(
          CORE_MODULE,
          CUSTOMER_ENTITY,
          ADMIN_MODIFY_COMPANY_PERMISSION
        )
      ) {
        let obj = {
          path: "/admin/company/:cid/users/add",
          component: CompanyUserAdd,
        };
        routes.push(obj);
      }

      if (
        this.checkRoleAccess(
          CORE_MODULE,
          CUSTOMER_ENTITY,
          ADMIN_MODIFY_COMPANY_PERMISSION
        )
      ) {
        let obj = {
          path: "/admin/company/:cid/users/:id/edit",
          component: CompanyUserEdit,
        };
        routes.push(obj);
      }

      if (this.checkRoleAccess(CORE_MODULE, VEHICLE_COMPONENT_ENTITY, CREATE_PERMISSION)) {
        let obj = { path: "/admin/settings", component: Settings };
        routes.push(obj);
      }

      routeComponents = routes.map(({ path, component, exact }, key) => (
        <Route exact path={path} component={component} key={key} />
      ));
    }
    return (
      <>
        {this.state.updated === true ? (
          <Switch>
            {routeComponents}
            <Route component={NotFoundPage} />
          </Switch>
        ) : (
          ""
        )}
      </>
    );
  }
}

Dashboard.propTypes = {};

Dashboard.defaultProps = {};

const mapStateToProps = (state) => ({
  localeData: state.Locale.localeData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
