import React from "react";
import { Route, Redirect } from "react-router-dom";
import { authHeader } from "../../utils";
import DefaultLayout from "../../containers/_layouts/default";

const PrivateRoute = ({
  component: Component,
  layout: Layout = DefaultLayout,
  ...rest
}) => {
  if (authHeader() !== "") {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    );
  }
  return <Redirect push to="/login" />;
};
export default PrivateRoute;
