import React, { Component } from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  PagingOptions,
  NoDataIndication,
  DelBtn,
  EditBtn,
  downloadFile,
  NO_DATA,
} from "../../utils";
import { fetchCompanyUser } from "../../store/actions/company";
import { showDeleteDialog } from "../../store/actions/dialog";
import { Link } from "react-router-dom";
import { fetchCustomer } from "../../store/actions/customer";

class UserScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          dataField: "assigned_client.id",
          text: "ID",
          hidden: true,
        },
        {
          dataField: "assigned_client.first_name",
          text: "Vorname",
          sort: true,
          headerClasses: "cell-header cell-user-firstname-header",
          classes: "cell-user-firstname",
        },
        {
          dataField: "assigned_client.last_name",
          text: "Familienname",
          sort: true,
          headerClasses: "cell-header cell-user-lastname-header",
          classes: "cell-user-lastname",
        },
        {
          dataField: "assigned_client.email",
          text: "Email",
          sort: true,
          headerClasses: "cell-header cell-user-email-header",
          classes: "cell-user-email",
        },
        {
          dataField: "id",
          text: "",
          sort: false,
          headerClasses:
            "cell-header cell-actions cell-actions-header cell-user-actions-header",
          classes: "cell-user-actions",
          formatter: (cellContent, row) => {
            return (
              <div className="svg">
                <Link
                  to={"#"}
                  onClick={() => {
                    this.editHandler(row);
                  }}
                >
                  <EditBtn />
                </Link>
                <Link
                  to={"#"}
                  onClick={() => {
                    this.deleteHandler(row);
                  }}
                >
                  <DelBtn />
                </Link>
              </div>
            );
          },
        },
      ],
      sort: [
        {
          dataField: "id",
          order: "desc",
        },
      ],
      delete_id: "",
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
    this.editHandler = this.editHandler.bind(this);
    this.addHandler = this.addHandler.bind(this);
  }

  componentDidMount() {
    let defaultOrder = this.state.sort;
    let sortOrderstring = "-" + defaultOrder[0]["order"];
    let data = {};
    data.attrs = {};
    data.attrs["order_by"] = {};
    data.attrs["prefetch"] = ["assigned_client"];
    data.attrs.order_by[sortOrderstring] = "me." + defaultOrder[0]["dataField"];
    data.conds = { binding_instance_of_customer: this.props.match.params.cid };
    data.page = 1;
    data.page_size = PagingOptions.sizePerPage;
    this.props.dispatch(fetchCompanyUser(data));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.customerData) !==
      JSON.stringify(this.props.customerData)
    ) {
      let customer_data =
        typeof this.props.customerData.rows !== "undefined" &&
        typeof this.props.customerData.rows.data[0] !== "undefined"
          ? this.props.customerData.rows.data[0]
          : null;
      // let salutation =
      //   customer_data !== null &&
      //   typeof customer_data.salutation !== "undefined"
      //     ? customer_data.salutation
      //     : "";
      let customer_id =
        customer_data !== null && typeof customer_data.id !== "undefined"
          ? customer_data.id
          : "";
      this.setState({ delete_id: customer_id });
      //console.log(this.state.delete_id);
    }
  }

  handleTableChange(
    type,
    { filters, page, sortField, sortOrder, sizePerPage }
  ) {
    let defaultOrder = this.state.sort;
    let sortOrderstring =
      typeof sortOrder !== "undefined"
        ? "-" + sortOrder
        : "-" + defaultOrder[0]["order"];
    let data = {};
    data.attrs = {};
    data.attrs["order_by"] = {};
    data.attrs["prefetch"] = ["assigned_client"];
    data.attrs.order_by[sortOrderstring] =
      sortField !== null ? sortField : defaultOrder[0]["dataField"];
    // data.auto_ident_depth = 2;
    // data.conds = {};
    data.conds = { binding_instance_of_customer: this.props.match.params.cid };
    data.page = page;
    data.page_size = sizePerPage;
    console.log(data);
    this.props.dispatch(fetchCompanyUser(data));
  }
  downloadAttachment(url, mimeType, filename) {
    downloadFile(url, filename, mimeType);
  }
  editHandler(row) {
    let url =
      "/admin/company/" +
      this.props.match.params.cid +
      "/users/" +
      row.assigned_client.id +
      "/edit";
    this.props.history.push(url);
  }

  deleteHandler(row) {
    let rowId = row.id;

    let company_id =
      row !== null &&
      typeof row.binding_instance_of_customer !== "undefined" &&
      row.binding_instance_of_customer !== null
        ? row.binding_instance_of_customer
        : null;
    let client_customer_id = row.assigned_client.id;
    this.setState({ delete_id: "" });
    if (client_customer_id !== null && company_id !== null) {
      if (client_customer_id !== "") {
        let data = {};
        data.conds = { owning_client: client_customer_id };
        data.attrs = {};
        data.attrs["prefetch"] = ["owning_client"];
        this.props.dispatch(fetchCustomer(data));
      }
      if (this.state.delete_id !== "") {
        let rowData = { post: {} };
        rowData["post"]["client_customer_id"] = this.state.delete_id;
        rowData["post"]["company_id"] = company_id;
        rowData["url"] = "/plugins/client_company_leave";
        rowData["redirect_to"] = "/admin/company/" + company_id + "/users";
        if (row !== null && typeof rowId !== "undefined") {
          this.props.dispatch(showDeleteDialog(rowData));
        }
      }
    }
  }

  addHandler() {
    let url = "/admin/company/" + this.props.match.params.cid + "/users/add";
    this.props.history.push(url);
  }

  render() {
    let options = PagingOptions;
    options.totalSize =
      typeof this.props.companyData !== "undefined" &&
      typeof this.props.companyData.rows !== "undefined" &&
      typeof this.props.companyData.rows.meta !== "undefined" &&
      typeof this.props.companyData.rows.meta.filtered !== "undefined"
        ? this.props.companyData.rows.meta.filtered
        : "";
    options.sizePerPage =
      typeof this.props.companyData !== "undefined" &&
      typeof this.props.companyData.rows !== "undefined" &&
      typeof this.props.companyData.rows.meta !== "undefined" &&
      typeof this.props.companyData.rows.meta.page_size != "undefined"
        ? this.props.companyData.rows.meta.page_size
        : 10;
    let data =
      typeof this.props.companyData !== "undefined" &&
      typeof this.props.companyData.rows !== "undefined" &&
      typeof this.props.companyData.rows.data !== "undefined"
        ? this.props.companyData.rows.data
        : [];

    return (
      <div className="wrapper_datatable">
        <div className="table_main">
          <h4>Company Users</h4>
          <button
            type="button"
            className="btn btn-dark"
            onClick={() => this.addHandler()}
          >
            neuen Benutzer anlegen
          </button>
        </div>
        <BootstrapTable
          remote={{ pagination: true }}
          bootstrap4
          keyField="id"
          data={data}
          columns={this.state.columns}
          bordered={false}
          rowClasses={"table-row"}
          defaultSorted={this.state.sorted}
          pagination={paginationFactory(options)}
          onTableChange={this.handleTableChange}
          noDataIndication={() =>
            this.props.companyData.loading ? <NoDataIndication /> : NO_DATA
          }
        />
      </div>
    );
  }
}

UserScreen.propTypes = {};
UserScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  companyData: state.CUser.companyData,
  customerData: state.Customer.customerData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(mapStateToProps, mapDispatchToProps)(UserScreen);
