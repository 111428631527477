import * as Types from "../constants/types";

export function deleteRow(data) {
  return {
    type: Types.DELETE_ROW,
    data,
  };
}

export function deleteSuccess(response) {
  return {
    type: Types.DELETE_SUCCESS,
    response,
  };
}

export function deleteError(error) {
  return {
    type: Types.DELETE_ERROR,
    error,
  };
}

export function deleteReset() {
  return {
    type: Types.DELETE_RESET,
  };
}

export function setDeleteData(data) {
  return {
    type: Types.DELETE_DATA,
    data,
  };
}
