import { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import {
  fetchCompanyUser,
  updateCompanyUser,
  resetUpdateCompanyUser,
} from "../../store/actions/company";
import { fetchCustomer } from "../../store/actions/customer";
import { SYSTEM_MODULE, CLIENT_ENTITY } from "../../utils";
import ColumnText from "../../components/Column";

import {
  inputErrorHandler,
  signalTransitionSuccess,
  signalTransitionError,
  removeEmptyValues,
  getSalutaions,
} from "../../utils";

class UserEditScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        client_id: "",
        customer_id: "",
        salutation: "",
        first_name: "",
        last_name: "",
        email: "",
      },
      errors: {
        salutation: "",
        first_name: "",
        last_name: "",
        email: "",
      },
      reset: {
        fields: {
          client_id: "",
          customer_id: "",
          salutation: "",
          first_name: "",
          last_name: "",
          email: "",
        },
        errors: {
          salutation: "",
          first_name: "",
          last_name: "",
          email: "",
        },
      },
    };
  }

  componentDidMount() {
    let data = {};
    data.attrs = {};
    data.attrs["prefetch"] = ["assigned_client"];
    data.conds = { "assigned_client.id": this.props.match.params.id };

    this.props.dispatch(fetchCompanyUser(data));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.clientData) !==
      JSON.stringify(this.props.clientData)
    ) {
      let customer = {};
      let binding_data =
        typeof this.props.clientData.rows !== "undefined" &&
        typeof this.props.clientData.rows.data[0] !== "undefined"
          ? this.props.clientData.rows.data[0]
          : null;
      let client_data =
        binding_data !== null &&
        typeof binding_data.assigned_client !== "undefined" &&
        binding_data.assigned_client !== null
          ? binding_data.assigned_client
          : null;
      let customer_data =
        binding_data !== null &&
        typeof binding_data.binding_instance_of_customer !== "undefined" &&
        binding_data.binding_instance_of_customer !== null
          ? binding_data.binding_instance_of_customer
          : null;

      let id =
        client_data !== null && typeof client_data.id !== "undefined"
          ? client_data.id
          : "";

      let email =
        client_data !== null && typeof client_data.email !== "undefined"
          ? client_data.email
          : "";
      let first_name =
        client_data !== null && typeof client_data.first_name !== "undefined"
          ? client_data.first_name
          : "";
      let last_name =
        client_data !== null && typeof client_data.last_name !== "undefined"
          ? client_data.last_name
          : "";
      // let salutation =
      //   customer_data !== null &&
      //     typeof customer_data.salutation !== "undefined"
      //     ? customer_data.salutation
      //     : "";
      let customer_id =
        customer_data !== null && typeof customer_data.id !== "undefined"
          ? customer_data.id
          : "";
      customer["client_id"] = id;
      customer["customer_id"] = customer_id;
      // customer["salutation"] = salutation;
      customer["first_name"] = first_name;
      customer["last_name"] = last_name;
      customer["email"] = email;

      this.setState({ fields: customer });
      let reset = this.state.reset;
      reset.fields = customer;
      this.setState({ reset: reset });

      if (id !== "") {
        let data = {};
        data.conds = { owning_client: id };
        data.attrs = {};
        data.attrs["prefetch"] = ["owning_client"];
        this.props.dispatch(fetchCustomer(data));
      }
    }
    if (
      JSON.stringify(prevProps.customerData) !==
      JSON.stringify(this.props.customerData)
    ) {
      let customer_data =
        typeof this.props.customerData.rows !== "undefined" &&
        typeof this.props.customerData.rows.data[0] !== "undefined"
          ? this.props.customerData.rows.data[0]
          : null;
      let salutation =
        customer_data !== null &&
        typeof customer_data.salutation !== "undefined"
          ? customer_data.salutation
          : "";
      let customer_id =
        customer_data !== null && typeof customer_data.id !== "undefined"
          ? customer_data.id
          : "";

      let fields = this.state.fields;
      fields.salutation = salutation;
      fields.customer_id = customer_id;
      this.setState({ fields: fields });
      let reset = this.state.reset;
      reset.fields = fields;
      this.setState({ reset: reset });
    }

    if (this.props.updateData.success) {
      signalTransitionSuccess("Erfolg bearbeiten");
      this.props.dispatch(resetUpdateCompanyUser());
      let url = "/admin/company/" + this.props.match.params.cid + "/users";
      this.props.history.push(url);
    } else if (this.props.updateData.error) {
      this.props.dispatch(resetUpdateCompanyUser());
      signalTransitionError("Bearbeitung fehlgeschlagen");
    }
  }

  resetHandler(evt) {
    this.setState({ fields: this.state.reset.fields });
    this.setState({ errors: this.state.reset.errors });
  }

  submitHandler(evt) {
    evt.preventDefault();
    // get our new errors
    let fields = this.state.fields;

    let newErrors = {};
    newErrors["first_name"] = inputErrorHandler("", fields.first_name, true);
    newErrors["last_name"] = inputErrorHandler("", fields.last_name, true);
    newErrors["email"] = inputErrorHandler("", fields.email, true);
    // newErrors["salutation"] = inputErrorHandler("", fields.salutation, true);

    let errors = removeEmptyValues(newErrors);
    console.log(errors);

    // Conditional logic:
    if (Object.keys(errors).length > 0) {
      this.setState({ errors: errors });
    } else {
      let ClientData = {
        client: {},
        client_id: "",
      };
      ClientData.client_id = fields.client_id;

      ClientData.client.email = fields.email;
      ClientData.client.first_name = fields.first_name;
      ClientData.client.last_name = fields.last_name;
      // ClientData.customer.salutation = fields.salutation;

      this.props.dispatch(updateCompanyUser(ClientData));
    }
  }

  changeHandler(evt, name) {
    let value = evt.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: null,
      },
    }));
  }

  render() {
    // let salutations = getSalutaions();
    return (
      <div className="wrapper">
        <div className="main d-flex align-items-start flex-column">
          <h4>Benutzer bearbeiten</h4>
        </div>
        <div className="sub_main">
          <Form>
            <Row>
              <Col lg={6}>
                <Form.Group as={Row} controlId="email">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={SYSTEM_MODULE}
                      Entity={CLIENT_ENTITY}
                      Label={"email"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={this.state.fields.email}
                      onChange={(evt) => this.changeHandler(evt, "email")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.email}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                {/* <Form.Group as={Row} controlId="salutation">
                  <Form.Label column sm="4">
                    Anrede
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      as="select"
                      placeholder="Anrede"
                      name="salutation"
                      value={this.state.fields.salutation}
                      onChange={(evt) => this.changeHandler(evt, "salutation")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.salutation}
                    >
                      <option value="">Anrede</option>
                      {salutations.map((item) => (
                        <option
                          value={item.value}
                          selected={
                            this.state.fields.salutation === item.value
                              ? true
                              : false
                          }
                        >
                          {item.text}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.salutation}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group> */}
                <Form.Group as={Row} controlId="first_name">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={SYSTEM_MODULE}
                      Entity={CLIENT_ENTITY}
                      Label={"first_name"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Vorname"
                      name="first_name"
                      value={this.state.fields.first_name}
                      onChange={(evt) => this.changeHandler(evt, "first_name")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.first_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.first_name}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="last_name">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={SYSTEM_MODULE}
                      Entity={CLIENT_ENTITY}
                      Label={"last_name"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Familienname"
                      name="last_name"
                      value={this.state.fields.last_name}
                      onChange={(evt) => this.changeHandler(evt, "last_name")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.last_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.last_name}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <div className="form-btn">
                  <Button
                    type="Button"
                    className="btn btn-dark Button"
                    onClick={(evt) => this.submitHandler(evt)}
                  >
                    OK
                  </Button>
                  <Button
                    type="Button"
                    className="btn btn-dark"
                    onClick={(evt) => this.resetHandler(evt)}
                  >
                    Abbrechen
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

UserEditScreen.propTypes = {};
UserEditScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  customerData: state.Customer.customerData,
  localeData: state.Locale.localeData,
  updateData: state.CUser.updateData,
  clientData: state.CUser.companyData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(mapStateToProps, mapDispatchToProps)(UserEditScreen);
