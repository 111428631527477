import { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import AutoRecommend from "../../components/AutoSuggest";

import {
  fetchVehicleMaintenance,
  fetchMaintenanceNote,
  resetUpdateMaintenanceNote,
  updateMaintenanceNote,
} from "../../store/actions/vehicle";
import { showAlertDialog } from "../../store/actions/dialog";
import {
  inputErrorHandler,
  signalTransitionSuccess,
  signalTransitionError,
  removeEmptyValues,
  getFileContent,
  checkFileType,
  getMaxBase64StringSize,
  FILE_TOO_LARGE_MESSAGE,
  FILE_NAME_SEPARATOR,
  FILE_TYPE_MESSAGE,
  CORE_MODULE,
  VEHICLE_MAINTENANCE_NOTE_ENTITY,
} from "../../utils";
import bsCustomFileInput from "bs-custom-file-input";
import ColumnText from "../../components/Column";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "font-awesome/css/font-awesome.css";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

class MaintenanceNoteEditScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        maintenance_id: "",
        maintenance_name: "",
        note: "",
        files: "",
      },

      errors: {
        maintenance_id: "",
        note: "",
        files: "",
      },
      reset: {
        fields: {
          maintenance_id: "",
          maintenance_name: "",
          note: "",
          files: "",
        },
        errors: {
          maintenance_id: "",
          note: "",
          files: "",
        },
      },
      fileName: "No File Choosen",
      defaultName: "No File Choosen",
      value: "",
      suggestions: [],
    };
    this.datePickerHandler = this.datePickerHandler.bind(this);
    this.changeFileHandler = this.changeFileHandler.bind(this);
  }

  componentDidMount() {
    bsCustomFileInput.init();
    let vdata = {};
    vdata.attrs = {};
    vdata.attrs["prefetch"] = ["owning_customer", "vehicle"];
    vdata.conds = {};
    this.props.dispatch(fetchVehicleMaintenance(vdata));
    let mdata = {};
    mdata.attrs = {};
    mdata.attrs["prefetch"] = [{ vehicle_maintenance: "vehicle" }];
    mdata.conds = { "me.id": this.props.match.params.id };
    this.props.dispatch(fetchMaintenanceNote(mdata));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.maintenanceData) !==
      JSON.stringify(this.props.maintenanceData)
    ) {
      let maintenances =
        typeof this.props.maintenanceData !== "undefined" &&
        typeof this.props.maintenanceData.rows !== "undefined" &&
        typeof this.props.maintenanceData.rows.data !== "undefined"
          ? this.props.maintenanceData.rows.data
          : [];
      let data = [];
      let suggestions = this.state.suggestions;
      for (let i = 0; i < suggestions.length; i++) {
        let obj = {};
        obj.id = suggestions.id;
        obj.name = suggestions.name;
        data.push(obj);
      }
      for (let i = 0; i < maintenances.length; i++) {
        let obj = {};
        obj.id = maintenances[i].id;
        obj.name = maintenances[i].vehicle.vin;
        data.push(obj);
      }
      this.setState({ suggestions: data });
    }
    if (
      JSON.stringify(prevProps.indexData) !==
      JSON.stringify(this.props.indexData)
    ) {
      let maintenance_data =
        typeof this.props.indexData.rows.data[0] !== "undefined"
          ? this.props.indexData.rows.data[0]
          : null;
      let vehicle_maintenace =
        maintenance_data !== null &&
        typeof maintenance_data.vehicle_maintenance !== "undefined"
          ? maintenance_data.vehicle_maintenance
          : null;
      let vehicle_data =
        vehicle_maintenace !== null &&
        typeof vehicle_maintenace.vehicle !== "undefined"
          ? vehicle_maintenace.vehicle
          : null;

      let mnt_id =
        vehicle_maintenace !== null &&
        typeof vehicle_maintenace.id !== "undefined"
          ? vehicle_maintenace.id
          : "";
      let note =
        maintenance_data !== null &&
        typeof maintenance_data.content !== "undefined"
          ? maintenance_data.content
          : "";
      let maintenance_name =
        vehicle_data !== null && typeof vehicle_data.vin !== "undefined"
          ? vehicle_data.vin
          : "";
      let attachment =
        maintenance_data !== null &&
        typeof maintenance_data.attachment !== "undefined"
          ? JSON.parse(maintenance_data.attachment)
          : "";
      let fileName =
        attachment !== null &&
        typeof attachment !== "undefined" &&
        typeof attachment.original_name !== "undefined"
          ? attachment.original_name
          : "";

      let mnt = {};
      mnt["maintenance_id"] = mnt_id;
      mnt["maintenance_name"] = maintenance_name;
      mnt["note"] = note;
      mnt["files"] = "";

      if (fileName) {
        this.setState({ fileName: fileName });
      }

      this.setState({ fields: mnt });
      let reset = this.state.reset;
      reset.fields = mnt;
      this.setState({ reset: reset });
      this.setState({ value: maintenance_name });
    }

    if (this.props.updateData.success) {
      this.props.dispatch(resetUpdateMaintenanceNote());
      signalTransitionSuccess("Update Success");
      let url = "/admin/maintenance/" + this.props.match.params.mid + "/notes";
      this.props.history.push(url);
    } else if (this.props.updateData.error) {
      this.props.dispatch(resetUpdateMaintenanceNote());
      signalTransitionError("Update Failed");
    }
  }

  resetHandler(evt) {
    this.setState({ fields: this.state.reset.fields });
    this.setState({ errors: this.state.reset.errors });
  }

  submitHandler(evt) {
    evt.preventDefault();
    // get our new errors

    let fields = this.state.fields;
    //console.log(fields);
    let newErrors = {};
    newErrors["maintenance_id"] = inputErrorHandler(
      "",
      fields.maintenance_id,
      true
    );
    newErrors["note"] = inputErrorHandler("", fields.note, true);
    // newErrors["files"] = inputErrorHandler("",fields.files,true);
    if (fields.maintenance_id === "" || fields.maintenance_name === "") {
      newErrors["maintenance_id"] = "Required Parameter";
    } else if (fields.maintenance_name !== this.state.value) {
      newErrors["maintenance_id"] = "Please select valid Maintenance ID";
    }
    let errors = removeEmptyValues(newErrors);
    if (Object.keys(errors).length > 0) {
      this.setState({ errors: errors });
    } else {
      let noteData = { maintenance_id: "", note: {} };

      // noteData.note.date = fields.maint_date;
      noteData.maintenance_id = this.props.match.params.id;
      if (fields.files !== "") {
        noteData.note.attachment = fields.files;
      }
      noteData.note.content = fields.note;
      noteData.note.vehicle_maintenance = fields.maintenance_id;
      //console.log(maintenanceData);
      this.props.dispatch(updateMaintenanceNote(noteData));
    }
  }

  changeHandler(evt, name) {
    let value = evt.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: null,
      },
    }));
  }

  datePickerHandler(value) {
    let items = this.state.fields;
    items["maint_date"] = value;
    this.setState({ fields: items });

    let errors = this.state.errors;
    errors["maint_date"] = "";
    this.setState({ errors: errors });
  }

  changeFileHandler(evt) {
    let errors = this.state.errors;
    errors["files"] = "";
    this.setState({ errors: errors });
    if (
      typeof evt.target.files[0] !== "undefined" &&
      typeof evt.target.files[0].name !== "undefined"
    ) {
      this.setState({ fileName: evt.target.files[0].name });
      if (checkFileType(evt.target.files)) {
        getFileContent(evt.target.files)
          .then((data) => {
            if (data.length > getMaxBase64StringSize()) {
              this.props.dispatch(showAlertDialog(FILE_TOO_LARGE_MESSAGE));
              this.setState({ fileName: this.state.defaultName });
              let fields = this.state.fields;
              fields["files"] = "";
              this.setState({ fields: fields });
            } else {
              //console.log(`${this.state.fileName}${FILE_NAME_SEPARATOR}${data}`);
              let fields = this.state.fields;
              fields[
                "files"
              ] = `${this.state.fileName}${FILE_NAME_SEPARATOR}${data}`;
              this.setState({ fields: fields });
            }
          })
          .catch((e) => console.log("Error: ", e.message));
      } else {
        this.props.dispatch(showAlertDialog(FILE_TYPE_MESSAGE));
        this.setState({ fileName: this.state.defaultName });
        let fields = this.state.fields;
        fields["files"] = "";
        this.setState({ fields: fields });
      }
    }
  }

  onValueChange = (newValue) => {
    this.setState({
      value: newValue,
    });
    let errors = this.state.errors;
    errors["maintenance_id"] = "";
    this.setState({ errors: errors });
  };

  onSuggestionsFetchRequested = (value) => {
    this.loadSuggestions(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  loadSuggestions(value) {
    if (value !== "") {
      let vdata = {};
      vdata.attrs = {};
      vdata.attrs["prefetch"] = ["owning_customer", "vehicle"];
      vdata.conds = { "vehicle.vin": { "-like": "%" + value + "%" } };
      //vdata.conds = { 'me.id':value};
      this.props.dispatch(fetchVehicleMaintenance(vdata));
    }
  }

  loadMoreReferences = () => {
    let meta_data =
      typeof this.props.vehicleData !== "undefined" &&
      typeof this.props.vehicleData.rows !== "undefined" &&
      typeof this.props.vehicleData.rows.meta !== "undefined"
        ? this.props.vehicleData.rows.meta
        : [];

    let vdata = {};
    vdata.attrs = {};
    vdata.conds = meta_data.conds;
    vdata.page = meta_data.page + 1;
    vdata.page_size = meta_data.page_size;
    this.props.dispatch(fetchVehicleMaintenance(vdata));
  };

  onSelect = (suggestionValue) => {
    const reference = this.state.suggestions.find(
      (r) => r["name"] === suggestionValue //.replace('/Maintenance - /i',''),
    );
    let fields = this.state.fields;
    //console.log(reference);
    fields["maintenance_id"] = reference.id;
    fields["maintenance_name"] = reference.name;
    this.setState({ fields: fields });
  };

  render() {
    // let customer_type = 2;

    // let core_module = "core";
    // let vehicle_entity = "vehicle";
    // let maintenance_entity = "vehicle_maintenance";
    // let customer_entity = "customer";
    // let system_module = "system";
    // let client_entity = "client";

    return (
      <div className="wrapper">
        <div className="main d-flex align-items-start flex-column">
          <h4>
            <ColumnText
              Module={CORE_MODULE}
              Entity={VEHICLE_MAINTENANCE_NOTE_ENTITY}
            />{" "}
            bearbeiten
          </h4>
        </div>
        <div className="sub_main">
          <Form>
            <Row>
              <Col lg={6}>
                <Form.Group as={Row} controlId="maintenance_id">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_MAINTENANCE_NOTE_ENTITY}
                      Label={"vehicle_maintenance"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <AutoRecommend
                      value={this.state.value}
                      onValueChange={this.onValueChange}
                      suggestions={this.state.suggestions}
                      onSuggestionsClearRequested={
                        this.onSuggestionsClearRequested
                      }
                      fetchSuggestions={this.onSuggestionsFetchRequested}
                      onSelect={this.onSelect}
                      disabled
                      placeholder="Wählen Sie Wartung"
                      onScrollEnd={this.loadMoreReferences}
                      inputClasses={
                        typeof this.state.errors.maintenance_id !==
                          "undefined" && this.state.errors.maintenance_id !== ""
                          ? "is-invalid form-control"
                          : "form-control"
                      }
                    />
                    {typeof this.state.errors.maintenance_id !== "undefined" &&
                    this.state.errors.maintenance_id !== "" ? (
                      <div className="has-error">
                        {this.state.errors.maintenance_id}
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="note">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_MAINTENANCE_NOTE_ENTITY}
                      Label={"content"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Note"
                      name="note"
                      value={this.state.fields.note}
                      onChange={(evt) => this.changeHandler(evt, "note")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.note}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.note}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="attachment">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_MAINTENANCE_NOTE_ENTITY}
                      Label={"attachment"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.File
                      type="file"
                      // className={(this.state.errors.files !== "")?"is-invalid custom-file-label":"form-control custom-file-label"}
                      id="inputGroupFile01"
                      label={this.state.fileName}
                      onChange={(evt) => this.changeFileHandler(evt)}
                      accept="application/pdf, image/*"
                      custom
                      isInvalid={!!this.state.errors.files}
                    />
                    {this.state.errors.files !== "" ? (
                      <div className="has-error">{this.state.errors.files}</div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>

                <div className="form-btn">
                  <Button
                    type="Button"
                    className="btn btn-dark Button"
                    onClick={(evt) => this.submitHandler(evt)}
                  >
                    OK
                  </Button>
                  <Button
                    type="Button"
                    className="btn btn-dark"
                    onClick={(evt) => this.resetHandler(evt)}
                  >
                    Abbrechen
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

MaintenanceNoteEditScreen.propTypes = {};
MaintenanceNoteEditScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  customerData: state.Customer.customerData,
  vehicleData: state.Vehicle.vehicleData,
  localeData: state.Locale.localeData,
  updateData: state.MNote.updateData,
  dialogData: state.Dialog.dialogData,
  maintenanceData: state.Maintenance.maintenanceData,
  indexData: state.MNote.maintenanceData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaintenanceNoteEditScreen);
