import * as downloadJs from "downloadjs";
import { Config } from "../config";
import  jwt_decode from "jwt-decode";
import { ReactComponent as Loader } from '../assets/loader.svg';

export const LOGIN_TOKEN_KEY = "loginToken";

/**
 * Gets the auth header from sessionStorage.
 *
 * @return {object} Returns an object with header if logged in.
 */
export function authHeader() {
  // return authorization header with jwt token
  const token = getAuthToken();
  // Todo : Cleanup
  if ((token === null || typeof(token) === undefined ||  isTokenExpired(token)) && (( window.location.href.indexOf("forgot-password") === -1)
        && ( window.location.href.indexOf("login") === -1 )  && ( window.location.href.indexOf("reset-login-password") === -1 )) ) {
        console.log(isTokenExpired(token));
        if(isTokenExpired(token)){  
          destroyToken(); 
        }
        else {
          window.location.href = "/login";
        }
  }
  if (token) {
        return `Bearer ${token}`;
  }
  return "";
}

export const getPrettyTitle = (title) => {
  let clean_title = title.replace(/[^a-zA-Z0-9\/_| -]/g, '');
      clean_title = clean_title.replace(/^[\-]+/, ''); // trim start
      clean_title = clean_title.replace(/[\-]+$/, ''); // trim end
      clean_title = clean_title.toLowerCase();
      clean_title = clean_title.replace(/[\/_| -]+/g, '-');
   return clean_title;
}

export const setAuthToken = (token) => {
  sessionStorage.setItem(LOGIN_TOKEN_KEY, token);
};

export const isLoggedIn = () => {
  const token = getAuthToken();
  return token && !isTokenExpired(token);
};

export const getAuthToken = () => {
  return sessionStorage.getItem(LOGIN_TOKEN_KEY);
};

export function destroyToken(){
	window.sessionStorage.removeItem(LOGIN_TOKEN_KEY);
    window.sessionStorage.clear();
    return;
}

const isTokenExpired = (token) => {
  try {
    const decoded = jwt_decode(token);
    const dateNumber = Date.parse(decoded["jwt.claim.expirationTimestamp@UTC"]);
    return dateNumber <= Date.now();
  } catch(err) {
    //console.log(err.message);
    return true;
  }
};

export const DelBtn = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-trash"
      viewBox="0 0 16 16"
    >
      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
      <path
        fillRule="evenodd"
        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
      />
    </svg>
  );
};

export const EditBtn = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-pencil-square"
      viewBox="0 0 16 16"
    >
      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
      <path
        fillRule="evenodd"
        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
      />
    </svg>
  );
};

export const ViewBtn = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-eye"
      viewBox="0 0 16 16"
    >
      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
    </svg>
  );
};

export const ListBtn = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-file-earmark-text"
      viewBox="0 0 16 16"
    >
      <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
      <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
    </svg>
  );
};
export const DocumentBtn = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journal" viewBox="0 0 16 16">
  <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"/>
  <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"/>
</svg>
);
};

export const CloseBtn = () => {
  return (
    <svg  id="icon" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
    </svg>
  );
};

export const NoDataIndication = () => {
  return (
   
      // <div className="spinner">
      //   <div className="rect1" />
      //   <div className="rect2" />
      //   <div className="rect3" />
      //   <div className="rect4" />
      //   <div className="rect5" />
      // </div>
      <div className="spinner">
      <Loader/>
      </div>
   
  );
};

export const downloadFile = async (url, fileName, mimeType) => {
  const result = await fetch(`${Config.API_URL}${url}`, {
    headers: { Authorization: authHeader() },
  });
  if (result.status === 200) {
    const fileBlob = await result.blob();
    downloadJs(fileBlob, fileName);
  }
};

export const PagingOptions = {
  sizePerPage: 10,
  lastPageText: ">>",
  firstPageText: "<<",
  nextPageText: ">",
  prePageText: "<",
  hideSizePerPage: false,
  hidePageListOnlyOnePage: true,
  showTotal: false,
  alwaysShowAllBtns: true,
  sizePerPageList: [
    {
      text: "10",
      value: 1,
    },
    {
      text: "20",
      value: 20,
    },
    {
      text: "50",
      value: 50,
    },
    {
      text: "100",
      value: 100,
    },
  ],
};

export const defaultUniverseEntity = {
	attributes: {},
	transitions: {},
	estimatedCount: 0,
	workflowVerb: '',
	workflowUrl: '',
	id: null,
};

export const removeEmptyValues = (obj) => {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || obj[propName].length === 0) {
      delete obj[propName];
    }
  }
  return obj
}

export const getMaxBase64StringSize = () => {
	return (`${Config.FILE_MAX_SIZE_KB}` * 1000) ;
};

export const getFileContent = (files) =>{
    const file = files[0];
    return new Promise((resolve, reject) => {
      const r = new FileReader();
      r.readAsDataURL(file);
      r.onload = () => { 
        const base64 = r.result;
        const commaIndex = base64.indexOf(',');
        const resultString = base64.substring(commaIndex);
         resolve(resultString)
      };
      r.onerror = (error) => { reject(error) }
    });
    

}

export const checkFileType = (files) =>{
  var fileType = files[0].type;
  let allowedExtension = ['image/jpeg', 'image/jpg', 'image/png','image/gif'];
  var isImage = (allowedExtension.indexOf(fileType) > -1)?true:false;
  var isPdf = /^(application\/pdf)/i.test(fileType);
  if(isImage || isPdf){
    return true;
  }
  return false;
}

export const checkSuggestionValue = (suggestions,value)=>{
  const reference = suggestions.find(
    r => r["name"] === value
  );
  //console.log(suggestions);
}

export const getSalutaions = () =>  {
  return [
          {"text":"Herr","value":"Herr"},
          {"text":"Frau","value":"Frau"},
          // {"text":"Miss","value":"Miss"},
          // {"text":"Ms","value":"Ms"},
          // {"text":"Dr","value":"Dr"},
        ]

}

export {validateURLFormat, inputErrorHandler} from "./validate.js";
export { ToastContainer, toast, signalTransitionSuccess,signalTransitionError } from "./Toast.js";
export {FILE_NAME_SEPARATOR,FILE_TOO_LARGE_MESSAGE,FILE_TYPE_MESSAGE,
  CORE_MODULE,
  SYSTEM_MODULE,
  VEHICLE_ENTITY,
  CUSTOMER_ENTITY,
  CUSTOMER_NOTE_ENTITY,
  VEHICLE_COMPONENT_ENTITY,
  VEHICLE_MAINTENANCE_NOTE_ENTITY,
  VEHICLE_CUSTOMER_NOTE_ENTITY,
  VEHICLE_NOTE_ENTITY,
  VEHICLE_MAINTENANCE_COMPONENT_ENTITY,
  VEHICLE_MAINTENANCE_COMPONENT_NOTE_ENTITY,
  VEHICLE_MAINTENANCE_ENTITY,
  VEHICLE_DOCUMENT_TYPE_ENTITY,
  VEHICLE_FILE_ENTITY,
  CLIENT_ENTITY,

  DELETE_PERMISSION,
  EDIT_PERMISSION,
  VIEW_PERMISSION,
  MODIFY_PERMISSION,
  INSERT_PERMISSION,
  ADMIN_MODIFY_CLIENT_PERMISSION,
  ADMIN_MODIFY_COMPANY_PERMISSION,
  CREATE_PERMISSION,
  ADD_PERMISSION,
  REMOVE_PERMISSION,
  NO_DATA 

} from "./constants.js";
export {getSuggestionValue,renderSuggestion}from "./auto_suggest.js";
export {hasView,hasAdd,hasEdit,hasDelete} from "./permission"

