import * as Types from "../../actions/constants";

const initialState = {
    noteData: {
      loading: false,
      error: false,
      success:false,
      rows: { data: [], meta: [] },
    },
    generalData: {
      loading: false,
      error: false,
      success:false,
      rows: { data: [], meta: [] },
    },
    internalData: {
      loading: false,
      error: false,
      success:false,
      rows: { data: [], meta: [] },
    },
    
    updateData: {
      loading: false,
      error: false,
      success: false,
      data: null,
    },
    createData: {
      loading: false,
      error: false,
      success: false,
      data: null,
    },
    indexData: {
      loading: false,
      error: false,
      success: false,
      data: null,
    },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.CREATE_VEHICLE_NOTE:
      return {
        ...state,
        createData: {
          loading: true,
          error: false,
          success: false,
          rows: { data: [], meta: [] },
        },
      };
    case Types.CREATE_VEHICLE_NOTE_SUCCESS:
      return {
        ...state,
        createData: {
          loading: false,
          error: false,
          success: true,
          rows: action.response.data,
        },
      };
    case Types.CREATE_VEHICLE_NOTE_ERROR:
      return {
        ...state,
        createData: {
          loading: false,
          error: true,
          success: false,
          rows: action.response,
        },
      };
    case Types.RESET_CREATE_VEHICLE_NOTE:
      return {
        ...state,
        createData: {
          loading: false,
          error: false,
          success: false,
          rows: { data: [], meta: [] },
        },
      };


      case Types.UPDATE_VEHICLE_NOTE:
        return {
          ...state,
          updateData: {
            loading: true,
            error: false,
            success: false,
            rows: { data: [], meta: [] },
          },
        };
      case Types.UPDATE_VEHICLE_NOTE_SUCCESS:
        return {
          ...state,
          updateData: {
            loading: false,
            error: false,
            success: true,
            rows: action.response.data,
          },
        };
      case Types.UPDATE_VEHICLE_NOTE_ERROR:
        return {
          ...state,
          updateData: {
            loading: false,
            error: true,
            success: false,
            rows: action.response,
          },
        };
      case Types.RESET_UPDATE_VEHICLE_NOTE:
        return {
          ...state,
          updateData: {
            loading: false,
            error: false,
            success: false,
            rows: { data: [], meta: [] },
          },
        };
  





      case Types.GET_VEHICLE_NOTE:
      return {
        ...state,
        noteData: {
          loading: true,
          error: false,
          success: false,
          rows: { data: [], meta: [] },
        },
      };
    case Types.VEHICLE_NOTE_SUCCESS:
      return {
        ...state,
        noteData: {
          loading: false,
          error: false,
          success: true,
          rows: action.response.data,
        },
      };
    case Types.VEHICLE_NOTE_ERROR:
      return {
        ...state,
        noteData: {
          loading: false,
          error: true,
          success: false,
          rows: action.response,
        },
      };



      case Types.GET_GENERAL_VEHICLE_NOTE:
        return {
          ...state,
          generalData: {
            loading: true,
            error: false,
            success: false,
            rows: { data: [], meta: [] },
          },
        };
      case Types.GENERAL_VEHICLE_NOTE_SUCCESS:
        return {
          ...state,
          generalData: {
            loading: false,
            error: false,
            success: true,
            rows: action.response.data,
          },
        };
      case Types.GENERAL_VEHICLE_NOTE_ERROR:
        return {
          ...state,
          generalData: {
            loading: false,
            error: true,
            success: false,
            rows: action.response,
          },
        };





        case Types.GET_INTERNAL_VEHICLE_NOTE:
          return {
            ...state,
            internalData: {
              loading: true,
              error: false,
              success: false,
              rows: { data: [], meta: [] },
            },
          };
        case Types.INTERNAL_VEHICLE_NOTE_SUCCESS:
          return {
            ...state,
            internalData: {
              loading: false,
              error: false,
              success: true,
              rows: action.response.data,
            },
          };
        case Types.INTERNAL_VEHICLE_NOTE_ERROR:
          return {
            ...state,
            internalData: {
              loading: false,
              error: true,
              success: false,
              rows: action.response,
            },
          };
      
  

      case Types.GET_VEHICLE_NOTE_BY_ID:
        return {
          ...state,
          indexData: {
            loading: true,
            error: false,
            success: false,
            rows: { data: [], meta: [] },
          },
        };
      case Types.VEHICLE_NOTE_BY_ID_SUCCESS:
        return {
          ...state,
          indexData: {
            loading: false,
            error: false,
            success: true,
            rows: action.response.data,
          },
        };
      case Types.VEHICLE_NOTE_BY_ID_ERROR:
        return {
          ...state,
          indexData: {
            loading: false,
            error: true,
            success: false,
            rows: action.response,
          },
        };

















    default:
      return state;
  }
};
