import { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import {
  createCustomer,
  resetCreateCustomer,
} from "../../store/actions/customer";

import {
  inputErrorHandler,
  signalTransitionSuccess,
  signalTransitionError,
  removeEmptyValues,
  getSalutaions,
  CORE_MODULE,
  SYSTEM_MODULE,
  CUSTOMER_ENTITY,
  CLIENT_ENTITY,
} from "../../utils";

import ColumnText from "../../components/Column";

class CustomerAddScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        company_name: "",
        password: "",
        confirm_password: "",
        identifier: "",
        salutation: "",
        first_name: "",
        last_name: "",
        email: "",
        mobile_number: "",
        phone_number: "",
        street_address: "",
        city: "",
        zip: "",
        country: "",
        customer_type: 1,
      },
      reset: {
        fields: {
          company_name: "",
          password: "",
          confirm_password: "",
          identifier: "",
          salutation: "",
          first_name: "",
          last_name: "",
          email: "",
          mobile_number: "",
          phone_number: "",
          street_address: "",
          city: "",
          zip: "",
          country: "",
          customer_type: 1,
        },
        errors: {
          company_name: "",
          identifier: "",
          salutation: "",
          password: "",
          first_name: "",
          last_name: "",
          email: "",
          mobile_number: "",
          phone_number: "",
          street_address: "",
          city: "",
          zip: "",
          country: "",
        },
      },
      errors: {
        company_name: "",
        identifier: "",
        salutation: "",
        password: "",
        first_name: "",
        last_name: "",
        email: "",
        mobile_number: "",
        phone_number: "",
        street_address: "",
        city: "",
        zip: "",
        country: "",
      },
      is_company: false,
      is_customer: true,
    };
  }

  componentDidMount() {
    // let data = {};
    // data.conds = { "me.id": this.props.match.params.id };
    // data.attrs = {};
    // data.attrs["prefetch"] = {
    //   "owning_client": "system__assignment__assigned_client"
    // };
    // data.conds = {
    //   "system__assignment__assigned_client.agenda": 9
    // };
    // this.props.dispatch(fetchCustomer(data));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.createData.success) {
      this.props.dispatch(resetCreateCustomer());
      signalTransitionSuccess("Update Success");

      let url = "/admin/customers";
      this.props.history.push(url);
    } else if (this.props.createData.error) {
      this.props.dispatch(resetCreateCustomer());
      signalTransitionError("Update Failed");
    }
  }

  resetHandler(evt) {
    this.setState({ fields: this.state.reset.fields });
    this.setState({ errors: this.state.reset.errors });
  }

  submitHandler(evt) {
    evt.preventDefault();
    // get our new errors
    let fields = this.state.fields;

    let newErrors = {};

    if (parseInt(fields.customer_type) === 1) {
      newErrors["first_name"] = inputErrorHandler("", fields.first_name, true);
      newErrors["last_name"] = inputErrorHandler("", fields.last_name, true);
      newErrors["password"] = inputErrorHandler("", fields.password, true);
      newErrors["confirm_password"] = inputErrorHandler(
        "",
        fields.confirm_password,
        true
      );

      if (
        fields.password !== fields.confirm_password &&
        typeof newErrors["password"] !== "undefined"
      ) {
        newErrors["password"] =
          "Passwort & Passwort bestätigen sollten gleich sein";
      }
      newErrors["email"] = inputErrorHandler("", fields.email, true);
      newErrors["salutation"] = inputErrorHandler("", fields.salutation, true);
    } else if (parseInt(fields.customer_type) === 0) {
      newErrors["company_name"] = inputErrorHandler(
        "",
        fields.company_name,
        true
      );
    }
    newErrors["identifier"] = inputErrorHandler("", fields.identifier, true);
    newErrors["mobile_number"] = inputErrorHandler(
      "",
      fields.mobile_number,
      true
    );
    newErrors["phone_number"] = inputErrorHandler(
      "",
      fields.phone_number,
      false
    );
    newErrors["street_address"] = inputErrorHandler(
      "",
      fields.street_address,
      true
    );
    newErrors["city"] = inputErrorHandler("", fields.city, true);
    newErrors["country"] = inputErrorHandler("", fields.country, true);
    newErrors["zip"] = inputErrorHandler("", fields.zip, true);

    var letters = /^[a-z0-9_-]{3,16}$/;
    if (
      fields.identifier !== "" &&
      typeof (newErrors["identifier"] !== "undefined") &&
      newErrors["identifier"] === "" &&
      !fields.identifier.match(letters)
    ) {
      newErrors["identifier"] =
        "Customer Number can only contain lowercase alphanumeric characters, underscore and having a length of 3 to 16 characters";
    }

    let errors = removeEmptyValues(newErrors);
    // Conditional logic:
    if (Object.keys(errors).length > 0) {
      this.setState({ errors: errors });
    } else {
      let ClientData = {};

      let addresses = [];
      let address = {};
      address.Name = "Main";
      address.Straße = fields.street_address;
      address.Stadt = fields.city;
      address.PLZ = fields.zip;
      address.Land = fields.country;
      addresses[0] = address;

      let phonenumbers = [];
      let phonenumber = {};

      phonenumber = {
        Name: "Mobil",
        Nummer: fields.mobile_number,
      };
      phonenumbers[0] = phonenumber;
      if (fields.phone_number.length > 0) {
        phonenumber = {
          Name: "Telefonnummer",
          Nummer: fields.phone_number,
        };
        phonenumbers[1] = phonenumber;
      }

      if (parseInt(fields.customer_type) === 0) {
        ClientData.name = fields.company_name;
      } else {
        ClientData.email = fields.email;
        ClientData.first_name = fields.first_name;
        ClientData.last_name = fields.last_name;
        ClientData.password = fields.password;
        ClientData.salutation = fields.salutation;
      }

      ClientData.is_client =
        parseInt(fields.customer_type) === 1 ? true : false;
      ClientData.identifier = fields.identifier;
      ClientData.addresses = addresses;
      ClientData.phonenumbers = phonenumbers;

      this.props.dispatch(createCustomer(ClientData));
    }
  }

  changeHandler(evt, name) {
    let value = evt.target.value;

    if (name === "customer_type" && parseInt(value) === 1) {
      this.setState({ is_company: false });
      this.setState({ is_customer: true });
      let fields = this.state.fields;
      fields["customer_type"] = parseInt(1);
      this.setState({ fields: fields });
    }
    if (name === "customer_type" && parseInt(value) === 0) {
      this.setState({ is_company: true });
      this.setState({ is_customer: false });
      let fields = this.state.fields;
      fields["customer_type"] = parseInt(0);
      this.setState({ fields: fields });
    }

    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: null,
      },
    }));
    this.props.dispatch(resetCreateCustomer());
  }

  render() {
    let customer_data =
      typeof this.props.customerData.rows !== "undefined" &&
      typeof this.props.customerData.rows.data[0] !== "undefined"
        ? this.props.customerData.rows.data[0]
        : null;
    let identifier =
      customer_data !== null && typeof customer_data.identifier !== "undefined"
        ? customer_data.identifier
        : "";
    let salutations = getSalutaions();

    return (
      <div className="wrapper">
        <div className="main d-flex align-items-start flex-column">
          <h4>
            <ColumnText Module={CORE_MODULE} Entity={CUSTOMER_ENTITY} />{" "}
            hinzufügen
          </h4>
        </div>
        <div className="sub_main">
          <Form>
            <Row>
              <Col lg={6}>
                <Form.Group as={Row}>
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={CUSTOMER_ENTITY}
                      Label={"is_client"}
                    />
                  </Form.Label>
                  <Col
                    sm="8"
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Form.Check
                      custom
                      inline
                      label="Kunde"
                      value={1}
                      type={"radio"}
                      id={`custom-inline-radio-1`}
                      checked={
                        parseInt(this.state.fields.customer_type) === 1
                          ? true
                          : false
                      }
                      onChange={(evt) =>
                        this.changeHandler(evt, "customer_type")
                      }
                    />
                    <Form.Check
                      custom
                      inline
                      readOnly
                      label="Firma"
                      value={0}
                      type={"radio"}
                      id={`custom-inline-radio-2`}
                      checked={
                        parseInt(this.state.fields.customer_type) === 0
                          ? true
                          : false
                      }
                      onChange={(evt) =>
                        this.changeHandler(evt, "customer_type")
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group as={Row} controlId="identifier">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={CUSTOMER_ENTITY}
                      Label={"identifier"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Customer Number"
                      name="identifier"
                      value={this.state.fields.identifier}
                      onChange={(evt) => this.changeHandler(evt, "identifier")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.identifier}
                    />

                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.identifier}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <div
                  style={{
                    display: this.state.is_company === false ? "block" : "none",
                  }}
                >
                  <Form.Group as={Row} controlId="password">
                    <Form.Label column sm="4">
                      Passwort
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="password"
                        placeholder="Passwort"
                        name="password"
                        value={this.state.fields.password}
                        onChange={(evt) => this.changeHandler(evt, "password")}
                        onKeyPress={this.onKeyPress}
                        isInvalid={!!this.state.errors.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {this.state.errors.password}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="confirm_password">
                    <Form.Label column sm="4">
                      Passwort bestätigen
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="password"
                        placeholder="Passwort bestätigen"
                        name="password"
                        value={this.state.fields.confirm_password}
                        onChange={(evt) =>
                          this.changeHandler(evt, "confirm_password")
                        }
                        onKeyPress={this.onKeyPress}
                        isInvalid={!!this.state.errors.confirm_password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {this.state.errors.confirm_password}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="salutation">
                    <Form.Label column sm="4">
                      <ColumnText
                        Module={CORE_MODULE}
                        Entity={CUSTOMER_ENTITY}
                        Label={"salutation"}
                      />
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        as="select"
                        placeholder="Anrede"
                        name="salutation"
                        value={this.state.fields.salutation}
                        onChange={(evt) =>
                          this.changeHandler(evt, "salutation")
                        }
                        onKeyPress={this.onKeyPress}
                        isInvalid={!!this.state.errors.salutation}
                      >
                        <option value="">Anrede</option>
                        {salutations.map((item) => (
                          <option
                            value={item.value}
                            selected={
                              this.state.fields.salutation === item.value
                                ? true
                                : false
                            }
                          >
                            {item.text}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {this.state.errors.salutation}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="first_name">
                    <Form.Label column sm="4">
                      <ColumnText
                        Module={SYSTEM_MODULE}
                        Entity={CLIENT_ENTITY}
                        Label={"first_name"}
                      />
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="Vorname"
                        name="first_name"
                        value={this.state.fields.first_name}
                        onChange={(evt) =>
                          this.changeHandler(evt, "first_name")
                        }
                        onKeyPress={this.onKeyPress}
                        isInvalid={!!this.state.errors.first_name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {this.state.errors.first_name}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="last_name">
                    <Form.Label column sm="4">
                      <ColumnText
                        Module={SYSTEM_MODULE}
                        Entity={CLIENT_ENTITY}
                        Label={"last_name"}
                      />
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="Familienname"
                        name="last_name"
                        value={this.state.fields.last_name}
                        onChange={(evt) => this.changeHandler(evt, "last_name")}
                        onKeyPress={this.onKeyPress}
                        isInvalid={!!this.state.errors.last_name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {this.state.errors.last_name}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="email">
                    <Form.Label column sm="4">
                      <ColumnText
                        Module={SYSTEM_MODULE}
                        Entity={CLIENT_ENTITY}
                        Label={"email"}
                      />
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="Email"
                        name="email"
                        value={this.state.fields.email}
                        onChange={(evt) => this.changeHandler(evt, "email")}
                        onKeyPress={this.onKeyPress}
                        isInvalid={!!this.state.errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {this.state.errors.email}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </div>
                <div
                  style={{
                    display: this.state.is_company === true ? "block" : "none",
                  }}
                >
                  <Form.Group as={Row} controlId="company_name">
                    <Form.Label column sm="4">
                      <ColumnText
                        Module={CORE_MODULE}
                        Entity={CUSTOMER_ENTITY}
                        Label={"name"}
                      />
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="Company Name"
                        name="company_name"
                        value={this.state.fields.company_name}
                        onChange={(evt) =>
                          this.changeHandler(evt, "company_name")
                        }
                        onKeyPress={this.onKeyPress}
                        isInvalid={!!this.state.errors.company_name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {this.state.errors.company_name}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </div>
                <Form.Group as={Row} controlId="mobile_number">
                  <Form.Label column sm="4">
                    Mobil
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Mobil"
                      name="mobile_number"
                      value={this.state.fields.mobile_number}
                      onChange={(evt) =>
                        this.changeHandler(evt, "mobile_number")
                      }
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.mobile_number}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.mobile_number}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="phone_number">
                  <Form.Label column sm="4">
                    Telefonnummer
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Phone Number"
                      name="phone_number"
                      value={this.state.fields.phone_number}
                      onChange={(evt) =>
                        this.changeHandler(evt, "phone_number")
                      }
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.phone_number}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.phone_number}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group as={Row} controlId="street_address">
                  <Form.Label column sm="4">
                    Straße
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Straße"
                      name="street_address"
                      value={this.state.fields.street_address}
                      onChange={(evt) =>
                        this.changeHandler(evt, "street_address")
                      }
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.street_address}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.street_address}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="city">
                  <Form.Label column sm="4">
                    Stadt
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Stadt"
                      name="city"
                      value={this.state.fields.city}
                      onChange={(evt) => this.changeHandler(evt, "city")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.city}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.city}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="zip">
                  <Form.Label column sm="4">
                    PLZ
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="PLZ"
                      name="zip"
                      value={this.state.fields.zip}
                      onChange={(evt) => this.changeHandler(evt, "zip")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.zip}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.zip}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="country">
                  <Form.Label column sm="4">
                    Land
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Land"
                      name="country"
                      value={this.state.fields.country}
                      onChange={(evt) => this.changeHandler(evt, "country")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.country}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.country}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <div className="form-btn">
                  <Button
                    type="Button"
                    className="btn btn-dark Button"
                    onClick={(evt) => this.submitHandler(evt)}
                  >
                    OK
                  </Button>
                  <Button
                    type="Button"
                    className="btn btn-dark"
                    onClick={(evt) => this.resetHandler(evt)}
                  >
                    Abbrechen
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

CustomerAddScreen.propTypes = {};
CustomerAddScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  customerData: state.Customer.customerData,
  localeData: state.Locale.localeData,
  createData: state.Customer.createData,
  clientData: state.Client.clientData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(mapStateToProps, mapDispatchToProps)(CustomerAddScreen);
