import { Component } from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  PagingOptions,
  NoDataIndication,
  EditBtn,
  CORE_MODULE,
  VEHICLE_DOCUMENT_TYPE_ENTITY,
  CREATE_PERMISSION,
  EDIT_PERMISSION,
  NO_DATA,
} from "../../utils";
import { fetchDocument } from "../../store/actions/vehicle";

import { HasAccess } from "../../components/Access";
import ColumnText from "../../components/Column";
import _ from "lodash";
import { Link } from "react-router-dom";
import { showModalDialog } from "../../store/actions/dialog";
import DocumentAdd from "../Vehicle/DocumentAddScreen";
import DocumentEdit from "../Vehicle/DocumentEditScreen";

class DocumentScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      docColumns: [
        {
          dataField: "id",
          text: "Doc. ID",
          sort: true,
          headerClasses: "Cell Cell-header",
          hidden: true,
        },
        {
          dataField: "name",
          text: (
            <ColumnText
              Module={CORE_MODULE}
              Entity={VEHICLE_DOCUMENT_TYPE_ENTITY}
              Label={"name"}
            />
          ),
          sort: true,
          headerClasses: "Cell Cell-header",
        },
        {
          dataField: "instance_entity.id",
          text: "",
          sort: false,
          headerClasses: "Cell Cell-header",
          formatter: (cellContent, row) => {
            return (
              <HasAccess
                Module={CORE_MODULE}
                Entity={VEHICLE_DOCUMENT_TYPE_ENTITY}
                Label={EDIT_PERMISSION}
              >
                <div className="svg">
                  <Link
                    to={"#"}
                    onClick={() => {
                      this.editDocumentHandler(row);
                    }}
                  >
                    <EditBtn />
                  </Link>
                </div>
              </HasAccess>
            );
          },
        },
      ],
      docSort: [
        {
          dataField: "id",
          order: "desc",
        },
      ],

      id: "",
    };
    this.documentHandler = this.documentHandler.bind(this);
    this.addDocumentHandler = this.addDocumentHandler.bind(this);
    this.editDocumentHandler = this.editDocumentHandler.bind(this);
  }

  addDocumentHandler = () => {
    this.props.dispatch(showModalDialog("Document-Add"));
  };

  editDocumentHandler = (row) => {
    this.setState({ id: row.id });
    this.props.dispatch(showModalDialog("Document-Edit"));
  };

  componentDidMount() {
    //   /** Vehicle  Document */
    //   let doc_defaultOrder = this.state.docSort;
    //   let doc_SortString = "-" + doc_defaultOrder[0]["order"];
    //   let doc_data = {};
    //   doc_data.attrs = {};
    //  // doc_data.attrs["prefetch"] = ["vehicle_component","vehicle_maintenance"];
    //   doc_data.attrs["order_by"] = {};
    //   doc_data.attrs.order_by[doc_SortString] = "me."+doc_defaultOrder[0]["dataField"];
    //   doc_data.conds = {};
    //   doc_data.page = 1;
    //   doc_data.page_size = PagingOptions.sizePerPage;
    //   this.props.dispatch(fetchDocument(doc_data));
  }

  documentHandler = (
    type,
    { filters, page, sortField, sortOrder, sizePerPage }
  ) => {
    let defaultOrder = this.state.compSort;
    let sortOrderstring =
      typeof sortOrder !== "undefined"
        ? "-" + sortOrder
        : "-" + defaultOrder[0]["order"];
    let data = {};
    data.attrs = {};
    //data.attrs["prefetch"] = ["vehicle_component","vehicle_maintenance"];
    data.attrs["order_by"] = {};
    data.attrs.order_by[sortOrderstring] =
      sortField !== null
        ? "me." + sortField
        : "me." + defaultOrder[0]["dataField"];
    //data.auto_ident_depth = 2;
    data.conds = {};
    data.page = page;
    data.page_size = sizePerPage;
    this.props.dispatch(fetchDocument(data));
  };

  render() {
    let doc_options = _.omit(PagingOptions, "totalSize");

    doc_options.totalSize =
      typeof this.props.documentData !== "undefined" &&
      typeof this.props.documentData.rows !== "undefined" &&
      typeof this.props.documentData.rows.meta.filtered !== "undefined"
        ? this.props.documentData.rows.meta.filtered
        : "";

    doc_options.sizePerPage =
      typeof this.props.documentData !== "undefined" &&
      typeof this.props.documentData.rows !== "undefined" &&
      typeof this.props.documentData.rows.meta.page_size !== "undefined"
        ? this.props.documentData.rows.meta.page_size
        : 10;

    let doc_data =
      typeof this.props.documentData !== "undefined" &&
      typeof this.props.documentData.rows !== "undefined" &&
      typeof this.props.documentData.rows.data !== "undefined"
        ? this.props.documentData.rows.data
        : [];

    return (
      <>
        <div>
          <div className="table_main">
            <h5>Fahrzeug Unterlagen</h5>
            <HasAccess
              Module={CORE_MODULE}
              Entity={VEHICLE_DOCUMENT_TYPE_ENTITY}
              Label={CREATE_PERMISSION}
            >
              <button
                type="button"
                className="btn btn-dark"
                onClick={(evt) => this.addDocumentHandler(evt)}
              >
                neuer Unterlagentyp anlegen
              </button>
            </HasAccess>
          </div>
          <BootstrapTable
            remote={{ pagination: true }}
            bootstrap4
            keyField="id"
            data={doc_data}
            columns={this.state.docColumns}
            bordered={false}
            rowClasses={"table-row"}
            defaultSorted={this.state.docSort}
            pagination={paginationFactory(doc_options)}
            onTableChange={this.documentHandler}
            noDataIndication={() =>
              this.props.documentData.loading ? <NoDataIndication /> : NO_DATA
            }
          />
          <DocumentAdd />
          <DocumentEdit compId={this.state.id} />
        </div>
      </>
    );
  }
}

DocumentScreen.propTypes = {};
DocumentScreen.defaultProps = { historyRef: "" };

const mapStateToProps = (state) => ({
  localeData: state.Locale.localeData,
  documentData: state.Document.documentData,
  dialogData: state.Dialog.dialogData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(mapStateToProps, mapDispatchToProps)(DocumentScreen);
