
export const GET_CLIENT = 'constants/client/GET_CLIENT';
export const CLIENT_SUCCESS = 'constants/client/CLIENT_SUCCESS';
export const CLIENT_ERROR = 'constants/client/CLIENT_ERROR';

export const CREATE_CLIENT = 'constants/client/CREATE_CLIENT';
export const CREATE_CLIENT_SUCCESS = 'constants/client/CREATE_CLIENT_SUCCESS';
export const CREATE_CLIENT_ERROR = 'constants/client/CREATE_CLIENT_ERROR';
export const RESET_CREATE_CLIENT = 'constants/client/RESET_CREATE_CLIENT';

export const UPDATE_CLIENT = 'constants/client/UPDATE_CLIENT';
export const UPDATE_CLIENT_SUCCESS = 'constants/client/UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_ERROR = 'constants/client/UPDATE_CLIENT_ERROR';
export const RESET_UPDATE_CLIENT = 'constants/client/RESET_UPDATE_CLIENT';

export const UPDATE_PASSWORD = 'constants/client/UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'constants/client/UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'constants/client/UPDATE_PASSWORD_ERROR';
export const RESET_UPDATE_PASSWORD = 'constants/client/RESET_UPDATE_PASSWORD';