import * as Types from "../constants/types";

// login actions
export function forgot(data) {
    return {
        type: Types.FORGOT,
        data
    };
};
export function forgotSuccess(response) {
    return {
        type: Types.FORGOT_SUCCESS,
        response
    };
};

export function forgotError(error) {
    return {
        type: Types.FORGOT_ERROR,
        error
    };
};
export function resetForgot() {
    return {
        type: Types.RESET_FORGOT
    };
};



// login actions
export function resetLoginPassword(data) {
    return {
        type: Types.RESET_LOGIN_PASSWORD,
        data
    };
};
export function resetLoginPasswordSuccess(response) {
    return {
        type: Types.RESET_LOGIN_PASSWORD_SUCCESS,
        response
    };
};

export function resetLoginPasswordError(error) {
    return {
        type: Types.RESET_LOGIN_PASSWORD_ERROR,
        error
    };
};




