import { call, all, put, takeLatest } from "redux-saga/effects";
import { authHeader } from "../../../utils";
import request from "../../../services/request";
import * as ActionTypes from "../../actions/constants";
import {
  fetchCompanyUserSuccess,
  fetchCompanyUserError,
  createCompanyUserSuccess,
  createCompanyUserError,
  updateCompanyUserSuccess,
  updateCompanyUserError,
} from "../../actions/company";

function* userSaga({ data }) {
  try {
    const requestURL = "data/assignment/core_customer/query";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data,
    });
    yield put(fetchCompanyUserSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(fetchCompanyUserError(error));
    }
  }
}

function* createSaga({ data }) {
  try {
    let clientData =
      typeof data.client !== "undefined" && data.client !== null
        ? data.client
        : null;
    let companyData =
      typeof data.company !== "undefined" && data.company !== null
        ? data.company
        : {};
    const requestURL = "/plugins/customer_client_create";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: clientData,
    });

    var clientCond = {
      "conds": {
        "me.email": clientData.email
      }
    }
    const client = yield call(request, {
      url: "/data/system/client/query",
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: clientCond,
    });
    if (client.data.data instanceof Array && client.data.data.length === 1) {
      const client_id = client.data.data[0].id;
      let clientAssignmentURL = "/data/system/assignment/transitions/create";
      let assignmentData = {
        "agenda": 9,
        "assigned_client": client_id
      };

      yield call(request, {
        url: clientAssignmentURL,
        method: "POST",
        headers: {
          Authorization: authHeader(),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: assignmentData
      });

      let companyJoinRequestURL = "/data/assignment/core_customer/transitions/create";

      var companyJoinData = {
        "assignment_type": "2",
        "binding_instance_of_customer": companyData.company_id,
        "assigned_client": client_id,
        "agenda": "9",
        "binding_entity": "79"
      };

      const joinResponse = yield call(request, {
        url: companyJoinRequestURL,
        method: "POST",
        headers: {
          Authorization: authHeader(),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: companyJoinData,
      });
      yield put(createCompanyUserSuccess(joinResponse));
    } else {
      const response = "Duplicate user email";
      yield put(createCompanyUserError(response));
    }
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(createCompanyUserError(error));
    }
  }
}

function* updateSaga({ data }) {
  try {
    let client_id =
      typeof data.client_id !== "undefined" && data.client_id !== null
        ? data.client_id
        : null;

    let clientData =
      typeof data.client !== "undefined" && data.client !== null
        ? data.client
        : null;
  
    const requestURL = `/data/system/client/${client_id}/transitions/edit`;
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: clientData,
    });
    
    yield put(updateCompanyUserSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(updateCompanyUserError(error));
    }
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(ActionTypes.GET_COMPANY_USER, userSaga),
    yield takeLatest(ActionTypes.CREATE_COMPANY_USER, createSaga),
    yield takeLatest(ActionTypes.UPDATE_COMPANY_USER, updateSaga),
  ]);
}
