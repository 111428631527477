import * as Types from "../../actions/constants";

const initialState = {
  customerData: {
    loading: false,
    error: false,
    success:false,
    rows: { data: [], meta: [] },
  },
  updateData:{
    loading: false,
    error: false,
    success: false,
    data:null
  },
  createData:{
    loading: false,
    error: false,
    success: false,
    data:null
  },
  deleteData: {
    loading: false,
    error: false,
    success: false,
    rows: { data: [], meta: [] },
  },

  indexData: {
    loading: false,
    error: false,
    success:false,
    rows: { data: [], meta: [] },
  },
  
  
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_CUSTOMER:
      return {
        ...state,
        customerData: {
          loading: true,
          error: false,
          success:false,
          rows: { data: [], meta: [] },
        },
      };
    case Types.CUSTOMER_SUCCESS:
       return {
        ...state,
        customerData: {
          loading: false,
          error: false,
          success:true,
          rows: action.response.data,
        },
      };
    case Types.CUSTOMER_ERROR:
      return {
        ...state,
        customerData: {
          loading: false,
          error: true,
          success:false,
          rows: action.response,
        },
      };
    case Types.UPDATE_CUSTOMER:
        return {
          ...state,
          updateData: {
            loading: false,
            error: false,
            success:false,
            data:null
          },
        };
      case Types.UPDATE_CUSTOMER_SUCCESS:
        return {
          ...state,
          updateData: {
            loading: false,
            error: false,
            success:true,
            data:action.response
          },
        };
      case Types.UPDATE_CUSTOMER_ERROR:
        return {
          ...state,
          updateData: {
            loading: false,
            error: true,
            success:false,
            data:action.response
          },
        };
        case Types.RESET_UPDATE_CUSTOMER:
          return {
            ...state,
            updateData: {
              loading: false,
              error: false,
              success:false,
              data: { data: [], meta: [] }
            },
          };  
      

        case Types.CREATE_CUSTOMER:
          return {
            ...state,
            createData: {
              loading: true,
              error: false,
              success:false,
              data:null
            },
          };
        case Types.CREATE_CUSTOMER_SUCCESS:
          return {
            ...state,
            createData: {
              loading: false,
              error: false,
              success:true,
              data:action.response
            },
          };
        case Types.CREATE_CUSTOMER_ERROR:
          return {
            ...state,
            createData: {
              loading: false,
              error: true,
              success:false,
              data:null
            },
          };
          case Types.RESET_CREATE_CUSTOMER:
          return {
            ...state,
            createData: {
              loading: false,
              error: false,
              success:false,
              data:action.response
            },
          };

    /*case Types.DELETE_RECORD:
      return {
        ...state,
        deleteData: {
          loading: true,
          error: false,
          success: false,
          rows: { data: [], meta: [] },
        },
      };
    case Types.DELETE_SUCCESS:
      return {
        ...state,
        deleteData: {
          loading: false,
          error: false,
          success: true,
          rows: action.response.data,
        },
      };
    case Types.DELETE_ERROR:
      return {
        ...state,
        deleteData: {
          loading: false,
          error: true,
          success: false,
          rows: action.response,
        },
      };*/

    case Types.GET_CUSTOMER_DETAIL:
      return {
        ...state,
        indexData: {
          loading: true,
          error: false,
          success:false,
          rows: { data: [], meta: [] },
        },
      };
    case Types.CUSTOMER_DETAIL_SUCCESS:
      return {
        ...state,
        indexData: {
          loading: false,
          error: false,
          success:true,
          rows: action.response.data,
        },
      };
    case Types.CUSTOMER_DETAIL_ERROR:
      return {
        ...state,
        indexData: {
          loading: false,
          error: true,
          success:false,
          rows: action.response,
        },
      };

    

    default:
      return state;
  }
};
