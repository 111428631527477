export const GET_COMPANY_USER = 'constants/company/GET_COMPANY_USER';
export const COMPANY_USER_SUCCESS = 'constants/company/COMPANY_USER_SUCCESS';
export const COMPANY_USER_ERROR = 'constants/company/COMPANY_USER_ERROR';

export const GET_COMPANY_USER_BY_ID = 'constants/company/GET_COMPANY_USER_BY_ID';
export const COMPANY_USER_BY_ID_SUCCESS = 'constants/company/COMPANY_USER_BY_ID_SUCCESS';
export const COMPANY_USER_BY_ID_ERROR = 'constants/company/COMPANY_USER_BY_ID_ERROR';

export const CREATE_COMPANY_USER = 'constants/company/CREATE_COMPANY_USER';
export const CREATE_COMPANY_USER_SUCCESS = 'constants/company/CREATE_COMPANY_USER_SUCCESS';
export const CREATE_COMPANY_USER_ERROR = 'constants/company/CREATE_COMPANY_USER_ERROR';
export const RESET_CREATE_COMPANY_USER = 'constants/company/RESET_CREATE_COMPANY_USER';

export const UPDATE_COMPANY_USER = 'constants/company/UPDATE_COMPANY_USER';
export const UPDATE_COMPANY_USER_SUCCESS = 'constants/company/UPDATE_COMPANY_USER_SUCCESS';
export const UPDATE_COMPANY_USER_ERROR = 'constants/company/UPDATE_COMPANY_USER_ERROR';
export const RESET_UPDATE_COMPANY_USER = 'constants/company/RESET_UPDATE_COMPANY_USER';


