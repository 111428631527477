import * as Types from "../constants";

// maintenance actions
export function createVehicleNote(data) {
    return {
        type: Types.CREATE_VEHICLE_NOTE,
        data
    };
};
export function createVehicleNoteSuccess(response) {
    return {
        type: Types.CREATE_VEHICLE_NOTE_SUCCESS,
        response
    };
};
export function createVehicleNoteError(error) {
    return {
        type: Types.CREATE_VEHICLE_NOTE_ERROR,
        error
    };
};
export function resetCreateVehicleNote() {
    return {
        type: Types.RESET_CREATE_VEHICLE_NOTE
    };
};


// maintenance actions
export function updateVehicleNote(data) {
    return {
        type: Types.UPDATE_VEHICLE_NOTE,
        data
    };
};
export function updateVehicleNoteSuccess(response) {
    return {
        type: Types.UPDATE_VEHICLE_NOTE_SUCCESS,
        response
    };
};
export function updateVehicleNoteError(error) {
    return {
        type: Types.UPDATE_VEHICLE_NOTE_ERROR,
        error
    };
};
export function resetUpdateVehicleNote() {
    return {
        type: Types.RESET_UPDATE_VEHICLE_NOTE
        
    };
};


// maintenance actions
export function fetchVehicleNote(data) {
    return {
        type: Types.GET_VEHICLE_NOTE,
        data
    };
};
export function fetchVehicleNoteSuccess(response) {
    return {
        type: Types.VEHICLE_NOTE_SUCCESS,
        response
    };
};
export function fetchVehicleNoteError(error) {
    return {
        type: Types.VEHICLE_NOTE_ERROR,
        error
    };
};



export function fetchGeneralVehicleNote(data) {
    return {
        type: Types.GET_GENERAL_VEHICLE_NOTE,
        data
    };
};
export function fetchGeneralVehicleNoteSuccess(response) {
    return {
        type: Types.GENERAL_VEHICLE_NOTE_SUCCESS,
        response
    };
};
export function fetchGeneralVehicleNoteError(error) {
    return {
        type: Types.GENERAL_VEHICLE_NOTE_ERROR,
        error
    };
};

//maintenance actions
export function fetchInternalVehicleNote(data) {
    return {
        type: Types.GET_INTERNAL_VEHICLE_NOTE,
        data
    };
};
export function fetchInternalVehicleNoteSuccess(response) {
    return {
        type: Types.INTERNAL_VEHICLE_NOTE_SUCCESS,
        response
    };
};
export function fetchInternalVehicleNoteError(error) {
    return {
        type: Types.INTERNAL_VEHICLE_NOTE_ERROR,
        error
    };
};




// maintenance actions
export function fetchVehicleNoteById(data) {
    return {
        type: Types.GET_VEHICLE_NOTE_BY_ID,
        data
    };
};
export function fetchVehicleNoteByIdSuccess(response) {
    return {
        type: Types.VEHICLE_NOTE_BY_ID_SUCCESS,
        response
    };
};
export function fetchVehicleNoteByIdError(error) {
    return {
        type: Types.VEHICLE_NOTE_BY_ID_ERROR,
        error
    };
};
