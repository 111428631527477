import { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import { fetchCustomer } from "../../store/actions/customer";
import {
  fetchVehicle,
  createVehicleNote,
  resetCreateVehicleNote,
} from "../../store/actions/vehicle";
import {
  inputErrorHandler,
  signalTransitionSuccess,
  signalTransitionError,
  removeEmptyValues,
  getFileContent,
  checkFileType,
  getMaxBase64StringSize,
  FILE_TOO_LARGE_MESSAGE,
  FILE_NAME_SEPARATOR,
  FILE_TYPE_MESSAGE,
  CORE_MODULE,
  VEHICLE_NOTE_ENTITY,
  VEHICLE_CUSTOMER_NOTE_ENTITY,
} from "../../utils";
import { showAlertDialog } from "../../store/actions/dialog";
import AutoRecommend from "../../components/AutoSuggest";

import "font-awesome/css/font-awesome.css";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";
import ColumnText from "../../components/Column";

class VehicleNoteAddScreen extends Component {
  vehicleId = null;
  constructor(props) {
    super(props);
    this.vehicleId = props.match.params.vid;
    this.noteType = props.match.params.notetype;
    this.state = {
      fields: {
        note_type: props.match.params.notetype === "general" ? 1 : 0,
        client_name: "",
        client_id: "",
        customer_id: "",
        vehicle_id: "",
        vehicle_name: "",
        vehicle_note: "",
        files: "",
      },
      reset: {
        fields: {
          note_type: props.match.params.notetype === "general" ? 1 : 0,
          client_name: "",
          client_id: "",
          customer_id: "",
          vehicle_id: "",
          vehicle_name: "",
          vehicle_note: "",
          files: "",
        },
        errors: {
          note_type: "",
          client_id: "",
          vehicle_id: "",
          vehicle_note: "",
          files: "",
        },
      },
      errors: {
        note_type: "",
        client_id: "",
        vehicle_id: "",
        vehicle_note: "",
        files: "",
      },
      fileName: "No File Choosen",
      defaultName: "No File Choosen",
      value: "",
      vehicle_value: "",
      suggestions: [],
      vehicle_suggestions: [],
    };
    this.changeFileHandler = this.changeFileHandler.bind(this);
  }

  componentDidMount() {
    let vdata = {};
    vdata.attrs = {};
    vdata.attrs["prefetch"] = ["core__vehicle_maintenance__vehicle"];
    vdata.conds = { "me.id": this.vehicleId };
    this.props.dispatch(fetchVehicle(vdata));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.vehicleData) !==
      JSON.stringify(this.props.vehicleData)
    ) {
      let vehicles =
        typeof this.props.vehicleData !== "undefined" &&
        typeof this.props.vehicleData.rows !== "undefined" &&
        typeof this.props.vehicleData.rows.data !== "undefined"
          ? this.props.vehicleData.rows.data
          : [];
      let data = [];
      let suggestions = this.state.suggestions;
      for (let i = 0; i < suggestions.length; i++) {
        let obj = {};
        obj.id = suggestions.id;
        obj.name = suggestions.name;
        data.push(obj);
      }
      for (let i = 0; i < vehicles.length; i++) {
        let obj = {};
        if (typeof vehicles[i]["id"] !== "undefined") {
          obj.id =
            typeof vehicles[i]["id"] !== "undefined" ? vehicles[i]["id"] : "";
          obj.name = vehicles[i]["vin"];
          this.setState({
            fields: { ...this.state.fields, vehicle_id: obj.id },
          });
          this.onVehicleChange(obj.name);
          data.push(obj);
        }
      }
      this.setState({ vehicle_suggestions: data });
    }

    if (this.props.createData.success) {
      this.props.dispatch(resetCreateVehicleNote());
      signalTransitionSuccess("Erfolgreich hinzugefügt");
      let url = "/admin/vehicles";
      if (this.props.match.params.vid) {
        url =
          "/admin/vehicles/" + this.props.match.params.vid + "/vehicle-notes";
      }
      this.props.history.push(url);
    } else if (this.props.createData.error) {
      this.props.dispatch(resetCreateVehicleNote());
      signalTransitionError("Hinzufügen fehlgeschlagen");
    }
  }

  changeFileHandler(evt) {
    let errors = this.state.errors;
    errors["files"] = "";
    this.setState({ errors: errors });
    if (
      typeof evt.target.files[0] !== "undefined" &&
      typeof evt.target.files[0].name !== "undefined"
    ) {
      this.setState({ fileName: evt.target.files[0].name });
      if (checkFileType(evt.target.files)) {
        getFileContent(evt.target.files)
          .then((data) => {
            if (data.length > getMaxBase64StringSize()) {
              this.props.dispatch(showAlertDialog(FILE_TOO_LARGE_MESSAGE));
              this.setState({ fileName: this.state.defaultName });
              let fields = this.state.fields;
              fields["files"] = "";
              this.setState({ fields: fields });
            } else {
              //console.log(`${this.state.fileName}${FILE_NAME_SEPARATOR}${data}`);
              let fields = this.state.fields;
              fields[
                "files"
              ] = `${this.state.fileName}${FILE_NAME_SEPARATOR}${data}`;
              this.setState({ fields: fields });
            }
          })
          .catch((e) => console.log("Error: ", e.message));
      } else {
        this.props.dispatch(showAlertDialog(FILE_TYPE_MESSAGE));
        this.setState({ fileName: this.state.defaultName });
        let fields = this.state.fields;
        fields["files"] = "";
        this.setState({ fields: fields });
      }
    }
  }

  onValueChange = (newValue) => {
    this.setState({
      value: newValue,
    });
    let errors = this.state.errors;
    errors["client_id"] = "";
    this.setState({ errors: errors });
  };

  onSuggestionsFetchRequested = (value) => {
    this.loadSuggestions(value);
  };

  onSuggestionsClearRequested = () => {
    /*this.setState({
      suggestions: []
    });*/
  };

  loadSuggestions(value) {
    if (value !== "") {
      let cdata = {};
      cdata.attrs = {};
      cdata.attrs["prefetch"] = {
        owning_client: "system__assignment__assigned_client",
      };
      cdata.conds = {
        "system__assignment__assigned_client.agenda": 9,
        "-or": [
          { first_name: { "-like": "%" + value + "%" } },
          { last_name: { "-like": "%" + value + "%" } },
        ],
      };
      this.props.dispatch(fetchCustomer(cdata));
    }
  }

  loadMoreReferences = () => {
    let meta_data =
      typeof this.props.customerData !== "undefined" &&
      typeof this.props.customerData.rows !== "undefined" &&
      typeof this.props.customerData.rows.meta !== "undefined"
        ? this.props.customerData.rows.meta
        : [];

    let cdata = {};
    cdata.attrs = meta_data.attrs;
    cdata.conds = meta_data.conds;
    cdata.page = meta_data.page + 1;
    cdata.page_size = meta_data.page_size;
    this.props.dispatch(fetchCustomer(cdata));
  };

  onSelect = (suggestionValue) => {
    const reference = this.state.suggestions.find(
      (r) => r["name"] === suggestionValue //.replace('/Maintenance - /i',''),
    );
    let fields = this.state.fields;
    fields["client_id"] = reference.id;
    fields["client_name"] = reference.name;
    fields["customer_id"] = reference.customer_id;
    fields["vehicle_id"] = "";
    fields["vehicle_name"] = "";
    this.setState({ vehicle_value: "" });
    this.setState({ fields: fields });

    let vdata = {};
    vdata.attrs = {};
    vdata.attrs["prefetch"] = [
      "core__vehicle_maintenance__vehicle",
      { owning_customer: "owning_client" },
    ];
    vdata.conds = { "owning_customer.id": this.state.fields.customer_id };
    this.props.dispatch(fetchVehicle(vdata));
  };

  onVehicleChange = (newValue) => {
    this.setState({
      vehicle_value: newValue,
    });
    let errors = this.state.errors;
    errors["vehicle_id"] = "";
    this.setState({ errors: errors });
  };

  onVehicleSuggestionsFetchRequested = (value) => {
    this.loadVehicleSuggestions(value);
  };

  onVehicleSuggestionsClearRequested = () => {
    this.setState({
      vehicle_suggestions: [],
    });
  };

  loadVehicleSuggestions(value) {
    if (value !== "") {
      let vdata = {};
      vdata.attrs = {};
      vdata.attrs["prefetch"] = [
        "core__vehicle_maintenance__vehicle",
        { owning_customer: "owning_client" },
      ];
      vdata.conds = {
        vin: { "-like": "%" + value + "%" },
        "owning_customer.id": this.state.fields.customer_id,
      };
      this.props.dispatch(fetchVehicle(vdata));
    }
  }

  loadVehicleMoreReferences = () => {
    let meta_data =
      typeof this.props.vehicleData !== "undefined" &&
      typeof this.props.vehicleData.rows !== "undefined" &&
      typeof this.props.vehicleData.rows.meta !== "undefined"
        ? this.props.vehicleData.rows.meta
        : [];

    let vdata = {};
    vdata.attrs = meta_data.attrs;
    vdata.conds = meta_data.conds;
    vdata.page = meta_data.page + 1;
    vdata.page_size = meta_data.page_size;
    this.props.dispatch(fetchVehicle(vdata));
  };

  onVehicleSelect = (suggestionValue) => {
    const reference = this.state.vehicle_suggestions.find(
      (r) => r["name"] === suggestionValue //.replace('/Maintenance - /i',''),
    );
    let fields = this.state.fields;
    fields["vehicle_id"] = reference.id;
    fields["vehicle_name"] = reference.name;
    this.setState({ fields: fields });
  };

  resetHandler(evt) {
    this.setState({ fields: this.state.reset.fields });
    this.setState({ errors: this.state.reset.errors });
  }

  submitHandler(evt) {
    evt.preventDefault();
    // get our new errors
    let fields = this.state.fields;
    let newErrors = {};
    newErrors["client_id"] = inputErrorHandler("", fields.client_id, false);
    newErrors["vehicle_id"] = inputErrorHandler("", fields.vehicle_id, false);
    newErrors["vehicle_note"] = inputErrorHandler(
      "",
      fields.vehicle_note,
      true
    );
    newErrors["files"] = inputErrorHandler("", fields.files, false);

    let errors = removeEmptyValues(newErrors);
    if (Object.keys(errors).length > 0) {
      this.setState({ errors: errors });
    } else {
      let vehicleData = { vehicle: {} };
      vehicleData.note_type =
        parseInt(fields.note_type) === 1 ? "GENERAL" : "INTERNAL";
      vehicleData.vehicle.vehicle = fields.vehicle_id;
      vehicleData.vehicle.content = fields.vehicle_note;
      if (fields.files) {
        vehicleData.vehicle.attachment = fields.files;
      }

      this.props.dispatch(createVehicleNote(vehicleData));
      //console.log(ClientData);
    }
  }

  changeHandler(evt, name) {
    let value = evt.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: null,
      },
    }));
  }

  changeStatus(value) {
    let fields = this.state.fields;
    fields["note_type"] = value;
    this.setState({ fields: fields });
    console.log(this.state.fields);
  }

  render() {
    return (
      <div className="wrapper">
        <div className="main d-flex align-items-start flex-column">
          <h4>
            {" "}
            <ColumnText
              Module={CORE_MODULE}
              Entity={VEHICLE_CUSTOMER_NOTE_ENTITY}
            />{" "}
            hinzufügen
          </h4>
        </div>
        <div className="sub_main">
          <Form>
            <Row>
              <Col lg={6}></Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group as={Row}>
                  <Form.Label column sm="4">
                    Note Type
                  </Form.Label>
                  <Col
                    sm="8"
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Form.Check
                      custom
                      inline
                      label="General"
                      type={"radio"}
                      id={`custom-inline-radio-1`}
                      checked={
                        parseInt(this.state.fields.note_type) === 1
                          ? true
                          : false
                      }
                      onClick={() => this.changeStatus(1)}
                    />
                    <Form.Check
                      custom
                      inline
                      label="Internal"
                      type={"radio"}
                      id={`custom-inline-radio-2`}
                      checked={
                        parseInt(this.state.fields.note_type) === 0
                          ? true
                          : false
                      }
                      onClick={() => this.changeStatus(0)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="vehicle_id">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_NOTE_ENTITY}
                      Label={"vehicle"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <AutoRecommend
                      value={this.state.vehicle_value}
                      onValueChange={this.onVehicleChange}
                      suggestions={this.state.vehicle_suggestions}
                      onSuggestionsClearRequested={
                        this.onVehicleSuggestionsClearRequested
                      }
                      fetchSuggestions={this.onVehicleSuggestionsFetchRequested}
                      onSelect={this.onVehicleSelect}
                      placeholder="Wählen Sie Fahrzeug"
                      disabled
                      onScrollEnd={this.loadVehicleMoreReferences}
                      inputClasses={
                        typeof this.state.errors.vehicle_id !== "undefined" &&
                        this.state.errors.vehicle_id !== ""
                          ? "is-invalid form-control"
                          : "form-control"
                      }
                    />
                    {typeof this.state.errors.vehicle_id !== "undefined" &&
                    this.state.errors.vehicle_id !== "" ? (
                      <div className="has-error">
                        {this.state.errors.vehicle_id}
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group as={Row} controlId="vehicle_note">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_NOTE_ENTITY}
                      Label={"content"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Note"
                      name="vehicle_note"
                      value={this.state.fields.vehicle_note}
                      onChange={(evt) =>
                        this.changeHandler(evt, "vehicle_note")
                      }
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.vehicle_note}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.vehicle_note}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="attachement">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_NOTE_ENTITY}
                      Label={"attachment"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.File
                      type="file"
                      // className={(this.state.errors.files !== "")?"is-invalid form-control custom-file-label":"form-control custom-file-label"}
                      id="inputGroupFile01"
                      label={this.state.fileName}
                      onChange={(evt) => this.changeFileHandler(evt)}
                      accept="application/pdf, image/*"
                      custom
                      isInvalid={!!this.state.errors.files}
                    />
                    {this.state.errors.files !== "" ? (
                      <div className="has-error">{this.state.errors.files}</div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
                <div className="form-btn">
                  <Button
                    type="Button"
                    className="btn btn-dark Button"
                    onClick={(evt) => this.submitHandler(evt)}
                  >
                    OK
                  </Button>
                  <Button
                    type="Button"
                    className="btn btn-dark"
                    onClick={(evt) => this.resetHandler(evt)}
                  >
                    Abbrechen
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

VehicleNoteAddScreen.propTypes = {};
VehicleNoteAddScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  customerData: state.Customer.customerData,
  vehicleData: state.Vehicle.vehicleData,
  localeData: state.Locale.localeData,
  createData: state.VNote.createData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleNoteAddScreen);
