import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import { DateTimePicker } from "react-tempusdominus-bootstrap";
import { setCustomerParams } from "../../store/actions/search";
import { removeEmptyValues } from "../../utils";
import moment from "moment";

import "font-awesome/css/font-awesome.css";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

class CustomerSearchScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        client_name: "",
        company_name: "",
        phone_number: "",
        service_date: "",
        service_period_from: "",
        service_period_to: "",
      },
      errors: {
        client_name: "",
        company_name: "",
        phone_number: "",
        service_date: "",
        service_period_from: "",
        service_period_to: "",
      },
      reset: {
        fields: {
          client_name: "",
          company_name: "",
          phone_number: "",
          service_date: "",
          service_period_from: "",
          service_period_to: "",
        },
        errors: {
          client_name: "",
          company_name: "",
          phone_number: "",
          service_date: "",
          service_period_from: "",
          service_period_to: "",
        },
      },
    };
    this.service_field = React.createRef();
    this.service_period_from_field = React.createRef();
    this.service_period_to_field = React.createRef();

    this.datePickerHandler = this.datePickerHandler.bind(this);
    this.resetHandler = this.resetHandler.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    //this.dateTimepickerCallback = this.dateTimepickerCallback.bind(this);
  }

  setServiceField = () => {
    if (
      typeof this.service_field.current[0] !== "undefined" &&
      typeof this.service_field.current[0].children[0] !== "undefined"
    ) {
      this.service_field.current[0].children[0].setAttribute(
        "placeholder",
        "Datum"
      );
    }
  };

  setPeriodFrom = () => {
    if (
      typeof this.service_period_from_field.current[0] !== "undefined" &&
      typeof this.service_period_from_field.current[0].children[0] !==
        "undefined"
    ) {
      this.service_period_from_field.current[0].children[0].setAttribute(
        "placeholder",
        "Datum"
      );
    }
  };

  setPeriodTo = () => {
    if (
      typeof this.service_period_to_field.current[0] !== "undefined" &&
      typeof this.service_period_to_field.current[0].children[0] !== "undefined"
    ) {
      this.service_period_to_field.current[0].children[0].setAttribute(
        "placeholder",
        "Datum"
      );
    }
  };

  resetHandler(evt) {
    this.setState({ fields: this.state.reset.fields });
    this.setState({ errors: this.state.reset.errors });
  }

  submitHandler(evt) {
    evt.preventDefault();
    let fields = this.state.fields;
    let newErrors = {};
    if (
      (fields.service_period_from !== "" || fields.service_period_to !== "") &&
      (fields.service_period_from === "" || fields.service_period_to === "")
    ) {
      if (fields.service_period_from === "") {
        newErrors["service_period_from"] = "Required parameter";
      }
      if (fields.service_period_to === "") {
        newErrors["service_period_to"] = "Required parameter";
      }
    }
    if (fields.service_date !== "") {
      fields.service_date = fields.service_date.format("YYYY-MM-DD");
    }
    if (fields.service_period_from !== "") {
      fields.service_period_from =
        fields.service_period_from.format("YYYY-MM-DD");
    }
    if (fields.service_period_to !== "") {
      fields.service_period_to = fields.service_period_to.format("YYYY-MM-DD");
    }

    let errors = removeEmptyValues(newErrors);
    if (Object.keys(errors).length > 0) {
      this.setState({ errors: errors });
    } else {
      this.props.dispatch(setCustomerParams(fields));
      let url = "/admin/customers";
      this.props.historyRef.push(url);
    }
  }

  changeHandler(evt, name) {
    let value = evt.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: null,
      },
    }));
  }

  datePickerHandler(value, name) {
    if (name === "service_period_from") {
      this.service_period_to_field.current.datetimepicker("minDate", value);
    } else if (name === "service_period_to") {
      this.service_period_from_field.current.datetimepicker("maxDate", value);
    }

    let items = this.state.fields;
    items[name] = value;
    this.setState({ fields: items });
    let errors = this.state.errors;
    errors[name] = "";
    this.setState({ errors: errors });
  }

  render() {
    return (
      <>
        <Form>
          <Row>
            <Col lg={3}>
              <Form.Group controlId="client_name">
                <Form.Label>Kundensuche</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Kundenname"
                  name="client_name"
                  value={this.state.fields.client_name}
                  onChange={(evt) => this.changeHandler(evt, "client_name")}
                  onKeyPress={this.onKeyPress}
                  isInvalid={!!this.state.errors.client_name}
                />
                <Form.Control.Feedback type="invalid">
                  {this.state.errors.client_name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group controlId="company_name">
                <Form.Label>Firmensuche</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name der Firma"
                  name="company_name"
                  value={this.state.fields.company_name}
                  onChange={(evt) => this.changeHandler(evt, "company_name")}
                  onKeyPress={this.onKeyPress}
                  isInvalid={!!this.state.errors.company_name}
                />
                <Form.Control.Feedback type="invalid">
                  {this.state.errors.company_name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            {/* <Col lg={3}>
              <Form.Group controlId="phone_number">
                <Form.Label>Search By Phone number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Phone number"
                  name="phone_number"
                  value={this.state.fields.phone_number}
                  onChange={(evt) => this.changeHandler(evt, "phone_number")}
                  onKeyPress={this.onKeyPress}
                  isInvalid={!!this.state.errors.phone_number}
                />
                <Form.Control.Feedback type="invalid">
                  {this.state.errors.phone_number}
                </Form.Control.Feedback>
              </Form.Group>
            </Col> */}
          </Row>

          <Row>
            <Col lg={3}>
              <Form.Group controlId="service_date">
                <Form.Label>Letzte Wartung</Form.Label>
                <DateTimePicker
                  pickerRef={this.service_field}
                  callback={() => this.setServiceField()}
                  date={this.state.fields.service_date}
                  //defaultDate={this.state.fields.service_date}
                  format="YYYY-MM-DD"
                  onChange={(e) =>
                    this.datePickerHandler(e.date, "service_date")
                  }
                  maxDate={moment().format("YYYY-MM-DD")}
                />
                {typeof this.state.errors.service_date !== "undefined" &&
                this.state.errors.service_date !== "" ? (
                  <div className="has-error">
                    {this.state.errors.service_date}
                  </div>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group controlId="service_period_from">
                <Form.Label>Letzte Wartung (von)</Form.Label>
                <DateTimePicker
                  date={this.state.fields.service_period_from}
                  pickerRef={this.service_period_from_field}
                  callback={() => this.setPeriodFrom()}
                  format="YYYY-MM-DD"
                  onChange={(e) =>
                    this.datePickerHandler(e.date, "service_period_from")
                  }
                />
                {typeof this.state.errors.service_period_from !== "undefined" &&
                this.state.errors.service_period_from !== "" ? (
                  <div className="has-error">
                    {this.state.errors.service_period_from}
                  </div>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group controlId="service_period_to">
                <Form.Label>Letzte Wartung (bis)</Form.Label>
                <DateTimePicker
                  date={this.state.fields.service_period_to}
                  format="YYYY-MM-DD"
                  pickerRef={this.service_period_to_field}
                  callback={() => this.setPeriodTo()}
                  onChange={(e) =>
                    this.datePickerHandler(e.date, "service_period_to")
                  }
                />
                {typeof this.state.errors.service_period_to !== "undefined" &&
                this.state.errors.service_period_to !== "" ? (
                  <div className="has-error">
                    {this.state.errors.service_period_to}
                  </div>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row className="search-btn-row">
            <div className="form-search-btn">
              <Button
                type="Button"
                className="btn-dark"
                onClick={(evt) => this.submitHandler(evt)}
              >
                OK
              </Button>
              <Button
                type="Button"
                className="btn-dark"
                onClick={(evt) => this.resetHandler(evt)}
              >
                Abbrechen
              </Button>
            </div>
          </Row>
        </Form>
      </>
    );
  }
}

CustomerSearchScreen.propTypes = {};
CustomerSearchScreen.defaultProps = {
  historyRef: "",
};

const mapStateToProps = (state) => ({
  customerData: state.Search.customerData,
  localeData: state.Locale.localeData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerSearchScreen);
