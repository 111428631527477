import * as Types from "../../actions/constants";

const initialState = {
  clientData: {
    loading: false,
    error: false,
    rows: { data: [], meta: [] },
  },
  updatePasswordData:{
    loading: false,
    error: false,
    success:false,
    rows:null,
  },
  createData:{
    loading: false,
    error: false,
    success:false,
    rows:{ data: [], meta: [] },
  },
  updateData:{
    loading: false,
    error: false,
    success:false,
    rows:{ data: [], meta: [] },
  }
  
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_CLIENT:
      return {
        ...state,
        clientData: {
          loading: true,
          error: false,
          success:false,
          rows: { data: [], meta: [] },
        },
      };
    case Types.CLIENT_SUCCESS:
      return {
        ...state,
        clientData: {
          loading: false,
          error: false,
          success:true,
          rows: action.response.data,
        },
      };
    case Types.CLIENT_ERROR:
      return {
        ...state,
        clientData: {
          loading: false,
          error: true,
          success:false,
          rows: action.response,
        },
      };
      
      case Types.UPDATE_PASSWORD:
        return {
          ...state,
          updatePasswordData: {
            loading: true,
            error: false,
            success:false,
            rows: null,
          },
        };
      case Types.UPDATE_PASSWORD_SUCCESS:
        return {
          ...state,
          updatePasswordData: {
            loading: false,
            error: false,
            success:true,
            rows: action.response.data.result,
          },
        };
      case Types.UPDATE_PASSWORD_ERROR:
        return {
          ...state,
          updatePasswordData: {
            loading: false,
            error: true,
            success:false,
            rows: action.response,
          },
        };
      case Types.RESET_UPDATE_PASSWORD:
          return {
            ...state,
            updatePasswordData: {
              loading: false,
              error: false,
              success:false,
              rows: null,
            },
          }; 

      case Types.CREATE_CLIENT:
            return {
              ...state,
              createData: {
                loading: true,
                error: false,
                success:false,
                rows: null,
              },
            };
          case Types.CREATE_CLIENT_SUCCESS:
            return {
              ...state,
              createData: {
                loading: false,
                error: false,
                success:true,
                rows: action.response.data.result,
              },
            };
          case Types.CREATE_CLIENT_ERROR:
            return {
              ...state,
              createData: {
                loading: false,
                error: true,
                success:false,
                rows: action.error.error,
              },
            };
          case Types.RESET_CREATE_CLIENT:
              return {
                ...state,
                createData: {
                  loading: false,
                  error: false,
                  success:false,
                  rows: null,
                },
              }; 

              case Types.UPDATE_CLIENT:
                return {
                  ...state,
                  updateData: {
                    loading: true,
                    error: false,
                    success:false,
                    rows: null,
                  },
                };
              case Types.UPDATE_CLIENT_SUCCESS:
                return {
                  ...state,
                  updateData: {
                    loading: false,
                    error: false,
                    success:true,
                    rows: action.response.data.result,
                  },
                };
              case Types.UPDATE_CLIENT_ERROR:
                return {
                  ...state,
                  updateData: {
                    loading: false,
                    error: true,
                    success:false,
                    rows: action.error.error,
                  },
                };
              case Types.RESET_UPDATE_CLIENT:
                  return {
                    ...state,
                    updateData: {
                      loading: false,
                      error: false,
                      success:false,
                      rows: null,
                    },
                  };           
    default:
      return state;
  }
};
