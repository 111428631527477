import React, { Component } from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  PagingOptions,
  NoDataIndication,
  DelBtn,
  EditBtn,
  downloadFile,
  CORE_MODULE,
  VEHICLE_NOTE_ENTITY,
  DELETE_PERMISSION,
  EDIT_PERMISSION,
  NO_DATA,
} from "../../utils";
import { fetchInternalVehicleNote } from "../../store/actions/vehicle";
import { showDeleteDialog } from "../../store/actions/dialog";
import moment from "moment";
import { Link } from "react-router-dom";
import { HasAccess } from "../../components/Access";
import ColumnText from "../../components/Column";
import { Config } from "../../config";

class VehicleInternalNoteScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: [
        {
          dataField: "id",
          order: "desc",
        },
      ],
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.addHandler = this.addHandler.bind(this);
    this.editHandler = this.editHandler.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
    this.downloadInvoice = this.downloadInvoice.bind(this);
  }

  renderColumns() {
    return [
      {
        dataField: "modification_time",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={VEHICLE_NOTE_ENTITY}
            Label={"modification_time"}
          />
        ),
        sort: true,
        headerClasses: "cell-header cell-vnote-modification_time-header",
        classes: "cell-vnote-created",
        formatter: (cellContent, row) => {
          if (cellContent !== "" && cellContent !== null && typeof cellContent !== "undefined") {
            return <>{moment(cellContent).format("DD.MM.YYYY")}</>;
          }
        },
      },
      {
        dataField: "vehicle.vin",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={VEHICLE_NOTE_ENTITY}
            Label={"vehicle"}
          />
        ),
        sort: true,
        headerClasses: "cell-header cell-vnote-vin-header",
        classes: "cell-vnote-vin",
        formatter: (cellContent, row) => {
          return (
            <>
              <Link
                to={"#"}
                onClick={() => {
                  this.vehicleDetailHandler(row);
                }}
              >
                {cellContent}
              </Link>
            </>
          );
        },
      },
      {
        dataField: "content",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={VEHICLE_NOTE_ENTITY}
            Label={"content"}
          />
        ),
        sort: true,
        headerClasses: "cell-header cell-vnote-content-header",
        classes: "cell-vnote-content",
      },

      {
        dataField: "attachment",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={VEHICLE_NOTE_ENTITY}
            Label={"attachment"}
          />
        ),
        sort: true,
        headerClasses: "cell-header",
        classes: "cell-vnote-attachment",
        formatter: (cellContent, row) => {
          let cell = cellContent !== null && typeof cellContent !== "undefined" ? JSON.parse(cellContent) : {};
          //let url = (cell !== {})?cell.path:'';
          let mime_type = cell !== {} ? cell.mime_type : "";
          let name = cell !== {} ? cell.original_name : "";

          let url =
            "data/core/vehicle_note/" + row.id + "/download/attachment";
          if (url !== "") {
            return (
              <>
                {" "}
                <Link
                  to="#"
                  onClick={() => this.downloadInvoice(url, mime_type, name)}
                >
                  {name}
                </Link>
              </>
            );
          }
        },
      },
      {
        dataField: "id",
        text: "",
        sort: false,
        headerClasses:
          "cell-header cell-actions-header cell-vnote-actions-header",
        classes: "cell-actions cell-vnote-actions",
        formatter: (cellContent, row) => {
          return (
            <>
            {row.content.indexOf(Config.INTERNAL_OWNERSHIP_CHANGE) === -1 &&
            <div className="svg">
              <HasAccess
                Module={CORE_MODULE}
                Entity={VEHICLE_NOTE_ENTITY}
                Label={EDIT_PERMISSION}
              >
                <Link
                  to={"#"}
                  onClick={() => {
                    this.editHandler(row);
                  }}
                >
                  <EditBtn />
                </Link>
              </HasAccess>
              <HasAccess
                Module={CORE_MODULE}
                Entity={VEHICLE_NOTE_ENTITY}
                Label={DELETE_PERMISSION}
              >
                <Link
                  to={"#"}
                  onClick={() => {
                    this.deleteHandler(row);
                  }}
                >
                  <DelBtn />
                </Link>
              </HasAccess>
            </div>}
            </>
          );
        },
      },
    ];
  }
  componentDidMount() {
    // let defaultOrder = this.state.sort;
    // let sortOrder = "-" + defaultOrder[0]["order"];
    // let sortField = "me." + defaultOrder[0]["dataField"];
    // let data = {};
    // data.attrs = {};
    // data.attrs["order_by"] = {};
    // data.attrs["prefetch"] = ["vehicle"];
    // data.attrs.order_by[sortOrder] = sortField;
    // data.conds = {};
    // if(this.props.vehicleId !== null) {
    //   data.conds["vehicle.id"] = this.props.vehicleId;
    // }
    // data.page = 1;
    // data.page_size = PagingOptions.sizePerPage;
    // this.props.dispatch(fetchInternalVehicleNote(data));
  }

  handleTableChange(
    type,
    { filters, page, sortField, sortOrder, sizePerPage }
  ) {
    let defaultOrder = this.state.sort;
    let sortOrderstring =
      typeof sortOrder !== "undefined"
        ? "-" + sortOrder
        : "-" + defaultOrder[0]["order"];
    let sort_field =
      defaultOrder[0]["dataField"].indexOf(".") === -1
        ? "me." + defaultOrder[0]["dataField"]
        : defaultOrder[0]["dataField"];
    if (sortField !== null) {
      sort_field =
        sortField.indexOf(".") === -1 ? "me." + sortField : sortField;
    }
    let data = {};
    data.attrs = {};
    data.attrs["prefetch"] = ["vehicle"];
    data.attrs["order_by"] = {};
    data.attrs.order_by[sortOrderstring] = sort_field;

    data.conds = {};
    if(this.props.vehicleId !== null) {
      data.conds["vehicle.id"] = this.props.vehicleId;
      // data.conds["me.content"] = {"-not_like": "%" + Config.INTERNAL_OWNERSHIP_CHANGE + "%"};
    }
    data.page = page;
    data.page_size = sizePerPage;

    this.props.dispatch(fetchInternalVehicleNote(data));
  }

  downloadInvoice(url, mimeType, filename) {
    downloadFile(url, filename, mimeType);
  }

  addHandler() {
    let url =
      "/admin/vehicles/" + this.props.match.params.vid + "/vehicle-notes/add/internal";
    this.props.historyRef.push(url);
  }
  vehicleDetailHandler(row) {
    if (typeof row.vehicle.id !== "undefined") {
      let url = "/admin/vehicles/view/" + row.vehicle.id;
      this.props.historyRef.push(url);
    }
  }

  editHandler(row) {
    let url =
      "/admin/vehicles/" +
      row.vehicle.id +
      "/vehicle-notes-internal/" +
      row.id +
      "/edit";
    this.props.historyRef.push(url);
  }

  deleteHandler(row) {
    let rowId = row.id;
    let successor = row !== null ? row.successor : null;
    let rowData = { post: { successor: null } };
    if (rowId !== null) {
      rowData["id"] = rowId;
    }
    if (successor !== null) {
      rowData["post"]["successor"] = successor;
    }
    rowData["url"] = "/data/core/vehicle_note/" + rowId + "/transitions/delete";
    rowData["redirect_to"] =
      "/admin/vehicles/" + row.vehicle.id + "/vehicle-notes";
    if (row !== null && typeof rowId !== "undefined") {
      this.props.dispatch(showDeleteDialog(rowData));
    }
  }

  render() {
    let options = PagingOptions;
    options.totalSize =
      typeof this.props.internalData !== "undefined" &&
      typeof this.props.internalData.rows !== "undefined" &&
      typeof this.props.internalData.rows.meta.filtered !== "undefined"
        ? this.props.internalData.rows.meta.filtered
        : "";
    options.sizePerPage =
      typeof this.props.internalData !== "undefined" &&
      typeof this.props.internalData.rows !== "undefined" &&
      typeof this.props.internalData.rows.meta.page_size !== "undefined"
        ? this.props.internalData.rows.meta.page_size
        : 10;
    let vehicle_data =
      typeof this.props.internalData !== "undefined" &&
      typeof this.props.internalData.rows !== "undefined" &&
      typeof this.props.internalData.rows.data !== "undefined"
        ? this.props.internalData.rows.data
        : [];

    let columns = this.renderColumns();
    return (
      <BootstrapTable
        remote={{ pagination: true }}
        bootstrap4
        keyField="id"
        data={vehicle_data}
        columns={columns}
        bordered={false}
        rowClasses={"table-row"}
        defaultSorted={this.state.sort}
        pagination={paginationFactory(options)}
        onTableChange={this.handleTableChange}
        noDataIndication={() =>
          this.props.internalData.loading ? <NoDataIndication /> : NO_DATA
        }
      />
    );
  }
}

VehicleInternalNoteScreen.propTypes = {};
VehicleInternalNoteScreen.defaultProps = {
  historyRef: "",
};

const mapStateToProps = (state) => ({
  internalData: state.VNote.internalData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleInternalNoteScreen);
