import { call, all, put, takeLatest } from "redux-saga/effects";
import request from "../../../services/request";
import * as ActionTypes from "../../actions/constants/types";
import {
  forgotSuccess,
  forgotError,
  resetLoginPasswordSuccess,
  resetLoginPasswordError,
} from "../../actions/forgot";

function* forgotSaga({ data }) {
  try {
    const requestURL = "/request_password_reset?email=" + data;
    const response = yield call(request, {
      url: requestURL,
      method: "GET",
    });
    yield put(forgotSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(forgotError(error));
    }
  }
}
function* resetSaga({ data }) {
  try {
    const requestURL = "/password_reset?token=" + data;
    const response = yield call(request, {
      url: requestURL,
      method: "GET",
    });
    yield put(resetLoginPasswordSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(resetLoginPasswordError(error));
    }
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(ActionTypes.FORGOT, forgotSaga),
    yield takeLatest(ActionTypes.RESET_LOGIN_PASSWORD, resetSaga),
  ]);
}
