import { Component } from "react";
import { connect } from "react-redux";
import { Tabs, Tab, Nav } from "react-bootstrap";

import CustomerSearch from "./CustomerSearchScreen";
import VehicleSearch from "./VehicleSearchScreen";

import "font-awesome/css/font-awesome.css";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";
import _ from "lodash";

class SearchByScreen extends Component {
  constructor(props) {
    super(props);
    this.changeTab = this.changeTab.bind(this);
    this.state = { label: "Customer" };
  }
  changeTab = (index, label) => {
    this.setState({ label: _.startCase(_.camelCase(index)) });
  };

  render() {
    return (
      <div className="wrapper">
        <Tab.Container
          className="wrapper"
          variant="tabs"
          id="left-tabs-example"
          defaultActiveKey="customer"
          onSelect={(index, label) => this.changeTab(index, label)}
        >
          <div className="main d-flex align-items-start flex-column">
            <h5 className="page-title">Allgemeine Suche</h5>
            <Nav variant="pills" className="nav nav-tabs search-tab-container">
              <Nav.Item>
                <Nav.Link eventKey="customer">Kunden</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="vehicle">Fahrzeuge</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="sub_main">
            <Tab.Content>
              <Tab.Pane eventKey="customer">
                <CustomerSearch historyRef={this.props.history} />
              </Tab.Pane>
              <Tab.Pane eventKey="vehicle">
                <VehicleSearch historyRef={this.props.history} />
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>
    );
  }
}

SearchByScreen.propTypes = {};
SearchByScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  customerData: state.Customer.customerData,
  vehicleData: state.Vehicle.vehicleData,
  localeData: state.Locale.localeData,
  updateData: state.Vehicle.updateData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(mapStateToProps, mapDispatchToProps)(SearchByScreen);
