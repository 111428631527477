import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import {
  fetchVehicle,
  updateVehicleDocument,
  resetUpdateVehicleDocument,
  fetchDocument,
  fetchVehicleDocumentById,
} from "../../store/actions/vehicle";
import {
  inputErrorHandler,
  signalTransitionSuccess,
  signalTransitionError,
  removeEmptyValues,
  getFileContent,
  checkFileType,
  getMaxBase64StringSize,
  FILE_TOO_LARGE_MESSAGE,
  FILE_NAME_SEPARATOR,
  FILE_TYPE_MESSAGE,
  CORE_MODULE,
  VEHICLE_FILE_ENTITY,
} from "../../utils";
import { showAlertDialog } from "../../store/actions/dialog";
import { DateTimePicker } from "react-tempusdominus-bootstrap";
import moment from "moment";
import AutoRecommend from "../../components/AutoSuggest";

import "font-awesome/css/font-awesome.css";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";
import ColumnText from "../../components/Column";

class VehicleDocumentEditScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        id: "",
        document_id: "",
        document_name: "",
        owner_id: "",
        owner_name: "",
        document_date: moment(),
        vehicle_id: "",
        vehicle_name: "",
        files: "",
      },
      reset: {
        fields: {
          id: "",
          document_id: "",
          document_name: "",
          owner_id: "",
          owner_name: "",
          document_date: moment(),
          vehicle_id: "",
          vehicle_name: "",
          files: "",
        },
        errors: {
          document_id: "",
          owner_id: "",
          expirty_date: "",
          vehicle_id: "",
          files: "",
        },
      },
      errors: {
        document_id: "",
        owner_id: "",
        expirty_date: "",
        vehicle_id: "",
        files: "",
      },
      fileName: "No File Choosen",
      defaultName: "No File Choosen",
      document_value: "",
      owner_value: "",
      vehicle_value: "",
      owner_suggestions: [],
      vehicle_suggestions: [],
      document_suggestions: [],
    };
    this.field = React.createRef();
    this.field_created = React.createRef();
    this.changeFileHandler = this.changeFileHandler.bind(this);
  }

  componentDidMount() {
    let cdata = {};
    cdata.conds = { "system__assignment__assigned_client.agenda": 9 };
    cdata.attrs = {};
    cdata.attrs["prefetch"] = {
      owning_client: "system__assignment__assigned_client",
    };
    // this.props.dispatch(fetchCustomer(cdata));

    let vdata = {};
    vdata.attrs = {};
    vdata.attrs["prefetch"] = [
      "core__vehicle_maintenance__vehicle",
      { owning_customer: "owning_client" },
    ];
    vdata.conds = {};
    this.props.dispatch(fetchVehicle(vdata));

    let data = {};
    data.conds = {};
    data.attrs = {};
    this.props.dispatch(fetchDocument(data));

    let doc = {};
    doc.conds = { "me.id": this.props.match.params.id };
    doc.attrs = {};
    doc.attrs["prefetch"] = [
      "vehicle_document_type",
      "vehicle",
      { owning_customer: "owning_client" },
    ];
    this.props.dispatch(fetchVehicleDocumentById(doc));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.customerData) !==
      JSON.stringify(this.props.customerData)
    ) {
      let customers =
        typeof this.props.customerData !== "undefined" &&
        typeof this.props.customerData.rows !== "undefined" &&
        typeof this.props.customerData.rows.data !== "undefined"
          ? this.props.customerData.rows.data
          : [];
      let data = [];
      let suggestions = this.state.owner_suggestions;
      for (let i = 0; i < suggestions.length; i++) {
        let obj = {};
        obj.id = suggestions.id;
        obj.name = suggestions.name;
        data.push(obj);
      }
      for (let i = 0; i < customers.length; i++) {
        let obj = {};
        if (
          typeof customers[i]["owning_client"] !== "undefined" &&
          customers[i]["owning_client"] !== null
        ) {
          obj.id =
            typeof customers[i]["id"] !== "undefined"
              ? customers[i]["owning_client"]["id"]
              : "";
          obj.name =
            customers[i]["owning_client"]["first_name"] +
            " " +
            customers[i]["owning_client"]["last_name"];
          obj.customer_id =
            typeof customers[i]["id"] !== "undefined" ? customers[i]["id"] : "";
          data.push(obj);
        }
      }
      this.setState({ owner_suggestions: data });
    }

    if (
      JSON.stringify(prevProps.vehicleData) !==
      JSON.stringify(this.props.vehicleData)
    ) {
      let vehicles =
        typeof this.props.vehicleData !== "undefined" &&
        typeof this.props.vehicleData.rows !== "undefined" &&
        typeof this.props.vehicleData.rows.data !== "undefined"
          ? this.props.vehicleData.rows.data
          : [];
      let data = [];
      let suggestions = this.state.vehicle_suggestions;
      for (let i = 0; i < suggestions.length; i++) {
        let obj = {};
        obj.id = suggestions.id;
        obj.name = suggestions.name;
        data.push(obj);
      }
      for (let i = 0; i < vehicles.length; i++) {
        let obj = {};
        if (typeof vehicles[i]["id"] !== "undefined") {
          obj.id =
            typeof vehicles[i]["id"] !== "undefined" ? vehicles[i]["id"] : "";
          obj.name = vehicles[i]["vin"];
          data.push(obj);
        }
      }
      this.setState({ vehicle_suggestions: data });
    }

    if (
      JSON.stringify(prevProps.documentData) !==
      JSON.stringify(this.props.documentData)
    ) {
      let documents =
        typeof this.props.documentData !== "undefined" &&
        typeof this.props.documentData.rows !== "undefined" &&
        typeof this.props.documentData.rows.data !== "undefined"
          ? this.props.documentData.rows.data
          : [];
      let data = [];
      let suggestions = this.state.document_suggestions;
      for (let i = 0; i < suggestions.length; i++) {
        let obj = {};
        obj.id = suggestions.id;
        obj.name = suggestions.name;
        data.push(obj);
      }
      for (let i = 0; i < documents.length; i++) {
        let obj = {};
        if (typeof documents[i]["id"] !== "undefined") {
          obj.id =
            typeof documents[i]["id"] !== "undefined" ? documents[i]["id"] : "";
          obj.name = documents[i]["name"];
          data.push(obj);
        }
      }
      this.setState({ document_suggestions: data });
    }

    if (
      JSON.stringify(prevProps.indexData) !==
      JSON.stringify(this.props.indexData)
    ) {
      let document_data =
        typeof this.props.indexData.rows.data[0] !== "undefined"
          ? this.props.indexData.rows.data[0]
          : null;
      let vehicle_data =
        document_data !== null && typeof document_data.vehicle !== "undefined"
          ? document_data.vehicle
          : null;
      let customer_data =
        document_data !== null &&
        typeof document_data.owning_customer !== "undefined"
          ? document_data.owning_customer
          : null;
      let document_type =
        document_data !== null &&
        typeof document_data.vehicle_document_type !== "undefined"
          ? document_data.vehicle_document_type
          : null;

      let id =
        document_data !== null &&
        document_data.id !== null &&
        typeof document_data.id !== "undefined"
          ? document_data.id
          : null;

      let file_name =
        document_data !== null && typeof document_data.file !== "undefined"
          ? JSON.parse(document_data.file).original_name
          : "";

      let customer_id =
        customer_data !== null && typeof customer_data.id !== "undefined"
          ? customer_data.id
          : null;
      let client_data =
        customer_data !== null &&
        typeof customer_data.owning_client !== "undefined"
          ? customer_data.owning_client
          : null;
      let document_date =
        document_data !== null &&
        document_data.date !== null &&
        typeof document_data.date !== "undefined"
          ? moment(document_data.date)
          : null;

      let document_id =
        document_type !== null &&
        document_type.id !== null &&
        typeof document_type.id !== "undefined"
          ? document_type.id
          : null;
      let document_name =
        document_type !== null &&
        document_type.name !== null &&
        typeof document_type.name !== "undefined"
          ? document_type.name
          : null;
      let vehicle_id =
        vehicle_data !== null && typeof vehicle_data.id !== "undefined"
          ? vehicle_data.id
          : null;
      let vehicle_name =
        vehicle_data !== null && typeof vehicle_data.vin !== "undefined"
          ? vehicle_data.vin
          : null;
      let client_id =
        client_data !== null && typeof client_data.id !== "undefined"
          ? client_data.id
          : "";

      let document = {};
      document["id"] = id;
      document["document_id"] = document_id;
      document["document_name"] = document_name;
      document["owner_id"] = client_id;
      document["owner_name"] = customer_id;
      document["document_date"] = document_date;
      document["vehicle_id"] = vehicle_id;
      document["vehicle_name"] = vehicle_name;
      document["files"] = "";

      var first_name = "";
      var last_name = "";
      if (
        client_data !== null &&
        typeof client_data.first_name !== "undefined"
      ) {
        first_name = client_data.first_name;
      }
      if (
        client_data !== null &&
        typeof client_data.last_name !== "undefined"
      ) {
        last_name = client_data.last_name;
      }
      document["owner_name"] = first_name + " " + last_name;
      document["owner_id"] = client_id;
      this.setState({ owner_value: first_name + " " + last_name });
      this.setState({ fields: document });
      let reset = this.state.reset;
      reset.fields = document;
      this.setState({ reset: reset });
      if (vehicle_name !== null) {
        this.setState({ vehicle_value: vehicle_name });
      }
      if (document_name !== null) {
        this.setState({ document_value: document_name });
      }
      if (file_name !== null) {
        this.setState({ fileName: file_name });
      }
    }

    if (this.props.updateData.success) {
      this.props.dispatch(resetUpdateVehicleDocument());
      signalTransitionSuccess("Update Success");
      let url = "/admin/vehicles/" + this.props.match.params.vid + "/documents";
      this.props.history.push(url);
    } else if (this.props.updateData.error) {
      this.props.dispatch(resetUpdateVehicleDocument());
      signalTransitionError("Update Failed");
    }
  }

  changeFileHandler(evt) {
    let errors = this.state.errors;
    errors["files"] = "";
    this.setState({ errors: errors });
    if (
      typeof evt.target.files[0] !== "undefined" &&
      typeof evt.target.files[0].name !== "undefined"
    ) {
      this.setState({ fileName: evt.target.files[0].name });
      if (checkFileType(evt.target.files)) {
        getFileContent(evt.target.files)
          .then((data) => {
            if (data.length > getMaxBase64StringSize()) {
              this.props.dispatch(showAlertDialog(FILE_TOO_LARGE_MESSAGE));
              this.setState({ fileName: this.state.defaultName });
              let fields = this.state.fields;
              fields["files"] = "";
              this.setState({ fields: fields });
            } else {
              //console.log(`${this.state.fileName}${FILE_NAME_SEPARATOR}${data}`);
              let fields = this.state.fields;
              fields[
                "files"
              ] = `${this.state.fileName}${FILE_NAME_SEPARATOR}${data}`;
              this.setState({ fields: fields });
            }
          })
          .catch((e) => console.log("Error: ", e.message));
      } else {
        this.props.dispatch(showAlertDialog(FILE_TYPE_MESSAGE));
        this.setState({ fileName: this.state.defaultName });
        let fields = this.state.fields;
        fields["files"] = "";
        this.setState({ fields: fields });
      }
    }
  }

  onDocumentChange = (newValue) => {
    this.setState({
      document_value: newValue,
    });
    let errors = this.state.errors;
    errors["document_id"] = "";
    this.setState({ errors: errors });
  };

  onDocumentFetchRequested = (value) => {
    this.loadDocumentSuggestions(value);
  };

  onDocumentClearRequested = () => {
    this.setState({
      document_suggestions: [],
    });
  };

  loadDocumentSuggestions(value) {
    if (value.trim() !== "") {
      let vdata = {};
      vdata.attrs = {};
      vdata.conds = { name: { "-like": "%" + value + "%" } };
      this.props.dispatch(fetchDocument(vdata));
    }
  }

  loadMoreDocumentReferences = () => {
    let meta_data =
      typeof this.props.vehicleData !== "undefined" &&
      typeof this.props.vehicleData.rows !== "undefined" &&
      typeof this.props.vehicleData.rows.meta !== "undefined"
        ? this.props.vehicleData.rows.meta
        : [];

    let vdata = {};
    vdata.attrs = meta_data.attrs;
    vdata.conds = meta_data.conds;
    vdata.page = meta_data.page + 1;
    vdata.page_size = meta_data.page_size;
    this.props.dispatch(fetchDocument(vdata));
  };

  onDocumentSelect = (suggestionValue) => {
    const reference = this.state.document_suggestions.find(
      (r) => r["name"] === suggestionValue //.replace('/Maintenance - /i',''),
    );
    let fields = this.state.fields;
    fields["document_id"] = reference.id;
    fields["document_name"] = reference.name;
    this.setState({ fields: fields });
  };
  onVehicleFetchRequested = (value) => {
    this.loadVehicleSuggestions(value);
  };

  onVehicleClearRequested = () => {
    this.setState({
      vehicle_suggestions: [],
    });
  };

  loadVehicleSuggestions(value) {
    if (value !== "") {
      let vdata = {};
      vdata.attrs = {};
      vdata.attrs["prefetch"] = [
        "core__vehicle_maintenance__vehicle",
        { owning_customer: "owning_client" },
      ];
      vdata.conds = {
        vin: { "-like": "%" + value + "%" },
        "owning_customer.id": this.state.fields.customer_id,
      };
      this.props.dispatch(fetchVehicle(vdata));
    }
  }

  loadMoreVehicleReferences = () => {
    let meta_data =
      typeof this.props.vehicleData !== "undefined" &&
      typeof this.props.vehicleData.rows !== "undefined" &&
      typeof this.props.vehicleData.rows.meta !== "undefined"
        ? this.props.vehicleData.rows.meta
        : [];

    let vdata = {};
    vdata.attrs = meta_data.attrs;
    vdata.conds = meta_data.conds;
    vdata.page = meta_data.page + 1;
    vdata.page_size = meta_data.page_size;
    this.props.dispatch(fetchVehicle(vdata));
  };

  onVehicleSelect = (suggestionValue) => {
    const reference = this.state.vehicle_suggestions.find(
      (r) => r["name"] === suggestionValue
    );
    let fields = this.state.fields;
    fields["vehicle_id"] = reference.id;
    fields["vehicle_name"] = reference.name;
    this.setState({ fields: fields });
  };

  resetHandler(evt) {
    this.setState({ fields: this.state.reset.fields });
    this.setState({ errors: this.state.reset.errors });
    this.setState({ document_value: "" });
    this.setState({ owner_value: "" });
    this.setState({ vehicle_value: "" });
  }

  submitHandler(evt) {
    evt.preventDefault();
    // get our new errors
    let fields = this.state.fields;
    let newErrors = {};
    newErrors["document_id"] = inputErrorHandler("", fields.document_id, true);
    // newErrors["owner_id"] = inputErrorHandler("", fields.owner_id, true);
    newErrors["document_date"] = inputErrorHandler(
      "",
      fields.document_date,
      true
    );
    newErrors["vehicle_id"] = inputErrorHandler("", fields.vehicle_id, true);
    // newErrors['files'] = inputErrorHandler('',fields.files,true);

    if (fields.document_id === "" || fields.document_name === "") {
      newErrors["document_id"] = "Required Parameter";
    } else if (fields.document_name !== this.state.document_value) {
      newErrors["document_id"] = "Please select valid Document";
    }
    // if (fields.owner_id === "" || fields.owner_name === "") {
    //   newErrors["owner_id"] = "Required Parameter";
    // } else if (fields.vehicle_name !== this.state.vehicle_value) {
    //   newErrors["owner_id"] = "Please select valid Owner";
    // }

    if (fields.vehicle_id === "" || fields.vehicle_name === "") {
      newErrors["vehicle_id"] = "Required Parameter";
    } else if (fields.vehicle_name !== this.state.vehicle_value) {
      newErrors["vehicle_id"] = "Please select valid Vehicle";
    }

    let errors = removeEmptyValues(newErrors);
    if (Object.keys(errors).length > 0) {
      this.setState({ errors: errors });
    } else {
      let documentData = { document_id: "", document: {} };
      documentData.document_id = fields.id;
      documentData.document.vehicle = fields.vehicle_id;
      documentData.document.date = fields.document_date;
      documentData.document.vehicle_document_type = fields.document_id;
      if (fields.files !== "") {
        documentData.document.file = fields.files;
      }
      this.props.dispatch(updateVehicleDocument(documentData));
    }
  }

  changeHandler(evt, name) {
    let value = evt.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: null,
      },
    }));
  }

  expiryHandler = (value) => {
    let items = this.state.fields;
    items["document_date"] = value;
    this.setState({ fields: items });

    let errors = this.state.errors;
    errors["document_date"] = "";
    this.setState({ errors: errors });
  };
  setFieldPlaceholder = () => {
    if (
      typeof this.field.current[0] !== "undefined" &&
      typeof this.field.current[0].children[0] !== "undefined"
    ) {
      this.field.current[0].children[0].setAttribute("placeholder", "Datum");
    }
  };
  setFieldCreatedPlaceholder = () => {
    if (
      typeof this.field_created.current[0] !== "undefined" &&
      typeof this.field_created.current[0].children[0] !== "undefined"
    ) {
      this.field_created.current[0].children[0].setAttribute(
        "placeholder",
        "Datum"
      );
    }
  };

  render() {
    return (
      <div className="wrapper">
        <div className="main d-flex align-items-start flex-column">
          <h4>
            {" "}
            <ColumnText
              Module={CORE_MODULE}
              Entity={VEHICLE_FILE_ENTITY}
            />{" "}
            bearbeiten
          </h4>
        </div>
        <div className="sub_main">
          <Form>
            <Row>
              <Col lg={6}></Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group as={Row} controlId="vehicle_id">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_FILE_ENTITY}
                      Label={"vehicle"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <AutoRecommend
                      value={this.state.vehicle_value}
                      onValueChange={this.onVehicleChange}
                      suggestions={this.state.vehicle_suggestions}
                      onSuggestionsClearRequested={this.onVehicleClearRequested}
                      fetchSuggestions={this.onVehicleFetchRequested}
                      onSelect={this.onVehicleSelect}
                      placeholder="Wählen Sie Fahrzeug"
                      disabled
                      onScrollEnd={this.loadMoreVehicleReferences}
                      inputClasses={
                        typeof this.state.errors.vehicle_id !== "undefined" &&
                        this.state.errors.vehicle_id !== ""
                          ? "is-invalid form-control"
                          : "form-control"
                      }
                    />
                    {typeof this.state.errors.vehicle_id !== "undefined" &&
                    this.state.errors.vehicle_id !== "" ? (
                      <div className="has-error">
                        {this.state.errors.vehicle_id}
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
                {/* <Form.Group as={Row} controlId="created_date">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_FILE_ENTITY}
                      Label={"created"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <DateTimePicker
                      pickerRef={this.field_created}
                      callback={() => this.setFieldCreatedPlaceholder()}
                      date={this.state.fields.created_date}
                      defaultDate={this.state.fields.created_date}
                      format="YYYY-MM-DD"
                      onChange={(e) => this.createdHandler(e.date)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.created_date}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group> */}
                <Form.Group as={Row} controlId="document_date">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_FILE_ENTITY}
                      Label={"date"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <DateTimePicker
                      pickerRef={this.field}
                      callback={() => this.setFieldPlaceholder()}
                      date={this.state.fields.document_date}
                      defaultDate={this.state.fields.document_date}
                      readOnly
                      format="YYYY-MM-DD"
                      onChange={(e) => this.expiryHandler(e.date)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.document_date}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group as={Row} controlId="document_id">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_FILE_ENTITY}
                      Label={"vehicle_document_type"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <AutoRecommend
                      value={this.state.document_value}
                      onValueChange={this.onDocumentChange}
                      suggestions={this.state.document_suggestions}
                      onSuggestionsClearRequested={
                        this.onDocumentClearRequested
                      }
                      fetchSuggestions={this.onDocumentFetchRequested}
                      onSelect={this.onDocumentSelect}
                      placeholder="Wählen Sie Dokumententyp"
                      onScrollEnd={this.loadMoreDocumentReferences}
                      inputClasses={
                        typeof this.state.errors.document_id !== "undefined" &&
                        this.state.errors.document_id !== ""
                          ? "is-invalid form-control"
                          : "form-control"
                      }
                    />
                    {typeof this.state.errors.document_id !== "undefined" &&
                    this.state.errors.document_id !== "" ? (
                      <div className="has-error">
                        {this.state.errors.document_id}
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="first_inspection">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_FILE_ENTITY}
                      Label={"file"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.File
                      type="file"
                      // className={(this.state.errors.files !== "")?"is-invalid custom-file-label":"form-control custom-file-label"}
                      id="inputGroupFile01"
                      label={this.state.fileName}
                      onChange={(evt) => this.changeFileHandler(evt)}
                      accept="application/pdf, image/*"
                      custom
                      isInvalid={!!this.state.errors.files}
                    />
                    {this.state.errors.files !== "" ? (
                      <div className="has-error">{this.state.errors.files}</div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
                <div className="form-btn">
                  <Button
                    type="Button"
                    className="btn btn-dark Button"
                    onClick={(evt) => this.submitHandler(evt)}
                  >
                    OK
                  </Button>
                  <Button
                    type="Button"
                    className="btn btn-dark"
                    onClick={(evt) => this.resetHandler(evt)}
                  >
                    Abbrechen
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

VehicleDocumentEditScreen.propTypes = {};
VehicleDocumentEditScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  customerData: state.Customer.customerData,
  vehicleData: state.Vehicle.vehicleData,
  documentData: state.Document.documentData,
  localeData: state.Locale.localeData,
  updateData: state.VDocument.updateData,
  indexData: state.VDocument.indexData,
  clientData: state.Customer.indexData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleDocumentEditScreen);
