import React, { Component } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { forgot, resetForgot } from "../../store/actions/forgot";
import { inputErrorHandler, removeEmptyValues } from "../../utils";
class ForgotPasswordScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        user_email: "",
      },
      errors: {
        user_email: "",
        userMsg: "",
      },
    };
  }
  componentDidUpdate(prevProps, prevState) {
    /*if (this.props.indexData.success) {
          this.props.dispatch(resetForgot());
          //signalTransitionSuccess("Update Success");
          //let url = "/admin/maintenance";
          //this.props.history.push(url);
        } else if (this.props.indexData.error) {
          this.props.dispatch(resetForgot());
          let errors = this.state.errors;
          errors['msg'] = this.props.indexData.msg;
          this.setState({'errors':"errors"})
          //signalTransitionError("Update Failed");
        }*/
  }

  submitHandler(evt) {
    evt.preventDefault();
    // get our new errors
    let fields = this.state.fields;
    let newErrors = {};
    newErrors["user_email"] = inputErrorHandler("", fields.user_email, true);
    let errors = removeEmptyValues(newErrors);
    // Conditional logic:
    if (Object.keys(errors).length > 0) {
      this.setState({ errors: errors });
    } else {
      console.log(newErrors);
      this.props.dispatch(forgot(fields.user_email));
    }
  }

  changeHandler(evt, name) {
    let value = evt.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: null,
      },
    }));
  }

  render() {
    return (
      <>
        <div className={"outer"}>
          <div className={"inner"}>
            <Form>
              <h4>Passwort vergessen</h4>
              <p>
                Geben Sie Ihre E-Mail-Adresse ein und wir senden Ihnen
                Anweisungen zum Zurücksetzen Ihres Passworts
              </p>
              {this.props.indexData.error && (
                <>
                  <Alert
                    variant="danger"
                    onClose={() => this.props.dispatch(resetForgot())}
                    dismissible
                  >
                    <p>{this.props.indexData.msg}</p>
                  </Alert>
                </>
              )}
              {this.props.indexData.success && (
                <>
                  <Alert
                    variant="success"
                    onClose={() => this.props.dispatch(resetForgot())}
                    dismissible
                  >
                    <p>{this.props.indexData.msg}</p>
                  </Alert>
                </>
              )}
              <Form.Group controlId="user_email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Email"
                  name="user_email"
                  value={this.state.fields.user_email}
                  onChange={(evt) => this.changeHandler(evt, "user_email")}
                  onKeyPress={this.onKeyPress}
                  isInvalid={!!this.state.errors.user_email}
                />
                <Form.Control.Feedback type="invalid">
                  {this.state.errors.user_email}
                </Form.Control.Feedback>
              </Form.Group>

              <div className="form-search-btn">
                <Button
                  variant="primary"
                  className="btn btn-dark button"
                  type="button"
                  onClick={(evt) => this.submitHandler(evt)}
                  disabled={
                    this.state.errors.user_email || this.state.errors.password
                  }
                >
                  OK
                </Button>
                <Button href="/" variant="link">
                  Anmeldung
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </>
    );
  }
}
ForgotPasswordScreen.propTypes = {};
ForgotPasswordScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  loginData: state.Login.loginData,
  indexData: state.Forgot.indexData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordScreen);
