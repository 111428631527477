import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  PagingOptions,
  NoDataIndication,
  DelBtn,
  EditBtn,
  ListBtn,
  ViewBtn,
  DocumentBtn,
  removeEmptyValues,
  CORE_MODULE,
  VEHICLE_ENTITY,
  VEHICLE_NOTE_ENTITY,
  CUSTOMER_ENTITY,
  VEHICLE_MAINTENANCE_ENTITY,
  DELETE_PERMISSION,
  MODIFY_PERMISSION,
  INSERT_PERMISSION,
  NO_DATA,
} from "../../utils";
import { fetchVehicle } from "../../store/actions/vehicle";
import { showDeleteDialog } from "../../store/actions/dialog";
import { resetVehicleParams } from "../../store/actions/search";
import moment from "moment";
import { HasAccess } from "../../components/Access";
import ColumnText from "../../components/Column";
import { Link } from "react-router-dom";

class VehicleScreen extends Component {
  customerId = null;

  constructor(props) {
    super(props);
    this.state = {
      showAll: true,
      sorted: [
        {
          dataField: "id",
          order: "desc",
        },
      ],
    };
    this.customerId = typeof (props.match.params.cid) !== "undefined" ? props.match.params.cid : null;
    this.handleTableChange = this.handleTableChange.bind(this);
    this.viewHandler = this.viewHandler.bind(this);
    this.listHandler = this.listHandler.bind(this);
    this.editHandler = this.editHandler.bind(this);
    this.addHandler = this.addHandler.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
    this.documentHandler = this.documentHandler.bind(this);
    this.vehicleDetailHandler = this.vehicleDetailHandler.bind(this);
    this.viewMaintenanceHandler = this.viewMaintenanceHandler.bind(this);
  }

  renderColumns = () => {
    return [
      {
        dataField: "vin",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={VEHICLE_ENTITY}
            Label={"vin"}
          />
        ),
        sort: true,
        headerClasses: "cell-header cell-vehicle-vin-header",
        classes: "cell-vehicle-vin-header",
        formatter: (cellContent, row) => {
          if (cellContent !== "") {
            return (
              <>
                <Link
                  to={"#"}
                  onClick={() => {
                    this.vehicleDetailHandler(row);
                  }}
                >
                  {cellContent}
                </Link>
              </>
            );
          }
        },
      },
      {
        dataField: "licence_plate",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={VEHICLE_ENTITY}
            Label={"licence_plate"}
          />
        ),
        sort: true,
        headerClasses: "cell-header cell-vehicle-licence-plate-header",
        classes: "cell-vehicle-licence-plate",
      },
      {
        dataField: "brand",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={VEHICLE_ENTITY}
            Label={"brand"}
          />
        ),
        sort: true,
        headerClasses: "cell-header cell-vehicle-brand-header",
        classes: "cell-vehicle-brand",
      },
      {
        dataField: "model",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={VEHICLE_ENTITY}
            Label={"model"}
          />
        ),
        sort: true,
        headerClasses: "cell-header cell-vehicle-model-header",
        classes: "cell-vehicle-model",
      },
      {
        dataField: "owning_customer.is_client",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={CUSTOMER_ENTITY}
            Label={"is_client"}
          />
        ),
        sort: true,
        headerClasses: "cell-header cell-vehicle-is-private",
        classes: "cell-vehicle-is-private",
        formatter: (cellContent, row) => {
          if (cellContent === 1) {
            return <>{"Kunde"}</>;
          } else {
            return <>{"Firma"}</>;
          }
        },
      },
      {
        dataField: "owning_customer",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={VEHICLE_ENTITY}
            Label={"owning_customer"}
          />
        ),
        sort: true,
        headerClasses: "cell-header cell-vehicle-owning-client-header",
        classes: "cell-vehicle-owning-client",
        formatter: (cellContent, row) => {
          let customer_name = "";
          if (cellContent.is_client === 1) {
            var obj = cellContent.owning_client;
            var first_name =
              obj !== null &&
                typeof obj !== "undefined" &&
                typeof obj.first_name !== "undefined"
                ? obj.first_name
                : "";
            var last_name =
              obj !== null &&
                typeof obj !== "undefined" &&
                typeof obj.last_name !== "undefined"
                ? obj.last_name
                : "";
            customer_name = first_name + " " + last_name;
          } else {
            customer_name = cellContent.name;
          }
          return <>{customer_name}</>;
        },
      },
      {
        dataField: "core__vehicle_maintenance__vehicle",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={VEHICLE_MAINTENANCE_ENTITY}
            Label={"date"}
          />
        ),
        sort: false,
        headerClasses: "cell-header cell-vehicle-service-header",
        classes: "cell-vehicle-service",
        formatter: (cellContent, row) => {
          let service_date = "";
          if (cellContent !== null && typeof cellContent !== "undefined") {
            if (cellContent.length > 0) {
              let total = cellContent.length;
              let last = cellContent[total - 1];
              let mant_id =
                typeof last.id !== "undefined" && last.id !== null
                  ? last.id
                  : "";
              service_date =
                typeof last.date !== "undefined"
                  ? moment(last.date).format("DD.MM.YYYY")
                  : "";
              if (service_date !== "" && mant_id !== "") {
                return (
                  <>
                    <Link
                      to={"#"}
                      onClick={() => {
                        this.viewMaintenanceHandler(mant_id);
                      }}
                    >
                      {service_date}
                    </Link>
                  </>
                );
              }
            }
          }
          return <>{service_date}</>;
        },
      },
      {
        dataField: "id",
        text: "",
        sort: false,
        headerClasses:
          "cell-header cell-actions-header cell-vehicle-actions-header",
        classes: "cell-actions cell-vehicle-actions",
        formatter: (cellContent, row) => {
          return (
            <div className="svg">
              {typeof row.core__vehicle_file__vehicle !== "undefined" &&
                row.core__vehicle_file__vehicle.length > 0 ? (
                <Link
                  to={"#"}
                  onClick={() => {
                    this.documentHandler(row);
                  }}
                >
                  <DocumentBtn />
                </Link>
              ) : (
                ""
              )}
              <HasAccess Module={CORE_MODULE} Entity={VEHICLE_NOTE_ENTITY}>
                <Link
                  to={"#"}
                  onClick={() => {
                    this.listHandler(row);
                  }}
                >
                  <ListBtn />
                </Link>
              </HasAccess>
              <Link
                to={"#"}
                onClick={() => {
                  this.viewHandler(row);
                }}
              >
                <ViewBtn />
              </Link>

              <HasAccess
                Module={CORE_MODULE}
                Entity={VEHICLE_ENTITY}
                Label={MODIFY_PERMISSION}
              >
                <Link
                  to={"#"}
                  onClick={() => {
                    this.editHandler(row);
                  }}
                >
                  <EditBtn />
                </Link>
              </HasAccess>
              <HasAccess
                Module={CORE_MODULE}
                Entity={VEHICLE_ENTITY}
                Label={DELETE_PERMISSION}
              >
                <Link
                  to={"#"}
                  onClick={() => {
                    this.deleteHandler(row);
                  }}
                >
                  <DelBtn />
                </Link>
              </HasAccess>
            </div>
          );
        },
      },
    ];
  };

  deleteHandler = (row) => {
    let rowId = row.id;
    let successor = row !== null ? row.successor : null;
    let rowData = { post: { successor: null } };
    if (rowId !== null) {
      rowData["id"] = rowId;
    }
    if (successor !== null) {
      rowData["post"]["successor"] = successor;
    }
    rowData["url"] =
      "/data/" +
      CORE_MODULE +
      "/" +
      VEHICLE_ENTITY +
      "/" +
      rowId +
      "/transitions/delete";
    rowData["redirect_to"] = "/admin/vehicles";
    if (row !== null && typeof rowId !== "undefined") {
      this.props.dispatch(showDeleteDialog(rowData));
    }
  };

  getSearchParams = (clearSearchParams) => {
    if (clearSearchParams) {
      this.props.dispatch(resetVehicleParams());
      return {};
    }
    let fields = removeEmptyValues(this.props.searchData.data);

    if (fields && Object.keys(fields).length === 0) {
      this.setState({ showAll: true });
    } else {
      this.setState({ showAll: false });
    }

    let licence_plate =
      fields !== null && typeof fields.licence_plate !== "undefined"
        ? fields.licence_plate
        : "";
    let brand_name =
      fields !== null && typeof fields.brand_name !== "undefined"
        ? fields.brand_name
        : "";
    let model =
      fields !== null && typeof fields.model !== "undefined"
        ? fields.model
        : "";
    let customer_name =
      fields !== null && typeof fields.customer_name !== "undefined"
        ? fields.customer_name
        : "";
    let service_date =
      fields !== null && typeof fields.service_date !== "undefined"
        ? fields.service_date
        : "";
    let service_date_from =
      fields !== null && typeof fields.service_period_from !== "undefined"
        ? fields.service_period_from
        : "";
    let service_date_to =
      fields !== null && typeof fields.service_period_to !== "undefined"
        ? fields.service_period_to
        : "";
    let search_cond = {};

    if (fields !== "" && fields !== null) {
      let conds = [];
      if (licence_plate !== "") {
        let cond_1 = {
          "me.licence_plate": {
            "-ilike": "%" + licence_plate + "%",
          },
        };
        conds.push(cond_1);
      }

      if (brand_name !== "") {
        let cond_2 = {
          "me.brand": {
            "-ilike": "%" + brand_name + "%",
          },
        };
        conds.push(cond_2);
      }

      if (model !== "") {
        let cond_3 = {
          "me.type": {
            "-ilike": "%" + model + "%",
          },
        };
        conds.push(cond_3);
      }
      if (customer_name !== "") {
        let cond_4 = {
          "-or": [
            {
              "-and": [
                { "owning_customer.is_client": 1 },
                {
                  "-or": [
                    {
                      "owning_client.first_name": {
                        "-ilike": "%" + customer_name + "%",
                      },
                    },
                    {
                      "owning_client.last_name": {
                        "-ilike": "%" + customer_name + "%",
                      },
                    },
                  ],
                }
              ]
            },
            {
              "-and": [
                { "owning_customer.is_client": 0 },
                {
                  "owning_customer.name": {
                    "-ilike": "%" + customer_name + "%",
                  },
                },
              ],
            },
          ],
        };
        conds.push(cond_4);
      }
      if (this.customerId !== null) {
        let cond_5 = {
          "-and": [
            {
              "-and": [
                //{ "me.is_client": 1 },
                {
                  "-and": [
                    //{ "me.is_client": 0 },
                    {
                      "-or": [
                        {
                          "owning_customer.id": this.customerId,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        };
        conds.push(cond_5);
      }
      let service_conds = [];
      if (service_date !== "") {
        let cond_4 = {
          "core__vehicle_maintenance__vehicle.date": service_date,
        };
        service_conds.push(cond_4);
      }

      if (service_date_from !== "" && service_date_to !== "") {
        let cond_5 = {
          "core__vehicle_maintenance__vehicle.date": {
            "-between": [service_date_from, service_date_to],
          },
        };
        service_conds.push(cond_5);
      }
      if (service_conds.length > 1) {
        conds.push({ "-or": [service_conds] });
      } else if (service_conds.length > 0) {
        conds.push(service_conds);
      }

      if (conds.length > 1) {
        search_cond["-and"] = conds;
      } else if (conds.length > 0) {
        search_cond = conds;
      } else {
        search_cond = {};
      }
    }
    // this.props.dispatch(resetVehicleParams());

    return search_cond;
  };

  componentDidMount() {
    // this.loadVehicles();
  }

  loadVehicles(clearSearchParams) {
    let search_cond = this.getSearchParams(clearSearchParams);
    let defaultOrder = this.state.sorted;
    let sortOrder = "-" + defaultOrder[0]["order"];
    let sortField = "me." + defaultOrder[0]["dataField"];
    let data = {};
    data.attrs = {};
    data.attrs["prefetch"] = [
      "core__vehicle_maintenance__vehicle",
      { owning_customer: "owning_client" },
      "core__vehicle_file__vehicle",
    ];
    data.attrs["order_by"] = {};
    data.attrs.order_by[sortOrder] = sortField;
    data.conds = search_cond;
    data.page = 1;
    data.page_size = PagingOptions.sizePerPage;
    this.props.dispatch(fetchVehicle(data));
  }

  handleTableChange(
    type,
    { filters, page, sortField, sortOrder, sizePerPage }
  ) {
    let search_cond = this.getSearchParams(false);
    let conds = {};
    if (this.customerId !== null) {
      conds["owning_customer.id"] = this.customerId;

      var all = [];
      all.push(conds);
      all.push(search_cond);
      search_cond = {};
      search_cond["-and"] = all;
    }

    let defaultOrder = this.state.sorted;
    let sortOrderstring =
      typeof sortOrder !== "undefined"
        ? "-" + sortOrder
        : "-" + defaultOrder[0]["order"];
    let sort_field =
      defaultOrder[0]["dataField"].indexOf(".") === -1
        ? "me." + defaultOrder[0]["dataField"]
        : defaultOrder[0]["dataField"];
    if (sortField !== null) {
      sort_field =
        sortField.indexOf(".") === -1 ? "me." + sortField : sortField;
    }
    let data = {};
    data.attrs = {};
    data.attrs["prefetch"] = [
      "core__vehicle_maintenance__vehicle",
      { owning_customer: "owning_client" },
      "core__vehicle_file__vehicle",
    ];
    data.attrs["order_by"] = {};
    data.attrs.order_by[sortOrderstring] = sort_field;
    data.conds = search_cond;
    data.page = page;
    data.page_size = sizePerPage;
    this.props.dispatch(fetchVehicle(data));
  }
  viewMaintenanceHandler = (id) => {
    let url = "/admin/maintenance/view/" + id;
    this.props.history.push(url);
  };

  viewHandler = (row) => {
    let url = "/admin/vehicles/view/" + row.id;
    this.props.history.push(url);
  };
  editHandler = (row) => {
    let url = "/admin/vehicles/" + row.id + "/edit";
    this.props.history.push(url);
  };

  addHandler = () => {
    let url = "/admin/vehicles/add";
    if (this.customerId) {
      url = "/admin/customers/" + this.customerId + "/vehicles/add";
    }
    this.props.history.push(url);
  };
  listHandler = (row) => {
    let url = "/admin/vehicles/" + row.id + "/vehicle-notes";
    this.props.history.push(url);
  };

  documentHandler = (row) => {
    let url = "/admin/vehicles/" + row.id + "/documents";
    this.props.history.push(url);
  };
  vehicleDetailHandler = (row) => {
    let url = "/admin/vehicles/view/" + row.id;
    this.props.history.push(url);
  };

  render() {
    let options = PagingOptions;
    options.totalSize =
      typeof this.props.vehicleData !== "undefined" &&
        typeof this.props.vehicleData.rows !== "undefined" &&
        typeof this.props.vehicleData.rows.meta !== "undefined" &&
        typeof this.props.vehicleData.rows.meta.filtered !== "undefined"
        ? this.props.vehicleData.rows.meta.filtered
        : "";
    options.sizePerPage =
      typeof this.props.vehicleData !== "undefined" &&
        typeof this.props.vehicleData.rows !== "undefined" &&
        typeof this.props.vehicleData.rows.meta !== "undefined" &&
        typeof this.props.vehicleData.rows.meta.page_size !== "undefined"
        ? this.props.vehicleData.rows.meta.page_size
        : 10;
    let vehicle_data =
      typeof this.props.vehicleData !== "undefined" &&
        typeof this.props.vehicleData.rows !== "undefined" &&
        typeof this.props.vehicleData.rows.data !== "undefined"
        ? this.props.vehicleData.rows.data
        : [];

    let columns = this.renderColumns();

    return (
      <div className="wrapper_datatable">
        <div className="table_main">
          <h4>
            <ColumnText Module={CORE_MODULE} Entity={VEHICLE_ENTITY} />
          </h4>
          {!this.state.showAll && (
            <button
              type="button"
              className="btn btn-dark pull-right"
              onClick={() => {
                this.loadVehicles(true);
                this.setState({ showAll: true });
              }}
            >
              Show All
            </button>
          )}
          <HasAccess
            Module={CORE_MODULE}
            Entity={VEHICLE_ENTITY}
            Label={INSERT_PERMISSION}
          >
            <button
              type="button"
              className="btn btn-dark"
              onClick={() => {
                this.addHandler();
              }}
            >
              {" "}
              <ColumnText
                Module={CORE_MODULE}
                Entity={VEHICLE_ENTITY}
              />{" "}
              hinzufügen
            </button>
          </HasAccess>
        </div>
        <BootstrapTable
          remote={{ pagination: true }}
          bootstrap4
          keyField="id"
          data={vehicle_data}
          columns={columns}
          bordered={false}
          rowClasses={"table-row"}
          defaultSorted={this.state.sorted}
          pagination={paginationFactory(options)}
          onTableChange={this.handleTableChange}
          noDataIndication={() =>
            this.props.vehicleData.loading ? <NoDataIndication /> : NO_DATA
          }
        />
      </div>
    );
  }
}

VehicleScreen.propTypes = {};
VehicleScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  vehicleData: state.Vehicle.vehicleData,
  deleteData: state.Delete.deleteData,
  dialogData: state.Dialog.dialogData,
  searchData: state.Search.vehicleData,
  localeData: state.Locale.localeData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(mapStateToProps, mapDispatchToProps)(VehicleScreen);
