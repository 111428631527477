
export const DELETE_DIALOG_SHOW = 'constants/dialog/DELETE_DIALOG_SHOW';
export const DELETE_DIALOG_HIDE = 'constants/dialog/DELETE_DIALOG_HIDE';

export const LOGOUT_DIALOG_SHOW = 'constants/dialog/LOGOUT_DIALOG_SHOW';
export const LOGOUT_DIALOG_HIDE = 'constants/dialog/LOGOUT_DIALOG_HIDE';

export const MODAL_DIALOG_SHOW = 'constants/dialog/MODAL_DIALOG_SHOW';
export const MODAL_DIALOG_HIDE = 'constants/dialog/MODAL_DIALOG_HIDE';

export const ALERT_DIALOG_SHOW = 'constants/dialog/ALERT_DIALOG_SHOW';
export const ALERT_DIALOG_HIDE = 'constants/dialog/ALERT_DIALOG_HIDE';
export const RESET_DIALOG = 'constants/dialog/RESET_DIALOG';