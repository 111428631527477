import { call, all, put, takeLatest } from "redux-saga/effects";
import { authHeader } from "../../../utils";
import request from "../../../services/request";
import * as ActionTypes from "../../actions/constants";
import {
  fetchComponentSuccess,
  fetchComponentError,
  fetchComponentByIdSuccess,
  fetchComponentByIdError,
  createComponentSuccess,
  createComponentError,
  updateComponentSuccess,
  updateComponentError,
} from "../../actions/vehicle";

function* componentSaga({ data }) {
  try {
    const requestURL = "data/core/vehicle_component/query";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data,
    });
    yield put(fetchComponentSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined"
    ) {
      yield put(fetchComponentError(error.data.error));
    }
  }
}

function* indexSaga({ data }) {
  try {
    const requestURL = "data/core/vehicle_component/query";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data,
    });
    yield put(fetchComponentByIdSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined"
    ) {
      yield put(fetchComponentByIdError(error.data.error));
    }
  }
}

function* createSaga({ data }) {
  try {
    let componentData =
      typeof data.component !== "undefined" && data.component !== null
        ? data.component
        : null;
    const requestURL = "data/core/vehicle_component/transitions/create";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: componentData,
    });
    yield put(createComponentSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined"
    ) {
      yield put(createComponentError(error.data.error));
    }
  }
}

function* updateSaga({ data }) {
  try {
    let component_id =
      typeof data.id !== "undefined" && data.id !== null ? data.id : null;
    let componentData =
      typeof data.component !== "undefined" && data.component !== null
        ? data.component
        : null;
    const requestURL =
      "data/core/vehicle_component/" + component_id + "/transitions/edit";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: componentData,
    });
    yield put(updateComponentSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined"
    ) {
      yield put(updateComponentError(error.data.error));
    }
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(ActionTypes.GET_COMPONENT, componentSaga),
    yield takeLatest(ActionTypes.GET_COMPONENT_BY_ID, indexSaga),
    yield takeLatest(ActionTypes.CREATE_COMPONENT, createSaga),
    yield takeLatest(ActionTypes.UPDATE_COMPONENT, updateSaga),
  ]);
}
