import { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import {
  createCompanyUser,
  resetCreateCompanyUser,
} from "../../store/actions/company";
import { fetchCustomer } from "../../store/actions/customer";

import {
  inputErrorHandler,
  signalTransitionSuccess,
  signalTransitionError,
  removeEmptyValues,
  SYSTEM_MODULE,
  CLIENT_ENTITY,
} from "../../utils";
import ColumnText from "../../components/Column";

class UserAddScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        password: "",
        confirm_password: "",
        // salutation: "",
        first_name: "",
        last_name: "",
        email: "",
      },
      errors: {
        // salutation: "",
        password: "",
        first_name: "",
        last_name: "",
        email: "",
      },
      reset: {
        fields: {
          password: "",
          confirm_password: "",
          // salutation: "",
          first_name: "",
          last_name: "",
          email: "",
        },
        errors: {
          password: "",
          confirm_password: "",
          // salutation: "",
          first_name: "",
          last_name: "",
          email: "",
        },
      },
    };
  }

  componentDidMount() {
    let data = {};
    data.conds = { "me.id": this.props.match.params.cid };
    data.attrs = {};
    data.attrs["prefetch"] = [
      {
        owning_client: "system__assignment__assigned_client",
      },
      "core__vehicle_maintenance__owning_customer",
    ];
    this.props.dispatch(fetchCustomer(data));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.createData.success) {
      signalTransitionSuccess("Erfolgreich hinzugefügt");
      this.props.dispatch(resetCreateCompanyUser());
      let url = "/admin/company/" + this.props.match.params.cid + "/users";
      this.props.history.push(url);
    } else if (this.props.createData.error) {
      this.props.dispatch(resetCreateCompanyUser());
      if (
        this.props.createData.data.error.involvedAttributes.includes("email")
      ) {
        let newErrors = {};
        newErrors["email"] = "Please Enter Valid Email Address";
        this.setState({ errors: newErrors });
      } else {
        signalTransitionError("Hinzufügen fehlgeschlagen");
      }
    }
  }

  resetHandler(evt) {
    this.setState({ fields: this.state.reset.fields });
    this.setState({ errors: this.state.reset.errors });
  }

  submitHandler(evt) {
    evt.preventDefault();
    // get our new errors
    let fields = this.state.fields;

    let newErrors = {};
    newErrors["first_name"] = inputErrorHandler("", fields.first_name, true);
    newErrors["last_name"] = inputErrorHandler("", fields.last_name, true);
    newErrors["password"] = inputErrorHandler("", fields.password, true);
    newErrors["confirm_password"] = inputErrorHandler(
      "",
      fields.confirm_password,
      true
    );
    newErrors["email"] = inputErrorHandler("email", fields.email, true);
    // newErrors["salutation"] = inputErrorHandler("", fields.salutation, true);
    if (fields.password !== fields.confirm_password) {
      newErrors["confirm_password"] =
        "Passwort & Passwort bestätigen sollten gleich sein";
    }
    let errors = removeEmptyValues(newErrors);
    // Conditional logic:
    if (Object.keys(errors).length > 0) {
      this.setState({ errors: errors });
    } else {
      let clientData = { customer: {}, client: {}, company: {} };
      clientData.client.email = fields.email;
      clientData.client.first_name = fields.first_name;
      clientData.client.last_name = fields.last_name;
      clientData.client.password = fields.password;
      clientData.client.identifier = fields.identifier;
      clientData.client.salutation = fields.salutation;
      clientData.company.company_id = parseInt(this.props.match.params.cid);

      if (
        typeof this.props.match.params.cid !== "undefined" &&
        this.props.match.params.cid !== "" &&
        parseInt(this.props.match.params.cid) !== 0
      ) {
        this.props.dispatch(createCompanyUser(clientData));
      }
    }
  }

  changeHandler(evt, name) {
    let value = evt.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: null,
      },
    }));
  }

  render() {
    let customer_data =
      typeof this.props.customerData.rows.data[0] !== "undefined"
        ? this.props.customerData.rows.data[0]
        : null;
    let cust_identifier =
      customer_data !== null && typeof customer_data.identifier !== "undefined"
        ? customer_data.identifier
        : null;
    let cust_salutation =
      customer_data !== null &&
      typeof customer_data.salutation !== "undefined" &&
      customer_data.salutation !== null
        ? customer_data.salutation
        : "";

    let fields = this.state.fields;
    fields.identifier = cust_identifier;
    fields.salutation = cust_salutation;

    return (
      <div className="wrapper">
        <div className="main d-flex align-items-start flex-column">
          <h4>neuen Benutzer anlegen zum Unternehmen</h4>
        </div>
        <div className="sub_main">
          <Form>
            <Row>
              <Col lg={6}>
                <Form.Group as={Row} controlId="email">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={SYSTEM_MODULE}
                      Entity={CLIENT_ENTITY}
                      Label={"email"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={this.state.fields.email}
                      onChange={(evt) => this.changeHandler(evt, "email")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.email}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="password">
                  <Form.Label column sm="4">
                    Passwort
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="password"
                      placeholder="Passwort"
                      name="password"
                      value={this.state.fields.password}
                      onChange={(evt) => this.changeHandler(evt, "password")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.password}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="confirm_password">
                  <Form.Label column sm="4">
                    Passwort bestätigen
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="password"
                      placeholder="Passwort bestätigen"
                      name="password"
                      value={this.state.fields.confirm_password}
                      onChange={(evt) =>
                        this.changeHandler(evt, "confirm_password")
                      }
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.confirm_password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.confirm_password}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                {/* <Form.Group as={Row} controlId="salutation">
                  <Form.Label column sm="4">
                  <ColumnText
                        Module={CORE_MODULE}
                        Entity={CUSTOMER_ENTITY}
                        Label={"salutation"}
                      />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      as="select"
                      placeholder="Anrede"
                      name="salutation"
                      value={this.state.fields.salutation}
                      onChange={(evt) => this.changeHandler(evt, "salutation")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.salutation}
                    >
                      <option value="">Anrede</option>
                      {salutations.map((item) => (
                        <option
                          key={item.value}
                          value={item.value}
                          defaultValue={
                            this.state.fields.salutation === item.value
                              ? true
                              : false
                          }
                        >
                          {item.text}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.salutation}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group> */}
                <Form.Group as={Row} controlId="first_name">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={SYSTEM_MODULE}
                      Entity={CLIENT_ENTITY}
                      Label={"first_name"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Vorname"
                      name="first_name"
                      value={this.state.fields.first_name}
                      onChange={(evt) => this.changeHandler(evt, "first_name")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.first_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.first_name}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="last_name">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={SYSTEM_MODULE}
                      Entity={CLIENT_ENTITY}
                      Label={"last_name"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Familienname"
                      name="last_name"
                      value={this.state.fields.last_name}
                      onChange={(evt) => this.changeHandler(evt, "last_name")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.last_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.last_name}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <div className="form-btn">
                  <Button
                    type="Button"
                    className="btn btn-dark Button"
                    onClick={(evt) => this.submitHandler(evt)}
                  >
                    OK
                  </Button>
                  <Button
                    type="Button"
                    className="btn btn-dark"
                    onClick={(evt) => this.resetHandler(evt)}
                  >
                    Abbrechen
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

UserAddScreen.propTypes = {};
UserAddScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  customerData: state.Customer.customerData,
  localeData: state.Locale.localeData,
  createData: state.CUser.createData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(mapStateToProps, mapDispatchToProps)(UserAddScreen);
