import { call, all, put, takeLatest } from "redux-saga/effects";
import { authHeader } from "../../../utils";
import request from "../../../services/request";
import * as ActionTypes from "../../actions/constants";
import {
  updateVehicleDocumentSuccess,
  updateVehicleDocumentError,
  createVehicleDocumentSuccess,
  createVehicleDocumentError,
  fetchVehicleDocumentSuccess,
  fetchVehicleDocumentError,
  fetchVehicleDocumentByIdSuccess,
  fetchVehicleDocumentByIdError,
} from "../../actions/vehicle";

function* documentSaga({ data }) {
  try {
    const requestURL = "data/core/vehicle_file/query";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data,
    });
    yield put(fetchVehicleDocumentSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(fetchVehicleDocumentError(error));
    }
  }
}

function* indexSaga({ data }) {
  try {
    const requestURL = "data/core/vehicle_file/query";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data,
    });
    yield put(fetchVehicleDocumentByIdSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(fetchVehicleDocumentByIdError(error));
    }
  }
}

function* updateSaga({ data }) {
  try {
    let document_id =
      typeof data.document_id !== "undefined" && data.document_id !== null
        ? data.document_id
        : null;
    let documentData =
      typeof data.document !== "undefined" && data.document !== null
        ? data.document
        : null;
    const requestURL =
      "/data/core/vehicle_file/" + document_id + "/transitions/edit";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: documentData,
    });
    yield put(updateVehicleDocumentSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(updateVehicleDocumentError(error));
    }
  }
}

function* createSaga({ data }) {
  try {
    //let maintenance_id = ((typeof(data.maintenance_id) !== 'undefined')&&(data.maintenance_id !== null))?data.maintenance_id:null;
    let documentData =
      typeof data.document !== "undefined" && data.document !== null
        ? data.document
        : null;
    const requestURL = "/data/core/vehicle_file/transitions/create";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: documentData,
    });
    yield put(createVehicleDocumentSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(createVehicleDocumentError(error));
    }
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(ActionTypes.GET_VEHICLE_DOCUMENT, documentSaga),
    yield takeLatest(ActionTypes.GET_VEHICLE_DOCUMENT_BY_ID, indexSaga),
    yield takeLatest(ActionTypes.CREATE_VEHICLE_DOCUMENT, createSaga),
    yield takeLatest(ActionTypes.UPDATE_VEHICLE_DOCUMENT, updateSaga),
  ]);
}
