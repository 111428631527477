import React, { Component } from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  PagingOptions,
  NoDataIndication,
  DelBtn,
  EditBtn,
  ViewBtn,
  CORE_MODULE,
  VEHICLE_ENTITY,
  VEHICLE_COMPONENT_ENTITY,
  VEHICLE_MAINTENANCE_COMPONENT_ENTITY,
  REMOVE_PERMISSION,
  MODIFY_PERMISSION,
  ADD_PERMISSION,
  NO_DATA,
} from "../../utils";
import { fetchMaintenanceComponent } from "../../store/actions/vehicle";
import { showDeleteDialog } from "../../store/actions/dialog";
import { HasAccess } from "../../components/Access";
import ColumnText from "../../components/Column";

import moment from "moment";
import { Link } from "react-router-dom";

class MaintenanceComponentScreen extends Component {
  maintenanceId = null;

  constructor(props) {
    super(props);
    this.state = {
      sort: [
        {
          dataField: "id",
          order: "desc",
        },
      ],
    };
    this.maintenanceId = props.match.params.mid;
    this.handleTableChange = this.handleTableChange.bind(this);
    this.viewHandler = this.viewHandler.bind(this);
    this.editHandler = this.editHandler.bind(this);
    this.addHandler = this.addHandler.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
  }

  renderColumns = () => {
    return [
      {
        dataField: "id",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={VEHICLE_COMPONENT_ENTITY}
            Label={"id"}
          />
        ),
        sort: true,
        headerClasses: "cell-header cell-vcomp-id-header",
        classes: "cell-vcomp-id",
        formatter: (cellContent, row) => {
          if (cellContent !== "") {
            return (
              <>
                <Link
                  to={"#"}
                  onClick={() => {
                    this.maintenanceComponentDetailHandler(row);
                  }}
                >
                  {cellContent}
                </Link>
              </>
            );
          }
        },
      },
      {
        dataField: "vehicle_maintenance.vehicle.vin",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={VEHICLE_ENTITY}
            Label={"vin"}
          />
        ),
        sort: false,
        headerClasses: "cell-header cell-vcomp-vin-header",
        classes: " cell-vcomp-vin",
        formatter: (cellContent, row) => {
          return (
            <>
              <Link
                to={"#"}
                onClick={() => {
                  this.vehicleHandler(row);
                }}
              >
                {cellContent}
              </Link>
            </>
          );
        },
      },
      {
        dataField: "vehicle_component.name",
        text: (
          <ColumnText Module={CORE_MODULE} Entity={VEHICLE_COMPONENT_ENTITY} />
        ),
        sort: true,
        headerClasses: "cell-header cell-vcomp-name-header",
        classes: "cell-vcomp-name",
        formatter: (cellContent, row) => {
          return <>{cellContent}</>;
        },
      },
      {
        dataField: "note",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={VEHICLE_MAINTENANCE_COMPONENT_ENTITY}
            Label={"note"}
          />
        ),
        sort: true,
        headerClasses: "cell-header cell-vcomp-review-header",
        classes: "cell-vcomp-review",
      },
      {
        dataField: "review_date",
        text: (
          <ColumnText
            Module={CORE_MODULE}
            Entity={VEHICLE_MAINTENANCE_COMPONENT_ENTITY}
            Label={"review_date"}
          />
        ),
        sort: true,
        headerClasses: "cell-header cell-vcomp-review-header",
        classes: "cell-vcomp-review",
        formatter: (cellContent, row) => {
          if (cellContent !== "") {
            let review_date = moment(cellContent).format("YYYY.MM.DD");
            return <>{review_date}</>;
          }
        },
      },
      {
        dataField: "id",
        text: "",
        sort: false,
        headerClasses:
          "cell-header cell-actions-header cell-vcomp-actions-header",
        classes: "cell-actions cell-vcomp-actions",
        formatter: (cellContent, row) => {
          return (
            <div className="svg">
              <Link
                to={"#"}
                onClick={() => {
                  this.viewHandler(row);
                }}
              >
                <ViewBtn />
              </Link>

              <HasAccess
                Module={CORE_MODULE}
                Entity={VEHICLE_MAINTENANCE_COMPONENT_ENTITY}
                Label={MODIFY_PERMISSION}
              >
                <Link
                  to={"#"}
                  onClick={() => {
                    this.editHandler(row);
                  }}
                >
                  <EditBtn />
                </Link>
              </HasAccess>
              <HasAccess
                Module={CORE_MODULE}
                Entity={VEHICLE_MAINTENANCE_COMPONENT_ENTITY}
                Label={REMOVE_PERMISSION}
              >
                <Link
                  to={"#"}
                  onClick={() => {
                    this.deleteHandler(row);
                  }}
                >
                  <DelBtn />
                </Link>
              </HasAccess>
            </div>
          );
        },
      },
    ];
  };

  componentDidMount() {
    
  }

  handleTableChange(
    type,
    { filters, page, sortField, sortOrder, sizePerPage }
  ) {
    let defaultOrder = this.state.sort;
    let sortOrderstring =
      typeof sortOrder !== "undefined"
        ? "-" + sortOrder
        : "-" + defaultOrder[0]["order"];
    let sort_field =
      defaultOrder[0]["dataField"].indexOf(".") === -1
        ? "me." + defaultOrder[0]["dataField"]
        : defaultOrder[0]["dataField"];
    if (sortField !== null) {
      sort_field =
        sortField.indexOf(".") === -1 ? "me." + sortField : sortField;
    }
    let data = {};
    data.attrs = {};
    data.attrs["order_by"] = {};
    data.attrs.order_by[sortOrderstring] = sort_field;
    data.attrs["prefetch"] = [
      { vehicle_maintenance: "vehicle" },
      "vehicle_component",
    ];
    data.conds = {};
    if (this.maintenanceId !== null) {
      data.conds["vehicle_maintenance.id"] = this.maintenanceId;
    }
    data.page = page;
    data.page_size = sizePerPage;
    this.props.dispatch(fetchMaintenanceComponent(data));
  }

  viewHandler(row) {
    let url =
      "/admin/maintenance/" +
      this.props.match.params.mid +
      "/components/view/" +
      row.id;
    this.props.history.push(url);
  }

  editHandler(row) {
    let url =
      "/admin/maintenance/" +
      this.props.match.params.mid +
      "/components/" +
      row.id +
      "/edit";
    this.props.history.push(url);
  }

  addHandler() {
    let url =
      "/admin/maintenance/" + this.props.match.params.mid + "/components/add";
    this.props.history.push(url);
  }

  vehicleHandler(row) {
    if (typeof row.vehicle_maintenance.vehicle.id !== "undefined") {
      let url = "/admin/vehicles/" + row.vehicle_maintenance.vehicle.id;
      this.props.history.push(url);
    }
  }
  maintenanceDetailHandler(row) {
    if (typeof row.vehicle_maintenance.id !== "undefined") {
      let url =
        "/admin/maintenance/" +
        this.props.match.params.mid +
        "/components/view/" +
        row.vehicle_maintenance.id;
      this.props.history.push(url);
    }
  }
  maintenanceComponentDetailHandler(row) {
    if (typeof row.id !== "undefined") {
      let url =
        "/admin/maintenance/" +
        this.props.match.params.mid +
        "/components/view/" +
        row.id;
      this.props.history.push(url);
    }
  }
  vehicleDetailHandler(row) {
    if (typeof row.vehicle_maintenance.id !== "undefined") {
      let url = "/admin/maintenance/view/" + row.vehicle_maintenance.id;
      this.props.history.push(url);
    }
  }
  deleteHandler(row) {
    let rowId = row.id;
    let successor = row !== null ? row.successor : null;
    let rowData = { post: { successor: null } };
    if (rowId !== null) {
      rowData["id"] = rowId;
    }
    if (successor !== null) {
      rowData["post"]["successor"] = successor;
    }
    rowData["url"] =
      "/data/core/vehicle_maintenance_component/" +
      rowId +
      "/transitions/remove";
    rowData["redirect_to"] =
      "/admin/maintenance/" + this.props.match.params.mid + "/components";
    if (row !== null && typeof rowId !== "undefined") {
      this.props.dispatch(showDeleteDialog(rowData));
    }
  }

  render() {
    let options = PagingOptions;
    options.totalSize =
      typeof this.props.maintenanceData !== "undefined" &&
      typeof this.props.maintenanceData.rows !== "undefined" &&
      typeof this.props.maintenanceData.rows.meta.filtered !== "undefined"
        ? this.props.maintenanceData.rows.meta.filtered
        : "";
    options.sizePerPage =
      typeof this.props.maintenanceData !== "undefined" &&
      typeof this.props.maintenanceData.rows !== "undefined" &&
      typeof this.props.maintenanceData.rows.meta.page_size !== "undefined"
        ? this.props.maintenanceData.rows.meta.page_size
        : 10;
    let component_data =
      typeof this.props.maintenanceData !== "undefined" &&
      typeof this.props.maintenanceData.rows !== "undefined" &&
      typeof this.props.maintenanceData.rows.data !== "undefined"
        ? this.props.maintenanceData.rows.data
        : [];
    let columns = this.renderColumns();

    return (
      <div className="wrapper_datatable">
        <div className="table_main">
          <h4>
            {" "}
            <ColumnText
              Module={CORE_MODULE}
              Entity={VEHICLE_MAINTENANCE_COMPONENT_ENTITY}
            />
          </h4>
          <div className="btngroup">
            <HasAccess
              Module={CORE_MODULE}
              Entity={VEHICLE_MAINTENANCE_COMPONENT_ENTITY}
              Label={ADD_PERMISSION}
            >
              <button
                type="button"
                className="btn btn-dark"
                onClick={() => this.addHandler()}
              >
                <ColumnText
                  Module={CORE_MODULE}
                  Entity={VEHICLE_MAINTENANCE_COMPONENT_ENTITY}
                />{" "}
                hinzufügen
              </button>
            </HasAccess>
          </div>
        </div>
        <BootstrapTable
          remote={{ pagination: true }}
          bootstrap4
          keyField="id"
          data={component_data}
          columns={columns}
          bordered={false}
          rowClasses={"table-row"}
          defaultSorted={this.state.sort}
          pagination={paginationFactory(options)}
          onTableChange={this.handleTableChange}
          noDataIndication={() =>
            this.props.maintenanceData.loading ? <NoDataIndication /> : NO_DATA
          }
        />
      </div>
    );
  }
}

MaintenanceComponentScreen.propTypes = {};
MaintenanceComponentScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  maintenanceData: state.MComponent.maintenanceData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaintenanceComponentScreen);
