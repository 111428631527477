import * as Types from "../../actions/constants";

const initialState = {

  noteData: {
    loading: false,
    error: false,
    success:false,
    rows: { data: [], meta: [] },
  },
  createData: {
    loading: false,
    error: false,
    success:false,
    rows: { data: [], meta: [] },
  },
  updateData: {
    loading: false,
    error: false,
    success:false,
    rows: { data: [], meta: [] },
  },
  indexData: {
    loading: false,
    error: false,
    success:false,
    rows: { data: [], meta: [] },
  },
  
};

export default (state = initialState, action) => {
  switch (action.type) {
     case Types.GET_CUSTOMER_NOTE:
      return {
        ...state,
        noteData: {
          loading: true,
          error: false,
          success:false,
          rows: { data: [], meta: [] },
        },
      };
    case Types.CUSTOMER_NOTE_SUCCESS:
      return {
        ...state,
        noteData: {
          loading: false,
          error: false,
          success:true,
          rows: action.response.data,
        },
      };
    case Types.CUSTOMER_NOTE_ERROR:
      return {
        ...state,
        noteData: {
          loading: false,
          error: true,
          success:false,
          rows: action.error.error,
        },
      };


      case Types.GET_CUSTOMER_NOTE_BY_ID:
      return {
        ...state,
        indexData: {
          loading: true,
          error: false,
          success:false,
          rows: { data: [], meta: [] },
        },
      };
    case Types.CUSTOMER_NOTE_BY_ID_SUCCESS:
      return {
        ...state,
        indexData: {
          loading: false,
          error: false,
          success:true,
          rows: action.response.data,
        },
      };
    case Types.CUSTOMER_NOTE_BY_ID_ERROR:
      return {
        ...state,
        indexData: {
          loading: false,
          error: true,
          success:false,
          rows: action.error.error,
        },
      };


                
      case Types.CREATE_CUSTOMER_NOTE:
        return {
          ...state,
          createData: {
            loading: true,
            error: false,
            success:false,
            rows: { data: [], meta: [] },
          },
        };
      case Types.CREATE_CUSTOMER_NOTE_SUCCESS:
        return {
          ...state,
          createData: {
            loading: false,
            error: false,
            success:true,
            rows: action.response.data,
          },
        };
      case Types.CREATE_CUSTOMER_NOTE_ERROR:
        return {
          ...state,
          createData: {
            loading: false,
            error: true,
            success:false,
            rows: action.error.error,
          },
        };
      case Types.RESET_CREATE_CUSTOMER_NOTE:
          return {
            ...state,
            createData: {
              loading: false,
              error: false,
              success:false,
              rows: {data:[],meta:[]},
            },
          }; 


                
          case Types.UPDATE_CUSTOMER_NOTE:
            return {
              ...state,
              updateData: {
                loading: true,
                error: false,
                success:false,
                rows: { data: [], meta: [] },
              },
            };
          case Types.UPDATE_CUSTOMER_NOTE_SUCCESS:
            return {
              ...state,
              updateData: {
                loading: false,
                error: false,
                success:true,
                rows: action.response.data,
              },
            };
          case Types.UPDATE_CUSTOMER_NOTE_ERROR:
            return {
              ...state,
              updateData: {
                loading: false,
                error: true,
                success:false,
                rows: action.error.error,
              },
            };
          case Types.RESET_UPDATE_CUSTOMER_NOTE:
              return {
                ...state,
                updateData: {
                  loading: false,
                  error: false,
                  success:false,
                  rows: {data:[],meta:[]},
                },
              }; 


    

    default:
      return state;
  }
};
