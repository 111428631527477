import React, { Component } from "react";
import { Form, Button, Row, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { login, resetAlert } from "../../store/actions/login";
import { removeEmptyValues, inputErrorHandler } from "../../utils";

class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        username: "",
        password: "",
      },
      errors: {
        username: "",
        password: "",
      },
    };
    this.cancelHandler = this.cancelHandler.bind(this);
  }
  componentDidUpdate() {
    if (this.props.loginData.success === true) {
      window.location = "/admin/search";
    }
  }

  submitHandler(evt) {
    evt.preventDefault();
    // get our new errors
    let fields = this.state.fields;
    const newErrors = {};
    newErrors["username"] = inputErrorHandler("", fields.username, true);
    newErrors["password"] = inputErrorHandler("", fields.password, true);
    let errors = removeEmptyValues(newErrors);
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      this.setState({ errors: newErrors });
    } else {
      let data = {};
      data.user = fields.username;
      data.password = fields.password;
      this.dispatchPromise = this.props.dispatch(login(data));
    }
  }
  cancelHandler(evt) {
    evt.preventDefault();
    if (typeof this.dispatchPromise !== "undefined") {
      this.dispatchPromise.abort();
    }
  }

  changeHandler(evt, name) {
    let value = evt.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: null,
      },
    }));
  }

  render() {
    return (
      <>
        <div className={"outer"}>
          <div className={"inner"}>
            <Form>
              <h3>Anmeldung</h3>
              {this.props.loginData.error && (
                <Alert
                  variant="danger"
                  onClose={() => this.props.dispatch(resetAlert())}
                  dismissible
                >
                  <p>{this.props.loginData.msg}</p>
                </Alert>
              )}
              <Form.Group controlId="user_name">
                <Form.Label>Benutzer</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Benutzer"
                  name="username"
                  value={this.state.fields.username}
                  onChange={(evt) => this.changeHandler(evt, "username")}
                  onKeyPress={this.onKeyPress}
                  isInvalid={!!this.state.errors.username}
                />
                <Form.Control.Feedback type="invalid">
                  {this.state.errors.username}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Passwort</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Passwort"
                  name="password"
                  value={this.state.fields.password}
                  onChange={(evt) => this.changeHandler(evt, "password")}
                  isInvalid={!!this.state.errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {this.state.errors.password}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="form-search-btn">
                <Button
                  variant="primary"
                  className="btn btn-dark button"
                  type="button"
                  onClick={(evt) => this.submitHandler(evt)}
                  disabled={
                    this.state.errors.username || this.state.errors.password
                  }
                >
                  OK
                </Button>
                <Button
                  variant="primary"
                  className="btn btn-dark"
                  onClick={(evt) => this.cancelHandler(evt)}
                  disabled={
                    this.state.errors.username || this.state.errors.password
                  }
                >
                  Abbrechen
                </Button>
              </div>
              <Row>
                <Button href="/forgot-password" variant="link">
                  Passwort vergessen
                </Button>
              </Row>
            </Form>
          </div>
        </div>
      </>
    );
  }
}
LoginScreen.propTypes = {};
LoginScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  loginData: state.Login.loginData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
