import { Component } from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  PagingOptions,
  NoDataIndication,
  EditBtn,
  CORE_MODULE,
  VEHICLE_COMPONENT_ENTITY,
  CREATE_PERMISSION,
  EDIT_PERMISSION,
  NO_DATA,
} from "../../utils";
import { fetchComponent } from "../../store/actions/vehicle";
import { showModalDialog } from "../../store/actions/dialog";
import { HasAccess } from "../../components/Access";
import ColumnText from "../../components/Column";
import _ from "lodash";
import { Link } from "react-router-dom";
import ComponentAdd from "../Vehicle/ComponentAddScreen";
import ComponentEdit from "../Vehicle/ComponentEditScreen";

class ComponentScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showComp: false,
      compColumns: [
        {
          dataField: "id",
          text: "Comp. ID",
          sort: true,
          headerClasses: "Cell Cell-header",
          hidden: true,
        },
        {
          dataField: "name",
          text: (
            <ColumnText
              Module={CORE_MODULE}
              Entity={VEHICLE_COMPONENT_ENTITY}
              Label={"name"}
            />
          ),
          sort: true,
          headerClasses: "Cell Cell-header",
        },
        {
          dataField: "instance_entity.id",
          text: "",
          sort: false,
          headerClasses: "Cell Cell-header",
          formatter: (cellContent, row) => {
            return (
              <HasAccess
                Module={CORE_MODULE}
                Entity={VEHICLE_COMPONENT_ENTITY}
                Label={EDIT_PERMISSION}
              >
                <div className="svg">
                  <Link
                    to={"#"}
                    onClick={() => {
                      this.editComponentHandler(row);
                    }}
                  >
                    <EditBtn />
                  </Link>
                </div>
              </HasAccess>
            );
          },
        },
      ],
      compSort: [
        {
          dataField: "id",
          order: "desc",
        },
      ],
      id: "",
    };
    this.componentHandler = this.componentHandler.bind(this);
    this.addComponentHandler = this.addComponentHandler.bind(this);
    this.editComponentHandler = this.editComponentHandler.bind(this);
  }

  editComponentHandler = (row) => {
    this.setState({ id: row.id });
    this.props.dispatch(showModalDialog("Component-Edit"));
  };

  addComponentHandler = () => {
    this.props.dispatch(showModalDialog("Component-Add"));
  };

  componentDidMount() {
    // /** Vehicle Maintenance Component */
    // let defaultOrder = this.state.compSort;
    // let sortOrderstring = "-" + defaultOrder[0]["order"];
    // let data = {};
    // data.attrs = {};
    // data.attrs["order_by"] = {};
    // data.attrs.order_by[sortOrderstring] = "me."+defaultOrder[0]["dataField"];
    // data.conds = {};
    // data.page = 1;
    // data.page_size = PagingOptions.sizePerPage;
    // this.props.dispatch(fetchComponent(data));
  }

  componentHandler = (
    type,
    { filters, page, sortField, sortOrder, sizePerPage }
  ) => {
    let defaultOrder = this.state.compSort;
    let sortOrderstring =
      typeof sortOrder !== "undefined"
        ? "-" + sortOrder
        : "-" + defaultOrder[0]["order"];
    let data = {};
    data.attrs = {};
    //data.attrs["prefetch"] = ["vehicle_component","vehicle_maintenance"];
    data.attrs["order_by"] = {};
    data.attrs.order_by[sortOrderstring] =
      sortField !== null
        ? "me." + sortField
        : "me." + defaultOrder[0]["dataField"];
    data.conds = {};
    data.page = page;
    data.page_size = sizePerPage;
    this.props.dispatch(fetchComponent(data));
  };

  render() {
    let comp_options = _.omit(PagingOptions, "totalSize");
    comp_options.totalSize =
      typeof this.props.componentData !== "undefined" &&
      typeof this.props.componentData.rows !== "undefined" &&
      typeof this.props.componentData.rows.meta.filtered !== "undefined"
        ? this.props.componentData.rows.meta.filtered
        : "";
    comp_options.sizePerPage =
      typeof this.props.componentData !== "undefined" &&
      typeof this.props.componentData.rows !== "undefined" &&
      typeof this.props.componentData.rows.meta.page_size !== "undefined"
        ? this.props.componentData.rows.meta.page_size
        : 10;

    let data =
      typeof this.props.componentData !== "undefined" &&
      typeof this.props.componentData.rows !== "undefined" &&
      typeof this.props.componentData.rows.data !== "undefined"
        ? this.props.componentData.rows.data
        : [];

    return (
      <div>
        <div className="table_main">
          <h5>Fahrzeug Komponenten</h5>
          <HasAccess
            Module={CORE_MODULE}
            Entity={VEHICLE_COMPONENT_ENTITY}
            Label={CREATE_PERMISSION}
          >
            <button
              type="button"
              className="btn btn-dark"
              onClick={(evt) => this.addComponentHandler(evt)}
            >
              neue Komponente anlegen
            </button>
          </HasAccess>
        </div>
        <BootstrapTable
          remote={{ pagination: true }}
          bootstrap4
          keyField="id"
          data={data}
          columns={this.state.compColumns}
          bordered={false}
          rowClasses={"table-row"}
          defaultSorted={this.state.compSort}
          pagination={paginationFactory(comp_options)}
          onTableChange={this.componentHandler}
          noDataIndication={() =>
            this.props.componentData.loading ? <NoDataIndication /> : NO_DATA
          }
        />
        <ComponentAdd />
        <ComponentEdit compId={this.state.id} />
      </div>
    );
  }
}

ComponentScreen.propTypes = {};
ComponentScreen.defaultProps = { historyRef: "" };

const mapStateToProps = (state) => ({
  localeData: state.Locale.localeData,
  componentData: state.Component.componentData,
  dialogData: state.Dialog.dialogData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(mapStateToProps, mapDispatchToProps)(ComponentScreen);
