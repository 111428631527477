import { call, all, put, takeLatest } from "redux-saga/effects";
import { authHeader } from "../../../utils";
import request from "../../../services/request";
import * as ActionTypes from "../../actions/constants";
import {
  fetchBindingEntitySuccess,
  fetchBindingEntityError,
} from "../../actions/binding";

function* bindingSaga({ data }) {
  try {
    const requestURL = "/data/system/client/query";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      headers: {
        Authorization: authHeader(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data,
    });
    yield put(fetchBindingEntitySuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(fetchBindingEntityError(error));
    }
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(ActionTypes.GET_BINDING_ENTITY, bindingSaga)]);
}
