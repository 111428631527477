import { call, all, put, takeLatest } from "redux-saga/effects";
import request from "../../../services/request";
import * as ActionTypes from "../../actions/constants/types";
import { loginSuccess, loginError } from "../../actions/login";
import { setAuthToken } from "../../../utils";

function* loginSaga({ data }) {
  try {
    const requestURL = "/jwt";
    const response = yield call(request, {
      url: requestURL,
      method: "POST",
      data,
    });
    setAuthToken(response.data.token);
    yield put(loginSuccess(response));
  } catch (error) {
    if (
      typeof error !== "undefined" &&
      typeof error.data !== "undefined" &&
      typeof error.data.error !== "undefined" &&
      typeof error.data.error.message !== "undefined"
    ) {
      yield put(loginError(error));
    }
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(ActionTypes.LOGIN, loginSaga)]);
}
