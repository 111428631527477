import { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import { updateClient, resetUpdateClient } from "../../store/actions/client";
import { fetchCustomer } from "../../store/actions/customer";
import { fetchRole } from "../../store/actions/role";
import { fetchCompanyUser } from "../../store/actions/company";

import {
  inputErrorHandler,
  signalTransitionSuccess,
  signalTransitionError,
  removeEmptyValues,
  getSalutaions,
} from "../../utils";

class ClientEditScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        client_id: "",
        customer_id: "",
        username: "",
        salutation: "",
        first_name: "",
        last_name: "",
        email: "",
        role_id: "",
        role_name: "",
      },
      errors: {
        username: "",
        salutation: "",

        first_name: "",
        last_name: "",
        email: "",
        role_id: "",
      },
      reset: {
        fields: {
          client_id: "",
          customer_id: "",
          username: "",
          salutation: "",
          first_name: "",
          last_name: "",
          email: "",
          role_id: "",
          role_name: "",
        },
        errors: {
          username: "",
          salutation: "",
          first_name: "",
          last_name: "",
          email: "",
          role_id: "",
        },
      },
      previous_role: [],
      previous_role_id: "",
      value: "",
      suggestions: [],
    };
  }

  componentDidMount() {
    let data = {};
    data.conds = {
      owning_client:
        typeof this.props.match.params.id !== "undefined"
          ? this.props.match.params.id
          : "-1",
    };
    data.attrs = {};
    data.attrs["prefetch"] = {
      owning_client: "system__assignment__assigned_client",
    };
    this.props.dispatch(fetchCustomer(data));

    let vdata = {};
    vdata.attrs = {};
    vdata.conds = { name: { "-in": ["admin", "employee"] } };
    this.props.dispatch(fetchRole(vdata));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.roleData) !== JSON.stringify(this.props.roleData)
    ) {
      let roles =
        typeof this.props.roleData !== "undefined" &&
        typeof this.props.roleData.rows !== "undefined" &&
        typeof this.props.roleData.rows.data !== "undefined"
          ? this.props.roleData.rows.data
          : [];
      let data = [];
      let suggestions = this.state.suggestions;
      for (let i = 0; i < suggestions.length; i++) {
        let obj = {};
        obj.id = suggestions.id;
        obj.name = suggestions.description;
        obj.binding_entity = suggestions.binding_entity;
        data.push(obj);
      }
      for (let i = 0; i < roles.length; i++) {
        let obj = {};
        // if(roles[i]['binding_entity'] !== null){
        obj.id = roles[i].id;
        obj.name = roles[i].description;
        obj.binding_entity = roles[i]["binding_entity"];
        data.push(obj);
        //}
      }
      this.setState({ suggestions: data });
    }

    if (
      JSON.stringify(prevProps.customerData) !==
      JSON.stringify(this.props.customerData)
    ) {
      let customer = {};
      let customer_data =
        typeof this.props.customerData.rows !== "undefined" &&
        typeof this.props.customerData.rows.data[0] !== "undefined"
          ? this.props.customerData.rows.data[0]
          : null;
      let client_data =
        customer_data !== null &&
        typeof customer_data.owning_client !== "undefined" &&
        customer_data.owning_client !== null
          ? customer_data.owning_client
          : null;
      let id =
        client_data !== null && typeof client_data.id !== "undefined"
          ? client_data.id
          : "";
      let username =
        client_data !== null && typeof client_data.login_name !== "undefined"
          ? client_data.login_name
          : "";
      let salutation =
        customer_data !== null &&
        typeof customer_data.salutation !== "undefined"
          ? customer_data.salutation
          : "";
      let first_name =
        client_data !== null && typeof client_data.first_name !== "undefined"
          ? client_data.first_name
          : "";
      let last_name =
        client_data !== null && typeof client_data.last_name !== "undefined"
          ? client_data.last_name
          : "";
      let email =
        client_data !== null && typeof client_data.email !== "undefined"
          ? client_data.email
          : "";
      let customer_id =
        customer_data !== null && typeof customer_data.id !== "undefined"
          ? customer_data.id
          : "";

      let system__assignment__assigned_clients =
        client_data !== null &&
        typeof client_data.system__assignment__assigned_client !== "undefined"
          ? client_data.system__assignment__assigned_client
          : [];

      let agenda = "";
      system__assignment__assigned_clients.map((item) => {
        if (item.agenda != 2) {
          agenda = item.agenda;
          return;
        }
      });

      let previous_assignment_array = [];
      system__assignment__assigned_clients.forEach((i) => {
        previous_assignment_array.push({ id: i.id });
      });

      //console.log(customer);
      customer["client_id"] = id;
      customer["customer_id"] = customer_id;
      // customer['customer_type'] = customer_type;
      customer["username"] = username;
      customer["salutation"] = salutation;
      customer["first_name"] = first_name;
      customer["last_name"] = last_name;
      customer["email"] = email;
      customer["role_id"] = agenda;

      this.setState({ previous_role: previous_assignment_array });
      this.setState({ fields: customer });
      let reset = this.state.reset;
      reset.fields = customer;
      this.setState({ reset: reset });

      if (id !== null && id !== "") {
        /*let bdata = {};
          bdata.attrs = {};
          bdata.attrs['prefetch'] = ["agenda"]
          bdata.conds = {"assigned_client":id};
          this.props.dispatch(fetchRoleById(bdata));*/

        let data = {};
        data.attrs = {};
        data.conds = {
          binding_instance_of_customer: customer_id,
          assigned_client: this.props.match.params.id,
        };
        this.props.dispatch(fetchCompanyUser(data));
      }
    }

    if (
      JSON.stringify(prevProps.indexData) !==
      JSON.stringify(this.props.indexData)
    ) {
      let roles =
        typeof this.props.indexData.rows !== "undefined" &&
        typeof this.props.indexData.rows.data !== "undefined"
          ? this.props.indexData.rows.data
          : [];
      let predefined_roles = [];
      roles.map(function (item) {
        let data = {};
        data.id = item.id;
        data.role_id = item.agenda.id;
        data.successor = item.successor;
        if (data.role_id != 2) predefined_roles.push(data);
      });

      this.setState({ previous_role: predefined_roles });
    }

    if (this.props.updateData.success) {
      signalTransitionSuccess("Erfolg bearbeiten Success");
      this.props.dispatch(resetUpdateClient());
      let url = "/admin/settings";
      window.location = url;
      //this.props.history.push(url);
      // window.location.reload();
    } else if (this.props.updateData.error) {
      this.props.dispatch(resetUpdateClient());
      if (
        typeof this.props.updateData.rows !== "undefined" &&
        typeof this.props.updateData.rows.errorType !== "undefined" &&
        this.props.updateData.rows.errorType === "UNIQUENESS"
      ) {
        let newErrors = {};
        newErrors["username"] = "Please Enter Unique Username";
        this.setState({ errors: newErrors });
      } else {
        signalTransitionError("Bearbeitung fehlgeschlagen");
      }
    }
  }

  resetHandler(evt) {
    this.setState({ fields: this.state.reset.fields });
    this.setState({ errors: this.state.reset.errors });
  }

  submitHandler(evt) {
    evt.preventDefault();
    // get our new errors
    let fields = this.state.fields;

    let newErrors = {};
    newErrors["username"] = inputErrorHandler("", fields.username, true);
    newErrors["first_name"] = inputErrorHandler("", fields.first_name, true);
    newErrors["last_name"] = inputErrorHandler("", fields.last_name, true);
    newErrors["email"] = inputErrorHandler("", fields.email, true);
    newErrors["salutation"] = inputErrorHandler("", fields.salutation, true);

    let errors = removeEmptyValues(newErrors);
    // Conditional logic:
    if (Object.keys(errors).length > 0) {
      this.setState({ errors: errors });
    } else {
      let clientData = {
        client: {},
        client_id: "",
        customer_id: "",
        customer: {},
        role: {},
        previous_role: "",
        previous_role_id: "",
        locked: false,
      };
      clientData.client_id = fields.client_id;
      clientData.customer_id = fields.customer_id;
      clientData.client.email = fields.email;
      clientData.client.first_name = fields.first_name;
      clientData.client.last_name = fields.last_name;
      clientData.client.login_name = fields.username;
      clientData.client.locked = false;
      // clientData.client.language = 1;
      //clientData.client.password = fields.password;

      // clientData.customer.salutation = fields.salutation;
      let obj = this.state.suggestions.find((o) => o.id === fields.role_id);
      clientData.role.binding_entity =
        typeof obj !== "undefined" &&
        obj !== null &&
        obj !== "-1" &&
        obj.id !== 9
          ? obj["binding_entity"]
          : null; //this.state.suggestions[fields.role_id]['binding_entity'];//fields.binding_entity;
      clientData.role.agenda = fields.role_id;
      let obj_role = this.state.previous_role.find(
        (o) => o.role_id === fields.role_id
      );
      clientData.predefined_roles = this.state.previous_role;
      clientData.systemUser = 1;

      //console.log(clientData);

      this.props.dispatch(updateClient(clientData));
    }
  }

  changeHandler(evt, name) {
    let value = evt.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: null,
      },
    }));
  }

  render() {
    let salutations = getSalutaions();

    return (
      <div className="wrapper">
        <div className="main d-flex align-items-start flex-column">
          <h4>Benutzer bearbeiten</h4>
        </div>
        <div className="sub_main">
          <Form>
            <Row>
              <Col lg={6}>
                <Form.Group as={Row} controlId="user_name" hidden>
                  <Form.Label column sm="4">
                    Benutzer
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Benutzer"
                      name="username"
                      value={this.state.fields.username}
                      onChange={(evt) => this.changeHandler(evt, "username")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.username}
                      readOnly
                    />
                    {/* <p className="forgot-password text-left">
                      <Link to="#"  >
                          Kennwort aktualisieren
   </Link> 
                    </p>*/}
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.username}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="email">
                  <Form.Label column sm="4">
                    Email
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={this.state.fields.email}
                      onChange={(evt) => this.changeHandler(evt, "email")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.email}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="salutation">
                  <Form.Label column sm="4">
                    Anrede
                  </Form.Label>
                  <Col sm="8">
                    {/* <Form.Control
                      as="select"
                      placeholder="Anrede"
                      name="salutation"
                      value={this.state.fields.salutation}
                      onChange={(evt) => this.changeHandler(evt, "salutation")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.salutation}
                    >
                      <option value="">Anrede</option>
                      {salutations.map((item) => (
                        <option
                          value={item.value}
                          selected={
                            this.state.fields.salutation === item.value
                              ? true
                              : false
                          }
                        >
                          {item.text}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.salutation}
                    </Form.Control.Feedback> */}
                    <Form.Control
                      type="text"
                      name="salutation"
                      value={
                        this.state.fields.salutation
                          ? this.state.fields.salutation
                          : "-"
                      }
                      readOnly
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="first_name">
                  <Form.Label column sm="4">
                    First Name
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Vorname"
                      name="first_name"
                      value={this.state.fields.first_name}
                      onChange={(evt) => this.changeHandler(evt, "first_name")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.first_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.first_name}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="last_name">
                  <Form.Label column sm="4">
                    Last Name
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Familienname"
                      name="last_name"
                      value={this.state.fields.last_name}
                      onChange={(evt) => this.changeHandler(evt, "last_name")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.last_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.last_name}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="role_id">
                  <Form.Label column sm="4">
                    User Role
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      as="select"
                      placeholder="role_id"
                      name="role_id"
                      value={this.state.fields.role_id}
                      onChange={(evt) => this.changeHandler(evt, "role_id")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.role_id}
                    >
                      <option value="">Wählen Sie Rolle</option>
                      {this.state.suggestions.map((item) => (
                        <option
                          value={item.id}
                          selected={
                            this.state.fields.role_id === item.id ? true : false
                          }
                        >
                          {item.name}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.role_id}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <div className="form-btn">
                  <Button
                    type="Button"
                    className="btn btn-dark Button"
                    onClick={(evt) => this.submitHandler(evt)}
                  >
                    OK
                  </Button>
                  <Button
                    type="Button"
                    className="btn btn-dark"
                    onClick={(evt) => this.resetHandler(evt)}
                  >
                    Abbrechen
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

ClientEditScreen.propTypes = {};
ClientEditScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  customerData: state.Customer.customerData,
  localeData: state.Locale.localeData,
  updateData: state.Client.updateData,
  clientData: state.Client.clientData,
  roleData: state.Role.roleData,
  indexData: state.Role.indexData,
  companyData: state.CUser.companyData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(mapStateToProps, mapDispatchToProps)(ClientEditScreen);
