import React, { Component } from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute";
import PublicRoute from "../components/PublicRoute";
import NotFoundPage from "../containers/NotFoundPage/index";
import Dashboard from "../containers/Dashboard/index";
import Login from "../containers/Login/index";
import ForgotPassword from "../containers/Forgot/ForgotPassword";
import ResetPassword from "../containers/Forgot/ResetPassword";
import DefaultLayout from "../containers/_layouts/default";
import LoginLayout from "../containers/_layouts/login";
import DialogContent from "../components/Dialog";
import { connect } from "react-redux";
import { logout } from "../store/actions/logout";
import {
  hideLogoutDialog,
  hideDeleteDialog,
  hideAlertDialog,
  resetDialog,
} from "../store/actions/dialog";
import {
  getAuthToken,
  destroyToken,
  ToastContainer,
  authHeader,
} from "../utils";
import { fetchLocale } from "../store/actions/universe";
import { deleteRow, deleteReset } from "../store/actions/delete";
import { signalTransitionSuccess, signalTransitionError } from "../utils";

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmButton: true,
      redirect_to: "",
    };

    this.closeHandler = this.closeHandler.bind(this);
    this.confirmHandler = this.confirmHandler.bind(this);
    this.deleteCloseHandler = this.deleteCloseHandler.bind(this);
    this.alertCloseHandler = this.alertCloseHandler.bind(this);
    this.deleteConfirmHandler = this.deleteConfirmHandler.bind(this);
  }
  componentDidMount() {
    if (authHeader() !== "") {
      this.props.dispatch(fetchLocale());
    }
  }

  closeHandler() {
    this.props.dispatch(hideLogoutDialog());
  }
  confirmHandler() {
    let data = {};
    data.token = getAuthToken();
    this.props.dispatch(hideLogoutDialog());
    this.props.dispatch(logout(data));
  }
  deleteCloseHandler() {
    this.props.dispatch(hideDeleteDialog());
  }
  alertCloseHandler() {
    this.props.dispatch(hideAlertDialog());
    this.props.dispatch(resetDialog());
  }
  deleteConfirmHandler() {
    let data =
      typeof this.props.dialogData.data !== "undefined"
        ? this.props.dialogData.data
        : null;
    let redirect_to =
      typeof data.redirect_to !== "undefined" ? data.redirect_to : "";
    this.setState({ redirect_to: redirect_to });
    if (data !== null) {
      this.props.dispatch(deleteRow(data));
    }
    this.props.dispatch(hideDeleteDialog());
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.deleteData.success) {
      this.props.dispatch(deleteReset());
      signalTransitionSuccess("Delete Success");
      window.location = this.state.redirect_to;
    } else if (this.props.deleteData.error) {
      this.props.dispatch(deleteReset());
      signalTransitionError("Delete Failed");
    }
  }

  render() {
    return (
      <>
        <Switch>
          <PublicRoute exact path="/" layout={LoginLayout} component={Login} />
          <PublicRoute
            path="/forgot-password"
            layout={LoginLayout}
            component={ForgotPassword}
          />
          <PublicRoute
            path="/reset-login-password"
            layout={LoginLayout}
            component={ResetPassword}
          />
          <PublicRoute path="/login" layout={LoginLayout} component={Login} />
          <PrivateRoute
            path="/admin"
            layout={DefaultLayout}
            component={Dashboard}
          />
          <PublicRoute layout={DefaultLayout} component={NotFoundPage} />
        </Switch>
        {this.props.dialogData.logoutDialog ? (
          <DialogContent
            showConfirmButton={this.state.showConfirmButton}
            onDeny={this.closeHandler}
            onConfirm={this.confirmHandler}
            title={"Abmelden?"}
            content={"Wollen Sie sich wirklich abmelden?"}
            closeBtnTitle={"Abbrechen"}
            confirmBtnTitle={"OK"}
          />
        ) : (
          ""
        )}

        {this.props.dialogData.deleteDialog ? (
          <DialogContent
            showConfirmButton={this.state.showConfirmButton}
            onDeny={this.deleteCloseHandler}
            onConfirm={this.deleteConfirmHandler}
            title={"Delete Confirmation"}
            content={"Are you sure you want Delete Record ?"}
            closeBtnTitle={"No, cancel"}
            confirmBtnTitle={"Yes"}
          />
        ) : (
          ""
        )}

        {this.props.dialogData.alertDialog ? (
          <DialogContent
            showConfirmButton={false}
            onDeny={this.alertCloseHandler}
            onConfirm={this.deleteConfirmHandler}
            title={"Alert!!"}
            content={this.props.dialogData.content}
            closeBtnTitle={"Ok"}
            confirmBtnTitle={"Yes"}
          />
        ) : (
          ""
        )}

        <ToastContainer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  logoutData: state.Logout.logoutData,
  dialogData: state.Dialog.dialogData,
  deleteData: state.Delete.deleteData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(mapStateToProps, mapDispatchToProps)(Routes);
