import React, { Component } from "react";
import AutoSuggest from "react-autosuggest";
class AutoRecommend extends Component {
  renderSuggestion = (suggestion, { isHighlighted }) => (
    <div className={isHighlighted ? "selected" : ""}>{suggestion.name}</div>
  );
  renderInputComponent = (inputProps) => {
    delete inputProps.className;

    return (
      <>
        <input className="form-control" {...inputProps} />
      </>
    );
  };

  getSuggestionValue = (sugg) => sugg.name;
  render() {
    return (
      <AutoSuggest
        suggestions={this.props.suggestions}
        getSuggestionValue={this.getSuggestionValue}
        inputProps={{
          value: this.props.value,
          onChange: (_event, { newValue }) =>
            this.props.onValueChange(newValue),
          style: this.props.style,
          label: this.props.label,
          disabled: this.props.disabled,
          placeholder: this.props.placeholder,
          className: this.props.inputClasses,
        }}
        theme={{
          suggestionsList: {
            margin: 0,
            padding: 0,
            listStyleType: "none",
          },
          container: { width: "100%" },
        }}
        onSuggestionsFetchRequested={({ value }) =>
          this.props.fetchSuggestions(value)
        }
        renderSuggestion={this.renderSuggestion}
        onSuggestionsClearRequested={this.props.onSuggestionsClearRequested}
        renderInputComponent={this.renderInputComponent}
        onSuggestionSelected={(_event, { suggestionValue }) =>
          this.props.onSelect(suggestionValue)
        }
        renderSuggestionsContainer={(options) => (
          <div
            className="jss110 jss114"
            {...options.containerProps}
            style={{
              position: "absolute",
              zIndex: 1,
              maxHeight: this.props.maxHeight,
              overflow: "auto",
              width: "92%",
            }}
            onScroll={(event) => {
              const { offsetHeight, scrollTop, scrollHeight } =
                event.currentTarget;
              if (offsetHeight + scrollTop >= scrollHeight) {
                this.props.onScrollEnd();
              }
            }}
          >
            {options.children}
          </div>
        )}
        shouldRenderSuggestions={this.props.shouldRenderSuggestions}
      />
    );
  }
}

AutoRecommend.propTypes = {};
AutoRecommend.defaultProps = {
  value: "",
  suggestions: [],
  onValueChange: (val) => {},
  fetchSuggestions: (val) => {},
  onSelect: (val) => {},
  style: {},
  onSuggestionsClearRequested: () => {},
  label: "",
  disabled: false,
  shouldRenderSuggestions: (value) => true,
  maxHeight: 400,
  onScrollEnd: () => {},
  placeholder: "",
  inputClasses: "",
};

export default AutoRecommend;
