import { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import {
  fetchCustomer,
  updateCustomer,
  resetUpdateCustomer,
} from "../../store/actions/customer";
import {
  inputErrorHandler,
  signalTransitionSuccess,
  signalTransitionError,
  removeEmptyValues,
  getSalutaions,
  CORE_MODULE,
  SYSTEM_MODULE,
  CUSTOMER_ENTITY,
  CLIENT_ENTITY,
} from "../../utils";
import ColumnText from "../../components/Column";

class CustomerEditScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        company_name: "",
        client_id: "",
        customer_id: "",
        identifier: "",
        salutation: "",
        first_name: "",
        last_name: "",
        email: "",
        mobile_number: "",
        phone_number: "",
        street_address: "",
        city: "",
        country: "",
        zip: "",
        customer_type: 1,
      },
      reset: {
        fields: {
          company_name: "",
          client_id: "",
          customer_id: "",
          identifier: "",
          salutation: "",
          first_name: "",
          last_name: "",
          email: "",
          mobile_number: "",
          phone_number: "",
          street_address: "",
          city: "",
          zip: "",
          country: "",
          customer_type: 1,
        },
        errors: {
          company_name: "",
          identifier: "",
          salutation: "",
          first_name: "",
          last_name: "",
          email: "",
          mobile_number: "",
          phone_number: "",
          street_address: "",
          city: "",
          zip: "",
          country: "",
        },
      },
      errors: {
        company_name: "",
        identifier: "",
        salutation: "",
        first_name: "",
        last_name: "",
        email: "",
        mobile_number: "",
        phone_number: "",
        street_address: "",
        city: "",
        zip: "",
        country: "",
      },
      is_company: false,
      is_customer: true,
    };
    this.resetHandler = this.resetHandler.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.showPasswordHandler = this.showPasswordHandler.bind(this);
  }

  componentDidMount() {
    let data = {};
    data.conds = {
      "me.id":
        typeof this.props.match.params.id !== "undefined"
          ? this.props.match.params.id
          : "-1",
    };
    data.attrs = {};
    data.attrs["prefetch"] = {
      owning_client: "system__assignment__assigned_client",
    };
    this.props.dispatch(fetchCustomer(data));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.customerData) !==
      JSON.stringify(this.props.customerData)
    ) {
      let customer = {};
      let customer_data =
        typeof this.props.customerData.rows !== "undefined" &&
        typeof this.props.customerData.rows.data[0] !== "undefined"
          ? this.props.customerData.rows.data[0]
          : null;
      let client_data =
        customer_data !== null &&
        typeof customer_data.owning_client !== "undefined"
          ? customer_data.owning_client
          : null;

      let id =
        client_data !== null && typeof client_data.id !== "undefined"
          ? client_data.id
          : "";
      let customer_id =
        customer_data !== null && typeof customer_data.id !== "undefined"
          ? customer_data.id
          : "";
      let customer_type =
        customer_data !== null &&
        typeof customer_data.is_client !== "undefined" &&
        customer_data.is_client
          ? customer_data.is_client
          : "0";

      let identifier =
        customer_data !== null &&
        typeof customer_data.identifier !== "undefined"
          ? customer_data.identifier
          : "";
      let salutation =
        customer_data !== null &&
        typeof customer_data.salutation !== "undefined"
          ? customer_data.salutation
          : "";
      let first_name =
        client_data !== null && typeof client_data.first_name !== "undefined"
          ? client_data.first_name
          : "";
      let last_name =
        client_data !== null && typeof client_data.last_name !== "undefined"
          ? client_data.last_name
          : "";
      let email =
        client_data !== null && typeof client_data.email !== "undefined"
          ? client_data.email
          : "";
      let phonenumbers =
        customer_data !== null &&
        typeof customer_data.phonenumbers !== "undefined" &&
        customer_data.phonenumbers !== null
          ? JSON.parse(customer_data.phonenumbers)
          : null;

      let mobile_number =
        phonenumbers !== null &&
        phonenumbers[0] !== null &&
        typeof phonenumbers[0] !== "undefined" &&
        phonenumbers[0].Name === "Mobil" &&
        typeof phonenumbers[0].Nummer !== undefined
          ? phonenumbers[0].Nummer
          : "";

      let phone_number =
        phonenumbers !== null &&
        phonenumbers[1] !== null &&
        typeof phonenumbers[1] !== "undefined" &&
        phonenumbers[1].Name === "Telefonnummer" &&
        typeof phonenumbers[1].Nummer !== undefined
          ? phonenumbers[1].Nummer
          : "";
      let addresses =
        customer_data !== null && typeof customer_data.addresses !== "undefined"
          ? JSON.parse(customer_data.addresses)
          : null;
      let address =
        addresses != null && typeof addresses[0] !== "undefined"
          ? addresses[0]
          : null;
      let street_address =
        address !== null && typeof address.Straße !== "undefined"
          ? address.Straße
          : "-";
      let city =
        address !== null && typeof address.Stadt !== "undefined"
          ? address.Stadt
          : "-";
      let zip =
        address !== null && typeof address.PLZ !== "undefined"
          ? address.PLZ
          : "-";
      let country =
        address !== null && typeof address.Land !== "undefined"
          ? address.Land
          : "";
      let company_name =
        customer_data !== null && typeof customer_data.name !== "undefined"
          ? customer_data.name
          : "";

      // console.log(customer_type);
      customer["client_id"] = id;
      customer["customer_id"] = customer_id;
      customer["customer_type"] = customer_type;
      customer["identifier"] = identifier;
      customer["salutation"] = salutation;
      customer["first_name"] = first_name;
      customer["last_name"] = last_name;
      customer["email"] = email;
      customer["mobile_number"] = mobile_number;
      customer["phone_number"] = phone_number;
      customer["street_address"] = street_address;
      customer["city"] = city;
      customer["zip"] = zip;
      customer["country"] = country;
      if (parseInt(customer_type) === 0) {
        customer["company_name"] = company_name;
        this.setState({ is_company: true });
        this.setState({ is_customer: false });
      } else {
        this.setState({ is_company: false });
        this.setState({ is_customer: true });
      }
      //console.log(customer);
      this.setState({ fields: customer });
      let reset = this.state.reset;
      reset.fields = customer;
      this.setState({ reset: reset });
    }

    if (this.props.updateData.success) {
      this.props.dispatch(resetUpdateCustomer());
      signalTransitionSuccess("Update Success");
      let url = "/admin/customers";
      this.props.history.push(url);
    } else if (this.props.updateData.error) {
      this.props.dispatch(resetUpdateCustomer());
      signalTransitionError("Update Failed");
    }
  }

  resetHandler(evt) {
    this.setState({ fields: this.state.reset.fields });
    this.setState({ errors: this.state.reset.errors });
  }
  showPasswordHandler() {
    let url = "/admin/reset-password";
    this.props.history.push(url);
  }

  submitHandler(evt) {
    evt.preventDefault();
    // get our new errors
    let fields = this.state.fields;
    let newErrors = {};
    // newErrors["identifier"] = inputErrorHandler("", fields.identifier, true);
    if (parseInt(fields.customer_type) === 1) {
      newErrors["first_name"] = inputErrorHandler("", fields.first_name, true);
      newErrors["last_name"] = inputErrorHandler("", fields.last_name, true);
      newErrors["email"] = inputErrorHandler("", fields.email, true);
      newErrors["salutation"] = inputErrorHandler("", fields.salutation, true);
    }
    newErrors["mobile_number"] = inputErrorHandler(
      "",
      fields.mobile_number,
      true
    );
    newErrors["phone_number"] = inputErrorHandler(
      "",
      fields.phone_number,
      false
    );
    newErrors["street_address"] = inputErrorHandler(
      "",
      fields.street_address,
      true
    );
    newErrors["city"] = inputErrorHandler("", fields.city, true);
    newErrors["country"] = inputErrorHandler("", fields.country, true);
    newErrors["zip"] = inputErrorHandler("", fields.zip, true);

    if (parseInt(fields.customer_type) === 0) {
      newErrors["company_name"] = inputErrorHandler(
        "",
        fields.company_name,
        true
      );
    }

    let errors = removeEmptyValues(newErrors);
    // Conditional logic:
    if (Object.keys(errors).length > 0) {
      this.setState({ errors: errors });
    } else {
      let ClientData = {
        customer_id: fields.customer_id,
        client: {},
        customer: {},
      };
      let addresses = [];
      let address = {};
      address.Name = "Main";
      address.Straße = fields.street_address;
      address.Stadt = fields.city;
      address.PLZ = fields.zip;
      address.Land = fields.country;
      addresses[0] = address;

      let phonenumbers = [];
      let phonenumber = {};

      phonenumber = {
        Name: "Mobil",
        Nummer: fields.mobile_number,
      };
      phonenumbers[0] = phonenumber;
      if (fields.phone_number.length > 0) {
        phonenumber = {
          Name: "Telefonnummer",
          Nummer: fields.phone_number,
        };
        phonenumbers[1] = phonenumber;
      }
      if (parseInt(fields.customer_type) === 1) {
        ClientData.client_id = fields.client_id;
        ClientData.client.email = fields.email;
        ClientData.client.first_name = fields.first_name;
        ClientData.client.last_name = fields.last_name;
        //ClientData.fields.identifier = fields.identifier;
        // ClientData.client.salutation = fields.salutation;
      }
      // ClientData.customer.is_client = fields.customer_type;
      ClientData.customer.addresses = JSON.stringify(addresses);
      ClientData.customer.phonenumbers = JSON.stringify(phonenumbers);
      if (parseInt(fields.customer_type) === 0) {
        ClientData.customer.name = fields.company_name;
        delete ClientData.client;
      }
      ClientData.is_client = parseInt(fields.customer_type);

      this.props.dispatch(updateCustomer(ClientData));
      //console.log(ClientData);
    }
  }

  changeHandler(evt, name) {
    let value = evt.target.value;
    if (name === "customer_type" && parseInt(value) === 1) {
      this.setState({ is_company: false });
      this.setState({ is_customer: true });
      let fields = this.state.fields;
      fields["customer_type"] = parseInt(1);
      this.setState({ fields: fields });
    }
    if (name === "customer_type" && parseInt(value) === 0) {
      this.setState({ is_company: true });
      this.setState({ is_customer: false });
      let fields = this.state.fields;
      fields["customer_type"] = parseInt(0);
      this.setState({ fields: fields });
    }
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: null,
      },
    }));
  }

  render() {
    let customer_data =
      typeof this.props.customerData.rows !== "undefined" &&
      typeof this.props.customerData.rows.data[0] !== "undefined"
        ? this.props.customerData.rows.data[0]
        : null;
    let identifier =
      customer_data !== null && typeof customer_data.identifier !== "undefined"
        ? customer_data.identifier
        : "";
    let salutations = getSalutaions();

    return (
      <div className="wrapper">
        <div className="main d-flex align-items-start flex-column">
          <h4>
            {" "}
            <ColumnText Module={CORE_MODULE} Entity={CUSTOMER_ENTITY} />{" "}
            bearbeiten
          </h4>
        </div>
        <div className="sub_main">
          <Form>
            <Row>
              <Col lg={6}>
                <Form.Group as={Row}>
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={CUSTOMER_ENTITY}
                      Label={"is_client"}
                    />
                  </Form.Label>
                  <Col
                    sm="8"
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Form.Check
                      custom
                      inline
                      label="Kunde"
                      type={"radio"}
                      id={`custom-inline-radio-1`}
                      checked={
                        parseInt(this.state.fields.customer_type) === 1
                          ? true
                          : false
                      }
                      disabled
                    />
                    <Form.Check
                      custom
                      inline
                      readOnly
                      label="Firma"
                      type={"radio"}
                      id={`custom-inline-radio-2`}
                      checked={
                        parseInt(this.state.fields.customer_type) === 0
                          ? true
                          : false
                      }
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group as={Row} controlId="user_name">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={CUSTOMER_ENTITY}
                      Label={"identifier"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Customer Number"
                      name="identifier"
                      value={this.state.fields.identifier}
                      onChange={(evt) => this.changeHandler(evt, "identifier")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.identifier}
                      readOnly
                    />
                    {/* <p className="forgot-password text-left">
                    <Link to="#" onClick={()=>this.showPasswordHandler()} >
                        Kennwort aktualisieren
                  </Link> 
                    </p>*/}
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.identifier}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <div
                  style={{
                    display: this.state.is_company === false ? "block" : "none",
                  }}
                >
                  <Form.Group as={Row} controlId="salutation">
                    <Form.Label column sm="4">
                      <ColumnText
                        Module={CORE_MODULE}
                        Entity={CUSTOMER_ENTITY}
                        Label={"salutation"}
                      />
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        name="salutation"
                        value={
                          this.state.fields.salutation
                            ? this.state.fields.salutation
                            : "-"
                        }
                        readOnly
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="first_name">
                    <Form.Label column sm="4">
                      <ColumnText
                        Module={SYSTEM_MODULE}
                        Entity={CLIENT_ENTITY}
                        Label={"first_name"}
                      />
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="Vorname"
                        name="first_name"
                        value={this.state.fields.first_name}
                        onChange={(evt) =>
                          this.changeHandler(evt, "first_name")
                        }
                        onKeyPress={this.onKeyPress}
                        isInvalid={!!this.state.errors.first_name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {this.state.errors.first_name}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="last_name">
                    <Form.Label column sm="4">
                      <ColumnText
                        Module={SYSTEM_MODULE}
                        Entity={CLIENT_ENTITY}
                        Label={"last_name"}
                      />
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="Familienname"
                        name="last_name"
                        value={this.state.fields.last_name}
                        onChange={(evt) => this.changeHandler(evt, "last_name")}
                        onKeyPress={this.onKeyPress}
                        isInvalid={!!this.state.errors.last_name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {this.state.errors.last_name}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="email">
                    <Form.Label column sm="4">
                      <ColumnText
                        Module={SYSTEM_MODULE}
                        Entity={CLIENT_ENTITY}
                        Label={"email"}
                      />
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="Email"
                        name="email"
                        value={this.state.fields.email}
                        onChange={(evt) => this.changeHandler(evt, "email")}
                        onKeyPress={this.onKeyPress}
                        isInvalid={!!this.state.errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {this.state.errors.email}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </div>
                <div
                  style={{
                    display: this.state.is_company === true ? "block" : "none",
                  }}
                >
                  <Form.Group as={Row} controlId="company_name">
                    <Form.Label column sm="4">
                      <ColumnText
                        Module={CORE_MODULE}
                        Entity={CUSTOMER_ENTITY}
                        Label={"name"}
                      />
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="Company Name"
                        name="company_name"
                        value={this.state.fields.company_name}
                        onChange={(evt) =>
                          this.changeHandler(evt, "company_name")
                        }
                        onKeyPress={this.onKeyPress}
                        isInvalid={!!this.state.errors.company_name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {this.state.errors.company_name}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </div>
                <Form.Group as={Row} controlId="mobile_number">
                  <Form.Label column sm="4">
                    Mobil
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Mobile Number"
                      name="mobile_number"
                      value={this.state.fields.mobile_number}
                      onChange={(evt) =>
                        this.changeHandler(evt, "mobile_number")
                      }
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.mobile_number}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.mobile_number}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="phone_number">
                  <Form.Label column sm="4">
                    Telefonnummer
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Phone Number"
                      name="phone_number"
                      value={this.state.fields.phone_number}
                      onChange={(evt) =>
                        this.changeHandler(evt, "phone_number")
                      }
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.phone_number}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.phone_number}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group as={Row} controlId="street_address">
                  <Form.Label column sm="4">
                    Straße
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Street Address"
                      name="street_address"
                      value={this.state.fields.street_address}
                      onChange={(evt) =>
                        this.changeHandler(evt, "street_address")
                      }
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.street_address}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.street_address}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="city">
                  <Form.Label column sm="4">
                    Stadt
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="City/Town/Locality"
                      name="city"
                      value={this.state.fields.city}
                      onChange={(evt) => this.changeHandler(evt, "city")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.city}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.city}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="zip">
                  <Form.Label column sm="4">
                    PLZ
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Zip"
                      name="zip"
                      value={this.state.fields.zip}
                      onChange={(evt) => this.changeHandler(evt, "zip")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.zip}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.zip}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="Land">
                  <Form.Label column sm="4">
                    Land
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Country"
                      name="country"
                      value={this.state.fields.country}
                      onChange={(evt) => this.changeHandler(evt, "country")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.country}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.country}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <div className="form-btn">
                  <Button
                    type="Button"
                    className="btn btn-dark Button"
                    onClick={(evt) => this.submitHandler(evt)}
                  >
                    OK
                  </Button>
                  <Button
                    type="Button"
                    className="btn btn-dark"
                    onClick={(evt) => this.resetHandler(evt)}
                  >
                    Abbrechen
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

CustomerEditScreen.propTypes = {};
CustomerEditScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  customerData: state.Customer.customerData,
  localeData: state.Locale.localeData,
  updateData: state.Customer.updateData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(mapStateToProps, mapDispatchToProps)(CustomerEditScreen);
