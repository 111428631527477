import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import AutoRecommend from "../../components/AutoSuggest";

import {
  fetchVehicleMaintenance,
  resetUpdateMaintenanceComponent,
  updateMaintenanceComponent,
  fetchMaintenanceComponentById,
  fetchComponent,
} from "../../store/actions/vehicle";
import {
  inputErrorHandler,
  signalTransitionSuccess,
  signalTransitionError,
  removeEmptyValues,
  CORE_MODULE,
  VEHICLE_MAINTENANCE_COMPONENT_ENTITY,
} from "../../utils";
import { DateTimePicker } from "react-tempusdominus-bootstrap";
import moment from "moment";
import ColumnText from "../../components/Column";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "font-awesome/css/font-awesome.css";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

class MaintenanceComponentEditScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        mnt_id: "",
        mnt_name: "",
        mnt_comp_id: "",
        mnt_comp_name: "",
        mnt_date: moment(),
        note: "",
      },
      reset: {
        fields: {
          mnt_id: "",
          mnt_comp_id: "",
          mnt_date: moment(),
          note: "",
        },
        errors: {
          mnt_id: "",
          mnt_comp_id: "",
          mnt_date: "",
          note: "",
        },
      },
      errors: {
        mnt_id: "",
        mnt_comp_id: "",
        mnt_date: "",
        note: "",
      },
      cmpt_value: "",
      value: "",
      suggestions: [],
      cmpt_suggestions: [],
    };
    this.field = React.createRef();
    this.datePickerHandler = this.datePickerHandler.bind(this);
  }

  componentDidMount() {
    let vdata = {};
    vdata.attrs = {};
    vdata.attrs["prefetch"] = ["vehicle"];
    vdata.conds = {};
    this.props.dispatch(fetchVehicleMaintenance(vdata));

    let mdata = {};
    mdata.attrs = {};
    mdata.attrs["prefetch"] = [
      "vehicle_component",
      { vehicle_maintenance: "vehicle" },
    ];
    mdata.conds = { "me.id": this.props.match.params.id };
    this.props.dispatch(fetchMaintenanceComponentById(mdata));

    let cdata = {};
    cdata.attrs = {};
    cdata.conds = {};
    this.props.dispatch(fetchComponent(cdata));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.maintenanceData) !==
      JSON.stringify(this.props.maintenanceData)
    ) {
      let maintenances =
        typeof this.props.maintenanceData !== "undefined" &&
        typeof this.props.maintenanceData.rows !== "undefined" &&
        typeof this.props.maintenanceData.rows.data !== "undefined"
          ? this.props.maintenanceData.rows.data
          : [];
      let data = [];
      let suggestions = this.state.suggestions;
      for (let i = 0; i < suggestions.length; i++) {
        let obj = {};
        obj.id = suggestions.id;
        obj.name = suggestions.name;
        data.push(obj);
      }
      for (let i = 0; i < maintenances.length; i++) {
        let obj = {};
        obj.id = maintenances[i].id;
        obj.name = maintenances[i].vehicle.vin;
        data.push(obj);
      }
      this.setState({ suggestions: data });
    }

    if (
      JSON.stringify(prevProps.componentData) !==
      JSON.stringify(this.props.componentData)
    ) {
      let mnt_cmpts =
        typeof this.props.componentData !== "undefined" &&
        typeof this.props.componentData.rows !== "undefined" &&
        typeof this.props.componentData.rows.data !== "undefined"
          ? this.props.componentData.rows.data
          : [];

      let data = [];
      let suggestions = this.state.cmpt_suggestions;
      for (let i = 0; i < suggestions.length; i++) {
        let obj = {};
        obj.id = suggestions.id;
        obj.name = suggestions.name;
        data.push(obj);
      }
      for (let i = 0; i < mnt_cmpts.length; i++) {
        let obj = {};
        obj.id = mnt_cmpts[i].id;
        obj.name = mnt_cmpts[i].name;
        data.push(obj);
      }
      this.setState({ cmpt_suggestions: data });
    }

    if (
      JSON.stringify(prevProps.indexData) !==
      JSON.stringify(this.props.indexData)
    ) {
      let component_data =
        typeof this.props.indexData.rows.data[0] !== "undefined"
          ? this.props.indexData.rows.data[0]
          : null;
      let vehicle_component =
        component_data !== null &&
        typeof component_data.vehicle_component !== "undefined"
          ? component_data.vehicle_component
          : null;
      let vehicle_maintenance =
        component_data !== null &&
        typeof component_data.vehicle_maintenance !== "undefined"
          ? component_data.vehicle_maintenance
          : null;
      let vehicle_data =
        vehicle_maintenance !== null &&
        typeof vehicle_maintenance.vehicle !== "undefined"
          ? vehicle_maintenance.vehicle
          : null;

      let mnt_id =
        vehicle_maintenance !== null &&
        typeof vehicle_maintenance.id !== "undefined"
          ? vehicle_maintenance.id
          : "";
      let mnt_comp_id =
        vehicle_component !== null &&
        typeof vehicle_component.id !== "undefined"
          ? vehicle_component.id
          : "";
      let mnt_date =
        component_data !== null &&
        typeof component_data.review_date !== "undefined"
          ? moment(component_data.review_date).format("YYYY.MM.DD HH:MM:SS")
          : "";
      let note =
        component_data !== null && typeof component_data.note !== "undefined"
          ? component_data.note
          : "";
      let comp_name =
        vehicle_component !== null &&
        typeof vehicle_component.name !== "undefined"
          ? vehicle_component.name
          : "";
      let vehicle_name =
        vehicle_data !== null && typeof vehicle_data.vin !== "undefined"
          ? vehicle_data.vin
          : "";

      let mnt = {};
      mnt["mnt_id"] = mnt_id;
      mnt["mnt_name"] = vehicle_name;
      mnt["mnt_comp_id"] = mnt_comp_id;
      mnt["mnt_comp_name"] = comp_name;
      mnt["mnt_date"] = mnt_date;

      mnt["note"] = note;

      this.setState({ fields: mnt });
      let reset = this.state.reset;
      reset.fields = note;
      this.setState({ reset: reset });
      this.setState({ cmpt_value: comp_name });
      this.setState({ value: vehicle_name });
    }

    if (this.props.updateData.success) {
      this.props.dispatch(resetUpdateMaintenanceComponent());
      signalTransitionSuccess("Update Success");
      let url =
        "/admin/maintenance/" + this.props.match.params.mid + "/components";
      this.props.history.push(url);
    } else if (this.props.updateData.error) {
      this.props.dispatch(resetUpdateMaintenanceComponent());
      signalTransitionError("Update Failed");
    }
  }

  resetHandler(evt) {
    this.setState({ fields: this.state.reset.fields });
    this.setState({ errors: this.state.reset.errors });
  }

  submitHandler(evt) {
    evt.preventDefault();
    // get our new errors

    let fields = this.state.fields;
    let newErrors = {};
    newErrors["mnt_id"] = inputErrorHandler("", fields.mnt_id, true);
    newErrors["mnt_comp_id"] = inputErrorHandler("", fields.mnt_comp_id, true);
    newErrors["mnt_date"] = inputErrorHandler("", fields.mnt_date, true);
    newErrors["note"] = inputErrorHandler("", fields.note, true);

    if (fields.mnt_id === "" || fields.mnt_name === "") {
      newErrors["mnt_id"] = "Required Parameter";
    } else if (fields.mnt_name !== this.state.value) {
      newErrors["mnt_id"] = "Please select valid Maintenance ID";
    }

    if (fields.mnt_comp_id === "" || fields.mnt_comp_name === "") {
      newErrors["mnt_comp_id"] = "Required Parameter";
    } else if (fields.mnt_comp_name !== this.state.cmpt_value) {
      newErrors["mnt_comp_id"] = "Please select valid Component";
    }

    let errors = removeEmptyValues(newErrors);
    if (Object.keys(errors).length > 0) {
      this.setState({ errors: errors });
    } else {
      let maintenanceData = { id: "", maintenance: {} };
      maintenanceData.id = fields.mnt_id;
      // noteData.note.date = fields.maint_date;
      maintenanceData.maintenance.note = fields.note;
      maintenanceData.maintenance.review_date = fields.mnt_date;
      //maintenanceData.maintenance.vehicle_maintenance = fields.mnt_id;
      //maintenanceData.maintenance.vehicle_component = fields.mnt_comp_id;

      this.props.dispatch(updateMaintenanceComponent(maintenanceData));
    }
  }

  changeHandler(evt, name) {
    let value = evt.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: null,
      },
    }));
  }

  datePickerHandler = (value) => {
    let items = this.state.fields;
    items["mnt_date"] = value;
    this.setState({ fields: items });

    let errors = this.state.errors;
    errors["mnt_date"] = "";
    this.setState({ errors: errors });
    console.log(this.state.fields);
  };

  onValueChange = (newValue) => {
    this.setState({
      value: newValue,
    });
    let errors = this.state.errors;
    errors["mnt_id"] = "";
    this.setState({ errors: errors });
  };

  onSuggestionsFetchRequested = (value) => {
    this.loadSuggestions(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  loadSuggestions(value) {
    if (value !== "") {
      let vdata = {};
      vdata.attrs = {};
      vdata.attrs["prefetch"] = ["vehicle"];
      //  vdata.attrs["prefetch"] = ["owning_customer"];
      vdata.conds = { vin: { "-like": "%" + value + "%" } };
      //vdata.conds = { 'me.id':value};
      this.props.dispatch(fetchVehicleMaintenance(vdata));
    }
  }

  loadMoreReferences = () => {
    let meta_data =
      typeof this.props.vehicleData !== "undefined" &&
      typeof this.props.vehicleData.rows !== "undefined" &&
      typeof this.props.vehicleData.rows.meta !== "undefined"
        ? this.props.vehicleData.rows.meta
        : [];

    let vdata = {};
    vdata.attrs = meta_data.attrs;
    vdata.conds = meta_data.conds;
    vdata.page = meta_data.page + 1;
    vdata.page_size = meta_data.page_size;
    this.props.dispatch(fetchVehicleMaintenance(vdata));
  };

  onSelect = (suggestionValue) => {
    const reference = this.state.suggestions.find(
      (r) => r["name"] === suggestionValue //.replace('/Maintenance - /i',''),
    );
    let fields = this.state.fields;
    //console.log(reference);
    fields["mnt_id"] = reference.id;
    fields["mnt_name"] = reference.name;
    this.setState({ fields: fields });
  };

  onComponentValueChange = (newValue) => {
    this.setState({
      cmpt_value: newValue,
    });
    let errors = this.state.errors;
    errors["mnt_comp_id"] = "";
    this.setState({ errors: errors });
  };

  onComponentSuggestionsFetchRequested = (value) => {
    this.loadComponentSuggestions(value);
  };

  onComponentSuggestionsClearRequested = () => {
    this.setState({
      cmpt_suggestions: [],
    });
  };

  loadComponentSuggestions(value) {
    if (value !== "") {
      let cdata = {};
      cdata.attrs = {};
      cdata.conds = { name: { "-like": "%" + value + "%" } };
      this.props.dispatch(fetchComponent(cdata));
    }
  }

  loadMoreComponentReferences = () => {
    let meta_data =
      typeof this.props.componentData !== "undefined" &&
      typeof this.props.componentData.rows !== "undefined" &&
      typeof this.props.componentData.rows.meta !== "undefined"
        ? this.props.componentData.rows.meta
        : [];

    let vdata = {};
    vdata.attrs = {};
    vdata.conds = meta_data.conds;
    vdata.page = meta_data.page + 1;
    vdata.page_size = meta_data.page_size;
    this.props.dispatch(fetchComponent(vdata));
  };

  onComponentSelect = (suggestionValue) => {
    const reference = this.state.cmpt_suggestions.find(
      (r) => r["name"] === suggestionValue
    );
    let fields = this.state.fields;
    fields["mnt_comp_id"] = reference.id;
    fields["mnt_comp_name"] = reference.name;
    this.setState({ fields: fields });
  };

  setFieldPlaceholder = () => {
    if (
      typeof this.field.current[0] !== "undefined" &&
      typeof this.field.current[0].children[0] !== "undefined"
    ) {
      this.field.current[0].children[0].setAttribute("placeholder", "Datum");
    }
  };

  render() {
    // let customer_type = 2;

    // let core_module = "core";
    // let vehicle_entity = "vehicle";
    // let maintenance_entity = "vehicle_maintenance";
    // let customer_entity = "customer";
    // let system_module = "system";
    // let client_entity = "client";

    return (
      <div className="wrapper">
        <div className="main d-flex align-items-start flex-column">
          <h4>
            <ColumnText
              Module={CORE_MODULE}
              Entity={VEHICLE_MAINTENANCE_COMPONENT_ENTITY}
            />{" "}
            bearbeiten
          </h4>
        </div>
        <div className="sub_main">
          <Form>
            <Row>
              <Col lg={6}>
                <Form.Group as={Row} controlId="mnt_id">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_MAINTENANCE_COMPONENT_ENTITY}
                      Label={"vehicle_maintenance"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <AutoRecommend
                      value={this.state.value}
                      onValueChange={this.onValueChange}
                      suggestions={this.state.suggestions}
                      onSuggestionsClearRequested={
                        this.onSuggestionsClearRequested
                      }
                      fetchSuggestions={this.onSuggestionsFetchRequested}
                      onSelect={this.onSelect}
                      placeholder="Wählen Sie Wartung"
                      disabled
                      onScrollEnd={this.loadMoreReferences}
                      inputClasses={
                        typeof this.state.errors.mnt_id !== "undefined" &&
                        this.state.errors.mnt_id !== ""
                          ? "is-invalid form-control"
                          : "form-control"
                      }
                    />
                    {typeof this.state.errors.mnt_id !== "undefined" &&
                    this.state.errors.mnt_id !== "" ? (
                      <div className="has-error">
                        {this.state.errors.mnt_id}
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="mnt_comp_id">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_MAINTENANCE_COMPONENT_ENTITY}
                      Label={"vehicle_component"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <AutoRecommend
                      value={this.state.cmpt_value}
                      onValueChange={this.onComponentValueChange}
                      suggestions={this.state.cmpt_suggestions}
                      onSuggestionsClearRequested={
                        this.onComponentSuggestionsClearRequested
                      }
                      fetchSuggestions={
                        this.onComponentSuggestionsFetchRequested
                      }
                      onSelect={this.onComponentSelect}
                      placeholder="Wählen Sie Wartungskomponente"
                      onScrollEnd={this.loadMoreComponentReferences}
                      inputClasses={
                        typeof this.state.errors.mnt_comp_id !== "undefined" &&
                        this.state.errors.mnt_comp_id !== ""
                          ? "is-invalid form-control"
                          : "form-control"
                      }
                    />
                    {typeof this.state.errors.mnt_comp_id !== "undefined" &&
                    this.state.errors.mnt_comp_id !== "" ? (
                      <div className="has-error">
                        {this.state.errors.mnt_comp_id}
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="mnt_date">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_MAINTENANCE_COMPONENT_ENTITY}
                      Label={"review_date"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <DateTimePicker
                      pickerRef={this.field}
                      callback={() => this.setFieldPlaceholder()}
                      date={this.state.fields.mnt_date}
                      defaultDate={this.state.fields.mnt_date}
                      format="YYYY-MM-DD"
                      onChange={(e) => this.datePickerHandler(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.mnt_date}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="note">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_MAINTENANCE_COMPONENT_ENTITY}
                      Label={"note"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Note"
                      name="note"
                      value={this.state.fields.note}
                      onChange={(evt) => this.changeHandler(evt, "note")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.note}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.note}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <div className="form-btn">
                  <Button
                    type="Button"
                    className="btn btn-dark Button"
                    onClick={(evt) => this.submitHandler(evt)}
                  >
                    OK
                  </Button>
                  <Button
                    type="Button"
                    className="btn btn-dark"
                    onClick={(evt) => this.resetHandler(evt)}
                  >
                    Abbrechen
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

MaintenanceComponentEditScreen.propTypes = {};
MaintenanceComponentEditScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  customerData: state.Customer.customerData,
  vehicleData: state.Vehicle.vehicleData,
  localeData: state.Locale.localeData,
  updateData: state.MComponent.updateData,
  dialogData: state.Dialog.dialogData,
  maintenanceData: state.Maintenance.maintenanceData,
  componentData: state.Component.componentData,
  indexData: state.MComponent.indexData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaintenanceComponentEditScreen);
