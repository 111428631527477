import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import AutoRecommend from "../../components/AutoSuggest";

import {
  fetchVehicle,
  createVehicleMaintenance,
  resetCreateVehicleMaintenance,
} from "../../store/actions/vehicle";
import { showAlertDialog } from "../../store/actions/dialog";
import {
  inputErrorHandler,
  signalTransitionSuccess,
  signalTransitionError,
  removeEmptyValues,
  getFileContent,
  checkFileType,
  getMaxBase64StringSize,
  FILE_TOO_LARGE_MESSAGE,
  FILE_NAME_SEPARATOR,
  FILE_TYPE_MESSAGE,
  CORE_MODULE,
  VEHICLE_MAINTENANCE_ENTITY,
} from "../../utils";
import { DateTimePicker } from "react-tempusdominus-bootstrap";
import bsCustomFileInput from "bs-custom-file-input";
import ColumnText from "../../components/Column";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "font-awesome/css/font-awesome.css";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

class MaintenanceAddScreen extends Component {
  vehicleId = null;
  vehicleName = null;

  constructor(props) {
    super(props);
    this.vehicleId = props.match.params.vid;
    this.state = {
      fields: {
        maintenance_id: "",
        vehicle_id: "",
        vehicle_name: "",
        maint_date: "",
        mileage: "",
        note: "",
        files: "",
      },
      reset: {
        maintenance_id: "",
        vehicle_id: "",
        vehicle_name: "",
        maint_date: "",
        mileage: "",
        note: "",
        files: "",
      },
      errors: {
        vehicle_id: "",
        maint_date: "",
        mileage: "",
        note: "",
        files: "",
      },
      fileName: "",
      defaultName: "",
      value: "",
      suggestions: [],
    };
    this.field = React.createRef();
    this.datePickerHandler = this.datePickerHandler.bind(this);
    this.changeFileHandler = this.changeFileHandler.bind(this);
  }

  componentDidMount() {
    bsCustomFileInput.init();
    let vdata = {};
    vdata.attrs = {};
    vdata.conds = {};
    if (this.vehicleId) {
      vdata.conds = { "me.id": this.vehicleId };
    }
    this.props.dispatch(fetchVehicle(vdata));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.vehicleData) !==
      JSON.stringify(this.props.vehicleData)
    ) {
      let vehicles =
        typeof this.props.vehicleData !== "undefined" &&
        typeof this.props.vehicleData.rows !== "undefined" &&
        typeof this.props.vehicleData.rows.data !== "undefined"
          ? this.props.vehicleData.rows.data
          : [];
      let data = [];
      let suggestions = this.state.suggestions;
      for (let i = 0; i < suggestions.length; i++) {
        let obj = {};
        obj.id = suggestions.id;
        obj.name = suggestions.name;
        data.push(obj);
      }
      for (let i = 0; i < vehicles.length; i++) {
        let obj = {};
        obj.id = vehicles[i].id;
        obj.name = vehicles[i].vin;
        data.push(obj);
        if (this.vehicleId && parseInt(this.vehicleId) === vehicles[i].id) {
          this.vehicleName = vehicles[i].vin;
        }
      }
      if (this.vehicleId) {
        let fields = this.state.fields;
        fields.vehicle_id = this.vehicleId;
        fields.vehicle_name = this.vehicleName;
        this.setState({ fields: fields });
        if (this.vehicleName) {
          this.setState({ value: this.vehicleName });
        }
      }
      this.setState({ suggestions: data });
    }

    if (this.props.createData.success) {
      this.props.dispatch(resetCreateVehicleMaintenance());
      signalTransitionSuccess("Update Success");
      let url = "/admin/maintenance";
      if (this.vehicleId) {
        url = "/admin/vehicles/" + this.vehicleId + "/maintenance";
      }
      this.props.history.push(url);
    } else if (this.props.createData.error) {
      this.props.dispatch(resetCreateVehicleMaintenance());
      signalTransitionError("Update Failed");
    }
  }

  resetHandler(evt) {
    this.setState({ fields: this.state.reset });
  }

  submitHandler(evt) {
    evt.preventDefault();
    // get our new errors

    let fields = this.state.fields;
    //console.log(fields);
    let newErrors = {};
    newErrors["vehicle_id"] = inputErrorHandler("", fields.vehicle_id, true);
    newErrors["note"] = inputErrorHandler("", fields.note, true);
    newErrors["maint_date"] = inputErrorHandler("", fields.maint_date, true);
    newErrors["mileage"] = inputErrorHandler("", fields.mileage, true);
    newErrors["files"] = inputErrorHandler("", fields.files, false);
    if (fields.vehicle_id === "" || fields.vehicle_name === "") {
      newErrors["vehicle_id"] = "Erforderlicher Parameter";
    } else if (fields.vehicle_name !== this.state.value) {
      newErrors["vehicle_id"] = "Bitte wählen Sie eine gültige Fahrzeug-ID aus";
    }

    let errors = removeEmptyValues(newErrors);

    if (Object.keys(errors).length > 0) {
      this.setState({ errors: errors });
    } else {
      let maintenanceData = { maintenance: {} };

      maintenanceData.maintenance.date = fields.maint_date;
      if (fields.files && fields.files.length > 0) {
        maintenanceData.maintenance.invoice = fields.files;
      }
      maintenanceData.maintenance.mileage = fields.mileage;
      maintenanceData.maintenance.note = fields.note;
      maintenanceData.maintenance.vehicle = fields.vehicle_id;
      //console.log(maintenanceData);
      this.props.dispatch(createVehicleMaintenance(maintenanceData));
    }
  }

  changeHandler(evt, name) {
    let value = evt.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: null,
      },
    }));
  }

  datePickerHandler(value) {
    let items = this.state.fields;
    items["maint_date"] = value;
    this.setState({ fields: items });

    let errors = this.state.errors;
    errors["maint_date"] = "";
    this.setState({ errors: errors });
  }

  changeFileHandler(evt) {
    let errors = this.state.errors;
    errors["files"] = "";
    this.setState({ errors: errors });
    if (
      typeof evt.target.files[0] !== "undefined" &&
      typeof evt.target.files[0].name !== "undefined"
    ) {
      this.setState({ fileName: evt.target.files[0].name });
      if (checkFileType(evt.target.files)) {
        getFileContent(evt.target.files)
          .then((data) => {
            if (data.length > getMaxBase64StringSize()) {
              this.props.dispatch(showAlertDialog(FILE_TOO_LARGE_MESSAGE));
              this.setState({ fileName: this.state.defaultName });
              let fields = this.state.fields;
              fields["files"] = "";
              this.setState({ fields: fields });
            } else {
              let fields = this.state.fields;
              fields[
                "files"
              ] = `${this.state.fileName}${FILE_NAME_SEPARATOR}${data}`;
              this.setState({ fields: fields });
            }
          })
          .catch((e) => console.log("Error: ", e.message));
      } else {
        this.props.dispatch(showAlertDialog(FILE_TYPE_MESSAGE));
        this.setState({ fileName: this.state.defaultName });
        let fields = this.state.fields;
        fields["files"] = "";
        this.setState({ fields: fields });
      }
    }
  }

  onValueChange = (newValue) => {
    this.setState({
      value: newValue,
    });
    let errors = this.state.errors;
    errors["vehicle_id"] = "";
    this.setState({ errors: errors });
  };

  onSuggestionsFetchRequested = (value) => {
    this.loadSuggestions(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  loadSuggestions(value) {
    if (value !== "") {
      let vdata = {};
      vdata.attrs = {};
      vdata.conds = { vin: { "-like": "%" + value + "%" } };
      this.props.dispatch(fetchVehicle(vdata));
    }
  }

  loadMoreReferences = () => {
    let meta_data =
      typeof this.props.vehicleData !== "undefined" &&
      typeof this.props.vehicleData.rows !== "undefined" &&
      typeof this.props.vehicleData.rows.meta !== "undefined"
        ? this.props.vehicleData.rows.meta
        : [];

    let vdata = {};
    vdata.attrs = {};
    vdata.conds = meta_data.conds;
    vdata.page = meta_data.page + 1;
    vdata.page_size = meta_data.page_size;
    this.props.dispatch(fetchVehicle(vdata));
  };

  onSelect = (suggestionValue) => {
    const reference = this.state.suggestions.find(
      (r) => r["name"] === suggestionValue
    );
    let fields = this.state.fields;
    fields["vehicle_id"] = reference.id;
    fields["vehicle_name"] = reference.name;
    this.setState({ fields: fields });
  };

  setFieldPlaceholder = () => {
    if (
      typeof this.field.current[0] !== "undefined" &&
      typeof this.field.current[0].children[0] !== "undefined"
    ) {
      this.field.current[0].children[0].setAttribute("placeholder", "Datum");
    }
  };

  render() {
    return (
      <div className="wrapper">
        <div className="main d-flex align-items-start flex-column">
          <h4>
            {" "}
            <ColumnText
              Module={CORE_MODULE}
              Entity={VEHICLE_MAINTENANCE_ENTITY}
            />{" "}
            hinzufügen
          </h4>
        </div>
        <div className="sub_main">
          <Form>
            <Row>
              <Col lg={6}>
                <Form.Group as={Row} controlId="vehicle_id">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_MAINTENANCE_ENTITY}
                      Label={"vehicle"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <AutoRecommend
                      value={this.state.value}
                      onValueChange={this.onValueChange}
                      suggestions={this.state.suggestions}
                      onSuggestionsClearRequested={
                        this.onSuggestionsClearRequested
                      }
                      fetchSuggestions={this.onSuggestionsFetchRequested}
                      onSelect={this.onSelect}
                      placeholder="Wählen Sie Fahrzeug"
                      onScrollEnd={this.loadMoreReferences}
                      disabled={!!this.vehicleId}
                      inputClasses={
                        typeof this.state.errors.vehicle_id !== "undefined" &&
                        this.state.errors.vehicle_id !== ""
                          ? "is-invalid form-control"
                          : "form-control"
                      }
                    />
                    {typeof this.state.errors.vehicle_id !== "undefined" &&
                    this.state.errors.vehicle_id !== "" ? (
                      <div className="has-error">
                        {this.state.errors.vehicle_id}
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="maint_date">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_MAINTENANCE_ENTITY}
                      Label={"date"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <DateTimePicker
                      pickerRef={this.field}
                      callback={() => this.setFieldPlaceholder()}
                      date={this.state.fields.maint_date}
                      defaultDate={this.state.fields.maint_date}
                      format="YYYY-MM-DD"
                      onChange={(e) => this.datePickerHandler(e.date)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.maint_date}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="mileage">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_MAINTENANCE_ENTITY}
                      Label={"mileage"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Mileage"
                      name="mileage"
                      value={this.state.fields.mileage}
                      onChange={(evt) => this.changeHandler(evt, "mileage")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.mileage}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.mileage}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="note">
                  <Form.Label column sm="4">
                    Notiz
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Notiz"
                      name="note"
                      value={this.state.fields.note}
                      onChange={(evt) => this.changeHandler(evt, "note")}
                      onKeyPress={this.onKeyPress}
                      isInvalid={!!this.state.errors.note}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.errors.note}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="invoice">
                  <Form.Label column sm="4">
                    <ColumnText
                      Module={CORE_MODULE}
                      Entity={VEHICLE_MAINTENANCE_ENTITY}
                      Label={"invoice"}
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.File
                      type="file"
                      // className={(this.state.errors.files !== "")?"is-invalid custom-file-label":"form-control custom-file-label"}
                      id="inputGroupFile01"
                      label={this.state.fileName}
                      onChange={(evt) => this.changeFileHandler(evt)}
                      accept="application/pdf, image/*"
                      custom
                      isInvalid={!!this.state.errors.files}
                    />
                    {this.state.errors.files !== "" ? (
                      <div className="has-error">{this.state.errors.files}</div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>

                <div className="form-btn">
                  <Button
                    type="Button"
                    className="btn btn-dark Button"
                    onClick={(evt) => this.submitHandler(evt)}
                  >
                    OK
                  </Button>
                  <Button
                    type="Button"
                    className="btn btn-dark"
                    onClick={(evt) => this.resetHandler(evt)}
                  >
                    Abbrechen
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

MaintenanceAddScreen.propTypes = {};
MaintenanceAddScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  customerData: state.Customer.customerData,
  vehicleData: state.Vehicle.vehicleData,
  localeData: state.Locale.localeData,
  createData: state.Maintenance.createData,
  dialogData: state.Dialog.dialogData,
  maintenanceData: state.Maintenance.maintenanceData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

// We normally do both in one step, like this:
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaintenanceAddScreen);
